import { CurrentUserInfo } from "../../../api/auth";
import { MessagesListItem, isDraftMessageListItem, isPersistedMessageListItem } from "../../redux/chat-state";
import { DraftMessageItem } from './draft-message-item';
import { PersistedMessageItem } from './persisted-message-item';

export interface MessageItemProps {
  item: MessagesListItem,
  currentUser: CurrentUserInfo,
}

export function MessageItem(props: MessageItemProps) {

  const isDraft = isDraftMessageListItem(props.item);
  const isPersisted = isPersistedMessageListItem(props.item);

  if (isDraft) {
    return <DraftMessageItem {...props} />
  }

  if (isPersisted) {
    return <PersistedMessageItem {...props} />
  }

  return null;
}

