import { useEffect, useState } from "react";
import { BasicChatroom } from "../../api/chat";
import { useCent } from "../../cent/context/cent-context";
import { Subscription } from "centrifuge";
import api from "../../api";

/**
 * 
 * use this hook to get centrifugo subscription
 * for this channels chatroom.
 * 
 */
export const useChatroomSubscription = (chatroom: BasicChatroom) => {

  const cent = useCent();
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    if (!cent) {
      return;
    }

    const channelName = chatroom.channel_name;

    // reuse or create subscription
    let sub = cent.getSubscription(channelName);

    if (!sub) {
      sub = cent.newSubscription(channelName, {
        getToken: async () => {
          const { token } = await api.chat.getSubscriptionToken(chatroom.id);
          return token;
        }
      });
    }

    sub.subscribe();
    setSubscription(sub);

  }, [cent, chatroom.id, chatroom.channel_name]);

  return subscription;
};
