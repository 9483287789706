import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { useAuthUser } from "../hooks/useAuthUser";

export const Guest = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const { initialized, user } = useAuthUser();

  // when user info is being fetched
  // render nothing
  if (!initialized) {
    return null;
  }

  
  const isGuest = user === null;
  const userIsAnalystOrTrader = !isGuest && [
    UserRole.analyst, UserRole.trader
  ].includes(user.role);

  // redundant but easy to read
  if (!isGuest && userIsAnalystOrTrader) {
    return (
      <Navigate to={location?.state?.from?.pathname || "/app/dashboard"} />
    );
  }

  // show component
  return <>{children}</>;
};
