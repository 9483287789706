import { Subscription } from "centrifuge";
import { useEffect, useState } from "react";
import api from "../../api";
import { GENERAL_CHANNEL } from "../constants";
import { useCent } from "../context/cent-context"

export const useGeneralChannelSubscription = () => {

  const cent = useCent();
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {

    if (!cent) {
      return;
    }

    // reuse or create subscription
    let sub = cent.getSubscription(GENERAL_CHANNEL);

    if (!sub) {
      sub = cent.newSubscription(GENERAL_CHANNEL, {
        getToken: async () => {
          const { token } = await api.cent.createGeneralChannelSubscriptonToken();
          return token;
        }
      })
    }
    
    sub.subscribe();
    setSubscription(sub);

  }, [cent]);

  return subscription;
}