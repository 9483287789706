import axios from './axios'

export interface CentTokenResponse {
  token: string
}

const centApi = {
  async createConnectionToken() {
    const { data } = await axios.post<CentTokenResponse>('/centrifugo/connection-token');
    return data;
  },

  async createGeneralChannelSubscriptonToken() {
    const { data } = await axios.post<CentTokenResponse>('/centrifugo/subscription-token/general');
    return data;
  },

}

export default centApi;