import axios from 'axios';
import refreshAccessToken from './interceptors/refresh-access-token';
import { BASE_URL } from './constants';
import transformError from './errors';
import addBrokerHeader from './interceptors/add-broker-header';
import attachToken from './interceptors/attach-token';

// axios instance for making api calls
const instance = axios.create({
    baseURL: BASE_URL,
    paramsSerializer: {
        // serialize arrays as arr=1&arr=2 
        indexes: null, 
    }
});

// axios has an issue
// it calls request interceptors in reverse order
// https://github.com/axios/axios/issues/5657

type SuccessInterceptor = Parameters<typeof instance.interceptors.request.use>[0];

const combinedInterceptor : SuccessInterceptor = async (config) => {
    return refreshAccessToken(config)
        .then(attachToken)
        .then(addBrokerHeader)
}

instance.interceptors.request.use(combinedInterceptor);

// // before making api call refresh access token token has expired
// instance.interceptors.request.use(refreshAccessToken);

// // attach token to request
// instance.interceptors.request.use(attachToken);

// // add broker id header
// instance.interceptors.request.use(addBrokerHeader)

// save expiry after login
// instance.interceptors.response.use(saveTokenExpiry);

// transforms errors
instance.interceptors.response.use(null, transformError);

declare module 'axios' {
    interface AxiosRequestConfig {
        needsBrokerId?: boolean;
    }
    interface RawAxiosRequestConfig {
        needsBrokerId?: boolean;
    }
}

export default instance;