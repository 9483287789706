import { Card, Divider, Typography } from "antd";
import { fixDecimalPlaces, formatNumberAuto } from "../../shared/utils";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { useMaskInfoContext } from "../../shared/context/MaskInfoContext";
const { Title, Text } = Typography;

export const AccountPageHeader = () => {
  const { user } = useAuthUser();
  const total = formatNumberAuto(fixDecimalPlaces(user?.total_coins || 0))
  const monthly = formatNumberAuto(fixDecimalPlaces(user?.monthly_coins || 0))
  const extra = formatNumberAuto(fixDecimalPlaces(user?.coin_balance || 0))
  const { masked } = useMaskInfoContext();

  return <Card size="small">
    <Title level={3}>My Account</Title>
    <Text type="secondary">
      Plan: { user?.candefi_membership?.plan?.name || '-' }
    </Text>
    <Divider type="vertical" />
    <Text type="secondary">
      CandeFi Coins <Text type="success"> 
      {
        !masked ? total : '***'
      }
      </Text> 
      {' '}<Text type="secondary">
        ({ !masked ? monthly : '***' } Monthly, &nbsp;{ !masked ? extra : '***' } Extra)
      </Text>
    </Text>
  </Card>
}