import { Skeleton, Spin } from "antd";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { useReferralInfo } from "../hooks/useReferralInfo"
import { Signup } from "./Signup";

export function SignupWithReferral() {
  const { isLoading, error, data } = useReferralInfo();

  if (isLoading) {
    return <div>
      <Skeleton />
    </div>
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  return <Signup />
}