import axios from "./axios";

export type FeeResponse = {
  subscription: number
}

const feesApi = {
  async get() {
    const { data } = await axios.get<FeeResponse>('/fees');
    return data;
  }
}

export default feesApi;