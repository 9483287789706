import axios from "./axios"


export type GetStocksQuoteRequest = {
  ticker_symbol: string
}

export type StocksQuoteResponse = {
  ask_price: number
  ask_volume: number
  bid_price: number
  bid_volume: number
  last_price: number
}

export type LastPriceQuote = {
  last_price: number
}

const stocksQuoteApi = {
  async getQuote(request : GetStocksQuoteRequest) {
    const { data } = await axios.get<StocksQuoteResponse | null>(
      "/stocks-quote",
      {
        params: request,
        needsBrokerId: true,
      }
    )
    return data;
  },

  async getAssetLastPrice(request: GetStocksQuoteRequest) {
    const { data } = await axios.get<LastPriceQuote | null>(
      "/stocks-quote/last-price",
      {
        params: request,
        needsBrokerId: true,
      }
    )
    return data;
  }
}

export default stocksQuoteApi;
