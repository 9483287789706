import { useEmotionCss } from "@ant-design/use-emotion-css"

export const useStretchedTabs = () => {
  return useEmotionCss(({ token })=> ({
    '.ant-tabs-nav-list': {
      width: '99.9%',
      display: 'flex',
      '.ant-tabs-ink-bar': {
        display: 'none',
      },
      '.ant-tabs-tab': {
        flexGrow: 1,
        flexShrink: 1,
        margin: 0,
        letterSpacing: 2,
        '.ant-tabs-tab-btn': {
          textAlign: 'center',
          display: 'block',
          width: '100%',
        },
        '&.ant-tabs-tab-active': {
          borderBottom: `2px solid ${token.colorPrimary}`
        }
      }
    }
  }))
}