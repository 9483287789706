import { List, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { closeSignalsFormatPopup } from "../../redux/chat.slice";
import { Typography } from 'antd';

const { Text } = Typography;

export function SignalsFormatPopup() {
  const open = useAppSelector(state => state.chat.signalsFormatPopup.open);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeSignalsFormatPopup());
  }

  const supportedFormatsOptions = [
    'OPTIONS',
    'Buy NVDA 120C 07/20 @3.5',
    'Buy SQQQ 10 Put 01/15/2024 @2',
  ];

  const supportedFormatsStocks = [
    'STOCKS',
    'Buy SNAP @ $10',
  ];

  return <Modal
    title="Signals Format"
    open={open}
    onCancel={onClose}
    footer={null}
  >
    <div style={{ marginTop: 24 }}>
      <Text style={{ fontSize: '1rem' }}>
      In the Chat, analysts have the ability to directly share signals. However, these signals must adhere to specific formats. Here are the supported formats:
      </Text>
    </div>
    <List
      style={{ marginTop: 24, marginBottom: 24 }}
      size="large"
      bordered
      dataSource={supportedFormatsOptions}
      renderItem={(format) => (
        <List.Item>
           <Text style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} copyable>{format}</Text>
        </List.Item>
      )}
    />

    <List
      style={{ marginTop: 24, marginBottom: 24 }}
      size="large"
      bordered
      dataSource={supportedFormatsStocks}
      renderItem={(format) => (
        <List.Item>
           <Text style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} copyable>{format}</Text>
        </List.Item>
      )}
    />
  </Modal>
}