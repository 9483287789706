import { Avatar, Button, Card } from 'antd';
import { brokerTypeLabels } from '../../api/enum-labels';
import { BrokerAccount, BrokerType } from '../entities/broker-account.entity';
import webullIcon from '../images/webull_icon.png';
import robinhoodIcon from '../images/robinhood_icon.png';
import { Link } from 'react-router-dom';
import {
  EditOutlined
} from '@ant-design/icons';
import styles from './broker-account-card.module.scss';
import { useMaskInfoContext } from '../../shared/context/MaskInfoContext';

const icons : Record<BrokerType, string> = {
  [BrokerType.robinhood]: robinhoodIcon,
  [BrokerType.webull]: webullIcon,
  [BrokerType.tda]: '',
}

const links : Record<BrokerType, string> = {
  [BrokerType.robinhood]: '/robinhood-connect',
  [BrokerType.webull]: '/webull-connect',
  [BrokerType.tda]: '',
}

export const BrokerAccountCard = ({
  account
} : {
  account: BrokerAccount
}) => {
  const { masked } = useMaskInfoContext();

  return <Card className={styles.card}>
    <div className={styles.cardContent}>
      <Card.Meta
        title={brokerTypeLabels[account.type]}
        description={ !masked ? account.username : '**************'}
        avatar={
          <Avatar 
            src={icons[account.type]} 
            size="large"
          />
        }
      />
      <Link to={links[account.type]}>
        <Button icon={<EditOutlined/>}>
          Reconnect
        </Button>
      </Link>
    </div>
  </Card>
}