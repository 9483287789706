import differenceInHours from "date-fns/differenceInHours";
import { MyCandeFiMembership } from "../api/candefi-memberships";
import { utcToZonedTime } from "date-fns-tz";
import { parseISO } from "date-fns";

export const DOWNGRADE_TIME_WINDOW_HOURS = 48;

export function checkCanDowngradeNow(currentMembership: MyCandeFiMembership) {
  // probably free plan
  if (currentMembership.current_billing_cycle_end === null) {
    return true
  }

  const endDateEst = parseISO(currentMembership.current_billing_cycle_end);
  const nowEST =  utcToZonedTime(new Date(), "America/New_York");
  const hours = differenceInHours(endDateEst, nowEST);
  return hours <= DOWNGRADE_TIME_WINDOW_HOURS;
}