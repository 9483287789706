import { Line } from '@ant-design/plots';
import { useState } from 'react';
import useSWR from 'swr';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import api from '../api';
import { ListEarningsRequest } from '../api/analyst-earnings';
import format from 'date-fns/format';
import { toLineChartData, transformApiResponse } from './utils';

export function EarningsChart({
  planIds
} : {
  planIds?: number[]
}) {

  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 6)),
    new Date(),
  ]);

  const {
    data,
    isLoading,
    error
  } = useSWR(
    [ 'analysts-earnings', 'by-month', planIds, ...dateRange ],
    async function(key) {
      const [_,__, planIds, start, end] = key;
      const startString = format(start, "yyyy-MM-dd");
      const endString = format(end, "yyyy-MM-dd");
      const request = new ListEarningsRequest(startString, endString);
      request.plan_ids = planIds;
      const response = await api.analystEarnings.getMonthlyEarnings(request);
      return toLineChartData(transformApiResponse(start, end, response));
    } 
  );


  return <div>
    <Line
      data={data || []}
      xField='date'
      xAxis={{
        type: 'time'
      }}
      yField='earning'
      seriesField='plan'
    />
  </div>
}