import { Alert, Button, Form, Input, Typography } from "antd"
import api from "../../api"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useState } from "react";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { GenericStatusResponse } from "../../api/types";
import useSWRMutation from 'swr/mutation';
import { useSWRConfig } from "swr";
import getStore from "../store";
import { getDefaultBrokerInfo } from "../../trade-preference/utils";
import selectedBrokerRepo from "../../brokers/repos/selected-broker-repo";


const { Text, Title } = Typography;

type VerifyEmailFormStep = 'send-otp' | 'verify-otp' | 'success';


export const VerifyEmailForm = () => {
  const [searchParams, _] = useSearchParams();
  const email = searchParams.get('email');

  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState<VerifyEmailFormStep>('send-otp');
  const [sendOTPResponse, setSendOtpResponse] = useState<GenericStatusResponse | null>(null);

  const { mutate } = useSWRConfig();
  
  const onVerified = async () => {
    setStep('success');
    // await mutate('/auth/me'); 
    let redirectTo = location?.state?.from?.pathname || "/app/dashboard"
    redirectTo = `${redirectTo}?emailVerified=true`;
    navigate(redirectTo, {
      replace: true
    });
  }
  
  if (email === null) {
    return null;
  }

  if (step === 'send-otp') {
    return <div>
      <Title style={{ textAlign: 'center' }} level={1}>
        Send Verification OTP
      </Title>
      <SendOTPUi
        email={email}
        onSent={(response) => {
          setStep('verify-otp');
          setSendOtpResponse(response);
        }}
      />
    </div>
  }

  if (step === 'verify-otp') {
    return <div>
      <Title style={{ textAlign: 'center' }} level={1}>
        Verify Email
      </Title>
      {
        setSendOtpResponse !== null && <Alert
          message={sendOTPResponse?.message}
        />
      }
      <div style={{ marginTop: 16 }}>
        <VerifyOTPUi
          email={email}
          onVerified={onVerified}
          onResend={() => {
            setStep('send-otp');
          }}
        />
      </div>
      
    </div>
  }

  return null;
}


const SendOTPUi = ({
  email,
  onSent,
} : {
  email: string,
  onSent: (status: GenericStatusResponse) => void,
}) => {

  const {
    trigger,
    error,
    isMutating
  } = useSWRMutation(['/auth/email-verification/send', email], (key) => {
    return api.auth.sendVerificationEmail(key[1]);
  }, {
    onSuccess: (data) => {
      onSent(data)
    }
  });

  return <div>
    <Form 
      layout="vertical"
      onFinish={() => {
        trigger();
      }}
      initialValues={{ email }}>
      <ErrorMessage error={error} />
      <Form.Item
        label="Email"
        name="email"
      >
        <Input disabled />
      </Form.Item>
  
      <Form.Item>
        <Button
          style={{ width: '100%' }}
          loading={isMutating}
          disabled={isMutating}
          type="primary"
          size='large'
          htmlType="submit"
        >
          Send OTP
        </Button>
      </Form.Item>
    </Form>
  </div>
}

const VerifyOTPUi = ({ 
  email,
  onVerified,
  onResend,
} : {
  email: string
  onVerified: () => void,
  onResend: () => void,
}) => {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);

  return <div>
    <Form<{ otp: string }>
      onFinish={async (data) => {
        setSubmitting(true);
        setError(null);
        try {
          const tokens = await api.auth.verifyEmail(email, data.otp);

          const store = getStore()
          await store.setTokens(tokens);

          const user = await api.auth.me()
          await store.setUser(user);

          const defaultBrokerInfo = getDefaultBrokerInfo(user?.trade_preference);
          if (defaultBrokerInfo)  {
            await selectedBrokerRepo.setBroker(defaultBrokerInfo);
          }

          onVerified();
        } catch (e) {
          setError(e);
        } finally {
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      <ErrorMessage error={error} />
      <Form.Item
        label="OTP"
        name="otp"
        rules={[
          { required: true, message: 'otp is required' },
        ]}
      >
        <Input />
      </Form.Item>
  
      <Form.Item>
        <Button
          style={{ width: '100%' }}
          loading={submitting}
          disabled={submitting}
          type="primary"
          size='large'
          htmlType="submit"
        >
          Verify OTP
        </Button>
      </Form.Item>

      <Form.Item>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Typography.Link onClick={(e) => {
            e.preventDefault();
            onResend();
          }}>
            Didn't receive OTP? Resend.
          </Typography.Link>
        </div>
      </Form.Item>

    </Form>
  </div>
}