import React from "react";
import { Alert } from "antd";
import api from "../../api";
import useSWR from "swr";
import { ListMyMembershipRequest } from "../../api/membership";
import { MembershipStatus } from "../../api/enums";

export const AlreadyPurchasedAlert = ({ planId }: { planId: number; }) => {
  const { data: myMemberships } = useSWR(
    `/membership/me?status=active&membership_plan_id=${planId}`,
    async () => {
      const request = new ListMyMembershipRequest();
      request.page = 1;
      request.per_page = 10;
      request.status = MembershipStatus.Active;
      request.membership_plan_id = Number(planId);
      const response = await api.membership.listMyMemberships(request);
      return response.data;
    }
  );

  if (!myMemberships || myMemberships.length <= 0) {
    return null;
  }

  return (
    <Alert
      style={{ marginBottom: 10 }}
      message="Already purchased? Get ready to experience it again!"
      type="success"
      showIcon
      closable />
  );
};
