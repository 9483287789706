import { Card, Col, Row, Select, Typography } from "antd"
import { EarningsChart } from "../../analyst-earnings/earnings-chart"
import { SubscribersMembershipsTable } from "../../subscribers/components/SubscribersMembershipsTable"
import { SubscribersPaymentsTable } from "../../subscribers/components/SubscribersPaymentsTable"
import { useSearchParams } from "react-router-dom"
import { useAuthUser } from "../../auth/hooks/useAuthUser"

const { Title } = Typography;

export const SignalProviderEarningStats = () => {
  const [ searchParams, _] = useSearchParams();

  const planIds = searchParams.getAll('plan_ids')
    .map(id => parseInt(id))
    .filter((n) => !isNaN(n));

  return <div>
    <Row gutter={[8, 8]} align='stretch'>
      <Col span={24}>
        <Card>
          <StatsPageFilter />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small" title='6 months earnings'>
          <EarningsChart planIds={planIds} />
        </Card>
      </Col>
      <Col span={24} xl={{ span: 12 }} style={{ display: 'flex', alignItems: 'stretch' }}>
        <Card size="small" title="Subscriptions" style={{ width: '100%' }}>
          <SubscribersMembershipsTable planIds={planIds} />
        </Card>
      </Col>
      <Col span={24} xl={{ span: 12 }} style={{ display: 'flex', alignItems: 'stretch' }}>
        <Card size="small" title="Payment History" style={{ width: '100%' }}>
          <SubscribersPaymentsTable planIds={planIds} />
        </Card>
      </Col>
    </Row>
  </div>
}

function useSignalProvidersPlans() {
  const { user, initialized } = useAuthUser();
  const signalProvider = user?.signal_providers[0];
  const membershipPlans = (signalProvider?.membership_plans || []);
  return membershipPlans;
}

const StatsPageFilter = () => {

  const plans = useSignalProvidersPlans();
  const [ searchParams, setSearchParams] = useSearchParams();

  const options = plans.map(plan => ({
    label: plan.name,
    value: plan.id.toString(),
  }));

  const planIds = searchParams.getAll('plan_ids');
  const selectedOptions = options.filter(option => planIds.includes(option.value));

  const handleChange = (options: { label: string, value: string }[]) => {
    setSearchParams({
      'plan_ids': options.map(o => o.value),
    });
  }

  return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 8 }}>
    <div style={{ flexGrow: 1, flexShrink: 0 }}>
      <Title level={4} style={{ margin: 0 }}>
        Plans
      </Title>
    </div>
    <Select
      style={{
        width: 300
      }}
      labelInValue={true}
      optionLabelProp="label"
      mode="multiple"
      allowClear
      options={options}
      onChange={handleChange}
      value={selectedOptions}
    />
  </div>
}