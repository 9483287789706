import axios from 'axios';
import { BASE_URL } from './constants';
import transformError from './errors';

// axios instance for refreshing access token
const refreshInstance = axios.create({
    baseURL: BASE_URL,
});

// transforms errors
refreshInstance.interceptors.response.use(null, transformError);

export default refreshInstance;