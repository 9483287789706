import axios from './axios';
import { GenericIDResponse, GenericStatusResponse } from './types';

interface RobinhoodConnectRequestBase {
  username: string
  password: string
}

export interface RobinhoodCheckSessionRequest extends RobinhoodConnectRequestBase {}

export interface RobinhoodLoginMFARequest extends RobinhoodConnectRequestBase {
  code: string
}

export interface RobinhoodLoginChallengeRequest extends RobinhoodConnectRequestBase {
  challenge_id: string
  code: string
}

export interface RobinhoodCheckSessionResponse {
  is_valid: boolean
  is_mfa: boolean
  is_challenge: boolean
  challenge: RobinhoodChallenge
}

export interface RobinhoodChallenge {
  id: string
  type: string
  total: number
  remaining: number
}

const robinhoodConnect = {
  async createConnectSession() {
    const { data } = await axios.post<GenericIDResponse>('/robinhood/connect-sessions');
    return data;
  },

  async checkLogin(id: number, request: RobinhoodCheckSessionRequest) {
    const { data } = await axios.patch<RobinhoodCheckSessionResponse>(
      `/robinhood/connect-sessions/${id}/check-login`,
      request
    );
    return data;
  },

  async loginMFA(id: number, request: RobinhoodLoginMFARequest) {
    const { data } = await axios.patch<GenericStatusResponse>(
      `/robinhood/connect-sessions/${id}/login-mfa`,
      request
    );
    return data;
  },

  async loginChallenge(id: number, request: RobinhoodLoginChallengeRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(
      `/robinhood/connect-sessions/${id}/login-challenge`,
      request
    );
    return data;
  },
}

export default robinhoodConnect;