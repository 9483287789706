import { createContext, useContext, useState } from "react";
import api from "../../api";
import { RobinhoodChallenge } from "../../api/robinhood-connect"
import { GenericStatusResponse } from "../../api/types";

export enum RobinhoodConnectStep {
  Credentials = 'credentials',
  MFA = 'mfa',
  Complete = 'complete'
}

export interface RobinhoodConnectState {
  step: RobinhoodConnectStep;
  sessionId: number | null;
  username: string | null;
  password: string | null;
  challenge: RobinhoodChallenge | null;
  checkLogin: (username: string, password: string) => Promise<void>;
  checkMFA: (code: string) => Promise<GenericStatusResponse>;
}

const initialState : RobinhoodConnectState = {
  step: RobinhoodConnectStep.Credentials,
  sessionId: null,
  username: null,
  password: null,
  challenge: null,
  checkLogin(username, password) {
    throw new Error('not implemented')
  },
  checkMFA(code) {
    throw new Error('not implemented')
  },
}

const RobinhoodConnectContext = createContext<RobinhoodConnectState>(initialState);

export const RobinhoodConnectContextProvider = ({ children } : { children: React.ReactElement }) => {
  const [step, setStep] = useState(RobinhoodConnectStep.Credentials);
  const [sessionId, setSessionId] = useState<number | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [challenge, setChallenge] = useState<RobinhoodChallenge | null>(null);

  const getSessionId = async () => {
    if (!sessionId) {
      const { id } = await api.robinhoodConnect.createConnectSession();
      setSessionId(id);
      return id;
    }
    return sessionId;
  }

  const checkLogin = async (username: string, password: string) => {
    const sessionId = await getSessionId();

    const response = await api.robinhoodConnect.checkLogin(sessionId, {
      username,
      password,
    });
    
    if (!response.is_valid) {
      throw new Error("invalid username or password");
    }

    setUsername(username);
    setPassword(password);
    setChallenge(response.challenge);
    setStep(RobinhoodConnectStep.MFA);
  }

  const checkMFA = async (code: string) => {

    if (sessionId === null || username === null || password === null) {
      throw new Error("login first");
    }

    if (challenge === null) {
      const response = await api.robinhoodConnect.loginMFA(sessionId, {
        password,
        username,
        code
      });
      if (response.success) {
        setStep(RobinhoodConnectStep.Complete);
      }
      return response;
    }

    const response = await api.robinhoodConnect.loginChallenge(sessionId, {
      password,
      username,
      code,
      challenge_id: challenge.id,
    });

    if (response.success) {
      setStep(RobinhoodConnectStep.Complete);
    }

    return response;
  }

  const state : RobinhoodConnectState = {
    step,
    sessionId,
    username,
    password,
    challenge,
    checkLogin,
    checkMFA,
  }

  return <RobinhoodConnectContext.Provider value={state}>
    { children }
  </RobinhoodConnectContext.Provider>
}

export const useRobinhoodConnectContext = () => useContext(RobinhoodConnectContext);