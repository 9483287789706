import useSWR from 'swr';
import api from '../../api';
import { Card } from 'antd';
import { ErrorMessage } from '../../shared/components/ErrorMessage';
import { AnalystSignupRequestStatusSection } from '../../analyst-signup-request/components/AnalystSignupRequestStatusSection';
import { BecomeAnalystIntroSection } from '../../analyst-signup-request/components/BecomeAnalystIntroSection';

export const AnalystApplicationCard = () => {
  const {
    isLoading, error, data: signupRequest,
  } = useSWR('/analyst-signup-request/latest',
    () => api.analystSignupRequest.getLatest()
  );

  // loading
  if (isLoading) {
    return <Card loading></Card>;
  }

  // error
  if (!!error) {
    return <Card>
      <ErrorMessage error={error} />
    </Card>;
  }

  // traders has never requesed before
  if (signupRequest === null || signupRequest === undefined) {
    return <Card>
      <BecomeAnalystIntroSection />
    </Card>;
  }

  // trader has requested before
  return <Card>
    <AnalystSignupRequestStatusSection request={signupRequest} />
  </Card>;
};
