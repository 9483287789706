import { Mutex } from 'async-mutex';

// Message list needs to be strictly sorted
// when list is modified concurrently it breaks
// sort order. Or introduces duplicate elements.
// This is required for finding next / prev cursors as well.
// So we are using mutex to do these operations.
// All operations that modify messages array should use this mutex.
// We could use some implementation of sorted set, 
// but that could be incompatible with redux because of its mutable nature.
const mutex = new Mutex();

export default mutex;