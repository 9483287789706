import { Card } from "antd"
import { SignalProvider } from "../../api/signal-providers"

export const SignalProviderHeaderCard = ({
  signalProvider
} : {
  signalProvider: SignalProvider
}) => {
  return <Card bordered={false}>
    <Card.Meta
      title={signalProvider?.name}
      description={`@${signalProvider?.username}`}
    />
  </Card>
}