import { SpendLimit, SpendLimitType } from "./types";

export async function spendLimitValidator(_: unknown, value: SpendLimit) {

  // type should be present
  if (value.type === null) {
    throw new Error("please select spend limit type");
  }


  // validate quantity
  if (value.type === SpendLimitType.DefaultQuantity) {

    // should be present
    if (value.value === null) {
      throw new Error("please select quantity");
    }

    // should be int
    const isInt = (value.value % 1) === 0;
    if (!isInt) {
      throw new Error("quantity must be integer");
    }

    // should be atleast 1
    if (value.value < 1) {
      throw new Error("quantity must be atleast 1");
    }

  }

  // validate max amount
  if (value.type === SpendLimitType.MaxAmount) {

    // should be present
    if (value.value === null) {
      throw new Error("please select max amount");
    }

    // should be alteast 1
    if (value.value < 1) {
      throw new Error("max amount must be atleast 1");
    }

  }
}
