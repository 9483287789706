import axios from "./axios";
import { Direction } from "./enums";

export interface IsOptionableRequest {
  ticker_symbol: string
}

export interface IsOptionableResponse {
  ticker_symbol: string
  is_optionable: boolean
}

export interface GetStrikePricesRequest {
  ticker_symbol: string
  expiration_date: string
  direction: Direction,
}

export interface GetExpirationDatesRequest {
  ticker_symbol: string
}

export interface GetOptionsQuoteRequest {
  ticker_symbol: string
  expiration_date: string
  direction: Direction
  strike_price: number
}

export interface GetOptionsQuoteResponse {
  price: number
}

const optionsChainApi = {
  async isOptionable(request : IsOptionableRequest) {
    const { data } = await axios.get<IsOptionableResponse>(
      `/options-chain/is-optionable`,
      {
        params: request,
        needsBrokerId: true,
      }
    )
    return data;
  },

  async getExpirationDates(request : GetExpirationDatesRequest) {
    const { data } = await axios.get<string[]>(
      `/options-chain/expiration-dates`,
      {
        params: request,
        needsBrokerId: true,
      }
    )
    return data;
  },

  async getStrikePrices(request : GetStrikePricesRequest) {
    const { data } = await axios.get<number[]>(
      `/options-chain/strike-prices`,
      {
        params: request,
        needsBrokerId: true,
      }
    )
    return (data || []).sort((a, b) => a - b);
  },

  async getOptionsQuoteResponse(request : GetOptionsQuoteRequest) {
    const { data } = await axios.get<GetOptionsQuoteResponse>(
      `/options-chain/quote`,
      {
        params: request,
        needsBrokerId: true,
      }
    )
    return data;
  }
}

export default optionsChainApi;