import useSWR from 'swr';
import api from '../../api';
import { useEffect } from 'react';
import getStore from '../store';
import { ApiError } from '../../api/errors';
import { logout } from '../utils';

export function SyncUser() {

  useSWR('/auth/me', () => {
    return api.auth.me();
  }, {
    onSuccess(user) {
      getStore().setUser(user);
    },
    onError(err) {
      if (err instanceof ApiError && err.httpStatusCode === 401) {
        logout();
      }
    },
  });

  return null;
}