import { useEmotionCss } from "@ant-design/use-emotion-css";
import { CandeFiMembershipPlanBasic } from "../api/candefi-memberships";
import { FeatureRow } from "./plan-feature-row";

export function PlanFeatures({ plan } : { plan: CandeFiMembershipPlanBasic }) {
  return <div style={{ display: 'flex', flexDirection: 'column'}} >
    <FeatureRow included={true} text={`${plan.monthly_coins} Monthly Coins`} />
    <FeatureRow included={true} text="Copy Trading" />
    <FeatureRow included={plan.can_autotrade} text={`Auto Trading`} />
    <FeatureRow included={plan.can_autotrade} text={`${plan.max_autotrade_bots} Autotrade Bots`} />
    <FeatureRow included={plan.can_buy_extra_coins} text={`Buy Extra Coins`} />
  </div>
}

export function PlanFeaturesGrid({ plan } : { plan: CandeFiMembershipPlanBasic }) {
  const gridClass = useEmotionCss(({ token }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr', 
    [`@media (min-width: ${token.screenMD}px)`]: {
      gridTemplateColumns: 'repeat(2, 1fr)', 
    }
  }))

  return <div className={gridClass}>
    <FeatureRow included={true} text={`${plan.monthly_coins} Monthly Coins`} />
    <FeatureRow included={true} text="Copy Trading" />
    <FeatureRow included={plan.can_autotrade} text={`Auto Trading`} />
    <FeatureRow included={plan.can_autotrade} text={`${plan.max_autotrade_bots} Autotrade Bots`} />
    <FeatureRow included={plan.can_buy_extra_coins} text={`Buy Extra Coins`} />
  </div>
}