import { Select } from "antd";
import { TimeInForce } from "../../../api/enums";
import { timeInForceLabels, timeInForceShortLabels } from "../../../api/enum-labels";

export const TIFInput = ({
  value,
  setValue,
  options,
  defaultValue,
}: {
  value: TimeInForce | null;
  setValue: (value: TimeInForce) => void;
  options: TimeInForce[];
  defaultValue?: TimeInForce | null;
}) => {
  const selectOptions = options.map((option) => ({
    label: timeInForceShortLabels[option],
    value: option,
  }));

  return (
    <Select
      style={{ width: "100%", minWidth: 100 }}
      placeholder="TIF"
      defaultValue={defaultValue}
      options={selectOptions}
      value={value}
      onChange={(value) => setValue(value)}
    />
  );
};
