import useSWR from 'swr';
import api from '../../../api';
import { Card, Skeleton } from 'antd';
import { AutotradePreferenceForm } from './form';
import { UsersAutotradePreference } from '../../../api/autotrade-preference';
import { ErrorMessage } from '../../../shared/components/ErrorMessage';

export function AutotradePreferenceFormSection({
  onSuccess
}:{
  onSuccess?: () => void
}) {
  const {
    data: preference,
    mutate,
    isLoading,
    error
  } = useSWR('/autotrade-preference', api.autotradePreference.fetch);

  const handleSuccess = (data: UsersAutotradePreference) => {
    mutate(data);
    if (onSuccess) {
      onSuccess();
    }
  }

  if (isLoading) {
    return <Skeleton />
  }

  if (!!error) {
    return <ErrorMessage error={error} />
  }

  return <div>
    <AutotradePreferenceForm
      initialValues={preference!}
      onSuccess={handleSuccess}
    />
  </div>
}