import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import getStore from "../../auth/store";
import selectedBrokerRepo from "../../brokers/repos/selected-broker-repo";
import { getDefaultBrokerInfo } from "../../trade-preference/utils";

export function Impersonate() {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {

    const impersonate = async () => {
      const tokens = await api.impersonate.impersonate({
        token: token as string,
      });

      const store = getStore()
      await store.setTokens(tokens);
      
      const user = await api.auth.me()
      await store.setUser(user);

      const defaultBrokerInfo = getDefaultBrokerInfo(user?.trade_preference);
      if (defaultBrokerInfo)  {
        await selectedBrokerRepo.setBroker(defaultBrokerInfo);
      }

      navigate('/app/dashboard');
    }
    
    impersonate();
  }, [token, navigate])

  return null;
}