import { Button, Form, Input, Typography } from "antd"
import { MailOutlined } from '@ant-design/icons';
import { useState } from "react";
import { useRobinhoodConnectContext } from "../../context/RobinhoodConnectContext";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";

export const Login = () => {

  const [error, setError] = useState<unknown>(null);
  const [submitting, setSubmitting] = useState(false);

  const {
    checkLogin
  } = useRobinhoodConnectContext();

  return <div>
    <div style={{ textAlign: 'center', marginBottom: 24}}>
      <Typography.Title level={2}>
        Login
      </Typography.Title>
      <Typography.Text type="secondary">
        Enter email and password of your robinhood account
      </Typography.Text>
    </div>
    <Form<{ username: string, password: string }>
      layout="vertical"
      onFinish={async (values) => {
        try {
          setError(null);
          setSubmitting(true);
          await checkLogin(values.username, values.password);
        } catch (e) {
          setError(e);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <ErrorMessage error={error} />
      <Form.Item
        label="Email"
        name="username"
        rules={[
          { required: true, message: 'email is required' },
          { type: 'email', message: 'must be a valid email' },
        ]}
      >
        <Input
          addonBefore={<MailOutlined />} />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'password is required' },
        ]}
        required
      >
        <Input.Password />
      </Form.Item>
      <div>
        <Button 
          htmlType="submit"
          loading={submitting} 
          size="large" 
          type="primary"
          block
        >
          Login
        </Button>
      </div>
    </Form>
  </div>
}