import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { Typography } from 'antd';
const { Title, Paragraph, Text } = Typography;

function useSignalProviderLink() {
  const { user } = useAuthUser();
  const hasProvider = user?.signal_providers && user?.signal_providers.length > 0;

  if (!hasProvider) {
    return null;
  }

  return window.location.origin + `/app/signal-providers/${user?.signal_providers[0].username}`;
}

export function SignalProviderLink() {
  const { user } = useAuthUser();
  const link = useSignalProviderLink();

  const hasProvider = user?.signal_providers && user?.signal_providers.length > 0;
  if (!hasProvider) {
    return null;
  }

  return<Text copyable={{ text: link! }}>
    { link }
  </Text>
}
