import { Radio, Select } from "antd";
import { timeInForceShortLabels } from "../../../api/enum-labels";
import { TimeInForce } from "../../../api/enums";
import { useRadioGroupStyle } from "../../../shared/styles/useRadioGroup";

export const TIFInput = ({
  value,
  setValue,
  options,
} : {
  value: TimeInForce | null,
  setValue: (value: TimeInForce) => void,
  options: TimeInForce[],
}) => {

  const selectOptions = options.map((option) => ({
    label: timeInForceShortLabels[option],
    value: option,
  }));

  const radioGroup = useRadioGroupStyle();
  return  <Radio.Group
    className={radioGroup}
    optionType="button"
    buttonStyle="outline"
    options={selectOptions}
    onChange={(event) => setValue(event.target.value)}
    value={value}
  />;
}