import { ChatState, MessageCursor, MessagesListItem, PersistedMessageListItem, isPersistedMessageListItem } from "../chat-state";

function findLast<T>(arr: T[], predicate: (value: T) => boolean) {
  for (let i = arr.length - 1; i > 0; i--) {
    if (predicate(arr[i])) {
      return arr[i];
    }
  }
  return undefined;
}

function getCursors(messages: Array<MessagesListItem>) : { 
  prev: MessageCursor | null, 
  next: MessageCursor | null 
} {

  const oldestItem = messages.find(isPersistedMessageListItem);
  const newestItem = findLast(messages, isPersistedMessageListItem) as PersistedMessageListItem;

  return {
    prev: oldestItem?.cursor || null,
    next: newestItem?.cursor || null,
  }
}

export function updateCursors(list: MessagesListItem[], state: ChatState) {
  if (state.selectedChatroomMessages === null) {
    return;
  }
  const cursors = getCursors(list);
  state.selectedChatroomMessages.prev.cursor = cursors.prev;
  state.selectedChatroomMessages.next.cursor = cursors.next;
}