import { LoginResponse } from './auth';
import axios from './axios';

export type LoginWithTokenRequest = {
  token: string
}

const impersonateApi = {
  async impersonate(request: LoginWithTokenRequest) {
    const { data } = await axios.post<LoginResponse>('/impersonate', request);
    return data;
  }
}

export default impersonateApi;