import { List, Table, Typography } from "antd";
import { useState } from "react";
import useSWR from 'swr';
import api from "../../api";
import { ListSubscribersMembershipsRequest } from "../../api/subscribers";
import { memberhshipStatusLabels } from "../../api/enum-labels";
const { Text } = Typography;

export function SubscribersMembershipsTable({
  planIds
}: {
  planIds: Array<number> | null;
}) {
  const pageSize = 10;
  const [page, setPage] = useState(1);

  const {
    data: memberships, isLoading,
  } = useSWR(
    ['subscribers', 'memberships', planIds, page, pageSize],
    function (key) {
      const [_, __, planIds, page, per_page] = key;
      const request = new ListSubscribersMembershipsRequest();
      request.page = page;
      request.per_page = per_page;
      request.plan_ids = planIds || undefined;
      return api.subscribers.listMemberships(request);
    }
  );

  return <Table
    size="small"
    loading={isLoading}
    dataSource={memberships?.data || []}
    pagination={{
      pageSize,
      current: page,
      total: memberships?.total,
    }}
    onChange={(pagination, filters, sorter, extra) => {
      if (pagination.current) {
        setPage(pagination?.current);
      }
    }}
    columns={[
      {
        key: 'status',
        title: 'Status',
        render: (_, entity) => {
          return memberhshipStatusLabels[entity.status];
        },
      },
      {
        key: 'plan',
        dataIndex: ['membership_plan', 'name'],
        title: 'Plan',
      },
      {
        key: 'user',
        dataIndex: 'user',
        title: 'User',
        render: (_, entity) => {
          return entity.user.name;
        }
      }
    ]} />;
}
