import { Button, Form, Input } from "antd"
import { ChangeBotPasswordRequest } from "../../../api/autotrade-bots"
import { useState } from "react";
import api from "../../../api";

export function ChangeBotPasswordForm({
  botId,
  onSuccess
}: {
  botId: number,
  onSuccess?: () => void
}) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const handleFormSubmit = async (data: ChangeBotPasswordRequest) => {
    try {

      setSubmitting(true);
      const response = await api.autotradeBots.changePassword(botId, {
        password: data.password
      });
      if (onSuccess) {
        onSuccess();
      }

    } catch(e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  }
  return <Form<ChangeBotPasswordRequest>
    layout="vertical"
    onFinish={handleFormSubmit}
  >
    <Form.Item<String>
        name="password"
        label="Password"
        rules={[{required: true, message: 'Required'}]}
      >
        <Input.Password />
    </Form.Item>
    <Form.Item>
      <Button
        style={{ width: '100%' }}
        loading={submitting}
        disabled={submitting}
        type="primary"
        size='large'
        htmlType="submit"
      >
        Change Password
      </Button>
    </Form.Item>
  </Form>
}