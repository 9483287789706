import { Radio, Select } from "antd";
import { sideLabels } from "../../../api/enum-labels";
import { Side } from "../../../api/enums";
import { useGreenRedRadioGroupStyle } from "../../../shared/styles/useRadioGroup";

export const SideInput = ({
  options,
  value,
  setValue
} : {
  value: Side | null,
  options : Side[],
  setValue: (value: Side) => void
}) => {

  const selectOptions = options.map((option) => ({
    label: sideLabels[option],
    value: option,
  }))

  const radioClass = useGreenRedRadioGroupStyle();
  return <Radio.Group
    className={radioClass}
    options={selectOptions}
    optionType="button"
    buttonStyle="solid"
    size="large"
    onChange={(event) => setValue(event.target.value as Side)}
    value={value}
  />;
}