import { Layout } from "antd";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { SelectedBrokerProvider } from "../../brokers/context/SelectedBrokerContext";
import { SideMenu } from "../components/SideMenu";
import { MainHeader } from "../components/MainHeader";
import { ShareTradeContextProvider } from "../../trade/context/share-trade-context";
import { Protected } from "../../auth/components/Protected";
import { SyncUser } from "../../auth/components/SyncUser";
import { HelpButton } from "../components/HelpButton";

const { Content } = Layout;

export const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Protected>
      <SyncUser />
      <SelectedBrokerProvider>
        <ShareTradeContextProvider>
          <Layout hasSider>
            <SideMenu 
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
            <Layout style={ collapsed ? { marginLeft: 80 } : { marginLeft: 250 } }>
              <MainHeader
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
              <Content>
                <HelpButton />
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </ShareTradeContextProvider>
      </SelectedBrokerProvider>
    </Protected>
  )
};
