import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import fetchMyChatrooms from "./thunks/fetch-my-chatrooms.thunk";
import { appendMessagesInternalReducer } from "./reducers/append-messages-internal.reducer";
import { prependMessagesInternalReducer } from "./reducers/prepend-messages-internal.reducer";
import { Draft, initialChatState, initialMessagesListState } from "./chat-state";
import { appendDraftMessageInternalReducer } from "./reducers/append-draft-message-internal.reducer";
import { BasicChatroomMessageSignal, BasicChatroomWithPermissionSummary, ChatModerator, DirectMessageRequestStatus } from "../../api/chat";

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialChatState,
  reducers: {
    reset: () => initialChatState,

    setMyChatrooms(state, action: PayloadAction<BasicChatroomWithPermissionSummary[]>) {
      state.myChatrooms.loading = false;
      state.myChatrooms.data = action.payload;
    },

    setModerators(state, action: PayloadAction<ChatModerator[]>) {
      state.moderators = action.payload
    },

    setSelectedChatroomId(state, action: PayloadAction<string | null>) {
      state.selectedChatroomId = action.payload;
    },

    appendMessages: appendMessagesInternalReducer,
    prependMessages: prependMessagesInternalReducer,

    initialMessagesLoading(state) {
      state.selectedChatroomMessages = initialMessagesListState;
    },

    initialMessagesError(state, action: PayloadAction<unknown>) {
      if (state.selectedChatroomMessages === null) {
        return
      }
      state.selectedChatroomMessages.initial.error = action.payload;
    },

    initialMessagesSuccess(state) {
      if (state.selectedChatroomMessages === null) {
        return;
      }

      state.selectedChatroomMessages.initial.error = null;
      state.selectedChatroomMessages.initial.loading = false;
    },

    prevMessagesLoading(state) {
      const prev = state.selectedChatroomMessages?.prev || null
      if (prev === null) {
        return;
      }
      prev.loading = true;
      prev.error = null;
    },

    prevMessagesError(state, action: PayloadAction<unknown>) {
      const prev = state.selectedChatroomMessages?.prev || null;
      if (prev === null) {
        return;
      }
      prev.loading = false;
      prev.error = action.payload;
    },

    prevMessagesSuccess(state) {
      const prev = state.selectedChatroomMessages?.prev || null;
      if (prev === null || state.selectedChatroomMessages === null) {
        return;
      }

      prev.loading = false;
      prev.error = null;
    },

    nextMessagesLoading(state) {
      const next = state.selectedChatroomMessages?.next || null
      if (next === null) {
        return;
      }
      next.loading = true;
      next.error = null;
    },

    nextMessagesError(state, action: PayloadAction<unknown>) {
      const next = state.selectedChatroomMessages?.next || null
      if (next === null) {
        return;
      }
      next.loading = false;
      next.error = action.payload;
    },

    nextMessagesSuccess(state) {
      const next = state.selectedChatroomMessages?.next || null;
      if (next === null || state.selectedChatroomMessages === null) {
        return;
      }

      next.loading = false;
      next.error = null;
    },

    setPrevHasMore(state, action: PayloadAction<boolean>) {
      const prev = state.selectedChatroomMessages?.prev || null;
      if (prev === null) {
        return;
      }
      prev.hasMore = action.payload;
    },

    setNextHasMore(state, action: PayloadAction<boolean>) {
      const next = state.selectedChatroomMessages?.next || null;
      if (next === null) {
        return;
      }
      next.hasMore = action.payload;
    },

    addOrUpdateDraft(state, action: PayloadAction<Draft>) {
      if (!state.selectedChatroomMessages?.drafts) {
        return;
      }

      const draft = action.payload;
      state.selectedChatroomMessages.drafts[draft.id] = draft;
    },

    incrementUnreadMessageCount(state, action: PayloadAction<{ chatroomId: string, incrementBy?: number }>) {
      const { chatroomId, incrementBy=1 } = action.payload;
      state.unreadMessageCount[chatroomId] = (
        state.unreadMessageCount[chatroomId] || 0
      ) + incrementBy 
    },

    resetUnreadMessageCount(state, action: PayloadAction<{ chatroomId: string }>) {
      const { chatroomId } = action.payload;
      state.unreadMessageCount[chatroomId] = 0
    },

    appendDraftMessage: appendDraftMessageInternalReducer,

    openCopySignalPopup (state, action: PayloadAction<BasicChatroomMessageSignal>) {
      state.copySignalPopup.signal = action.payload;
      state.copySignalPopup.open = true;
    },

    closeCopySignalPopup (state) {
      state.copySignalPopup.open = false;
      state.copySignalPopup.signal = null;
    },

    changeDMRequestStatus(state, action: PayloadAction<{
      roomId: string,
      status: DirectMessageRequestStatus
    }>) {
      const { roomId, status } = action.payload
      const room = (state.myChatrooms?.data || []).find(room => room.id === roomId);
      if (!room) {
        return;
      }
      room.dm_request_status = status;
    },

    openCreateDMPopup(state) {
      state.createDMChatroomPopup.open = true;
    },

    closeCreateDMPopup(state) {
      state.createDMChatroomPopup.open = false;
    },

    openDmPrefsPopup(state) {
      state.dmPrefsPopup.open = true;
    },

    closeDmPrefsPopup(state) {
      state.dmPrefsPopup.open = false;
    },

    openGroupPrivacySettingsPopup(state) {
      state.groupPrivacySettingsPopup.open = true;
    },

    closeGroupPrivacySettingsPopup(state) {
      state.groupPrivacySettingsPopup.open = false;
    },

    openChatroomMembersPopup(state) {
      state.chatroomMembersPopup.open = true;
    },

    closeChatroomMembersPopup(state) {
      state.chatroomMembersPopup.open = false;
    },

    openImageUploadPopup(state) {
      state.imageUploadPopup.open = true;
    },

    closeImageUploadPopup(state) {
      state.imageUploadPopup.open = false;
    },

    openSignalsFormatPopup(state) {
      state.signalsFormatPopup.open = true;
    },

    closeSignalsFormatPopup(state) {
      state.signalsFormatPopup.open = false;
    },
  },

  extraReducers(builder) {

    builder.addCase(fetchMyChatrooms.pending, (state, action) => {

      if (state.myChatrooms.data !== undefined) {
        return
      }
      state.myChatrooms.data = undefined;
      state.myChatrooms.error = null;
      state.myChatrooms.loading = true;

    }).addCase(fetchMyChatrooms.fulfilled, (state, action) => {

      state.myChatrooms.data = action.payload;
      state.myChatrooms.error = null;
      state.myChatrooms.loading = false;

    }).addCase(fetchMyChatrooms.rejected, (state, action) => {

      state.myChatrooms.loading = false;
      if (state.myChatrooms.data === undefined) {
        state.myChatrooms.error = action.payload;
      }

    });
  },
});

export const {
  reset,
  setSelectedChatroomId,
  initialMessagesLoading,
  initialMessagesError,
  initialMessagesSuccess,
  prevMessagesLoading,
  prevMessagesSuccess,
  prevMessagesError,
  nextMessagesLoading,
  nextMessagesError,
  nextMessagesSuccess,
  prependMessages,
  appendMessages,
  setPrevHasMore,
  setNextHasMore,
  addOrUpdateDraft,
  appendDraftMessage,
  incrementUnreadMessageCount,
  resetUnreadMessageCount,
  openCopySignalPopup,
  closeCopySignalPopup,
  changeDMRequestStatus,
  openCreateDMPopup,
  closeCreateDMPopup,
  openDmPrefsPopup,
  closeDmPrefsPopup,
  openGroupPrivacySettingsPopup,
  closeGroupPrivacySettingsPopup,
  openChatroomMembersPopup,
  closeChatroomMembersPopup,
  openImageUploadPopup,
  closeImageUploadPopup,
  openSignalsFormatPopup,
  closeSignalsFormatPopup,
  setMyChatrooms,
  setModerators,
} = chatSlice.actions;

export default chatSlice.reducer;

