import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useThemeContext } from "../../shared/context/ThemeContext";
import { theme } from "antd";

const { useToken } = theme;

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const StripePayment = ({ 
  clientSecret, 
  redirectUrl,
}: { 
  clientSecret: string;
  redirectUrl: string;
}) => {
  const { isDark } = useThemeContext()
  const { token: themeToken } = useToken();

  const appearance : Appearance = isDark ? {
    theme: "night",
    labels: 'floating',
    variables: {
      colorPrimary: themeToken.colorPrimary,
    },
  } : {
    labels: 'floating',
    variables: {
      colorPrimary: themeToken.colorPrimary,
    }
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance,
      }}
    >
      <CheckoutForm 
        redirectUrl={redirectUrl} />
    </Elements>
  );
};

export default StripePayment;
