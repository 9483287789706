import { directionLabelsShort } from "../../api/enum-labels";
import { AssetType } from "../../api/enums";
import { DBOrder, OpenOrder } from "../../api/orders";
import { BrokerType } from "../../brokers/entities/broker-account.entity";
import { formatNumberAuto, formatUTCDate } from "../../shared/utils";

export const getQuantity = (order : Pick<OpenOrder, 'quantity'>) => {
  return order.quantity ? `${order.quantity}` : '';
}

export const getSymbol = (order: OpenOrder | DBOrder) => {
  if (order.asset_type === AssetType.Stocks) {
    return order.stock;
  }
  if (order.asset_type === AssetType.Options) {
    const stock = order.stock;
    if (!order.strike_price || 
        !order.expiration_date || 
        !order.direction) {
      return '';
    }
    const strike = formatNumberAuto(order.strike_price);
    const expiration = formatUTCDate(order.expiration_date, 'MM/dd');
    const direction = directionLabelsShort[order.direction];
    return `${stock} ${strike}${direction} ${expiration}`
  }
  return '';
}

export const isReplaceOrderSupported = (brokerType: BrokerType | null, paper: boolean) => {
  if (!brokerType) {
    return false;
  }
  const supportList :  Record<BrokerType, boolean> = {
    [BrokerType.robinhood]: false,
    [BrokerType.tda]: false,
    [BrokerType.webull]: true,
  }
  const supports = supportList[brokerType];
  // paper accounts do support replace but it does not work at the moment
  if (brokerType === BrokerType.webull && paper) {
    return false;
  }
  return supports;
}