import { Card, Button, Table, Divider } from "antd";
import useSWR from "swr";
import api from "../../api";
import { ListSignalsRequest } from "../../api/signals";
import React, { useState } from "react";
import { useResponsiveTable, useResponsiveTableWrapper } from "../../shared/styles/useResponsiveTable";
import { SignalRow } from "./SignalRow";
import { useRefreshSignals } from "../hooks/useRefreshSignals";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

const SocialTrade = () => {

  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(
    null
  );

  const [maxAgeDays, setMaxAgeDays] = useState<number>(7);

  const { data: signals, isLoading: signalsLoading, mutate: refreshSignals, error: signalsError } = useSWR(
    `/signals/subscribed?signal_provider_id=${selectedProviderId}&max_age_days=${maxAgeDays}`,
    () => {
      const request = new ListSignalsRequest();
      request.page = 1;
      request.per_page = 100;
      request.signal_provider_id = selectedProviderId || undefined;
      request.max_age_days = maxAgeDays;
      return api.signals.getSubscribedSignals(request);
    }
  );

  const { data: signalProviders, isLoading: signalProvidersLoading, error: signalProvidersError } = useSWR(
    "/signal-providers/subscribed",
    () => api.signalProviders.getSubscribedSignalProviders()
  );

  const responsiveTable = useResponsiveTable();
  useRefreshSignals(refreshSignals, signalProviders, selectedProviderId);

  const maxAgeOptions = [
    { value: 7, label: 'Past 7 Days' },
    { value: 3, label: 'Past 3 Days' },
    { value: 1, label: 'Today' },
  ];

  const responsiveTableWrapper = useResponsiveTableWrapper({
    columnsMobile: 9,
    rowsDesktop: 7
  })

  return (
    <>
      <Card style={{ width: '100%' }} size="small">
        <div style={{ marginBottom: 20, overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
          <Button
            type={ selectedProviderId === null ? 'primary' : 'default' }
            style={{ marginLeft: 5 }}
            onClick={() => setSelectedProviderId(null)}
            size="large"
          >
            All
          </Button>
          {signalProviders?.map((signalProvider) => (
            <Button
              key={signalProvider.id}
              type={ selectedProviderId === signalProvider.id ? 'primary' : 'default' }
              onClick={() => setSelectedProviderId(signalProvider.id)}
              loading={signalProvidersLoading}
              size="large"
            >
              @ {signalProvider.username}
            </Button>
          ))}
        </div>

        <div style={{ marginBottom: 20, overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
          {
            maxAgeOptions.map(option => (
              <Button
                key={option.value}
                type={ maxAgeDays === option.value ? 'primary' : 'default' }
                style={{ marginLeft: 5 }}
                onClick={() => setMaxAgeDays(option.value)}
              >
                { option.label }
              </Button>
            ))
          }
        </div>

        {
          signalProvidersError && <ErrorMessage error={signalProvidersError} />
        }
        {
          signalsError && <ErrorMessage error={signalsError} />
        }

        <div className={responsiveTableWrapper}>
          <Table
            loading={signalsLoading}
            dataSource={signals?.data}
            className={responsiveTable}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record, index) =>
              ({ record, index } as React.HTMLAttributes<any>)
            }
            components={{
              body: {
                row: SignalRow,
              },
            }}
            columns={[
              { title: "Date" },
              { title: "Asset" },
              { title: "Side" },
              { title: "Symbol" },
              { title: "TIF" },
              { title: "Price" },
              { title: "Qty" },
              { title: "Total" },
              { title: "" },
            ]}
          />
        </div>
      </Card>
    </>
  );
};

export default SocialTrade;
