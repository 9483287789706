import { MyChatrooms } from "./my-chatrooms"
import { MyChatroomsPageHeader } from "./my-chatrooms-page-header"

export const MyChatroomsPage = () => {
  return <div style={{ display: 'flex', flexDirection: 'column', height: '500px' }}>
    <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}>
      <MyChatroomsPageHeader />
    </div>
    <MyChatrooms />    
  </div>
}