import { BotAlertWithOrders, OptionsAlertCalculatedParams } from "../../api/autotrade-alerts";
import { directionLabelsShort } from "../../api/enum-labels";
import { BotAction, BotAssetType, Direction } from "../../api/enums";
import { formatDate, formatUTCDate } from "../../shared/utils";

export function getBotActionForDisplay(action : BotAction) {
  switch (action) {
    case BotAction.buy:
      return "Buy";
    case BotAction.sell:
      return "Sell";
    case BotAction.call:
      return "Buy";
    case BotAction.put:
      return "Buy";
    case BotAction.close:
      return "Close";
    default:
      const a : never = action;
  }
}

export function formatBotAlertSymbol(alert : BotAlertWithOrders) {
  if (alert.asset_type === BotAssetType.stocks) {
    return alert.asset_name;
  }
  if (alert.asset_type === BotAssetType.options) {
    const param = (alert.parameters && alert.parameters.length > 0) 
      ? alert.parameters[0] as OptionsAlertCalculatedParams
      : null;

    const stock = alert.asset_name;
    const strike = param?.strike_price || alert?.strike_price;

    let direction = null; 
    if (alert.action == BotAction.call) {
      direction = directionLabelsShort[Direction.Call];
    }
    if (alert.action == BotAction.put) {
      direction = directionLabelsShort[Direction.Put];
    }
    
    let expiration = alert.expiration_date;
    if (alert.action != BotAction.close && param?.expiration_date !== undefined) {
      expiration = param.expiration_date;
    }
    if (expiration !== null) {
      expiration = formatUTCDate(expiration, 'MM/dd');
    }

    return `${stock} ${strike || ''}${direction || ''} ${expiration || ''}`;
  }
  return '';
}

export function wasAlertExecuted(alert : BotAlertWithOrders) {
  return alert.orders.length > 0;
}
