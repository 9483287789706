import { Button, Form, Input } from "antd"
import { BrokerAccount } from "../../../brokers/entities/broker-account.entity"
import { SelectBrokerFormInput } from "../../../brokers/components/SelectBrokerFormInput"
import { useState } from "react"
import api from "../../../api"
import { CreateBotResponse } from "../../../api/autotrade-bots"
import { ErrorMessage } from "../../../shared/components/ErrorMessage"
import { SelectedBroker } from "../../../brokers/repos/selected-broker-repo"
import { useCanAutotrade } from "../../../candefi-memberships/hooks/useCanAutotrade"
import { PremiumButton } from "../../../shared/components/PremiumButton"
import { Link } from "../../../shared/components/Link"
import { CrownFilled } from '@ant-design/icons';

type FormInputType = {
  broker_info: SelectedBroker,
  password: string
  name: string;
}

export function CreateBotForm({
  onSuccess,
} :{
  onSuccess: (response: CreateBotResponse) => void
}) {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const canAutotrade = useCanAutotrade();

  const handleFormSubmit = async (data: FormInputType) => {
    try {

      setSubmitting(true);
      const response = await api.autotradeBots.create({
        broker_account_id: data.broker_info.account.id,
        paper: data.broker_info.paper,
        password: data.password,
        name: data.name,
      });
      onSuccess(response);

    } catch(e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  }

  return <Form<FormInputType> 
        layout="vertical"
        onFinish={handleFormSubmit}
      >
      <ErrorMessage error={error} />
      <Form.Item<BrokerAccount>
          name="broker_info"
          label="Broker Account"
          rules={[ { required: true, message: 'broker account is required' } ]} 
        >
        {/*@ts-ignore  */}
        <SelectBrokerFormInput />
      </Form.Item>
      <Form.Item<String>
          name="name"
          label="Name"
          rules={[ { required: true, message: 'name is required' } ]} 
        >
          <Input />
      </Form.Item>
      <Form.Item<String>
          name="password"
          label="Password"
          rules={[{required: true, message: 'Required'}]}
        >
          <Input.Password />
      </Form.Item>
      <Form.Item>
        {
          canAutotrade ? (
            <Button
              style={{ width: '100%' }}
              loading={submitting}
              disabled={submitting}
              type="primary"
              size='large'
              htmlType="submit"
            >
              Create bot
            </Button>
          ) : (
            // @ts-ignore
            <Link to='/app/account/candefi-memberships'>
              <PremiumButton
                style={{ width: '100%' }}
                loading={submitting}
                disabled={submitting}
                size='large'
                type="default"
                icon={<CrownFilled />}
              >
                Create bot
              </PremiumButton>
            </Link>
          )
        }
      </Form.Item>
    </Form>
}