import { Button, Card, Typography } from "antd";
import api from "../../../api";
import useSWR from 'swr';
import { useEffect, useState } from "react";
import { CreateBotModal } from "./create-bot-modal";
import createBotImage from './create_bot.svg';
import { LEARN_ABOUT_BOTS_URL } from "../../constants";
import { useCanAutotrade } from "../../../candefi-memberships/hooks/useCanAutotrade";
import { PremiumButton } from "../../../shared/components/PremiumButton";
import { CrownFilled } from '@ant-design/icons';
import { BotsListSection } from "./bots-list-section";


export function BotsSection() {
  const { data: bots, isLoading, mutate } = useSWR('/autotrade-bots', api.autotradeBots.list);
  const canAutotrade = useCanAutotrade();

  const showBots = !isLoading && Array.isArray(bots) && bots.length > 0 && canAutotrade;
  const showEmpty = !isLoading && Array.isArray(bots) && bots.length <= 0 || !canAutotrade;
  const [createOpen, setCreateOpen] = useState(false);
  

  useEffect(() => {
    let stale = false;
    async function deleteOverLimit() {
      const result = await api.autotradeBots.deleteOverLimit();
      if (!stale && result.deleted) {
        mutate();
      }
    }
    deleteOverLimit();
    return () => { stale = true };
  }, []);

  return <Card loading={isLoading}>
    <CreateBotModal
      open={createOpen}
      onClose={() => setCreateOpen(false)}
      onSuccess={mutate}
    />
    {
      showEmpty && (
        <div style={{ gap: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography.Title level={3}>Create A Bot</Typography.Title>
          </div>
          <img
            src={createBotImage}
            width={300}
          />
          <div style={{ display: 'flex', gap: 8 }}>
            {
              canAutotrade ? (
                <Button
                  size="large"
                  type="primary" onClick={() => setCreateOpen(true)}>
                  Create A Bot
                </Button>
              ) : (
                <PremiumButton
                  size="large"
                  onClick={() => setCreateOpen(true)}
                  icon={<CrownFilled />}
                >
                  Create A Bot
                </PremiumButton>
              )
            }
            <a href={LEARN_ABOUT_BOTS_URL} target="https://youtu.be/EjKA0E3R-eM">
              <Button size="large">
                Learn more
              </Button>
            </a>
          </div>
        </div>
      )
    }
    {
      showBots && (
        <BotsListSection bots={bots} mutate={mutate} />
      )
    }
  </Card>
}

