import { InputNumber, Spin } from "antd"
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

export const LimitInput = ({
  value,
  setValue,
  disabled = false,
  loading = false,
} : {
  value: number | null,
  setValue: (value : number | null) => void,
  disabled?: boolean,
  loading?: boolean
}) => {
  return <InputNumber
    addonBefore="$"
    style={{ display: 'block', width: '100%' }}
    placeholder="Limit Price"
    value={value}
    onChange={(value) => setValue(value)}
    disabled={disabled}
    min={0}
    addonAfter={
      loading 
        ? <Spin indicator={antIcon} />
        : undefined
    }
  />
}