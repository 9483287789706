import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Button, ButtonProps } from "antd"
import { forwardRef } from "react"

export const PremiumButton = forwardRef<HTMLElement, ButtonProps>((props, ref) => {

  const { type, children, className, ...rest } = props;

  const premiumButtonClassName = useEmotionCss(() => ({
    background: 'var(--premium-yellow)',
    color: 'var(--premium-yellow-foreground)',
    border: 'none',
    '&:hover': {
      background: 'var(--premium-yellow-light)',
      color: 'var(--premium-yellow-light-foreground) !important',
    }
  }))

  const newClassName = [premiumButtonClassName, className].filter(c => !!c).join(' ');

  return <Button ref={ref} {...rest} className={newClassName}>
    {children }
  </Button>
})


export const PremiumOutlinedButton = forwardRef<HTMLElement, ButtonProps>((props, ref) => {

  const { type, children, className, ...rest } = props;

  const premiumButtonClassName = useEmotionCss(() => ({
    borderColor: 'var(--premium-yellow)',
    color: 'var(--premium-yellow)',
    background: 'transparent',
    '&:hover': {
      borderColor: 'var(--premium-yellow-light) !important',
      color: 'var(--premium-yellow-light) !important',
    }
  }))

  const newClassName = [premiumButtonClassName, className].filter(c => !!c).join(' ');

  return <Button ref={ref} {...rest} className={newClassName}>
    {children }
  </Button>
})

