import { Card, Divider, Space, Tag, Typography } from "antd";
import type { BaseType } from "antd/es/typography/Base";
import React from "react";
import { memberhshipStatusLabels } from "../../api/enum-labels";
import { MembershipStatus } from "../../api/enums";
import { MembershipListItem } from "../../api/membership";
import { formatMoney } from "../../shared/utils";
import { MyMembershipCardActions } from "./MyMembershipCardActions";
const { Title, Text } = Typography;

const rowStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 8,
};

export const MyMembershipCard = ({
  membership,
  style,
}: {
  membership: MembershipListItem;
  style?: React.CSSProperties;
}) => {

  const statusColors: Record<MembershipStatus, BaseType> = {
    [MembershipStatus.Draft]: 'secondary',
    [MembershipStatus.Active]: 'success',
    [MembershipStatus.Cancelled]: 'danger',
    [MembershipStatus.Completed]: 'secondary',
  };

  return (
    <Card style={style}>
      <Title style={{ margin: 0 }} level={4}>
        {membership.membership_plan.signal_provider.name}
      </Title>
      <Text type="secondary">
        @{membership.membership_plan.signal_provider.username}
      </Text>
      <div style={{ display: 'flex', margin: '16px 0', flexWrap: 'wrap' }}>
        {
          membership.stocks && <Tag color="success">
            Stocks
          </Tag>
        }
        {
          membership.options && <Tag color="success">
            Options
          </Tag>
        }
      </div>
      <Divider dashed style={{ margin: '20px 0' }} />
      <div style={{ textAlign: 'center' }}>
        <Text>
          {membership.membership_plan.name}
        </Text>
      </div>
      <div style={rowStyle}>
        <Text type="secondary">Status</Text>
        <Text type={statusColors[membership.status]}>
          {memberhshipStatusLabels[membership.status]}
        </Text>
      </div>
      <div style={rowStyle}>
        <Text type="secondary">Billing Cycles</Text>
        <Text type="secondary">
          {
            membership.billing_cycles === 0 ? 'Infinite' : membership.billing_cycles
          }
        </Text>
      </div>
      <div style={rowStyle}>
        <Text type="secondary">Billing Cycles Charged</Text>
        <Text type="secondary">
          {membership.billing_cycles_charged}
        </Text>
      </div>
      <div style={rowStyle}>
        <Text type="secondary">Amount</Text>
        <Text type="secondary">
          {formatMoney(membership.payment_amount)}
        </Text>
      </div>
      <div style={{ marginTop: 16 }}>
        <MyMembershipCardActions membership={membership} />
      </div>
    </Card>
  );
};

