import { useState } from "react";
import { useWebullConnectContext } from "../../contexts/WebullConnectContext"
import { Form, Input, Typography, message, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'
import { ErrorMessage } from "../../../shared/components/ErrorMessage";

const { Title } = Typography;

export const MFASecurityQuestion = () => {

  const {
    securityQuestion,
    fetchAlternativeSecurityQuestion,
    checkSecurityQuestionAnswer,
  } = useWebullConnectContext();

  const [fetching, setFetching] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState<unknown>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchQuestion = async () => {
    try {
      setError(null);
      setFetching(true);
      await fetchAlternativeSecurityQuestion();
    } catch (e) {
      setError(e);
    } finally {
      setFetching(false);
    }
  }

  return <div style={{ margin: '1.5rem 0' }}>
    { contextHolder }
    <Title level={3}>{ securityQuestion?.questionName }</Title>
    <div style={{ margin: '1rem 0' }}>
      <Button
        type="dashed"
        block
        onClick={() => fetchQuestion()}
        loading={fetching}
        icon={<ReloadOutlined />}
      >
        Get Alternative Question
      </Button>
    </div>
    <Form<{ answer: string }>
      onFinish={async (values) => {
        try {
          setError(null);
          setVerifying(true);
          let success = await checkSecurityQuestionAnswer(values.answer);
          if (success) {
            messageApi.success('Security question answer is correct');
          } else {
            message.error("Incorrect Answer");
          }
        } catch (e) {
          setError(e);
        } finally {
          setVerifying(false);
        }
      }}
      layout="vertical"
      >
      <ErrorMessage error={error} />
      
      <Form.Item
        required
        name="answer"
        rules={[{ required: true, message: 'Please input your answer!' }]}
      >
        <Input/>
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span></span>
        <Button 
          htmlType="submit"
          loading={verifying} 
          size="large" 
          type="primary"
        >
          Next
        </Button>
      </div>
    </Form>
  </div>
}