import { Card, Typography, Button, Tag } from "antd";
import { SignalProviderListItem } from "../../api/signal-providers";
import { Link } from "react-router-dom";
import { formatMoney } from "../../shared/utils";
const { Text, Title, Paragraph } = Typography;

const SignalProviderCard = ({ signalProvider }: { signalProvider: SignalProviderListItem }) => {

  return (
    <Card style={{ width: '100%' }}>
        <Title level={3}>
          {signalProvider.signal_provider.name}
        </Title>
        <div style={{ margin: '16px 0' }}>      
          {
            signalProvider.stocks && <Tag color="success">
              Stocks
            </Tag>
          }
          {
            signalProvider.options && <Tag color="success">
              Options
            </Tag>
          }
        </div>
        <div style={{ marginTop: 20 }}>
          <Text type="secondary">Plans Starting At</Text>
          <Title style={{ margin: 0 }} level={4}>
            {formatMoney(signalProvider.starting_price, 2)}
          </Title>
        </div>
        <Paragraph style={{ marginTop: 20 }} type="secondary">
          { signalProvider.signal_provider.bio }
        </Paragraph>
        <Link
          to={`/app/signal-providers/${signalProvider.signal_provider.username}`}>
          <Button
            block
            size="large"
            type="primary"
          >
            See Subscriptions
          </Button>
        </Link>
    </Card>
  );
};

export default SignalProviderCard;
