import formatWithTZ from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { TZ } from './constants';
import toDate from 'date-fns-tz/toDate';
import addDays from 'date-fns/addDays';

/**
 * Gets current time in ET timezone
 * @returns {Date}
 */
export function getCurrentETTime(): Date {
  const date = new Date();
  const estDate = utcToZonedTime(parseISO(date.toISOString()), TZ);
  return estDate;
}

/**
 * Get ET Midnight for given expiration date
 * 
 * @param date expiration date in yyyy-MM-dd format
 * @returns { Date }
 */
export function expirationDateToMidnightET(date: string) : Date {
  return utcToZonedTime(toDate(date+"T00:00:00", { timeZone: TZ }), TZ);
}


/**
 * Check if given expiration date is in past
 * 
 * @param date Date string in yyyy-MM-dd format
 * @returns {boolean}
 * 
 */
export function isExpirationDateInPast(dateStr: string) : boolean {
  let todayStr = formatWithTZ(getCurrentETTime(), "yyyy-MM-dd", { timeZone: TZ });
  return dateStr < todayStr;
}


/**
 * 
 * Checks if given expiration date is today
 * 
 * @param date Date string in yyyy-MM-dd format
 * @returns {boolean}
 * 
 */
export function isExpirationDateToday(date: string) : boolean {
  let todayStr = formatWithTZ(getCurrentETTime(), "yyyy-MM-dd", { timeZone: TZ });
  return date === todayStr;
}


/**
 * 
 * Finds if given expiration date is today and after 2 pm.
 * 
 * @param date Date string in yyyy-MM-dd format
 * @returns {boolean}
 */
export function isExpirationDayTodayAndAfter2PM(date: string): boolean {
  const isToday = isExpirationDateToday(date);
  if (!isToday) {
    return false;
  }
  const currentTime = formatWithTZ(getCurrentETTime(), "HH:mm:ss", { timeZone: TZ });
  return currentTime > "14:00:00";
}


/**
 * 
 * @param dates List of expirationDates in "yyyy-MM-dd" format
 * @returns {string | null}
 */
export function getNextAvailableOptionExpirationDate(dates: string[]) : string | null {
  const sortedDates = dates.sort();
  for (let date of sortedDates) {
    if (isExpirationDateInPast(date)) {
      continue
    }
    if (isExpirationDayTodayAndAfter2PM(date)) {
      continue
    }
    return date;
  }
  return null;
}

/**
 * Check if expiration date is on friday
 * 
 * @param date expiration date in "yyyy-MM-dd" format
 * @returns { boolean }
 */
export function isExpirationDateFriday(date: string): boolean {
  
  const day = format(parseISO(date), "EEEEE");
  return day === "F";
}

/**
 * Checks if expiration date is this week
 * 
 * @param date expiration date in "yyyy-MM-dd" format
 * @returns { boolean }
 */
export function isExpirationDayThisWeek(date: string): boolean {
  const dateET = expirationDateToMidnightET(date);
  const todayET = getCurrentETTime();
  const dateWeekNumber = formatWithTZ(dateET, "ww", { timeZone: TZ });
  const currentWeekNumber = formatWithTZ(todayET, "ww", { timeZone: TZ });
  return dateWeekNumber === currentWeekNumber;
}

/**
 * Checks if expiration date is last friday of its month.
 * 
 * @param date expiration date in yyyy-MM-dd format
 */
export function isLastFridayExpiryOfMonth(date: string) {
  if (!isExpirationDateFriday(date)) {
    return false;
  }
  const dateET = expirationDateToMidnightET(date);
  const nextFriday = addDays(dateET, 7);
  const dateMonth = formatWithTZ(dateET, "MM", { timeZone: TZ });
  const nextFridayMonth = formatWithTZ(nextFriday, "MM", { timeZone: TZ });
  return nextFridayMonth != dateMonth;
}