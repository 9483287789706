import { Card, notification } from "antd";
import { useState } from "react";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { AutotradePreferenceFormSection } from "../../autotrade/components/preference/form-section";
import { TradePreferenceForm } from "../../trade-preference/components/TradePreferenceForm";

enum Tab {
  Socialtrade = 'social_trade',
  Autotrade = 'auto_trade'
}

export const TradeProfiler = () => {
  // const [tab, setTab] = useState<Tab>(Tab.Socialtrade);
  const [tab, setTab] = useState<Tab>(Tab.Autotrade);

  return (
    <Card
      title="Trade Profiler"
      style={{ height: "100%" }}
      bordered={false}
      tabProps={{
        size: "middle",
      }}
      tabList={[
        {
          key: Tab.Autotrade,
          tab: "AutoTrade Preference",
        },
        {
          key: Tab.Socialtrade,
          tab: "SocialTrade Preference",
        },
      ]}
      onTabChange={(tab) => setTab(tab as Tab)}
      activeTabKey={tab}
    >
      {
       tab === Tab.Autotrade && <AutotradeSection />
      }
      {
        tab === Tab.Socialtrade && <SocialtradeSection />
      }
    </Card>
  );
};

function SocialtradeSection() {  
  const { user, initialized } = useAuthUser();
  const tradePreference = user?.trade_preference || null;
  
  return <SectionContainer>
    <TradePreferenceForm  tradePreference={tradePreference} />
  </SectionContainer>
}

function AutotradeSection() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const onSuccess = () => {
    notificationApi.success({ message: 'Preference updated' });
  }

  return <SectionContainer>
      { contextHolder }
      <AutotradePreferenceFormSection 
        onSuccess={onSuccess}
      />
  </SectionContainer>
}

function SectionContainer ({ children } : { children: React.ReactNode }){
  return <div style={{ width: 'min(100%, 800px)' }}>
    { children }
  </div>
}
