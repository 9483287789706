import { useState } from "react";
import api from "../../api";

export const usePauseMembership = (membershipId: number) => {
  const [isPausing, setIsPausing] = useState(false);

  const pause = async () => {
    setIsPausing(true);
    try {
      await api.membership.pauseMembership(membershipId);
    } finally {
      setIsPausing(false);
    }
  };

  return {
    pause,
    isPausing,
  };
};
