import api from "../../../api";
import axios from "axios";
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { ImageUploadUrlResponse } from "../../../api/image-upload";
import { useAppDispatch } from "../../../redux/hooks";
import { sendMessage } from "../../redux/thunks/send-message.thunk";
import { closeImageUploadPopup } from "../../redux/chat.slice";

const { Dragger } = Upload

export function ImageUploadPopupContent({
  uploading,
  setUploading
} : {
  uploading: boolean,
  setUploading: (value: boolean) => void
}) {
  const dispatch = useAppDispatch();

  const sendImageMessage = (response: ImageUploadUrlResponse) => {
    dispatch(sendMessage({
      image: response.key,
      text: null
    }))
    dispatch(closeImageUploadPopup());
  }

  return <Dragger
    onChange={(info) => {
  
      setUploading(info.file.status === 'uploading')
  
      if (info.file.status === 'done') {
        const response = info.file.response as ImageUploadUrlResponse;
        sendImageMessage(response);
      }
    }}
    style={{ marginBottom: 16 }}
    multiple={false}
    accept="image/png, image/jpeg, image/gif"
    method="PUT"
    customRequest={async ({
      file, onProgress, onError, onSuccess,
    }) => {
      if (!(file instanceof File)) {
        throw new Error("Invalid file");
      }
      const { type } = file;
      const { url, key } = await api.imageUpload.createUrl({ content_type: type });

      try {
        await axios.put(url, file, {
          headers: {
            'Content-Type': type,
          },
          onUploadProgress: (e) => {
            if (e.progress !== undefined && onProgress !== undefined) {
              onProgress({ percent: e.progress * 100 });
            }
          }
        });
        if (onSuccess !== undefined) {
          onSuccess({ url, key });
        }
      } catch (e) {
        if (onError !== undefined) {
          onError(e as Error);
        }
        return;
      }
    }}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibited from uploading company data or other
      banned files.
    </p>
  </Dragger>
}
