import { Button, Space, Spin } from 'antd';
import { BasicChatroomMessage } from '../../../api/chat';
import { formatChatSignal } from '../../../signals/utils/signals';
import { useAppDispatch } from '../../../redux/hooks';
import { openCopySignalPopup } from '../../redux/chat.slice';
import useSWR from 'swr';
import api from '../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { CreatedByBotIcon } from '../../../signals/components/CreatedByBotIcon';

export const loadingIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />

export function MessageContentSignal({ message }: { message: BasicChatroomMessage; }) {
  const hasSignal = message.signal_id !== null;

  const {
    data: signal, isLoading,
  } = useSWR(
    message.signal_id !== null
      ? `/chat/messages/${message.id}/signal`
      : null,
    () => {
      return api.chat.messageSignal(message.id);
    }
  );

  const dispatch = useAppDispatch();

  const handlePlaceOrder: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!signal) {
      return;
    }
    dispatch(openCopySignalPopup(signal));
  };

  if (!hasSignal) {
    return null;
  }

  return <div style={{ marginTop: 12, display: 'flex', alignItems: 'center' }}>
    {
      isLoading && <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: 8 }}>
        <Spin indicator={loadingIcon} />
      </div>
    }
    {
      !!signal && <div>
        {
          !message.text && <Space>
            {
              !!signal && signal.created_by_bot && (
                <CreatedByBotIcon variant='chat' />
              )
            }
            {formatChatSignal(signal)}
          </Space>
        }
        <div style={{ margin: '8px 0' }}>
          <Button
            block
            onClick={handlePlaceOrder}
            type='default'>
            Place Order
          </Button>
        </div>
      </div>
    }
    {
      !isLoading && signal === null && <div>
        Your plan does not include this signal
      </div>
    }
  </div>
}
