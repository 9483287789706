import useSWR from 'swr';
import api from '../api';
import { Typography, Card, Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

const { Text } = Typography;

export function AffiliateAlert() {
  const {
    data
  } = useSWR('/affiliate', api.affiliate.findOne);

  if (data === undefined || data === null) {
    return null;
  }

  return <Card title={`Get Signals`}>
    <Row gutter={[ 16, 16 ]} align='middle'>
      <Col flex='1 1 auto'>
        <Text>
          Get Signals from @{ data.signal_provider.username }
        </Text>
      </Col>
      <Col>
        <div>
          <Link to={`/app/signal-providers/${data.signal_provider.username}`}>
            <Button type='primary'>
              Get Signals
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  </Card>
  // return <Alert
  //   type='success'
  //   style={{ marginTop: 8, marginBottom: 8 }}
  //   message={
  //     `@${data.signal_provider.username}`
  //   }
  //   description={`Get signals from @${ data.signal_provider.username }`}
  //   action={
  //     <Link to={`/app/signal-providers/${data.signal_provider.username}`}>
  //       <Button type='primary'>
  //         Get Signals
  //       </Button>
  //     </Link>
  //   }
  // />
}