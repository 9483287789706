import { Typography } from 'antd';

export function PrivacyPolicy() {
  return <div style={{ padding: '16px 30px' }}>
    <div style={{ padding: 16 }}>
      <Typography.Title level={1}>
        Privacy Policy
      </Typography.Title>
      <Typography>
        <p>
          In its capacity as a saas trading service, 
          CandeFi receives and/or comes into contact with certain 
          nonpublic personal information concerning its current and/or former customers, 
          including but not limited to certain nonpublic personal information related to 
          the securities account(s) that said customers open and/or maintain with CandeFi. 
          Any and all such nonpublic personal information related to such customers and/or 
          such customers' securities account(s) is hereinafter referred to as “nonpublic personal information”. 
          CandeFi receives and/or comes into contact with the nonpublic personal information 
          only for purposes of conducting its business as described above. 
          For convenience herein, “you” or “your” refers to any and all customer(s) 
          described above.
        </p>
        <h4>
          NO SALE OF YOUR NONPUBLIC PERSONAL INFORMATION. CANDEFI DOES NOT SELL YOUR NONPUBLIC PERSONAL INFORMATION. NONPUBLIC PERSONAL INFORMATION COLLECTED.
        </h4>
        <p>
          In conducting its business as described above, CandeFi collects (or may collect), for example, the following types of nonpublic personal information:
        </p>
        <p>
          A. Information about you: (1) information you provide in connection with trading account or any other financial product or service, whether in writing, in person, by telephone, electronically or by any other means, such as your name, address, social security number, tax ID number, assets, income, investment objectives, financial situation and debt; and (2) information CandeFi obtains (or may obtain) for the purpose of tax reporting to you and to various agencies to which CandeFi reports as required by law, including disclosures on various Internal Revenue Service (IRS) forms that CandeFi collects for tax reporting purposes.
        </p>
        <p>
          Information about your securities and/or other transactions: (1) information CandeFi collects and relates to account balance, payment history, trading activity and any other transactions concerning which CandeFi provides account maintenance services; (2) information CandeFi collects as part of servicing your account(s) as SAAS; and (3) information CandeFi may collects through an internet “cookie” (an information collecting device from a web server).
        </p>
        <ul>
          <li>
            Information about your transactions with nonaffiliated third parties: (1) information from nonaffiliated third parties pursuant to law, rules, regulations, standard securities industry practice and/or legal process, including information and/or documents received, shared, produced or provided in connection with a subpoena, discovery request or other legal process compelling production; and (2) information from non affiliated third parties related to servicing your account for purposes of providing securities trading services.
          </li>
        </ul>
        <h4>
          FORMER CUSTOMERS
        </h4>
        <p>
          CandeFi will disclose nonpublic personal information about the former customers of CandeFi only as permitted and/or required by law or legal process, or with your consent.
        </p>
        <h4>
          CONFIDENTIALITY AND SECURITY OF NONPUBLIC PERSONAL INFORMATION:
        </h4>
        <p>
          CandeFi is committed to implementing and maintaining commercially reasonable and appropriate measures to maintain your nonpublic personal information in a secure and confidential fashion. CandeFi's information and security procedures include. But are not limited to the following features;
        </p>
        <ul>
          <li>
            Access controls on customer information systems, including controls to authenticate and permit access only to authorized individuals and controls to prevent CandeFi employees from providing nonpublic information to unauthorized individuals who may seek to obtain this information through fraudulent means.
          </li>
          <li>
            Physical access restrictions at locations containing nonpublic personal information
          </li>
          <li>
            Stringent pre-employment screening, including fingerprinting, and segregation of duties for CandeFi employees with responsibilities for or access to nonpublic information.
          </li>
        </ul>
        <p> 
          CandeFi regularly reviews, revises and updates its information security program to account for changes in technology.
        </p>
        <p>
          CandeFi reserves the right to change this Privacy Policy at any time.
        </p>
      </Typography>
    </div>
  </div>
}