import api from "../../../api";
import { SortOrder } from "../../../api/enums";
import { createAppAsyncThunk } from "../../../redux/hooks";
import {
  prependMessages,
  prevMessagesError,
  prevMessagesLoading,
  prevMessagesSuccess,
  setPrevHasMore
} from "../chat.slice";
import { PAGE_SIZE } from "../constants";
import mutex from "./mutex";

export const fetchPrevMessages = createAppAsyncThunk(
  'chat/fetchPrevMessages',
  async function (_, { getState, dispatch }) {

    // Must run exclusively
    // See note on mutex variable for reason.
    mutex.runExclusive(async () => {
      const roomId = getState().chat.selectedChatroomId;

      const prev = getState().chat.selectedChatroomMessages?.prev || null;
      const cursor = prev?.cursor || null;
      const hasMore = prev?.hasMore || false;

      if (!roomId || !prev || !cursor || !hasMore) {
        return;
      }

      dispatch(prevMessagesLoading());

      try {

        const messages = await api.chat.listMessages(roomId, {
          cursor_timestamp: cursor.timestamp,
          cursor_uuid: cursor.uuid,
          limit: PAGE_SIZE,
          order: SortOrder.Desc
        });

        const reachedEnd = messages.length < PAGE_SIZE;
        if (reachedEnd) {
          dispatch(setPrevHasMore(false));
        }

        dispatch(prependMessages(messages.reverse()));
        dispatch(prevMessagesSuccess());

      } catch (e) {

        dispatch(prevMessagesError(e));
      }
    });

  }
);
