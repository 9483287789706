import { Card, Col, Radio, Row, Space, Typography } from "antd";
import SignalProvidersList from "../components/SignalProvidersList";
import { useState } from "react";
import { useRadioGroupRoundedStyle } from "../../shared/styles/useRadioGroup";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { UserRole } from "../../api/enums";
import { ReferAndEarnCard } from "../components/ReferAndEarnCard";
import { BecomeAnalystCard } from "../components/BecomeAnalystCard";
import { PageContainer } from "../../shared/components/PageContainer";
import { EmailVerifiedMessage } from "../../auth/components/EmailVerifiedMessage";

const { Title, Paragraph } = Typography;

export enum MarketplaceServiceFilter {
  Any = 'Any',
  Options = 'options',
  Stocks = 'stocks',
}

const marketplaceServiceFilterLabels : Record<MarketplaceServiceFilter, string> = {
  [MarketplaceServiceFilter.Any]: 'All',
  [MarketplaceServiceFilter.Options]: 'Options',
  [MarketplaceServiceFilter.Stocks]: 'Stocks'
}

export const MarketPlacePage = () => {

  const [filter, setFilter] = useState<MarketplaceServiceFilter>(MarketplaceServiceFilter.Any);

  const filterOptions = Object.values(MarketplaceServiceFilter).map((value) => ({
    label: marketplaceServiceFilterLabels[value],
    value: value
  }));

  const radioClass = useRadioGroupRoundedStyle();

  const filtersElement = (
      <Radio.Group
        className={radioClass}
        options={filterOptions}
        optionType="button"
        buttonStyle="solid"
        size="large"
        onChange={(event) => setFilter(event.target.value as MarketplaceServiceFilter)}
        value={filter}
      />
  );

  const { user } = useAuthUser();
  const isTrader = !!user && user.role === UserRole.trader;

  return (
    <PageContainer>
      <Space direction="vertical" size='small' style={{ width: '100%' }}>
        <EmailVerifiedMessage />
        <Card>
          <Title style={{ marginBottom: 8 }} level={2}>
            {/* SocialTrade Marketplace */}
            CopyTrade Marketplace
          </Title>
          <Paragraph type="secondary">
            {/* Trade like the pros. Subscribe to top providers and effortlessly copy their profitable signals. */}
            Trade like the pros. Subscribe to one or multiple Trade Signal Providers and effortlessly copy their trade signals.
          </Paragraph>
        </Card>
        <Card>
          <div style={{ marginBottom: 24 }}>
            { filtersElement }
          </div>
          <Row gutter={[ 8, 8 ]}>
            <Col span={24} xl={{ span: 18 }}>
              <SignalProvidersList 
                filter={filter}
              />
            </Col>
            <Col span={24} xl={{ span: 6 }}>
              <Row gutter={[ 8, 8 ]}>
                {
                  isTrader && <Col span={24}>
                    <BecomeAnalystCard />
                  </Col>
                }
                <Col span={24}>
                  <ReferAndEarnCard />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Space>
    </PageContainer>
  );
};