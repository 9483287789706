import { BillingIntervalUnit, Side } from "../api/enums";
import parseISO from 'date-fns/parseISO'
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import dateFNSFormat from 'date-fns/format';
import { TZ } from "./constants";
import { formatInTimeZone } from "date-fns-tz";

export const formatMoney = (amount : number, places = 2) => {
  if (amount < 0) {
    return `-\$${(amount * -1).toFixed(places)}`;
  }
  return `\$${amount.toFixed(places)}`;
}

export const formatMoneyAuto = (amount : number) => {
  if (amount < 0) {
    return `-\$${(amount * -1).toString()}`;
  }
  return `\$${amount.toString()}`;
}

export const formatNumber = (amount : number, places = 2) => {
  return amount.toFixed(places);
}

export const formatNumberAuto = (amount : number) => {
  return amount.toString();
}

export const fixDecimalPlaces = (num : number, places=2) => {
  return parseFloat(formatNumber(num, places));
}

export const formatBillingInterval = (interval: number, unit: BillingIntervalUnit) => {
  if (interval === 1) {
    return {
      [BillingIntervalUnit.Month]: 'Monthly',
      [BillingIntervalUnit.Week]: 'Weekly',
      [BillingIntervalUnit.Year]: 'Yearly'
    }[unit];
  }
  const unitDescription = {
    [BillingIntervalUnit.Month]: 'Month',
    [BillingIntervalUnit.Week]: 'Week',
    [BillingIntervalUnit.Year]: 'Year',
  }[unit];

  return `Every ${interval} ${unitDescription}`;
}

export const formatBillingCycles = (interval : number, unit: BillingIntervalUnit, cycles: number) => {
  if (cycles == 0) {
    return "until stopped";
  }

  const unitDescriptionSingular = {
    [BillingIntervalUnit.Month]: 'month',
    [BillingIntervalUnit.Week]: 'week',
    [BillingIntervalUnit.Year]: 'year',
  }[unit];

  const unitDescriptionPlural = {
    [BillingIntervalUnit.Month]: 'months',
    [BillingIntervalUnit.Week]: 'weeks',
    [BillingIntervalUnit.Year]: 'years',
  }[unit];

  const unitDescription = cycles == 1
    ? unitDescriptionSingular
    : unitDescriptionPlural;

  return `For ${cycles} ${unitDescription}`
}

export const formatDate = (
  dateStr : string,
  formatStr : string = 'MMM do yyyy',
) : string =>  {
  dateStr = dateStr.toUpperCase().includes("z") ? dateStr : dateStr + "Z"
  const zonedTime = utcToZonedTime(parseISO(dateStr), TZ);
  return format(zonedTime, formatStr, { timeZone: TZ });
}

export const formatUnixTsInTZ = (
  timestampSeconds: number,
  formatStr : string = 'MMM do yyyy',
) : string =>  {
  return formatInTimeZone(new Date(timestampSeconds*1000), TZ, formatStr);
}

export const formatTZDate = (
  dateStr : string,
  formatStr : string = 'MMM do yyyy',
) : string =>  {
  return dateFNSFormat(parseISO(dateStr), formatStr);
}

export const formatUTCDate = (
  dateStr : string,
  formatStr : string = 'MMM do yyyy',
) : string =>  {
  return format(parseISO(dateStr), formatStr, { timeZone: 'UTC' });
}

export function utcToTZ(utcDateStr: string) {
  return utcToZonedTime(parseISO(utcDateStr), TZ);
}

export function dateToTZ(date: Date) {
  return utcToZonedTime(date, TZ);
}

export const getErrorMessage = (error: unknown): string | null => {
  if (error === null) {
    return null;
  }
  if (typeof (error) === 'string') {
    return error;
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'object' && (error as Object).hasOwnProperty("message")) {
    // @ts-ignore
    return error.message as string;
  }
  return null;
};

export const removeUnderscores = (str: string) => {
  return str.replaceAll('_', ' ');
}

export function removeLeadingSlash (str: string) {
  return str.replace(/^\//, '');
}

export function removeTrailingSlash (str: string) {
  return str.replace(/\/$/, '');
}

export function removeSlash(str: string) {
  return removeTrailingSlash(removeLeadingSlash(str))
}

export const numberToAntTextType = (num: number) => {
  let type : undefined | "success" | "danger" = undefined;
  if (num > 0) {
    type = "success";
  }
  if (num < 0) {
    type = "danger"
  }
  return type;
}

export const sideToAntTextType = (side: Side) => {
  return {
    [Side.Buy]: 'success' as const,
    [Side.Sell]: 'danger' as const,
  }[side];
}

export const usernameRegex = /^[a-z_0-9]+$/
export const usernameRegexExplanation = 'Only lowercase letters, numbers and underscore are allowed'