import { Avatar } from "antd";
import { BasicChatroomUser } from "../../../api/chat"
import { generateAvatarColor } from "../../../shared/avatar-utils"

export const ChatAvatar = ({
  user,
}: {
  user: BasicChatroomUser,
}) => {
  const name = user.name;
  const nameTrimmed = user.name.slice(0, 2).toUpperCase();
  const color = generateAvatarColor(name);
  return <Avatar style={{ backgroundColor: color }}>
    { nameTrimmed }
  </Avatar>
}