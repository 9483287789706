import axios from "./axios";
import { GenericStatusResponse } from "./types";

export type ResetWebullPaperAccountRequest = {
  balance: number
}

const webullPaperAccountApi = {
  async reset(request: ResetWebullPaperAccountRequest) {
    const { data } = await axios.post<GenericStatusResponse>(
      '/webull-paper/reset', 
      request,
      { needsBrokerId: true }
    );
    return data
  }
}

export default webullPaperAccountApi;