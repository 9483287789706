import { Select } from "antd";
import { Theme, useThemeContext } from "../context/ThemeContext";
import { MoonIcon } from "./MoonIcon";
import { BulbOutlined } from '@ant-design/icons';
import { WandIcon } from "./WandIcon";

export const ThemeSwitcher = () => {
  const { theme, setTheme } = useThemeContext();

  return <div>
    <Select<Theme>
      onChange={(value) => setTheme(value)}
      value={theme}
      style={{ width: '7rem' }}
      options={[
        { value: Theme.System, label: <div><WandIcon /> System</div> },
        { value: Theme.Light, label: <div><BulbOutlined /> Light</div> },
        { value: Theme.Dark, label: <div><MoonIcon /> Dark</div> },
      ]}
    />
  </div>;
}
