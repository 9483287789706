import styles from "./account-details.module.css";
import { RobinhoodAccountAggregate } from "../../api/account-info";
import { Typography } from 'antd';
import { fixDecimalPlaces, formatMoneyAuto, numberToAntTextType, removeUnderscores } from "../../shared/utils";
import { useMaskInfoContext } from "../../shared/context/MaskInfoContext";
const { Text } = Typography;

const RobinhoodAccountDetails = ({ info } : { info: RobinhoodAccountAggregate }) => {
  const { account, portfolio } = info;
  const { masked } = useMaskInfoContext();

  const pl_day = portfolio.pl_day;
  const pl_day_percent_normalized = portfolio.pl_day_percent_normalized;

  return <div>
    <div className={styles.row}>
      <Text type="secondary">Account Type</Text>
      <Text  className={`${styles.capitalize}`}  type="success">{account.type}</Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Account Number</Text>
      <Text className={`${styles.capitalize}`} type="success">
        { 
          !masked 
            ? account.account_number 
            : <span style={{ textTransform: 'none' }}>******</span> 
        }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Market Value</Text>
      <Text className={`${styles.capitalize}`} type={numberToAntTextType(portfolio.market_value)}>
        { formatMoneyAuto(fixDecimalPlaces(portfolio.market_value)) }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Account Value</Text>
      <Text  className={`${styles.capitalize}`} type={numberToAntTextType(portfolio.equity)}>
        { formatMoneyAuto(fixDecimalPlaces(portfolio.equity)) }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Day P/L</Text>
      <Text  className={`${styles.capitalize}`} type={numberToAntTextType(pl_day)}>
        { formatMoneyAuto(fixDecimalPlaces(pl_day)) }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Day P/L%</Text>
      <Text  className={`${styles.capitalize}`} type={numberToAntTextType(pl_day_percent_normalized)}>
        { fixDecimalPlaces(pl_day_percent_normalized * 100) + '%' }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Buying Power</Text>
      <Text  className={`${styles.capitalize}`} type="success">
        { formatMoneyAuto(account.buying_power) }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Cash</Text>
      <Text  className={`${styles.capitalize}`} type="success">
        { formatMoneyAuto(account.cash) }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Options Trading On Expiration</Text>
      <Text  className={`${styles.capitalize}`} type="success">
        { account.option_trading_on_expiration_enabled ? 'Yes' : 'No' }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Equity Lock</Text>
      <Text  className={`${styles.capitalize}`} type="success">
        { removeUnderscores(account.equity_trading_lock) }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Options Lock</Text>
      <Text  className={`${styles.capitalize}`} type="success">
        { removeUnderscores(account.option_trading_lock) }
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Options Level</Text>
      <Text  className={`${styles.capitalize}`} type="success">
        { removeUnderscores(account.option_level) }
      </Text>
    </div>
  </div>
}

export default RobinhoodAccountDetails;