import { useCallback } from "react";
import { BasicChatroom } from "../api/chat";
import { DMRequestStatusChangedEventPayload, NewChatroomMessagePayload } from "./cent/chatroom-events";
import useChatroomCentEvents from "./cent/use-chatroom-cent-events";
import { useAppDispatch } from "../redux/hooks";
import { handleNewMessage } from './redux/thunks/handle-new-message.thunk';
import { changeDMRequestStatus } from "./redux/chat.slice";

/**
 * 
 * Subscribes to events for this chatroom
 * and dispatches necessary actions.
 * 
 */
export const ChatroomSubscription = ({ chatroom }: { chatroom: BasicChatroom; }) => {

  const dispatch = useAppDispatch();

  const newMessage = useCallback((message: NewChatroomMessagePayload) => {
    dispatch(handleNewMessage(message));
  }, [chatroom.id]);

  const dmRequestStatusChanged = useCallback((payload: DMRequestStatusChangedEventPayload) => {
    dispatch(changeDMRequestStatus({ roomId: chatroom.id, status: payload.status }));
  }, [chatroom.id]);

  useChatroomCentEvents(
    chatroom,
    {
      newMessage,
      dmRequestStatusChanged,
    }
  );

  return null;
};
