import { AxiosRequestConfig } from 'axios';
import { NO_TOKEN_PATHS } from '../constants';
import getStore from '../../auth/store';

export default async function attachToken(config : AxiosRequestConfig<any> ) {

    // some api endpoints don't need access token
    let path = (config.url || '').replace(/\/$/, '');
    if (NO_TOKEN_PATHS.includes(path)) {
        return config;
    }

    // get access token expiry date
    let authInfo = getStore().getTokens();
    let accessToken = authInfo?.access_token || null;

    if (accessToken) {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return config;
};
