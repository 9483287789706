import { Button, Form, Input, message, Select } from "antd"
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import api from "../../api";
import { CreateContactInquiryRequest } from "../../api/contact-inquiry";
import { contactReasonLabels } from "../../api/enum-labels";
import { ContactReason } from "../../api/enums";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { getErrorMessage } from "../../shared/utils";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const ContactInquiryForm = () => {
  const { user } = useAuthUser();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish =  async (data: CreateContactInquiryRequest) => {
    try {

      setError(null);
      setSubmitting(true);
      await api.contactInquiry.create(data);
      messageApi.success("We will get back to you soon");

    } catch (e) {

      const errorMessage = getErrorMessage(e)
      messageApi.error(errorMessage);
      setError(e);

    } finally {

      setSubmitting(false);

    }
  }

  return <div>
    { contextHolder }
    {
      error && <ErrorMessage error={error} />
    }
    <Form
      onFinish={onFinish}
      initialValues={{
        name: user?.name || null,
        email: user?.email || null,
        reason: null,
        message: null,
      }}
      labelCol={{ span: 6 }} 
      wrapperCol={{ span: 16 }}>
      <Form.Item 
        label="Name" 
        name='name'
        rules={[
          { required: true, message: 'name is required' }
        ]}
        required
      >
        <Input disabled={!!user} />
      </Form.Item>
      <Form.Item 
        label="Email" 
        name='email' 
        required
        rules={[
          { required: true, message: 'email is required' }
        ]}   
      >
        <Input disabled={!!user}/>
      </Form.Item>
      <Form.Item 
        label="Reason" 
        name='reason' 
        required
        rules={[
          { required: true, message: 'reason is required' }
        ]}
      >
        <Select
          options={
            Object.values(ContactReason).map((value) => ({
              value,
              label: contactReasonLabels[value],
            }))
          }
        />
      </Form.Item>
      <Form.Item 
        label="Message" 
        name='message'
        rules={[
          { required: true, message: 'message is required' }
        ]}
        required
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        wrapperCol={{ sm: { offset: 6, span: 16 } }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            htmlType="submit"
            disabled={submitting}
            loading={submitting}
            size="large"
            type="primary"   
          >
            Submit
          </Button>
        </div>
      </Form.Item>

    </Form>
  </div>
}