import api from "../../../api";
import { DirectMessageRequestStatus } from "../../../api/chat";
import { createAppAsyncThunk } from "../../../redux/hooks";
import { changeDMRequestStatus } from "../chat.slice";

export const acceptDmRequest = createAppAsyncThunk('chat/accept-dm-request', 
  async (roomId: string, thunkApi) => {
    const { dispatch } = thunkApi;
    await api.chat.acceptDmRequest(roomId);
    dispatch(changeDMRequestStatus({ roomId, status: DirectMessageRequestStatus.Accepted }));
  }
)