import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useSWR from 'swr';
import api from "../../../api";
import { Col, Modal, Row, Skeleton, Switch } from "antd";
import { closeDmPrefsPopup } from "../../redux/chat.slice";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { ChatDMPreference } from "../../../api/chat";
import { Typography } from 'antd'
const { Text } = Typography;

export function DMPrefsPopup() {

  const dispatch = useAppDispatch();
  const open = useAppSelector(state => state.chat.dmPrefsPopup.open);
  const { data, error, isLoading, mutate } = useSWR(
    '/chat/dm-preference',
    api.chat.getChatDMPreference,
  );

  const onClose = () => {
    dispatch(closeDmPrefsPopup());
  }

  return <Modal 
    title="Direct Message Preference"
    open={open} 
    onCancel={onClose} 
    footer={null}
  >
    {
      isLoading && <Skeleton />
    }
    {
      error !== null && <ErrorMessage error={error} />
    }
    {
      !isLoading && !error && data !== undefined && <DMPrefsForm
        onSuccess={() => mutate()}
        initialPrefs={data}
      />
    }
  </Modal>
}

function DMPrefsForm({
  initialPrefs,
  onSuccess,
} : {
  initialPrefs: ChatDMPreference | null,
  onSuccess: () => void,
}) {
  const [allow, setAllow] = useState(true);

  useEffect(() => {
    setAllow(initialPrefs === null ? true : initialPrefs.allow_dm);
  }, [initialPrefs]);

  useEffect(() => {
    const controller = new AbortController();
    const update = async () => {
      await api.chat.updateDMPreference({ allow_dm: allow }, controller.signal);
      onSuccess();
    }
    update();
    return () => controller.abort();
  }, [allow, onSuccess]);

  return <div style={{ padding: 8, marginTop: 20, display: 'flex' }}>
    <Row justify={'space-between'} style={{ width: '100%' }}>
      <Col>
        <Text>Allow DM</Text>
      </Col>
      <Col>
        <Switch
          checked={allow}
          onChange={setAllow}
        />
      </Col>
    </Row>
  </div>
}