import { RobinhoodConnectStep, useRobinhoodConnectContext } from "../context/RobinhoodConnectContext"
import { Complete } from "./steps/Complete";
import { Login } from "./steps/Login";
import { MFA } from "./steps/MFA";

export const RobinhoodConnect = () => {
  const {
    step
  } = useRobinhoodConnectContext();

  return <div>
    { step === RobinhoodConnectStep.Credentials && <Login/> }
    { step === RobinhoodConnectStep.MFA && <MFA /> }
    { step === RobinhoodConnectStep.Complete && <Complete /> }
  </div>
}