import { Button, Divider, FloatButton, Modal, Space, Typography } from "antd";
import { useState } from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

export function HelpButton() {
  const [open, setOpen] = useState(false);

  return <>
    <SupportPopup open={open} onClose={() => setOpen(false)} />
    <FloatButton
      onClick={() => setOpen(true)}
      icon={<QuestionCircleOutlined />}
      type="primary"
      style={{ insetInlineEnd: 24 }}
      tooltip="Need Help?" />
  </>
}


function SupportPopup({
  open,
  onClose,
} : {
  open: boolean,
  onClose: () => void,   
}) {
  return <Modal
    title="Need Help?" 
    open={open} 
    onCancel={onClose} 
    footer={null}
  >
    <Divider />
    <Space direction="vertical" size={"large"}>
      <div>
        <Typography.Title level={3}>
          Discord
        </Typography.Title>
        <Typography.Paragraph>
          Join our Discord community for support and strategy sharing
          {/* Join our community on Discord for real-time support and discussions */}
        </Typography.Paragraph>
        <Link to={JOIN_DISCORD_LINK}>
          <Button type="primary">
            Join Discord Server
          </Button>
        </Link>
      </div>
      <div>
        <Typography.Title level={3}>
          Daily Q/A and Support-Hour
          {/* Google Meet */}
        </Typography.Title>
        <Typography.Paragraph>
          {/* Join our daily Google Meet helpdesk call for assistance with questions or setup support. */}
          Join our daily Google Meet helpdesk session for questions or setup assistance
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Time:</strong> 12:00 PM - 12:45 PM <br/>
          <strong>Time Zone:</strong> America/New_York <br/>
        </Typography.Paragraph>
        <Link to={GOOGLE_MEET_LINK}>
          <Button type="primary">
            Google Meet Link
          </Button>
        </Link>
      </div>
    </Space>
  </Modal>
}

const JOIN_DISCORD_LINK = 'https://discord.gg/J7vrpSAakS'
const GOOGLE_MEET_LINK = 'https://meet.google.com/ayi-pdaq-fbj'