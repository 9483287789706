import { InputNumber, Select } from "antd";
import { TrailType } from "../../../api/enums"

export const TrailInput = ({
  trailType,
  trailValue,
  setTrailType,
  setTrailValue,
  trailTypeOptions,
} : {
  trailType: TrailType | null,
  trailValue: number | null,
  setTrailType: (trailType: TrailType | null) => void,
  setTrailValue: (trailValue: number | null) => void,
  trailTypeOptions: TrailType[]
}) => {
  const typeSelector = <Select
    options={trailTypeOptions.map((option) => ({
      label: {
        [TrailType.Dollar]: '$',
        [TrailType.Percentage]: '%',
      }[option],
      value: option,
    }))}
    value={trailType}
    onChange={(value) => setTrailType(value as TrailType)}
    style={{ minWidth: 60 }}
  />
  return <InputNumber
    addonBefore={typeSelector}
    style={{ display: 'block', width: '100%' }}
    placeholder="Trail"
    value={trailValue}
    onChange={(value) => setTrailValue(value)}
  />;
}