import { Button, Modal } from "antd"
import { useState } from "react"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { StockTickerAutocomplete } from "../../trade/components/inputs/StockTickerAutocomplete"
import { useWatchList } from "../context/watchlist-context"
import { 
  PlusOutlined,
} from '@ant-design/icons';

export const AddToWatchlistModal = ({
  open,
  setOpen,
  onSuccess,
} : {
  open: boolean,
  setOpen: (open : boolean) => void,
  onSuccess?: () => void
}) => {

  const { add } = useWatchList();

  const [stock, setStock] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<any>(null);

  const addToWatchlist = async () => {

    try {

      setError(null);
      setProcessing(true);
      if (!stock) {
        throw new Error('please select stock');
      }
      await add(stock);
      if (onSuccess) {
        onSuccess();
      }

    } catch (e) {

      setError(e);

    } finally {

      setProcessing(false);

    }
  }

  return <Modal
    title="Add to watchlist"
    open={open}
    onCancel={() => setOpen(false)}
    footer={null}
  >
    <div style={{
        display: 'flex', 
        flexDirection: 'column', 
        gap: 16 
    }}>
      {
        error && <ErrorMessage error={error} />
      }
      <StockTickerAutocomplete
        searchOptions={true}
        searchStocks={true}
        style={{ width: "100%" }}
        value={stock}
        placeholder="Stock"
        setValue={setStock}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          onClick={addToWatchlist} 
          type='primary'
          disabled={processing}
          icon={<PlusOutlined/>}
        >
          Add
        </Button>
      </div>
    </div>
  </Modal>
}