import { Direction, OrderType, Side, StrikePreference, TimeInForce, TrailType } from "../../api/enums";
import { LastPriceQuote, StocksQuoteResponse } from "../../api/stocks-quote";
import { BrokerType } from "../../brokers/entities/broker-account.entity";
import { fixDecimalPlaces } from "../../shared/utils";

export const optionsLimitPriceRequired = (orderType : OrderType) => {
  return [OrderType.Limit, OrderType.StopLimit].includes(orderType);
}

export const optionsStopPriceRequired = (orderType : OrderType) => {
  return [OrderType.Stop, OrderType.StopTrail, OrderType.StopLimit].includes(orderType);
}

export const optionsGetSupportedOrderTypes = (brokerType?: BrokerType | null) => {
  const all = [
    OrderType.Limit,
    OrderType.Market,
    OrderType.Stop,
    OrderType.StopLimit,
  ];

  return all;
}


export const optionsGetSupportedDirections = (brokerType?: BrokerType | null) => {
  const all = [
    Direction.Call,
    Direction.Put,
  ];

  return all;
}

export const optionsGetSupportedSides = (brokerType?: BrokerType | null) => {
  const all = [
    Side.Buy,
    Side.Sell,
  ];

  return all;
}

export const optionsGetSupportedTIFS = (brokerType?: BrokerType | null, orderType?: OrderType | null) => {
  if (orderType === OrderType.Market) {
    return [ TimeInForce.Day ]
  }

  const all = [
    TimeInForce.Day,
    TimeInForce.GoodTillCancelled,
    // TimeInForce.ImmediateOrCancelled,
  ];

  if(brokerType === BrokerType.robinhood) {
    return [
      TimeInForce.Day,
      TimeInForce.GoodTillCancelled,
    ];
  }

  return all;
}

export const optionsGetSupportedTrailTypes = (brokerType?: BrokerType | null) : TrailType[]  => {
  const all : TrailType[] = [];

  return all;
}

export const optionsGetQuantity = (unitPrice: number, budget: number) => {
  // lotSize, batchSize
  const quantityMultipler = 100;

  // price of batch / lot
  const totalPrice = unitPrice * quantityMultipler;

  // number of options you can get with your budget
  return Math.max(Math.floor(budget / totalPrice), 1);
}

export const chooseStrikePrice = (
  prices: number[], 
  quote: LastPriceQuote | null, 
  preference: StrikePreference | null
) => {
  // return first price if stock quote or preference are not known
  if (preference == null || quote == null || prices.length <= 0) {
    return prices.length > 0 
      ? prices[0] 
      : null;
  }

  const lastPrice = quote.last_price
  const abovePrices = prices.filter(price => price > lastPrice);
  const belowPrices = prices.filter(price => price < lastPrice);

  const closest = getClosestPrice(lastPrice, prices);

  // in the money price (price just below current price)
  if (preference === StrikePreference.InTheMoney) {
    return belowPrices.length <= 0 
      ? closest
      : Math.max(...belowPrices);
  }

  // out the money price (price just above current price)
  if (preference === StrikePreference.OutTheMoney) {
    return abovePrices.length <= 0
      ? closest
      : Math.min(...abovePrices)
  }

  // closest price
  return closest;
}

export const getClosestPrice = (stockMidPrice: number, prices: number[]) => {

  if (prices.length <= 0) {
    return null;
  }

  // find out min price closest to midPrice (marketPrice)
  const closest = prices.reduce<{ price: number, minDelta: number } | null>(
    (acc, price) => {
      const delta = Math.abs(price - stockMidPrice);
      if (acc == null) {
        return { price, minDelta: delta }
      }
      if (delta < acc.minDelta) {
        return { price, minDelta: delta }
      }
      return acc;
    },
    null
  );
  

  if (closest === null) {
    return null;
  }

  return closest.price;
}