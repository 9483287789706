import { Button, Form, Input, InputNumber } from "antd"
import { useState } from "react";
import api from "../../api";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

export function ResetWebullPaperAccountForm({
  onSuccess
} : {
  onSuccess?: () => void
}) {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const handleFormSubmit = async (data: { balance: number }) => {
    try {

      setError(null);
      setSubmitting(true);
      await api.webullPaperAccount.reset(data);
      if (onSuccess) {
        onSuccess();
      }
  
    } catch(e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  }

  return <Form<{ balance: number }>
    onFinish={handleFormSubmit}
    layout="vertical"
  >
    <ErrorMessage error={error} />
    <Form.Item
      name='balance'
      label='Initial Balance'
      rules={[
        { required: true, message: 'Required' },
      ]}
    >
      <InputNumber
        addonBefore="$"
        style={{ width: '100%', display: 'block' }}
        min={1}
        precision={0}
      />
    </Form.Item>
    <Form.Item>
        <Button
          style={{ width: '100%' }}
          loading={submitting}
          disabled={submitting}
          type="primary"
          size='large'
          htmlType="submit"
        >
          Reset Paper Account
        </Button>
      </Form.Item>
  </Form> 
}