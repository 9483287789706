import { Modal } from "antd";
import { AssetType } from "../../api/enums";
import { OpenOrder } from "../../api/orders";
import { EditOptionOrderForm } from "./EditOptionOrderForm";
import { EditStockOrderForm } from "./EditStockOrderForm";

export const EditOrderModal = ({
  order,
  open,
  setOpen,
  onSuccess,
}: {
  order: OpenOrder,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSuccess?: () => void,
})  => {

  const handleOnSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
    setOpen(false);
  }

  const forms : Record<AssetType, React.ReactElement | null> = {
    [AssetType.Stocks]: <EditStockOrderForm
      order={order}
      onSuccess={handleOnSuccess}  
    />,
    [AssetType.Options]: <EditOptionOrderForm
      order={order}
      onSuccess={handleOnSuccess}
    />,
    [AssetType.Crypto]: null,
  };

  const form = forms[order.asset_type];

  return <Modal
    title="Edit Order"
    open={open} 
    footer={null} 
    onCancel={() => setOpen(false)}
    key={order.broker_order_id}
  >
    { form }
  </Modal>;
}