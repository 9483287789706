import { Button, Result } from "antd"
import { Link } from "react-router-dom"

export const Complete = () => {
  return <div>
    <Result
      status='success'
      title='Robinhood Connected'
      extra={[
        <Link to='/app/dashboard' replace>
          <Button type="primary" size="large">
            Back To Dashboard
          </Button>
        </Link>
      ]}
    />
  </div>
}