import { Button, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { fixDecimalPlaces, formatNumberAuto } from "../../shared/utils";
import styles from './acccount-balance-card.module.scss';
import { PremiumOutlinedButton } from "../../shared/components/PremiumButton";
import { CrownFilled } from '@ant-design/icons';
import { useCanBuyExtraCoins } from "../../candefi-memberships/hooks/useCanBuyExtraCoins";
import { useMaskInfoContext } from "../../shared/context/MaskInfoContext";
const { Text, Title } = Typography;

export const AccountBalanceCard = () => {
  const { masked } = useMaskInfoContext();
  const { user } = useAuthUser();
  const visible = true;

  const total = formatNumberAuto(fixDecimalPlaces(user?.total_coins || 0))
  const monthly = formatNumberAuto(fixDecimalPlaces(user?.monthly_coins || 0))
  const extra = formatNumberAuto(fixDecimalPlaces(user?.coin_balance || 0))
  const canBuyExtraCoins = useCanBuyExtraCoins();

  return <Card bodyStyle={{ paddingTop: 16, paddingBottom: 16,}}>
    <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', alignItems: 'flex-end', gap: '1rem' }}>
      <div>
        <Text type="success">CandeFi Coins</Text>
        <Title type={masked ? 'secondary' : undefined} style={{ marginTop: 4, marginBottom: 0 }}>
          { !masked ? total : <span style={{ display: 'inline-block', transform: 'translateY(10px)' }}>***</span> }
        </Title>
      </div>
      <div>
        <Text type="secondary" style={{ fontSize: '1.1rem' }}>
          { !masked ? monthly : '***' } (monthly) { !masked ? extra : '***' } (extra)
        </Text>
      </div>
    </div>
    <div style={{ marginTop: 8, width: '100%' }}>
      <Link to="/app/buy-coins">
        {
          !canBuyExtraCoins && (
            <PremiumOutlinedButton size="middle" style={{  }} icon={<CrownFilled/>} className={styles.rechargeButton}>
              Buy Extra Coins
            </PremiumOutlinedButton>
          )
        }
        {
          canBuyExtraCoins && (
            <Button size="middle" className={styles.rechargeButton} style={{  }} >
              Buy Extra Coins
            </Button>
          )
        }
    </Link>
    </div>
  </Card>
};
