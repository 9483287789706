import { Typography, Avatar, Button, Dropdown, Space, List } from "antd"
import { useSelectedBroker } from "../context/SelectedBrokerContext";
import { brokerTypeLabels } from "../../api/enum-labels";
import { useEffect } from "react";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { brokerIcons } from "./broker-icons";
import { SelectedBroker } from "../repos/selected-broker-repo";
import { BrokerType } from "../entities/broker-account.entity";
import { useMaskInfoContext } from "../../shared/context/MaskInfoContext";
import { DownOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

export const SelectBrokerDropdown = ({
  type = 'ghost'
}: {
  type?: 'primary' | 'ghost'
}) => {
  const { broker, selectBroker } = useSelectedBroker();
  const { user } = useAuthUser();
  const { masked } = useMaskInfoContext();

  // clear invalid broker account
  useEffect(() => {
    if (!user || !broker?.account?.id) {
      return
    }
    const selectedAccountId = broker?.account?.id;
    const accountIds = (user.broker_accounts || []).map(account => account.id);

    const brokerIdValid = accountIds.includes(selectedAccountId);
    if (!brokerIdValid) {
      selectBroker(null);
    }

  }, [broker, user, user?.broker_accounts, selectBroker])


  if (!user) {
    return null;
  }

  const options = (user.broker_accounts || []).reduce<SelectedBroker[]>((arr, curr) => {
    if (curr.type === BrokerType.webull) {
      return [
        ...arr,
        {
          account: curr,
          paper: false,
        },
        {
          account: curr,
          paper: true,
        }
      ]
    }
    return [
      ...arr,
      {
        account: curr,
        paper: false,
      }
    ]
  }, []);

  return <Dropdown
    dropdownRender={(node) => <List>{node}</List>}
    menu={{
      items: options.map(({ account, paper }) => ({
        key: `${account.id}:${paper?'paper':''}`,
        label: <div>
          {brokerTypeLabels[account.type]}&nbsp;{paper ? 'Paper' : ''} <br />
          <Text type="secondary">
            { !masked ? account.username : '******' }
          </Text>
        </div>,
        icon: <Avatar src={brokerIcons[account.type]} />,
      })),
      onClick: (info) => {
        const parts = info.key.split(":");
        const accountId = parseInt(parts[0]);
        const paper = parts[1] === 'paper';
        let account = user.broker_accounts.find(account => account.id === accountId);
        if (!account) {
          return
        }
        selectBroker({
          account,
          paper,
        });
      },
      selectedKeys: [`${broker?.account.id || ''}:${broker?.paper ? 'paper' : ''}`],
    }}
    placement="bottomLeft">
    <Button 
      type={type} 
      style={{ 
        display: 'flex', 
        height: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 16
      }}
    >
      {
        broker && <Space direction="horizontal">
          <Avatar src={brokerIcons[broker.account.type]} />
          <div style={{ textAlign: 'start' }}>
            <Title level={4} style={{ marginBottom: 0, fontWeight: 300 }}>
              {brokerTypeLabels[broker.account.type]}&nbsp;{broker.paper ? 'Paper' : ''}
            </Title>
            <Text type="success">Connected</Text>
          </div>
        </Space>
      }
      {
        !broker && <Space>
          {
            user.broker_accounts.length > 0
              ? 'Select Broker'
              : 'No Brokers'
          }
        </Space>
      }
      <Text type="secondary">
        <DownOutlined 
          size={16}
        />
      </Text>
    </Button>
  </Dropdown>
}