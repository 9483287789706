import { useAppSelector } from "../../redux/hooks";
import { SendMessage } from "./send-message";
import { MessagesList } from "./messages-list";
import { isDmChatroom } from "../utils";
import { DirectMessageRequestStatus } from "../../api/chat";
import { DMAcceptRequestUI } from "./dm-accept-request-ui";
import { DMRequestWaitingUI } from "./dm-request-waiting-ui";
import { currentChatroomSelector } from "../redux/chat.selectors";
import { useAuthUser } from "../../auth/hooks/useAuthUser";


export function ChatroomDetailPageBody() {
  const { user } = useAuthUser();
  const chatroom = useAppSelector(currentChatroomSelector);

  if (chatroom === null || user === null) {
    return null;
  }

  const isDM = isDmChatroom(chatroom);
  const isDMSender = isDM && chatroom.dm_request_sender!.id === user!.id.toString();
  const isDMRecipient = isDM && chatroom.dm_request_recipient!.id === user!.id.toString();
  const dmStatus = chatroom.dm_request_status || null;

  const showMessages = (!isDM || dmStatus === DirectMessageRequestStatus.Accepted);
  const showAcceptRequestUi = isDM && dmStatus !== DirectMessageRequestStatus.Accepted && isDMRecipient;
  const showWaitingForRequestUi = isDM && dmStatus !== DirectMessageRequestStatus.Accepted && isDMSender;

  return <>
    {
      showMessages && (
        <>
          <MessagesList />
          <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto', padding: 8 }}>
            <SendMessage />
          </div>
        </>
      )
    }
    { showAcceptRequestUi && <DMAcceptRequestUI /> }
    { showWaitingForRequestUi && <DMRequestWaitingUI /> }
  </>;
}
