import axios from "./axios";
import { ListRequest } from "./types";

export class ListEarningsRequest extends ListRequest {
  plan_ids?: Array<number>
  start_date: string
  end_date: string

  constructor(start_date : string, end_date : string) {
    super();
    this.start_date = start_date;
    this.end_date = end_date;
  }
}

export type EarningsMembershipPlan = {
  id: number;
  name: string;
  active: boolean;
  stocks: boolean;
  options: boolean;
}

export type EarningsItem = {
  year: number
  month: number
  earnings: number
  plan: EarningsMembershipPlan
}

const analystEarningsApi = {
  async getMonthlyEarnings(request: ListEarningsRequest) {
    const { data } = await axios.get<Array<EarningsItem>>('/analyst-earnings/monthly', {
      params: request,
    });
    return data;
  }
}

export default analystEarningsApi;