import { useEmotionCss } from "@ant-design/use-emotion-css";
import { useThemeContext } from "../../shared/context/ThemeContext";
import dark3456 from './screenshots/3456_dark.png'
import light3456 from './screenshots/3456_light.png'
import dark1920 from './screenshots/1920_dark.png'
import light1920 from './screenshots/1920_light.png'
import dark1200 from './screenshots/1200_dark.png'
import light1200 from './screenshots/1200_light.png'
import dark992 from './screenshots/992_dark.png'
import light992 from './screenshots/992_light.png'
import dark768 from './screenshots/768_dark.png'
import light768 from './screenshots/768_light.png'
import dark576 from './screenshots/576_dark.png'
import light576 from './screenshots/576_light.png'

export function BlurredScreenshotBackground({ children }: { children: React.ReactNode; }) {
  const { isDark } = useThemeContext();

  const images = {
    '576': isDark ? dark576 : light576,
    '768': isDark ? dark768 : light768,
    '992': isDark ? dark992 : light992,
    '1200': isDark ? dark1200 : light1200,
    '1920': isDark ? dark1920 : light1920,
    '3456': isDark ? dark3456 : light3456,
  } as const;

  const blurBackdrop = useEmotionCss(({ theme, token }) => ({
    width: '100%',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'start'
  }));

  const bgContainer = useEmotionCss(({ theme, token }) => {

    const imageCss = Object.fromEntries(
      Object.entries(images).map(([size, url]) => {
        return [ 
          `@media (min-width: ${size}px)`, 
          { backgroundImage: `url(${url})` } 
        ]
      })
    );

    return {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      display: 'flex',
      backgroundImage: `url(${images['576']})`,
      ...imageCss,
    }
  });

  return <div className={bgContainer}>
    <div className={blurBackdrop}>
      {children}
    </div>
  </div>
}
