import { Button, Result } from "antd"
import {
  LinkOutlined
} from '@ant-design/icons';
import { useStripeLogo } from "../hooks/useStripeLogo";
import { useStripeConnect } from "../hooks/useStripeConnect";

export const ConnectStripe = () => {

  const logo = useStripeLogo();

  return <Result
    title="Connect Your Stripe Account"
    subTitle="
    Connect your stripe account, to receive payouts.
    If you've just connected your stripe account, please check back in a few minutes.
    We've partned with Stripe for secure payouts and financial services.
    You will receive payouts for your membership plans into this account.
    "
    icon={<img src={logo} style={{ maxWidth: 300 }}/>}
    extra={<StripeConnectButton/>}
  />
}

const StripeConnectButton = () => {
  const {
    createLink,
    creating
  } = useStripeConnect();

  return <Button 
    icon={<LinkOutlined />}
    type="primary" 
    size="large"
    loading={creating}
    onClick={(e) => createLink()}
  >
    Connect
  </Button>
}