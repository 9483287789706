import { Button, Card, Table } from 'antd';
import { useState } from 'react';
import useSWR from 'swr';
import { useSelectedBroker } from '../../../brokers/context/SelectedBrokerContext';
import api from '../../../api';
import { ListBotAlertRequest } from '../../../api/autotrade-alerts';
import { useResponsiveTable, useResponsiveTableWrapper } from '../../../shared/styles/useResponsiveTable';
import { AlertRow } from './alert-row';
import { AutotradeAlertEventPayload } from '../../cent/events';
import { useAutotradeCentEvents } from '../../cent/use-autotrade-cent-events';

export function AutotradeAlertsTable() {
  const { broker } = useSelectedBroker()

  const [day, setDay] = useState(7);

  const {
    data,
    isLoading,
    mutate,
  } = useSWR(['/autotrade-alerts', broker?.account?.id || null, broker?.paper || false, day], (key) => {
    const day = key[3];
    const page = 1;
    const request = new ListBotAlertRequest();
    request.max_age_days = day;
    request.page = page;
    request.per_page = 100;
    return api.autotradeAlerts.list(request);
  });

  // refresh data when alert related event is received
  const onAlertEvent = (payload: AutotradeAlertEventPayload) => {
    mutate();
  }

  // listen to autotrade related events
  // and call the callback above
  useAutotradeCentEvents({
    onAlertCreated: onAlertEvent,
    onAlertUpdated: onAlertEvent,
  })

  const responsiveTable = useResponsiveTable();

  const responsiveTableWrapper = useResponsiveTableWrapper({
    columnsMobile: 10,
    rowsDesktop: 7,
  })

  return <Card size='small'>
    <div style={{ marginBottom: 20, overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
      {[7, 14, 30].map((dayOption) => (
        <Button
          key={dayOption}
          type={ dayOption === day ? 'primary' : 'default' }
          onClick={() => setDay(dayOption)}
        >
          { dayOption } Days
        </Button>
      ))}
    </div>
    <div className={responsiveTableWrapper}>
      <Table
          loading={isLoading}
          dataSource={data?.data}
          className={responsiveTable}
          pagination={false}
          rowKey={(record) => record.id}
          onRow={(record, index) =>
            ({ record, index } as React.HTMLAttributes<any>)
          }
          components={{
            body: {
              row: AlertRow,
            },
          }}
          columns={[
            { title: "Bot" },
            { title: "Date" },
            { title: "Autotraded" },
            { title: "Asset" },
            { title: "Action" },
            { title: "Symbol" },
            { title: "Price" },
            { title: "Qty" },
            { title: "" },
          ]}
      />
    </div>
  </Card>
}

// bot name
// Options / Stocks
// BUY
// AAPL C strike date
// limit price
// quantity
// more info, place order

// more info
// - autotraded or not
// - error message
// - calcluated params
// - orders (details)
