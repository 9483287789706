import { createAppAsyncThunk } from "../../../redux/hooks";
import { NewChatroomMessagePayload } from "../../cent/chatroom-events";
import { appendMessages, incrementUnreadMessageCount } from "../chat.slice";
import mutex from "./mutex";

export const handleNewMessage = createAppAsyncThunk(
  'chat/handle-new-message',
  async (payload: NewChatroomMessagePayload, thunkApi) => {

    // Must run exclusively
    // See note on mutex variable for reason.
    mutex.runExclusive(async () => {
      const { getState, dispatch } = thunkApi;

      const chatroomId = getState().chat.selectedChatroomId;
      if (chatroomId !== null && chatroomId === payload.message.chatroom.id) {
        // remove chatroom from payload
        const { chatroom, ...basicMessage } = payload.message;
        dispatch(appendMessages([basicMessage]))
      } else {
        dispatch(incrementUnreadMessageCount({ chatroomId: payload.message.chatroom.id }));
      }
    });
  }
);