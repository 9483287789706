import { Card, Layout, Row } from 'antd';
import { Outlet, useMatch } from 'react-router-dom';
import { LargeLogo } from '../../shared/components/Logo';
import { useThemeContext } from '../../shared/context/ThemeContext';
import logoIcon from '../../shared/images/logo-dark-icon.png';
import logoText from '../../shared/images/logo-dark-text.png';
import { Guest } from '../components/Guest';
import { SyncUser } from '../components/SyncUser';

export const AuthLayout = () => {
  const { isDark } = useThemeContext();
  const isVerifyEmailPage = useMatch('/auth/verify-email') !== null;

  const layout = <Layout>
    <Layout.Content>
      <Row style={{ width: '100%', minHeight: '100vh', padding: 16 }}
        justify={'center'}
        align={'middle'}
      >
        <div style={{ width: 'min(100%, 450px)' }}>
          <div style={{ marginBottom: 24 }}>
            <LargeLogo />
          </div>

          <Card style={{ width: 'min(100%, 450px)' }}>
            <Outlet />
          </Card>
        </div>
      </Row>
    </Layout.Content>
  </Layout>

  if (isVerifyEmailPage) {
    return <>
      <SyncUser />
      { layout }
    </>
  }

  return <Guest>
    { layout }
  </Guest>
}