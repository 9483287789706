import { Avatar, Badge, List, Space, Tag } from "antd"
import { BasicChatroom } from "../../api/chat"
import { CurrentUserInfo } from "../../api/auth"
import { generateAvatarColor } from "../../shared/avatar-utils"
import { dmGetOtherUser, getTitle, isDmChatroom, isGroupChatroom } from "../utils"

export const ChatroomListItem = ({
  chatroom,
  user,
  unreadMessageCount,
  onClick,
  moderatorUserIds,
} : {
  chatroom: BasicChatroom,
  user: CurrentUserInfo,
  unreadMessageCount: number,
  onClick: () => void | Promise<void>,
  moderatorUserIds: number[],
}) => {
  const isDm = isDmChatroom(chatroom);
  const title = getTitle(chatroom, user.id);
  const avatarColor = generateAvatarColor(title);
  const titleTrimmed = title.slice(0, 2).toUpperCase();
  const subtitle = isDm
    ? '@' + dmGetOtherUser(chatroom, user.id)!.username
    : 'Signal Provider Group';
  const isTeamMember = isDm &&  moderatorUserIds.map(id => id.toString()).includes(dmGetOtherUser(chatroom, user.id)!.id)

  const avatar = <Badge count={unreadMessageCount}>
    <Avatar
      style={{ backgroundColor: avatarColor, position: 'relative' }}
    >
      { titleTrimmed }
    </Avatar>
  </Badge>

  return <List.Item
    style={{ cursor: 'pointer' }}  
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }}
  >
    <List.Item.Meta
      title={
        <Space size='small'>
          { title } {  isTeamMember &&  <Tag color='green'>CandeFi Team Member</Tag> }
        </Space>
      }
      avatar={avatar}
      description={subtitle}
    />
  </List.Item>  
}