export const useOptionsEstimate = ({
  quantity,
  limitPrice,
} : {
  quantity: number | null,
  limitPrice: number | null,
}) => {
  const lotSize = 100;
  if (!quantity || !limitPrice) {
    return null;
  }
  return quantity * lotSize * limitPrice;
}