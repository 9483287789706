import { Alert } from "antd";
import { getErrorMessage } from "./../utils";
import { Link } from "react-router-dom";
import { ApiWebullError } from "../../api/errors";

export const ErrorMessage = ({ error, style } : { error: unknown, style?: React.CSSProperties }) => {
  const alertStyle = style || { margin: '24px 0' }
  const message = getErrorMessage(error);
  if (message === null) {
    return null;
  }

  if (isWebullTokenExpiredError(error) || isWebullTokenExpiredErrorMessage(message)) {
    return <Alert style={alertStyle} type="error" message={<span>
      Webull refresh token expired. <Link to='/webull-connect'>Login again</Link>
    </span>} />
  }

  return <Alert style={alertStyle} type="error" message={message} />
}

function isWebullTokenExpiredError(error: any) {
  return error instanceof ApiWebullError && error.webullError?.code === 'trade.token.expire';
}

function isWebullTokenExpiredErrorMessage(message: string) {
  message = message.toLowerCase();
  return message.includes('webull') && message.includes('refresh') && message.includes('expired');
}