import { BasicChatroomMessage, BasicChatroomMessageSignal, BasicChatroomWithPermissionSummary, ChatModerator } from "../../api/chat";

export interface ChatState {
  myChatrooms: {
    data?: BasicChatroomWithPermissionSummary[];
    loading: boolean;
    error: unknown;
  };
  moderators: ChatModerator[],
  unreadMessageCount: Record<string, number>,
  selectedChatroomId: string | null;
  selectedChatroomMessages: MessagesListState | null;
  copySignalPopup: CopySignalPopupState,
  createDMChatroomPopup: CreateDmChatroomPopupState,
  dmPrefsPopup: DMPrefsPopupState,
  groupPrivacySettingsPopup: GroupPrivacySettingsPopupState,
  chatroomMembersPopup: ChatroomMembersPopupState,
  imageUploadPopup: ImageUploadPopupState,
  signalsFormatPopup: SignalsFormatPopupState,
}

export interface MessagesListState {
  initial: {
    loading: boolean,
    error: unknown,
  },
  prev: {
    loading: boolean,
    error: unknown,
    cursor: MessageCursor | null,
    hasMore: boolean,
  },
  next: {
    loading: boolean,
    error: unknown,
    cursor: MessageCursor | null,
    hasMore: boolean,
  },

  // stores both messages and draft messages
  // messages are sorted by created_at+uuid (oldest first)
  // they appear in similar manner in ui (old messages at top, new at bottom)
  messagesList: MessagesListItem[],

  messages: Record<string, BasicChatroomMessage>,

  drafts: Record<string, Draft>,
}

export interface GroupPrivacySettingsPopupState {
  open: boolean
}

export interface ImageUploadPopupState {
  open: boolean
}

export interface SignalsFormatPopupState {
  open: boolean
}

export interface CopySignalPopupState {
  open: boolean
  signal: BasicChatroomMessageSignal | null
}

export interface CreateDmChatroomPopupState {
  open: boolean
}

export interface DMPrefsPopupState {
  open: boolean
}

export interface ChatroomMembersPopupState {
  open: boolean
}

export interface MessageCursor {
  uuid: string;
  timestamp: number;
}

export type DraftMessageListItem = { type: 'draft'; id: string; };
export type PersistedMessageListItem = { type: 'persisted'; id: string; cursor: MessageCursor; };

export type MessagesListItem = DraftMessageListItem | PersistedMessageListItem;
export const isDraftMessageListItem = (item: MessagesListItem): item is DraftMessageListItem => item.type === 'draft';
export const isPersistedMessageListItem = (item: MessagesListItem): item is PersistedMessageListItem => item.type === 'persisted';

export type Draft = {
  id: string
  status: 'sending' | 'success' | 'failed'
  text: string | null
  image: string | null
  messageId: string | null
  error: unknown
}  

export const initialChatState: ChatState = {
  myChatrooms: {
    data: undefined,
    loading: false,
    error: null,
  },
  unreadMessageCount: {},
  moderators: [],
  selectedChatroomId: null,
  selectedChatroomMessages: null,
  copySignalPopup: {
    open: false,
    signal: null,
  },
  createDMChatroomPopup: {
    open: false,
  },
  dmPrefsPopup: {
    open: false,
  },
  groupPrivacySettingsPopup: {
    open: false,
  },
  chatroomMembersPopup: {
    open: false,
  },
  imageUploadPopup: {
    open: false,
  },
  signalsFormatPopup: {
    open: false,
  },
}

export const initialMessagesListState : MessagesListState =  {
  initial: {
    loading: true,
    error: null,
  },
  prev: {
    loading: false,
    error: null,
    cursor: null,
    hasMore: true,
  },
  next: {
    loading: false,
    error: null,
    cursor: null,
    hasMore: true,
  },
  messagesList: [],
  messages: {},
  drafts: {},
}