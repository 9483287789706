import { createAppAsyncThunk } from "../../../redux/hooks";
import { v4 as uuidV4 } from 'uuid';
import { Draft } from "../chat-state";
import { addOrUpdateDraft, appendDraftMessage } from "../chat.slice";
import api from "../../../api";
import mutex from "./mutex";

interface SendMessagePayload {
  text: string | null
  image: string | null
}

export const sendMessage = createAppAsyncThunk('chat/send-message', async (
  payload: SendMessagePayload,
  thunkApi
) => {

  const { getState, dispatch } = thunkApi;

  // chatroom must be defined
  const chatroomId = getState().chat.selectedChatroomId;
  if (!chatroomId) {
    return;
  }

  const { text, image } = payload;

  const id = uuidV4();

  const draft: Draft = {
    id,
    text,
    image,
    status: 'sending',
    error: null,
    messageId: null,
  };

  // update drafts hash
  dispatch(addOrUpdateDraft(draft));

  // Must run exclusively
  // See note on mutex variable for reason.
  mutex.runExclusive(async () => {
    // append to messages list
    dispatch(appendDraftMessage({ id: draft.id, type: 'draft' }))
  });

  try {

    const response = await api.chat.createMessage(chatroomId, {
      text: draft.text,
      image: draft.image,
    })

    dispatch(addOrUpdateDraft({
      ...draft,
      status: 'success',
      messageId: response.id,
    }))

  } catch (e) {

    dispatch(addOrUpdateDraft({
      ...draft,
      error: e,
      status: 'failed',
    }))

  }
});