import getStore from "./store";
import selectedBrokerRepo from '../brokers/repos/selected-broker-repo';
import { maskInfoRepo } from "../shared/context/mask-info-repo";

export async function logout() {
  const store = getStore();
  await Promise.all([
    store.setUser(null),
    store.setTokens(null),
    selectedBrokerRepo.removeBroker(),
    maskInfoRepo.setMasked(false),
  ]);
}
