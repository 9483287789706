import { InputNumber, Select } from "antd";
import { SpendLimit, SpendLimitType, spendLimitTypeLabels } from "./types";

export type SpendLimitInputProps = {
  value?: SpendLimit
  onChange: (value: SpendLimit) => void,
}

export const SpendLimitInput = ({
  value = { type: SpendLimitType.MaxAmount, value: null }, onChange
}: SpendLimitInputProps) => {

  const options = Object.values(SpendLimitType).map(type => ({
    value: type,
    label: spendLimitTypeLabels[type]
  }));

  const onTypeChange = (type: SpendLimitType) => {
    onChange({
      type,
      value: null,
    });
  };

  const onValueChange = (numberValue: number | null) => {
    onChange({
      type: value.type,
      value: numberValue,
    });
  };

  return <div style={{ display: 'flex', gap: 8 }}>
    <Select
      value={value?.type}
      options={options}
      onChange={onTypeChange}
      style={{ width: 150 }} />
    {value?.type === SpendLimitType.DefaultQuantity && <InputNumber
      onChange={onValueChange}
      value={value.value}
      style={{ flexGrow: 1 }}
      min={1}
      precision={0} />}
    {value?.type === SpendLimitType.MaxAmount && <InputNumber
      onChange={onValueChange}
      value={value.value}
      style={{ flexGrow: 1 }}
      min={1}
      precision={2}
      addonBefore="$" />}
  </div>;
};
