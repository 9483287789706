import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, message, Space, Typography } from "antd";
import { CreateMembershipPlanModal } from "../../membership-plans/components/CreateMembershipPlanModal";
import { UserRole } from "../../api/enums";
import { SignalProviderHeaderCard } from "../components/SignalProviderHeaderCard";
import { AddPlanIntro } from "../../membership-plans/components/AddPlanIntro";
import { useStripeConnectAccount } from "../../stripe-connect/hooks/useStripeConnectAccount";
import { StripeConnectAccountInfo } from "../../stripe-connect/components/StripeConnectAccountInfo";
import { ConnectStripe } from "../../stripe-connect/components/ConnectStripe";
import { useRefreshUser } from "../../auth/hooks/useRefreshUser";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { PlusOutlined, LineChartOutlined } from '@ant-design/icons';
import { useCardWithTopActions } from "../../shared/styles/useCardWithTopActions";
import { SignalProviderLink } from "../components/SignalProviderLink";
import { AnalystPlansList } from "../../membership-plans/components/AnalystPlansList";

const { Title, Text, Paragraph } = Typography;

const SignalProviderMembershipPlans = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const { user, initialized } = useAuthUser();
  const refresh = useRefreshUser();

  const signalProvider = user?.signal_providers[0];
  const membershipPlans = (signalProvider?.membership_plans || []);
  const { setupComplete: hasConnectedStripe } = useStripeConnectAccount();

  const hasMembershipPlans = membershipPlans.length > 0;

  const [messageApi, contextHolder] = message.useMessage();

  const cardClassName = useCardWithTopActions();

  useEffect(() => {
    if (!user || user.role !== UserRole.analyst) {
      return navigate('/app/dashboard', { replace: true });
    }
    if (user?.signal_providers.length <= 0) {
      return navigate('/app/account/analyst', { replace: true });
    }
  }, [navigate, user]);

  const handlePlanCreated = () => {
    messageApi.success("Plan Created");
    setShowCreateModal(false);
    refresh();
  }

  const openCreateModal = () => {
    setShowCreateModal(true);
  }

  if (!signalProvider) {
    return null;
  }

  return (
    <>
      {contextHolder}
      <Space direction="vertical" style={{ width: '100%' }}>
        <SignalProviderHeaderCard signalProvider={signalProvider} />
        <Card>
          {
            hasConnectedStripe
              ? <StripeConnectAccountInfo />
              : <ConnectStripe />
          }
        </Card>
        {
          hasConnectedStripe && <Card
            className={cardClassName}
            extra={
              <>
                <Link to='/app/account/analyst/stats'>
                  <Button
                    title="Earning Stats"
                    type="default"
                    icon={<LineChartOutlined />}>
                    Earning Stats
                  </Button>
                </Link>
                <Button
                  title="Create Plan"
                  icon={<PlusOutlined />}
                  onClick={openCreateModal}
                  type="primary"
                >
                  Create Plan
                </Button>
              </>
            }
            title="Plans"
            loading={!initialized}
            bordered={false}
          >
            {
              !hasMembershipPlans && <AddPlanIntro
                onClick={openCreateModal}
              />
            }

            {
              hasMembershipPlans && <AnalystPlansList
                plans={membershipPlans}
                refresh={refresh}
              />
            }
          </Card>
        }
        {
          user?.signal_providers && user?.signal_providers.length > 0 && <Card>
            <Title level={3}>Your Analyst Page</Title>
            <Paragraph type="secondary">
              {/* Share this unique link of your membership plan with your followers */}
              Share this exclusive link to your membership plan with your followers:

            </Paragraph>
            <Card size="small">
              <SignalProviderLink />
            </Card>
          </Card>
        }
      </Space>

      <CreateMembershipPlanModal
        signalProviderId={signalProvider.id}
        open={showCreateModal}
        setOpen={setShowCreateModal}
        onSuccess={handlePlanCreated}
      />
    </>
  );
};

export default SignalProviderMembershipPlans;
