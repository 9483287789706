import axios from './axios';
import { GenericIDResponse, GenericStatusResponse } from './types';

export interface MFAImage {
  backImage: string
  slidingImage: string
  yPos: string
}

export interface CreateWebullConnectSessionResponse {
  id: number
  new_device: boolean
}

export interface WebullShortLoginRequest {
  email: string
  password: string
  trading_pin: string
}

export interface GetMFAImageRequest {
  email: string
}

export interface CheckMFAImageRequest {
  email: string
  xPos: number
}

export interface CheckMFAImageResponse {
  status: boolean
  image: MFAImage | null
}

export interface GetSecurityQuestionRequest {
  email: string
  alternate: boolean
}

export interface SecurityQuestion {
  questionId: string
  questionName: string
}

export interface CheckSecurityAnswerRequest {
  email: string
  question_id: string
  answer: string
}

export interface CheckSecurityAnswerResponse {
  checkResult: boolean
}

export interface LoginRequest {
  email: string
  password: string
  x_pos: string
  security_question_id: string
  security_question_answer: string
  trading_pin: string
}

export interface CreateWebullConnectSessionRequest {
  email: string
}

const webullConnectApi = {
  async creteSession(request: CreateWebullConnectSessionRequest) {
    const { data } = await axios.post<CreateWebullConnectSessionResponse>('/webull/connect-sessions', request);
    return data;
  },

  async getMFAImage(sessionId: number, request: GetMFAImageRequest) {
    const { data } = await axios.patch<MFAImage>(`/webull/connect-sessions/${sessionId}/get-mfa-image`, request);
    return data;
  },

  async checkMFAImage(sessionId: number, request: CheckMFAImageRequest) {
    const { data } = await axios.patch<CheckMFAImageResponse>(`/webull/connect-sessions/${sessionId}/check-mfa-image`, request);
    return data;
  },

  async getSecurityQuestion(sessionId: number, request: GetSecurityQuestionRequest) {
    const { data } = await axios.patch<SecurityQuestion>(`/webull/connect-sessions/${sessionId}/security-question`, request);
    return data;
  },

  async checkSecurityAnswer(sessionId: number, request: CheckSecurityAnswerRequest) {
    const { data } = await axios.patch<CheckSecurityAnswerResponse>(`/webull/connect-sessions/${sessionId}/check-security-answer`, request);
    return data;
  },

  async login(sessionId: number, request: LoginRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(`/webull/connect-sessions/${sessionId}/login`, request);
    return data;
  },

  async loginShort(sessionId: number, request: WebullShortLoginRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(`/webull/connect-sessions/${sessionId}/login-short`, request);
    return data;
  }
}

export default webullConnectApi;