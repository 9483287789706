import { InputNumber } from "antd";

export const QuantityInput = ({
  value,
  setValue,
  defaultValue,
  precision = 0
}: {
  value: number | null;
  setValue: (value: number | null) => void;
  defaultValue?: number;
  precision?: number
}) => {
  return (
    <InputNumber
      style={{ display: "block", width: "100%" , minWidth: 100}}
      placeholder="Quantity"
      defaultValue={defaultValue}
      value={value}
      onChange={(value) => setValue(value)}
      // width={10}
      min={1}
      precision={precision}
    />
  );
};
