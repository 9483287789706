import { Card, notification } from "antd";
import { AutotradePreferenceFormSection } from "../../autotrade/components/preference/form-section";

export const AutotradePreference = () => {
  const [notificationApi, contextHolder] = notification.useNotification();

  const onSuccess = () => {
    notificationApi.success({ message: 'Preference updated' });
  }

  return (
    <Card
      title="AutoTrade Preference"
      style={{ height: "100%" }}
      bordered={false}
    >
      { contextHolder }
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: `min(100%, 1000px)` }}>
          <AutotradePreferenceFormSection 
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </Card>
  );
};
