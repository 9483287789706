import { ConfigProvider, Radio, Typography } from 'antd';
import { useState } from 'react';
import { CreateAnalystSignupRequestForm } from '../../analyst-signup-request/components/CreateAnalystSignupRequestForm';
import { SignupForm } from '../components/SignupForm';
import styles from './signup.module.scss';
import { usePrimaryColor } from '../../usePrimaryColor';
import { UserRole } from '../../api/enums';
import { SignupPageTitle } from '../components/SignupPageTitle';

const { Title, Text } = Typography;
type SignupFormType = 'trader' | 'analyst'

export const Signup = () => {
  const [form, setForm] = useState<SignupFormType>('trader');

  const primaryColor = usePrimaryColor(
    form === 'analyst' || undefined,
  );

  const role : UserRole = {
    'trader': UserRole.trader,
    'analyst': UserRole.analyst
  }[form];

  return <ConfigProvider
    theme={{
      token: {
        colorPrimary: primaryColor,
      }
    }}
  >
    <div>
      <SignupPageTitle />
      <Radio.Group
        optionType='button'
        buttonStyle='solid'
        className={styles.tabs}
        size='large'
        value={form}
        onChange={(e) => {
          setForm(e.target.value as SignupFormType)
        }}
        options={[
          { label: 'Trader', value: 'trader' },
          { label: 'Analyst', value: 'analyst' },
        ]}
      />
      <SignupForm role={role} />
      {/* {
        form === 'trader' && <SignupForm />
      }
      {
        form === 'analyst' && <CreateAnalystSignupRequestForm />
      } */}
    </div>
  </ConfigProvider>
}