import dayjs from 'dayjs';
import { TimeInForce } from "../../api/enums";
import { EditTradePreferenceRequest, Preference } from "../../api/user-trade-preference";
import { PreferenceFormValues, SpendLimitType } from "./types";

export const dayJSDateFormat = "YYYY-MM-DD"

/**
 * transform data from api to format understood by form
 * 
 * @param tradePreference 
 * @returns 
 */
export function toTradePreferenceFormValues(tradePreference: Preference | null): PreferenceFormValues {
  const expiryDate = tradePreference?.manual_expiry_date || null;
  return {
    spend_limit: {
      type: tradePreference?.default_quantity === null
        ? SpendLimitType.MaxAmount
        : SpendLimitType.DefaultQuantity,
      value: tradePreference?.default_quantity === null
        ? tradePreference?.max_amount || null
        : tradePreference?.default_quantity || null
    },
    stop_loss: tradePreference?.stop_loss || 0,
    take_profit: tradePreference?.take_profit || 0,
    trailing_stop: tradePreference?.trailing_stop || 0,
    time_in_force: tradePreference?.time_in_force || TimeInForce.Day,
    strike_preference: tradePreference?.strike_preference || null,
    expiry_preference: tradePreference?.expiry_preference || null,
    options_direction_preference: tradePreference?.options_direction_preference || null,
    order_type_preference: tradePreference?.order_type_preference || null,
    manual_expiry_date: !!expiryDate ? dayjs(expiryDate, dayJSDateFormat) : null,
    broker: (tradePreference?.broker_account === null || tradePreference?.broker_account === undefined) 
      ? null
      : { account: tradePreference.broker_account, paper: tradePreference.broker_account_paper  }
  }
}

/**
 * 
 * transform form values back to format understood by api
 * 
 * @param formValues 
 * @returns 
 */
export function toEditTradePreferenceRequest(formValues: PreferenceFormValues) : EditTradePreferenceRequest {

  const { spend_limit, manual_expiry_date , broker, ...rest } = formValues;

  const maxAmount = spend_limit.type === SpendLimitType.MaxAmount
    ? spend_limit.value
    : null;

  const defaultQuantity = spend_limit.type === SpendLimitType.DefaultQuantity
    ? spend_limit.value
    : null;

  const brokerAccount  = formValues?.broker?.account || null;
  const brokerAccountPaper = formValues?.broker?.paper === undefined 
    ? false
    : formValues?.broker?.paper;

  return {
    ...rest,
    max_amount: maxAmount,
    default_quantity: defaultQuantity,
    manual_expiry_date: manual_expiry_date?.format(dayJSDateFormat) || null,
    broker_account_id: brokerAccount?.id || null,
    broker_account_paper: brokerAccountPaper
  }
}