import { Card } from "antd";
import { useState } from "react";
import { useStretchedTabs } from "../../shared/styles/useStretchedTabs";
import { TradeOptions } from "./TradeOptions";
import { TradeStocks } from "./TradeStocks";
import { UserRole } from "../../api/enums";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export enum QuickTradeTabKeys {
  Options = "options",
  Stocks = "stocks",
  // Crypto = "crypto",
}

export const QuickTrade = ({
  syncWithWatchlist = false
}: {
  syncWithWatchlist?: boolean
}) => {
  const [tab, setTab] = useState<QuickTradeTabKeys>(QuickTradeTabKeys.Options);

  const stretchedTabs = useStretchedTabs();

  const { user } = useAuthUser();

  // const cardTitle = user?.role === UserRole.analyst
  //   ? 'Smart Trader' : 'Quick Trade'

  const cardTitle = 'Smart Trader'

  return (
    <Card
      style={{ width: '100%' }}
      title={cardTitle}
      size="small"
      className={stretchedTabs}
      tabList={[
        {
          key: QuickTradeTabKeys.Options,
          tab: "Options",
        },
        {
          key: QuickTradeTabKeys.Stocks,
          tab: "Stocks",
        },
        // {
        //   key: QuickTradeTabKeys.Crypto,
        //   tab: "Crypto",
        // },
      ]}
      onTabChange={(tab) => setTab(tab as QuickTradeTabKeys)}
      tabProps={{
        size: "middle",
        centered: true,
      }}
    >
      {
        tab === QuickTradeTabKeys.Options && (
          <TradeOptions syncWithWatchlist={syncWithWatchlist} />
        )
      }
      {
        tab === QuickTradeTabKeys.Stocks && (
          <TradeStocks syncWithWatchlist={syncWithWatchlist} />
        )
      }
    </Card>
  );
};
