import { InputNumber } from "antd"
import { DatabaseOutlined } from '@ant-design/icons';

export const QuantityInput = ({
  value,
  setValue,
  precision = 0,
} : {
  value: number | null,
  setValue: (value : number | null) => void,
  precision?: number,
}) => {
  return <InputNumber
    addonBefore={<DatabaseOutlined/>}
    style={{ display: 'block', width: '100%' }}
    placeholder="Quantity"
    value={value}
    onChange={(value) => setValue(value)}
    min={1}
    precision={precision}
  />
}