import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button, message } from "antd";
import { useState } from "react";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

const CheckoutForm = ({ redirectUrl } : { redirectUrl: string }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const stripe = useStripe();
  const elements = useElements();

  const { user } = useAuthUser();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async (event: any) => {
    setSubmitting(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    setSubmitting(false);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      messageApi.error(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      {contextHolder}
      <form>
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: { email: user?.email, name: user?.name },
            },
          }}
        />
        <Button
          disabled={!stripe}
          loading={submitting}
          onClick={handleSubmit}
          style={{ marginTop: 20 }}
          size="large"
          type="primary"
        >
          Pay Now
        </Button>
      </form>
    </>
  );
};

export default CheckoutForm;
