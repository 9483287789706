import { useWebullConnectContext, WebullConnectStep } from "../contexts/WebullConnectContext"
import { EnterEmail } from "./steps/EnterEmail";
import { Button, Result, Typography } from "antd"; 
import { MFAImage } from "./steps/MFAImage";
import { MFASecurityQuestion } from "./steps/MFASecurityQuestion";
import { PasswordAndPin } from "./steps/PasswordAndPin";
import { Link } from "react-router-dom";
const { Title, Text } = Typography;

const StepHeader = ({ title, description } : {
  title: string,
  description: string
}) => {
  return <div style={{ textAlign: 'center' }} >
    <Title level={2}>{title}</Title>
    <Text>{ description }</Text>
  </div>
}

export const WebullConnect = () => {
  const { currentStep } = useWebullConnectContext();
  return <div>
    {
      currentStep === WebullConnectStep.Email && <div>
        <StepHeader 
          title={'Enter Email'} 
          description={'Enter your webull email address to continue'} />
        <EnterEmail />
      </div>
    }
    {
      currentStep === WebullConnectStep.MFAImage && <div>
        <StepHeader 
          title={'MFA Image'} 
          description={'Move the jigsaw block to its place by adjusting the slider'} />
        <MFAImage />
      </div>
    }
    {
      currentStep === WebullConnectStep.SecurityQuestion && <div>
        <StepHeader 
          title={'Security Question'} 
          description={'Provide Answer to security question'} />
        <MFASecurityQuestion />
      </div>
    }
    {
      currentStep === WebullConnectStep.PasswordAndPin && <div>
        <StepHeader 
          title={'Password and Pin'} 
          description={'Enter your password and trading pin'} />
        <PasswordAndPin />
      </div>
    }
    {
      currentStep === WebullConnectStep.Success && <div>
        <Result
          status="success"
          title="Webull Connect Successfully"
          subTitle="You have successfully connected to Webull"
          extra={[
            <Link to="/app/dashboard">
              <Button type="primary">
                Back To Dashboard
              </Button>
            </Link>
          ]}
        />
      </div>
    }
  </div>
}