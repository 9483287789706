import { OptionsExpiryPreference } from "../api/enums";
import { getNextAvailableOptionExpirationDate, isExpirationDateFriday, isExpirationDateInPast, isExpirationDayThisWeek, isLastFridayExpiryOfMonth } from "./options-expiration-date-utils";

/**
 * Gets next available expiration date.
 * 
 * @param {string[]} dates list of expirtion dates in yyyy-MM-dd format
 * @param {OptionsExpiryPreference | null} pref
 * @param {string | null} manualDate manually selected date in yyyy-MM-dd format: only for OptionsExpiryPreference.Manual
 * @returns {string | null}
 * 
 */
export function getPreferredExpirationDate(
  dates: string[],
  pref: OptionsExpiryPreference | null,
  manualDate: string | null,
) : string | null {

  // dates empty
  if (dates.length <= 0) {
    return null;
  }

  // no preference
  if (pref === null) {
    return getNextAvailableOptionExpirationDate(dates);
  }

  // manual
  if (pref === OptionsExpiryPreference.Manual) {
    if (manualDate === null) {
      return null;
    }
    const inPast = isExpirationDateInPast(manualDate);
    if (inPast) {
      return getNextAvailableOptionExpirationDate(dates);
    }
    return manualDate;
  }

  // closest
  if (pref === OptionsExpiryPreference.Closest) {
    return getNextAvailableOptionExpirationDate(dates);
  }

  // this friday
  if (pref === OptionsExpiryPreference.FridayExpiry) {
    const fridays = dates.filter(d => isExpirationDateFriday(d));
    return getNextAvailableOptionExpirationDate(fridays);
  }

  // next week friday
  if (pref === OptionsExpiryPreference.NextWeekFriday) {
    // fridays
    const fridays = dates.filter(d => isExpirationDateFriday(d));
    // filter out this week friday
    const nextWeekOnwards = fridays.filter(d => !isExpirationDayThisWeek(d));
    // 
    return getNextAvailableOptionExpirationDate(nextWeekOnwards);
  }

  // eom friday
  if (pref === OptionsExpiryPreference.EomFriday) {
    const eomFridays = dates.filter(d => isLastFridayExpiryOfMonth(d));
    return getNextAvailableOptionExpirationDate(eomFridays);
  }

  return null;
}