import useSWR from 'swr';
import { Alert, Button, notification } from 'antd';
import api from '../../../api';
import { AutotradePreferenceFormModal } from './form-modal';
import { useState } from 'react';

export function SetPreferenceAlert() {
  const {
    data,
    isLoading,
    error,
    mutate,
  } = useSWR('/autotrade-preference', api.autotradePreference.fetch);

  const [open, setOpen] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();

  if (!!error || isLoading || !!data) {
    return null;
  }

  return <>
    { contextHolder }
    <AutotradePreferenceFormModal
      onSuccess={() => {
        notificationApi.success({ message: 'Preference updated' });
        mutate();
        setOpen(false);
      }}
      isOpen={open}
      onClose={() => setOpen(false)}
    />
    <Alert 
      action={[ <Button onClick={() => setOpen(true)} type="link">Set autotrade preference</Button> ]}
      message='Before you begin, please set autotrade preference' />
  </>
}