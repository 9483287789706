import { Layout } from "antd";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { SelectedBrokerProvider } from "../../brokers/context/SelectedBrokerContext";
import { SideMenu } from "../../shared/components/SideMenu";
import { MainHeader } from "../../shared/components/MainHeader";
import { ShareTradeContextProvider } from "../../trade/context/share-trade-context";
import { Protected } from "../../auth/components/Protected";
import { SyncUser } from "../../auth/components/SyncUser";
import { PageContainer } from "../../shared/components/PageContainer";
const { Content } = Layout;

export const SignalProviderLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout hasSider>
      <SyncUser />
      <SideMenu 
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <Layout style={ collapsed ? { marginLeft: 80 } : { marginLeft: 250 } }>
        <MainHeader
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Content>
          <PageContainer>
            <Outlet />
          </PageContainer>
        </Content>
      </Layout>
    </Layout>
  );
};
