import { TinyColor } from "@ctrl/tinycolor"
import { ThemeConfig } from "antd/es/config-provider/context"
import { theme } from "antd";

export const getDarkTheme = (colorPrimary: string) : ThemeConfig => {
  
  const colorBgBase = '#111114';
  const colorBgContainer = (new TinyColor(colorBgBase)).lighten(8).toHexString();
  const colorBgTrigger = (new TinyColor(colorBgBase)).lighten(5).toHexString();

  const colorLinkHover = (new TinyColor(colorPrimary)).lighten(10).toHexString();
  const colorLinkActive = (new TinyColor(colorPrimary)).darken(10).toHexString();

  const themeConfig = {
    algorithm: theme.darkAlgorithm,
    token: {
      colorPrimary: colorPrimary,
      colorLink: colorPrimary,
      colorLinkActive: colorLinkActive,
      colorLinkHover: colorLinkHover,
      colorBgBase: colorBgBase,
    },
    components: {
      Layout: {
        colorBgHeader: colorBgContainer,
        colorBgTrigger: colorBgTrigger,
        colorBgBody: '#141415',
      },
    }
  } satisfies ThemeConfig

  return themeConfig
}

export const getLightTheme = (colorPrimary: string) : ThemeConfig => {

  const colorLinkHover = (new TinyColor(colorPrimary)).lighten(10).toHexString();
  const colorLinkActive = (new TinyColor(colorPrimary)).darken(10).toHexString();

  const themeConfig = {
    algorithm: theme.defaultAlgorithm,
    token: {
      colorPrimary: colorPrimary,
      colorLink: colorPrimary,
      colorLinkActive: colorLinkActive,
      colorLinkHover: colorLinkHover,
    },
    components: {
      Layout: {
        colorBgBody: '#e7e0f9',
        colorBgHeader: '#fff',
        colorBgTrigger: '#eee',
      },
    }
  }

  return themeConfig
}