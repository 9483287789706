import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { useAuthUser } from "../hooks/useAuthUser";

export const Protected = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const { initialized, user } = useAuthUser();

  // when user info is being fetched
  // render nothing
  if (!initialized) {
    return null;
  }

  // when the user is not logged in
  // redirect the user to login page
  // and save current page in state
  const isGuest = user === null;
  const isAnalystOrTrader = !isGuest && [UserRole.analyst, UserRole.trader].includes(user.role);
  if (isGuest || !isAnalystOrTrader) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  // redirect to verify email page
  if (user.email_verified_at === null) {
    return <Navigate to={`/auth/verify-email?email=${encodeURIComponent(user.email)}`} state={{ from: location }} replace />
  }

  // show component
  return <>{children}</>;
};
