import { Button, Table, Typography } from "antd";
import useSWR from "swr";
import { useState } from "react";
import { ClosePositionDialog } from "./ClosePositionDialog";
import Column from "antd/es/table/Column";
import api from "../../api";
import { OpenPosition } from "../../api/positions";
import { useResponsiveTable, useResponsiveTableWrapper } from "../../shared/styles/useResponsiveTable";
import { fixDecimalPlaces, formatMoneyAuto, numberToAntTextType } from "../../shared/utils";
import { assetTypeLabels, sideLabels } from "../../api/enum-labels";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";
import { getClosePositionFormValues } from "../utils/getClosePositionFormValues";
import * as positionUtils from '../utils/positions';
import { AssetType } from "../../api/enums";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

const OpenPositions = ({
  assetTypes = Object.values(AssetType),
} : {
  assetTypes?: AssetType[],
}) => {
  const { data, isLoading, mutate, error } = useSWR("/api/positions/open", () =>
    api.positions.getOpenPositions()
  );

  const responsiveTable = useResponsiveTable();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [placeOrderFormInitialValues, setPlaceOrderFormInitialValues] =
    useState<PlaceOrderFormInitialValues | null>(null);

  const filteredData = data === undefined
    ? undefined
    : data.filter(position => assetTypes.includes(position.asset_type));

  const responsiveTableWrapper = useResponsiveTableWrapper({
    rowsDesktop: 8,
    columnsMobile: 11,
  })

  return (
    <div>
      <ErrorMessage error={error}/>
      <div className={responsiveTableWrapper}>
        <Table
          dataSource={filteredData}
          loading={isLoading}
          pagination={false}
          rowKey={(record) => record.id}
          className={responsiveTable}
        >
          <Column 
            title="Asset" 
            dataIndex="asset_type" 
            key="asset_type" 
            render={(_, position: OpenPosition) => assetTypeLabels[position.asset_type]} 
          />
          <Column 
            title="Side" 
            dataIndex="side" 
            key="side" 
            render={(_, position: OpenPosition) => position.side !== null ? sideLabels[position.side] : ''} 
          />
          <Column
            title="Symbol"
            dataIndex="short_name"
            key="short_name"
            render={(_, record: OpenPosition) => {
              return <span style={{ whiteSpace: 'nowrap' }}>
                { positionUtils.getSymbol(record) }
              </span>
            }}
          />
          <Column 
            title="Qty" 
            dataIndex="quantity" 
            key="quantity"
            render={(_, position: OpenPosition) => positionUtils.getQuantity(position)}            
          />
          <Column 
            title="Mkt Price" 
            dataIndex="market_price" 
            key="market_price" 
            render={(_, position: OpenPosition) => {
              if (!position.market_price) {
                return null;
              }
              return formatMoneyAuto(
                fixDecimalPlaces(position.market_price, 3)
              );
            }}
          />
          <Column 
            title="Cost" 
            dataIndex="unit_cost" 
            key="unit_cost" 
            render={(_, position: OpenPosition) => {
              if (!position.unit_cost) {
                return null;
              }
              return formatMoneyAuto(
                fixDecimalPlaces(
                  position.unit_cost,
                  3
                )
              );
            }}
          />
          <Column 
            title="Total Cost" 
            dataIndex="total_cost" 
            key="total_cost" 
            render={(_, position: OpenPosition) => {
              if (!position.total_cost) {
                return null;
              }
              return formatMoneyAuto(
                fixDecimalPlaces(
                  position.total_cost,
                  3
                )
              );
            }}
          />
          <Column 
            title="Mkt Value" 
            dataIndex="market_value" 
            key="market_value" 
            render={(_, position: OpenPosition) => {
              if (!position.total_cost || !position.market_value) {
                return null;
              }
              return formatMoneyAuto(
                fixDecimalPlaces(
                  position.market_value,
                  3
                )
              );
            }}
          />
          <Column 
            title="P/L" 
            dataIndex="unrealized_profit_loss" 
            key="unrealized_profit_loss" 
            render={(_, position: OpenPosition) => {
              if (position.unrealized_profit_loss === null) {
                return null;
              }
              return <Typography.Text style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }} type={numberToAntTextType(position.unrealized_profit_loss)}>
                { 
                  formatMoneyAuto(
                    fixDecimalPlaces(
                      position.unrealized_profit_loss,
                      3
                    )
                  ) 
                }
              </Typography.Text>
            }}
          />
          <Column 
            title="P/L%" 
            dataIndex="unrealized_profit_loss" 
            key="unrealized_profit_loss_percent" 
            render={(_, position: OpenPosition) => {
              if (position.unrealized_profit_loss_rate === null) {
                return null;
              }
              return <Typography.Text  style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }} type={numberToAntTextType(position.unrealized_profit_loss_rate)}>
                { (position.unrealized_profit_loss_rate * 100).toFixed(2) + '%' }
              </Typography.Text>
            }}
          />


          <Column
            title=""
            key="close"
            render={(_, position: OpenPosition) => {
              return (
                <Button
                  type="primary"
                  onClick={() => {
                    const initialValues = getClosePositionFormValues(position);
                    if (initialValues === null) {
                      return;
                    }

                    setPlaceOrderFormInitialValues(initialValues);
                    setShowModal(true);
                  }}
                >
                  Close
                </Button>
              );
            }}
          />
        </Table>
      </div>
      {/* position confirm dialog */}
      {placeOrderFormInitialValues != null && (
        <ClosePositionDialog
          showModal={showModal}
          setShowModal={setShowModal}
          placeOrderFormInitialValues={placeOrderFormInitialValues}
          onSuccess={() => {
            mutate(undefined, true)
          }}
        />
      )}
    </div>
  );
};

export default OpenPositions;
