import axios from './axios';
import { ReferralStatus, UserRole } from './enums';
import { SignalProviderListItem, SignalProviderWithMembershipPlans } from './signal-providers';
import { ListRequest, PaginatedResponse } from './types';


export type ReferralRewardsInfo = {
  analyst_free_coins: number
  trader_free_coins: number
  refer_analyst_coins: number
  refer_trader_coins: number
}

export type MyReferralCode = {
  code: string
}

export type ReferralCodeInfo = {
  code: string
  username: string
  user_display_name: string
  user_role: UserRole
  signal_providers: SignalProviderWithMembershipPlans[] | null
}

export type MyReferral = {
  id: number
  coins: number
  status: ReferralStatus
  created_at: string
  updated_at: string
}

export type SignalProviderReferralCode = {
  code: string
}

export class MyReferralsRequest extends ListRequest {}

const referralsApi = {
  async getRewardsInfo() {
    const { data } = await axios.get<ReferralRewardsInfo>('/referral/rewards-info');
    return data;
  },

  async getMyCode() {
    const { data } = await axios.get<MyReferralCode>('/referral/my-code');
    return data;   
  },

  async getCodeInfo(code: string) {
    const { data } = await axios.get<ReferralCodeInfo>(`/referral/${code}`);
    return data;
  },

  async getSignalProviderReferralCode(signalProviderUsername: string) {
    const { data } = await axios.get<SignalProviderReferralCode | null>(`/referral/signal-provider/${signalProviderUsername}`);
    return data;
  },

  async getMyReferrals(request: MyReferralsRequest) {
    const { data } = await axios.get<PaginatedResponse<MyReferral>>('/referral/my-referrals', {
      params: request,
    });
    return data;
  }
}

export default referralsApi;