import { List, Table, Typography } from "antd";
import { useState } from "react";
import useSWR from 'swr';
import api from "../../api";
import { ListSubscribersMembershipsRequest, ListSubscribersPaymentsRequest } from "../../api/subscribers";
import { memberhshipStatusLabels } from "../../api/enum-labels";
import { formatDate, formatMoneyAuto } from "../../shared/utils";
const { Text } = Typography;

export function SubscribersPaymentsTable({
  planIds
}: {
  planIds: Array<number> | null;
}) {
  const pageSize = 10;
  const [page, setPage] = useState(1);

  const {
    data: memberships, isLoading,
  } = useSWR(
    ['subscribers', 'payments', planIds, page, pageSize],
    function (key) {
      const [_, __, planIds, page, per_page] = key;
      const request = new ListSubscribersPaymentsRequest();
      request.page = page;
      request.per_page = per_page;
      request.plan_ids = planIds || undefined;
      return api.subscribers.listPayments(request);
    }
  );

  return <Table
    size="small"
    loading={isLoading}
    dataSource={memberships?.data || []}
    pagination={{
      pageSize,
      current: page,
      total: memberships?.total,
    }}
    onChange={(pagination, filters, sorter, extra) => {
      if (pagination.current) {
        setPage(pagination?.current);
      }
    }}
    columns={[
      {
        key: 'plan',
        dataIndex: ['membership', 'membership_plan', 'name'],
        title: 'Plan',
      },
      {
        key: 'amount',
        dataIndex: 'amount',
        title: 'Amount',
        render: (_, entity) => {
          return formatMoneyAuto(entity.amount - entity.commission);
        }
      },
      {
        key: 'date',
        title: 'Date',
        render: (_, entity) => {
          return formatDate(entity.created_at);
        }
      }
    ]} />;
}
