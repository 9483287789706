import { Button, Form, Input, Typography } from "antd"
import { useState } from "react";
import { useRobinhoodConnectContext } from "../../context/RobinhoodConnectContext";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";

export const MFA = () => {

  const [error, setError] = useState<unknown>(null);
  const [submitting, setSubmitting] = useState(false);

  const {
    checkMFA,
    challenge
  } = useRobinhoodConnectContext();

  return <div>
    <div style={{ textAlign: 'center', marginBottom: 24}}>
      <Typography.Title level={2}>
        Enter OTP
      </Typography.Title>
      <Typography.Text type="secondary">
        {
          challenge === null 
            ? 'TOTP / Email OTP'
            : ''
        }
      </Typography.Text>
    </div>
    <Form<{ code: string }>
      layout="vertical"
      onFinish={async (values) => {
        try {
          setError(null);
          setSubmitting(true);
          await checkMFA(values.code);
        } catch (e) {
          setError(e);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <ErrorMessage error={error} />
      <Form.Item
        label="Code"
        name="code"
        rules={[
          { required: true, message: 'code is required' },
        ]}
        required
      >
        <Input />
      </Form.Item>
      <div>
        <Button 
          htmlType="submit"
          loading={submitting} 
          size="large" 
          type="primary"
          block
        >
          Login
        </Button>
      </div>
    </Form>
  </div>
}