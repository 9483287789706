import { UserRole } from "./api/enums";
import { useAuthUser } from "./auth/hooks/useAuthUser";
import { useMiscConfig } from "./misc-config/use-misc-config";

export function usePrimaryColor (isAnalystOverride?: boolean) {

  const { analyst_color, color } = useMiscConfig();
  const { initialized, user } = useAuthUser();

  const userIsAnalyst = initialized && user?.role === UserRole.analyst;

  const isAnalyst = isAnalystOverride !== undefined
      ? isAnalystOverride
      : userIsAnalyst

  const primaryColor =  isAnalyst    
    ? analyst_color 
    : color;

  return primaryColor;
}