import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { closeCreateDMPopup } from "../../redux/chat.slice";
import { Button, Input, Modal } from "antd";
import { createDMChatroom } from "../../redux/thunks/create-dm-chatroom";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";

export const CreateDMChatroomPopup = () => {
  const open = useAppSelector(state => state.chat.createDMChatroomPopup.open);
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState('');
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const onClose = () => {
    dispatch(closeCreateDMPopup());
  }

  const onCreate : React.MouseEventHandler = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    if (!username) {
      return;
    }

    try {

      setError(null); 
      setCreating(true);
      const usernameWithoutAt = username.replace(/^@/, '')
      await dispatch(createDMChatroom({ username: usernameWithoutAt })).unwrap();
      
    } catch(e) {

      setError(e);

    } finally {

      setCreating(false);

    }
  }

  useEffect(() => {
    setUsername('');
  }, [open]);

  return <Modal 
    open={open}
    onCancel={onClose}
    footer={null}
    title="Enter username to send direct message">
    <div style={{ marginBottom: 12 }}>
      <Input 
        placeholder="Enter username" 
        name="username" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
      />
    </div>
    {
      error !== null && <ErrorMessage error={error} />
    }
    <Button
      onClick={onCreate}
      disabled={!username || creating} 
      loading={creating}
      type="primary">
      Send DM
    </Button>
  </Modal>
}