import { MenuOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { Header } from "antd/es/layout/layout";
import { useMaskInfoContext } from "../context/MaskInfoContext";
import { useThemeContext } from "../context/ThemeContext";
import { AccountDropdown } from "./AccountDropdown";
import styles from './main-header.module.scss';
import { ThemeSwitcher } from "./ThemeSwitcher";
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

export const MainHeader = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void
}) => {

  const { isDark } = useThemeContext();

  const { masked, setMasked } = useMaskInfoContext();

  const toggleMask = () => {
    setMasked(!masked)
  }

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  }

  return <Header 
    style={{ backgroundColor: isDark ? '#141414' : '#fff'  }}
    className={styles.mainHeader}>
    <div className={styles.mainNav}>
      <div>
        <Button
          size="large"
          type="text"
          icon={<MenuOutlined />}
          onClick={toggleMenu}
        ></Button>
      </div>

      <div className={styles.mainMenu}>
        <ThemeSwitcher />
        <Tooltip title={masked ? 'Show sensitive info' : 'Hide sensitive info like account number etc'}>
          <Button type="ghost" onClick={toggleMask}>
            {
              masked ? <EyeInvisibleOutlined /> : <EyeOutlined />
            }
          </Button>
        </Tooltip>
        <AccountDropdown />
      </div>
    </div>
  </Header>
}