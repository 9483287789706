import { Select } from "antd"
import { orderTypeLabels } from "../../../api/enum-labels";
import { OrderType } from "../../../api/enums"

export const OrdertypeInput = ({
  value,
  setValue,
  options,
  disabled=false,
} : {
  value: OrderType | null,
  setValue: (value: OrderType) => void,
  options: OrderType[],
  disabled?: boolean,
}) => {

  const selectOptions = options.map((option) => ({
    label: orderTypeLabels[option],
    value: option,
  }));

  return <Select
    style={{ width: '100%' }}
    placeholder="Order Type"
    options={selectOptions}
    value={value}
    onChange={(value) => setValue(value)}
  />
}