import { useEmotionCss } from "@ant-design/use-emotion-css"
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { dmGetOtherUser, getTitle, isDmChatroom } from "../utils";
import { Avatar, Button, Space, Tag } from "antd";
import { ArrowLeftOutlined, SettingOutlined, UserOutlined, LineChartOutlined } from '@ant-design/icons';
import { generateAvatarColor } from "../../shared/avatar-utils";
import { Typography } from 'antd';
import { openChatroomMembersPopup, openGroupPrivacySettingsPopup, openSignalsFormatPopup, setSelectedChatroomId } from "../redux/chat.slice";
import { ChatroomMemberRole } from "../../api/enums";
import { currentChatroomSelector, moderatorUserIdsSelector } from "../redux/chat.selectors";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

const { Text } = Typography

export function ChatroomDetailPageHeader() {

  const currentChatroom = useAppSelector(currentChatroomSelector);
  const dispatch = useAppDispatch();
  const moderatorUserIds = useAppSelector(moderatorUserIdsSelector)
  const { user } = useAuthUser();

  const headerClass = useEmotionCss(({ theme, token }) => ({
    backgroundColor: token.colorBgElevated,
    padding: 8,
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    gap: 8,
  }));

  const goBack = () => {
    dispatch(setSelectedChatroomId(null));
  }

  const onSettingsClick = () => {
    dispatch(openGroupPrivacySettingsPopup());
  }

  const onMembersClick = () => {
    dispatch(openChatroomMembersPopup());
  }

  const onSignalsFormatClick = () => {
    dispatch(openSignalsFormatPopup());
  }

  if (!currentChatroom || !user) {
    return null;
  }

  const isDm = isDmChatroom(currentChatroom!);
  const title = getTitle(currentChatroom, user.id);
  const isAdmin = currentChatroom.permission_summary!.role === ChatroomMemberRole.admin
  const avatarColor = generateAvatarColor(title);
  const titleTrimmed = title.slice(0, 2).toUpperCase();
  const subtitle = isDm
    ? '@' + dmGetOtherUser(currentChatroom, user.id)!.username
    : 'Signal Provider Group';
  const isTeamMember = isDm &&  moderatorUserIds.map(id => id.toString()).includes(dmGetOtherUser(currentChatroom, user.id)!.id)

  return <div className={headerClass}>
    
    <Button
      onClick={goBack}
      size="large"
      type="text"
      style={{ borderRadius: '100%' }}
      icon={<ArrowLeftOutlined />}>
    </Button>
    <Avatar
      style={{ backgroundColor: avatarColor }}
    >
      { titleTrimmed }
    </Avatar>
    <div style={{ flex: 1 }}>
      <div>
        <Space>
          { title } {  isTeamMember &&  <Tag color='green'>CandeFi Team Member</Tag> }
        </Space>
      </div>
      {
        subtitle && <Text type="secondary" style={{ fontSize: 10 }}>
          {subtitle} 
        </Text>
      }
    </div>
    <div style={{ justifySelf: 'flex-end' }}>
      <Space direction="horizontal">
        {
          isAdmin && <>
            <Button
              onClick={onSettingsClick}
              size="large"
              type="text"
              title="Settings"
              icon={<SettingOutlined />}
            />
            <Button
              onClick={onSignalsFormatClick}
              size="large"
              type="text"
              title="Signals format"
              icon={<LineChartOutlined />}
            />
            <Button
              onClick={onMembersClick}
              size="large"
              type="text"
              title="Members"
              icon={<UserOutlined />}
            />
          </>
        }      
      </Space>
    </div>
  </div>
}