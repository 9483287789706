import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Layout } from "antd";
import RechargePaymentStatus from "../components/RechargePaymentStatus";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const RechargePaymentStatusPage = () => {
  return (
    <Layout>
      <Layout.Content>
        <Elements stripe={stripePromise}>
          <RechargePaymentStatus />
        </Elements>
      </Layout.Content>
    </Layout>
  );
};

export default RechargePaymentStatusPage;
