import { OrderType, Side, TimeInForce, TrailType } from "../../api/enums";
import { StocksQuoteResponse } from "../../api/stocks-quote";
import { BrokerType } from "../../brokers/entities/broker-account.entity";

export const stocksLimitPriceRequired = (orderType : OrderType) => {
  return [OrderType.Limit, OrderType.StopLimit].includes(orderType);
}

export const stocksStopPriceRequired = (orderType : OrderType) => {
  return [OrderType.Stop, OrderType.StopTrail, OrderType.StopLimit].includes(orderType);
}

export const stocksTrailRequired = (orderType : OrderType) => {
  return [OrderType.StopTrail].includes(orderType);
}

export const stocksGetSupportedOrderTypes = (brokerType?: BrokerType | null) => {
  const all = [
    OrderType.Limit,
    OrderType.Market,
    OrderType.Stop,
    OrderType.StopLimit,
    OrderType.StopTrail,
  ];

  if(brokerType === BrokerType.robinhood) {
    return [
      OrderType.Limit,
      OrderType.Market,
      OrderType.Stop,
      OrderType.StopLimit,
    ];
  }

  return all;
}

export const stocksGetSupportedSides = (brokerType?: BrokerType | null) => {
  const all = [
    Side.Buy,
    Side.Sell,
    // Side.Short
  ];

  if(brokerType === BrokerType.robinhood) {
    return [
      Side.Buy,
      Side.Sell,
    ];
  }

  return all;
}

export const stocksGetSupportedTIFS = (
  brokerType?: BrokerType | null, 
  orderType?: OrderType | null
) => {
  if (orderType === OrderType.Market) {
    return [ TimeInForce.Day ];
  }

  const all = [
    TimeInForce.Day,
    TimeInForce.GoodTillCancelled,
    // TimeInForce.ImmediateOrCancelled,
  ];

  if(brokerType === BrokerType.robinhood) {
    return [
      TimeInForce.Day,
      TimeInForce.GoodTillCancelled,
    ];
  }

  return all;
}

export const stocksGetSupportedTrailTypes = (brokerType?: BrokerType | null) => {
  const all = [
    TrailType.Dollar, 
    TrailType.Percentage
  ];

  if(brokerType === BrokerType.robinhood) {
    return [];
  }

  return all;
}

export const stocksGetQuantity = (price: number, bugdet: number) => {
  // number of stocks you can get with your bugdet
  return Math.max(Math.floor(bugdet / price), 1);
}

export const stocksGetMidPrice = (quote: StocksQuoteResponse) => {
  return (quote.ask_price + quote.bid_price) / 2 
}