import { App as AntApp, ConfigProvider, theme } from "antd";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import "antd/dist/reset.css";
import { ThemeContextProvider, useThemeContext } from "./shared/context/ThemeContext";
import { getDarkTheme, getLightTheme } from "./theme";
import { useGlobalStyles } from "./useGlobalStyles";
import { CentContextProvider } from "./cent/context/cent-context";
import { InactivityLogout } from "./auth/components/InactivityLogout";
import { Provider as StoreProvider } from "react-redux";
import store from './redux/store';
import { useEffect } from "react";
import { usePrimaryColor } from "./usePrimaryColor";
import { MaskInfoContextProvider } from "./shared/context/MaskInfoContext";
import { IOSPwaPrompt } from "./shared/components/IOSPwaPrompt";

function App() {
  return (
    <StoreProvider store={store}>
      <IOSPwaPrompt />
      <MaskInfoContextProvider>
        <ThemeContextProvider>
          <CentContextProvider>
              <AntThemeProvider />
          </CentContextProvider>
        </ThemeContextProvider>
      </MaskInfoContextProvider>
    </StoreProvider>
  );
}

const AntThemeProvider = () => {
  const primaryColor = usePrimaryColor();

  const darkTheme = getDarkTheme(primaryColor);
  const lightTheme = getLightTheme(primaryColor);
  const { isDark } = useThemeContext();
  const theme = isDark ? darkTheme : lightTheme;

  return <ConfigProvider theme={theme}>
    <StyledApp />
  </ConfigProvider>
}

const StyledApp = () => {
  const styles = useGlobalStyles();
  const { isDark } = useThemeContext();
  useRootStyles();

  return <AntApp className={`${styles} ${isDark ? 'theme-dark' : 'theme-light'}`}>
    <InactivityLogout />
    <RouterProvider router={router} />
  </AntApp>
}

function useRootStyles() {

  const { isDark } = useThemeContext();
  const tokens = theme.useToken()

  useEffect(() => {
    const color = tokens.token.colorPrimary;
    const background = 'rgba(0, 0, 0, 0.3)';
    const bodyBackground = tokens.token.colorBgContainer;

    document.documentElement.style.setProperty('--body-bg-color', bodyBackground);
    document.documentElement.style.setProperty('--scrollbar-color', color);
    document.documentElement.style.setProperty('--scrollbar-background', background);

  }, [tokens, isDark]);

  return;
}

export default App;
