import { Descriptions, Modal, Typography } from "antd";
import { directionLabels, orderTypeLabels, sideLabels, timeInForceShortLabels } from "../../api/enum-labels";
import { Direction, OrderType, Side, TimeInForce } from "../../api/enums";
import { formatMoney, formatMoneyAuto } from "../../shared/utils";
import { useOptionsEstimate } from "../hooks/useOptionsEstimate";

const { Title } = Typography;

export const OptionsOrderConfirmationDialog = ({
  open,
  setOpen,
  onOk,
  stock,
  side,
  direction,
  expirationDate,
  strikePrice,
  limitPrice,
  quantity,
  stopPrice,
  timeInForce,
  orderType,
} : {
  open: boolean,
  setOpen: (open: boolean) => void,
  onOk: () => void,
  stock: string | null,
  side: Side | null,
  direction: Direction | null,
  expirationDate: string | null,
  strikePrice: number | null,
  limitPrice: number | null,
  quantity: number | null,
  stopPrice: number | null,
  timeInForce: TimeInForce | null,
  orderType: OrderType | null,
}) => {

  const estimate = useOptionsEstimate({
    quantity,
    limitPrice
  })

  return <Modal 
    title="Place Options Order?" 
    open={open} 
    onOk={onOk}
    onCancel={() => setOpen(false)}
    okText="Yes"
    cancelText="No"
  >
    {
      estimate && <div style={{ marginTop: 16, marginBottom: 16 }}>
        <Title level={5}>Estimate</Title>
        <span style={{ fontSize: '1.5rem' }}>
          { formatMoney(estimate) }
        </span>
      </div>
    }
    <Descriptions title="Details" size="small" column={{ xs: 2, sm: 2, lg: 2, xl: 2, xxl: 2 }}>
      <Descriptions.Item label="Stock">
        { stock }
      </Descriptions.Item>
      {
        !!side && <Descriptions.Item label="Side">
          { sideLabels[side] }
        </Descriptions.Item>
      }
      {
        !!direction && <Descriptions.Item label="Direction">
          { directionLabels[direction] }
        </Descriptions.Item>
      }
      {
        !!orderType && <Descriptions.Item label="Order Type">
          { orderTypeLabels[orderType] }
        </Descriptions.Item>
      }
      {
        !!expirationDate && <Descriptions.Item label="Expiration Date">
          { expirationDate }
        </Descriptions.Item>
      }
      {
        !!strikePrice && <Descriptions.Item label="Strike Price">
          { formatMoneyAuto(strikePrice) }
        </Descriptions.Item>
      }
      {
        !!limitPrice && <Descriptions.Item label="Limit Price">
          { formatMoneyAuto(limitPrice) }
        </Descriptions.Item>
      }
      {
        !!stopPrice && <Descriptions.Item label="Stop Price">
          { formatMoneyAuto(stopPrice) }
        </Descriptions.Item>
      }
      {
        !!quantity && <Descriptions.Item label="Quantity">
          { quantity }
        </Descriptions.Item>
      }
      {
        !!timeInForce && <Descriptions.Item label="Time In Force">
          { timeInForceShortLabels[timeInForce] }
        </Descriptions.Item>
      }
    </Descriptions>

  </Modal>;
}