import { useState } from "react"
import { RechargeResponse } from "../../api/recharges";
import StripePayment from "../../stripe/components/StripePayment";
import { RechargeForm } from "./RechargeForm";

enum RechargeStep {
  Form='form',
  Payment='payment'
}

export const RechargeAccount = () => {
  const [step, setStep] = useState<RechargeStep>(RechargeStep.Form);
  const [response, setResponse] = useState<RechargeResponse | null>(null);
  const redirectUrl = `${process.env.REACT_APP_CLIENT_URL}/recharge-payment-status`;

  if (step === RechargeStep.Form) {
    return <RechargeForm 
      onSuccess={(response) => {
        setResponse(response);
        setStep(RechargeStep.Payment);
      }}
    />
  }

  if (step === RechargeStep.Payment && response !== null) {
    return <StripePayment 
      redirectUrl={redirectUrl}
      clientSecret={response.client_secret} />
  }

  return null;
}