import { useReferralInfo } from "../hooks/useReferralInfo"
import { Typography } from 'antd';
import { useMemo } from "react";
import { UserRole } from "../../api/enums";
import { ReferralCodeInfo } from "../../api/referrals";
import { Link } from "../../shared/components/Link";

const { Title, Text } = Typography;

export function SignupPageTitle() {

  const { data } = useReferralInfo();

  if (data === null || data === undefined) {
    return <Title
      style={{ textAlign: 'center' }}
      level={1
      }>
      Signup
    </Title>
  }

  return <ReferralTitle info={data} />
}


function ReferralTitle({ info } : { info: ReferralCodeInfo }) {

  // Signal provider information
  const signalProvider = useMemo(() => {
    if (info.user_role !== UserRole.analyst) {
      return null;
    }

    const signalProviders = info.signal_providers || [];

    return signalProviders.length > 0 
      ? signalProviders[0]
      : null;
  
  }, [info]);

  // Username of signal provider or the user
  const username = useMemo(() => {
    return signalProvider?.name || info.username;
  }, [info, signalProvider]);

  // user name to display
  const displayName= useMemo(() => {
    return signalProvider?.name || info.user_display_name;
  }, [info, signalProvider]);

  // signalProvider Link
  const signalProviderLink = useMemo(() => {
    if (signalProvider === null) {
      return null;
    }
  
    return `/app/signal-providers/${signalProvider.username}`;
  }, [info, signalProvider]);

  return <div>
      <Title level={3} style={{ textAlign: 'center', display: 'block', }}>
        @{displayName}
      </Title>
      <Text type='secondary' style={{ fontSize: '1rem', textAlign: 'center', display: 'block' }}>
        {
          info.user_role === UserRole.trader && (
              <span>
                Signup with referral code of @{username}
              </span>
            )
          }
          {
            info.user_role === UserRole.analyst && (
              <span>
                Signup to get signals from &nbsp;
                {/* @ts-ignore */}
                <Link to={signalProviderLink}>@{ username }</Link> 
              </span>
            )
          }
      </Text>
  </div>
}