import axios from './axios'
import { GenericIDResponse, GenericStatusResponse } from './types'

export interface AddToWatchlistRequest {
  symbol: string
}

export interface WatchlistItem {
  symbol: string
  short_name: string | null
  long_name: string | null
}

const watchlistApi = {
  async add(request : AddToWatchlistRequest) {
    const { data } = await axios.post<GenericIDResponse>('/watchlist', request, {
      needsBrokerId: true,
    });
    return data;
  },

  async delete(symbol: string) {
    const { data } = await axios.delete<GenericIDResponse>(`/watchlist/${symbol}`);
    return data;
  },

  async list() {
    const { data } = await axios.get<WatchlistItem[]>('/watchlist');
    return data;
  },

  async deleteAll() {
    const { data } = await axios.delete<GenericStatusResponse>('/watchlist');
    return data;
  },
}

export default watchlistApi