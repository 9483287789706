import { Avatar, Space, Tag, Typography } from "antd";
import { AutotradeBotResponse } from "../../../api/autotrade-bots";
import { brokerIcons } from "../../../brokers/components/broker-icons";
import { brokerTypeLabels } from "../../../api/enum-labels";
import { useState } from "react";
import { BotInfoModal } from "./bot-info-modal";
import { ChangeBotPasswordModal } from "./change-bot-password-modal";
import { BotActions } from "./bot-actions";
import { isBotActive, isBotArchived } from "./utils";
import { useMaskInfoContext } from "../../../shared/context/MaskInfoContext";

export function BotsListItem({
  bot, 
  onUpdate,
  totalActiveBots,
}: {
  bot: AutotradeBotResponse;
  onUpdate?: () => void;
  totalActiveBots: number
}) {
  const [infoOpen, setInfoOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const isActive = isBotActive(bot);
  const isArchived = isBotArchived(bot);
  const { masked } = useMaskInfoContext();

  return <div style={{ padding: `16px 0`, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', gap: 16 }}>
    <BotInfoModal bot={bot} open={infoOpen} onClose={() => setInfoOpen(false)} />
    <ChangeBotPasswordModal botId={bot.id} open={changePasswordOpen} onClose={() => setChangePasswordOpen(false)} />
    <div style={{ display: 'flex', gap: 8 }}>
      <Avatar src={brokerIcons[bot.broker_account.type]} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Space >
          <Typography.Title level={5} style={{ marginBottom: 0 }}>{bot.name}</Typography.Title>
          {
            isArchived && <Tag color='error'>Archived</Tag>
          }
          {
            isActive && <Tag color='success'>Active</Tag>
          }
        </Space>
        <Typography.Text type="secondary">{brokerTypeLabels[bot.broker_account.type]} {bot?.paper ? 'Paper' : ''}</Typography.Text>
        <Typography.Text type="secondary">
          { !masked ? bot.broker_account.username : '********'}
        </Typography.Text>
      </div>
    </div>
    <BotActions
      totalActiveBots={totalActiveBots}
      bot={bot}
      setChangePasswordOpen={setChangePasswordOpen}
      setInfoOpen={setInfoOpen}
      onUpdate={onUpdate} />
  </div>;
}
