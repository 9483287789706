import { InputNumber } from "antd"

export const StopInput = ({
  value,
  setValue,
} : {
  value: number | null,
  setValue: (value : number | null) => void,
}) => {
  return <InputNumber
    addonBefore="$"
    style={{ display: 'block', width: '100%' }}
    placeholder="Stop Price"
    value={value}
    onChange={(value) => setValue(value)}
    min={0}
  />
}