import { useIOSDeviceAndVersion } from "../hooks/useIOSDeviceAndVersion";
import { useISPWA } from "../hooks/useISPWA";
import PWAPrompt from 'react-ios-pwa-prompt'
import { useEffect, useState } from "react";

export function IOSPwaPrompt() {
  const { isIOS } = useIOSDeviceAndVersion();
  const isPWA = useISPWA();
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(!isPWA && (isIOS || false));
  }, [isPWA, isIOS]);

  return <PWAPrompt 
    isShown={isShown}
    onClose={() => {
      setIsShown(false);
    }}
    appIconPath="/maskable_icon_x192.png"
    copyDescription="This website has app functionality. Add it to your home screen to use it in fullscreen."
  />
}