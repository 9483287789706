import { Button, ConfigProvider, message, Spin } from "antd";
import { useState } from "react";
import { useWatchList } from "../context/watchlist-context";
import { AddToWatchlistModal } from "./AddToWatchlistModal";
import { WatchlistItem } from "./WatchlistItem";
import { PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import styles from './watchlist.module.scss';
import { ClearWatchlistButton } from "./ClearWatchlistButton";

const { Text, Title } = Typography;

export const Watchlist = ({
  layout,
} : {
  layout: 'horizontal' | 'vertical',
}) => {

  const [showAddModal, setShowAddModal] = useState(false);
  const [ messageApi, contextHolder ] = message.useMessage();

  const { watchlist } = useWatchList();

  const { items, loading, error } = watchlist;

  const onAdded = () => {
    setShowAddModal(false);
    messageApi.success('added successfully');
  }

  return <div className={`${styles.watchlist} ${layout === 'horizontal' ? styles.horizontal : styles.vertical }`}>
    <>
      { contextHolder }
      <AddToWatchlistModal 
        open={showAddModal} 
        setOpen={setShowAddModal} 
        onSuccess={onAdded}
      />
      <Title className={styles.title} level={5}>Watchlist</Title>
      {/* change error color to green, to create green add button */}
      <ConfigProvider theme={{
        token: {
          colorError: '#14d118',
        }
      }}>
        <Button 
          icon={<PlusOutlined/>}
          type='default'
          danger
          className={styles.addButton}
          onClick={() => setShowAddModal(true)}>
          Add
        </Button>
      </ConfigProvider>
      <ClearWatchlistButton />
      {
        loading && <Spin className={styles.spinner}/>
      }
      {
        !loading && !error && items.map(item => <WatchlistItem 
          item={item} 
          key={item.symbol} 
        />)
      }
      {
        !loading && !error && items.length == 0 && <div className={styles.empty}>
          <Text type="secondary">Watchlist Empty</Text>
        </div>
      }
    </>
  </div>;
}