import axios from "./axios";

interface StripeConnectRequest {
  return_url: string
  refresh_url: string
}

interface StripeConnectLink {
  url: string
}

const stripeConnectApi = {
  async createStripeConnectLink(request: StripeConnectRequest) {
    const { data } = await axios.post<StripeConnectLink>('/stripe-connect/link', request);
    return data;
  }
}

export default stripeConnectApi;