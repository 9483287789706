import { createAppAsyncThunk } from "../../../redux/hooks";
import { reset } from "../chat.slice";
import fetchMyChatrooms from "./fetch-my-chatrooms.thunk";

export const initializeChatState = createAppAsyncThunk('chat/initialize', async function(_, thunkApi) {
  const { dispatch } =  thunkApi;
  await dispatch(reset());
  // TODO: dispatch actions to cleanup state of chat related components
  // or let them clean up their state after this thunk resolves
  await dispatch(fetchMyChatrooms());
});