import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useSWR from 'swr';
import api from "../../../api";
import { Button, List, Modal, Skeleton, message } from "antd";
import { closeChatroomMembersPopup } from "../../redux/chat.slice";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { BasicChatroomMembersInfo } from "../../../api/chat";
import { Typography } from 'antd';
import { isGroupChatroom } from "../../utils";
import { currentChatroomSelector } from "../../redux/chat.selectors";
import { ChatAvatar } from "../message-item/chat-avatar";
import { chatroomMemberRoleLabels } from "../../../api/enum-labels";
import { getErrorMessage } from "../../../shared/utils";
import { ChatroomMemberRole } from "../../../api/enums";
const { Text } = Typography;

export function ChatroomMembersPopup() {

  const dispatch = useAppDispatch();

  const chatroom = useAppSelector(currentChatroomSelector);

  const isGroup = chatroom !== null 
    ? isGroupChatroom(chatroom) 
    : null;

  const open = useAppSelector(state => state.chat.chatroomMembersPopup.open);

  const { data, error, isLoading, mutate } = useSWR(
    chatroom !== null && isGroup
      ? `/chat/${chatroom.id}/members`
      : null,
    () => api.chat.basicChatroomMemberInfo(chatroom!.id),
  );

  const onClose = () => {
    dispatch(closeChatroomMembersPopup());
  }

  return <Modal 
    title="Members"
    open={open} 
    onCancel={onClose} 
    footer={null}
  >
    {
      isLoading && <Skeleton />
    }
    {
      error !== null && <ErrorMessage error={error} />
    }
    {
      !isLoading && !error && data !== undefined && <ChatroomMembersForm
        chatroomId={chatroom!.id}
        onSuccess={() => mutate()}
        membersInfo={data}
      />
    }
  </Modal>
}

function ChatroomMembersForm({
  chatroomId,
  membersInfo,
  onSuccess,
} : {
  chatroomId: string,
  membersInfo: BasicChatroomMembersInfo | null,
  onSuccess: () => void,
}) {

  const [messageApi, contextHolder] = message.useMessage();
  const [blockedUsersMap, setBlockedUsersMap] = useState<Record<string, boolean>>({});

  useEffect(() => {
    let blockedUsersMap : Record<string, boolean> = {};
    
    // no member is blocked by default
    (membersInfo?.members || [])
      .forEach(member => blockedUsersMap[member.user.id.toString()] = false);
    
    // members who are explictly blocked
    (membersInfo?.blocked_users || [])
      .forEach(u => blockedUsersMap[u.user.id.toString()] = true);

    // blocks
    setBlockedUsersMap(blockedUsersMap);

  }, [membersInfo]);

  const toggleBlock = async (userId: string) => {
    const isBlocked = blockedUsersMap[userId] || false;

    const action = isBlocked
      ? api.chat.unblockChatroomUser(chatroomId, Number(userId))
      : api.chat.blockChatroomUser(chatroomId, Number(userId))
    
    setBlockedUsersMap({
      ...blockedUsersMap,
      [userId]: !isBlocked,
    });

    try {
      await action
      onSuccess();
    } catch (e) {
      messageApi.error(getErrorMessage(e) || 'Something went wrong');
    }
  }

  return <div>
    { contextHolder }
    <List
      dataSource={membersInfo?.members || []}
      renderItem={(member) => {
        const isBlocked = blockedUsersMap[member.user.id.toString()] || false;
        const isAdmin = member.role === ChatroomMemberRole.admin;
        return <List.Item
          actions={
            !isAdmin
              ? [
                <Button onClick={() => toggleBlock(member.user.id.toString())}>
                  { isBlocked ? 'Unblock' : 'Block'  }
                </Button>
              ] : []
          }
        >
          <List.Item.Meta
            avatar={<ChatAvatar user={member.user} />}
            title={member.user.name}
            description={(
              <span>
                @{ member.user.username } - { chatroomMemberRoleLabels[member.role] }
              </span>
            )}
          />
        </List.Item>
      }}
    />
  </div>
}