import { Button, Card, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { MembershipPlan, SignalProvider } from "../../api/signal-providers";
import { formatBillingCycles, formatBillingInterval } from "../../shared/utils";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { MembershipListItem } from "../../api/membership";
import { useSignalProviderReferralCode } from "../hooks/useSignalProviderReferralCode";
const { Text, Title } = Typography;

export function SignalProviderPlanCard({ plan, signalProvider, myMemberships }: { plan: MembershipPlan; signalProvider: SignalProvider; myMemberships?: MembershipListItem[]; }) {
  const { user } = useAuthUser();
  const isSubscribed = (myMemberships || []).some(m => m.membership_plan.id == plan.id);
  const {
    data: signalProviderReferralCode,
  } = useSignalProviderReferralCode(signalProvider.username);

  return <Card size="small" title={plan.name} style={{ textAlign: 'center' }}>
    <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      {plan.stocks && <Tag color="success">
        Stocks
      </Tag>}
      {plan.options && <Tag color="success">
        Options
      </Tag>}
    </div>
    <div style={{ textAlign: "center" }}>
      <Title style={{ marginTop: 12, marginBottom: 4 }}>${plan.amount}</Title>
      <Text>
        {formatBillingInterval(plan.billing_interval, plan.billing_interval_unit)}
      </Text>
      <Text type="secondary" style={{ display: 'block', textTransform: 'capitalize' }}>
        {formatBillingCycles(plan.billing_interval, plan.billing_interval_unit, plan.billing_cycles)}
      </Text>
      <div style={{ marginTop: 20 }}>
        {user === null && signalProviderReferralCode !== null && (
          <Link to={`/ref/${signalProviderReferralCode.code}?dest=/app/signal-providers/${signalProvider.username}`}>
            <Button
              block
              size="large"
              type="primary"
            >
              Subscribe
            </Button>
          </Link>
        )}
        {user !== null && isSubscribed && (
          <Button
            block
            size="large"
            type="primary"
            disabled
          >
            Active
          </Button>
        )}
        {user !== null && !isSubscribed && (

          <Link to={`plans/${plan.id}/purchase`}>
            <Button
              style={{ marginTop: 20 }}
              block
              size="large"
              type="primary"
            >
              Subscribe
            </Button>
          </Link>
        )}
      </div>
    </div>
  </Card>;
}
