import { useEffect } from "react";
import { BasicChatroom } from "../../api/chat";
import { DMRequestStatusChangedEventPayload, NewChatroomMessagePayload, isChatroomNewMessageEvent, isDMRequestStatusChangedEvent } from "./chatroom-events";
import { useChatroomSubscription } from "./use-chatroom-subscription";
import { PublicationContext } from "centrifuge";

/**
 * 
 * invokes listener when new message arrive in this chatroom.
 * 
 */
const useChatroomCentEvents = (
  chatroom: BasicChatroom,
  listeners: {
    newMessage?: (payload: NewChatroomMessagePayload) => void | Promise<void>,
    dmRequestStatusChanged?: (payload: DMRequestStatusChangedEventPayload) => void | Promise<void>,
  }
) => {

  const subscription = useChatroomSubscription(chatroom);

  useEffect(() => {
    if (subscription === null) {
      return;
    }

    const onPublish = (ctx: PublicationContext) => {
      if (
        listeners.newMessage !== undefined 
        && isChatroomNewMessageEvent(ctx.data)
      ) {
        listeners.newMessage(ctx.data.payload);
        
      }

      if (
        listeners.dmRequestStatusChanged !== undefined 
        && isDMRequestStatusChangedEvent(ctx.data)
      ) {
        listeners.dmRequestStatusChanged(ctx.data.payload);
      }
    }
  
    subscription.addListener('publication', onPublish);
  
    return () => {
      subscription.removeListener('publication', onPublish)
    };
  
  }, [subscription, listeners, chatroom]);

  return null;
}

export default useChatroomCentEvents;