import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useSWR from 'swr';
import api from "../../../api";
import { Col, Modal, Row, Skeleton, Switch } from "antd";
import { closeGroupPrivacySettingsPopup } from "../../redux/chat.slice";
import { ErrorMessage } from "../../../shared/components/ErrorMessage";
import { BasicChatroomPrivacySettings } from "../../../api/chat";
import { Typography } from 'antd'
import { isGroupChatroom } from "../../utils";
import { currentChatroomSelector } from "../../redux/chat.selectors";
const { Text } = Typography;

export function GroupPrivacySettingsPopup() {

  const dispatch = useAppDispatch();

  const chatroom = useAppSelector(currentChatroomSelector);

  const isGroup = chatroom !== null 
    ? isGroupChatroom(chatroom) 
    : null;

  const open = useAppSelector(state => state.chat.groupPrivacySettingsPopup.open);

  const { data, error, isLoading, mutate } = useSWR(
    chatroom !== null && isGroup
      ? `/chat/${chatroom.id}/privacy-settings`
      : null,
    () => api.chat.getPrivacySettings(chatroom!.id),
  );

  const onClose = () => {
    dispatch(closeGroupPrivacySettingsPopup());
  }

  return <Modal 
    title="Group Settings"
    open={open} 
    onCancel={onClose} 
    footer={null}
  >
    {
      isLoading && <Skeleton />
    }
    {
      error !== null && <ErrorMessage error={error} />
    }
    {
      !isLoading && !error && data !== undefined && <GroupPrivacySettingsForm
        chatroomId={chatroom!.id}
        onSuccess={() => mutate()}
        initialPrefs={data}
      />
    }
  </Modal>
}

function GroupPrivacySettingsForm({
  chatroomId,
  initialPrefs,
  onSuccess,
} : {
  chatroomId: string,
  initialPrefs: BasicChatroomPrivacySettings | null,
  onSuccess: () => void,
}) {
  const [membersCanSend, setMembersCanSend] = useState(true);

  useEffect(() => {
    setMembersCanSend(initialPrefs === null ? true : initialPrefs.members_can_send);
  }, [initialPrefs]);

  useEffect(() => {
    const controller = new AbortController();
    const update = async () => {
      await api.chat.editPrivacySettings(
        chatroomId, 
        { members_can_send: membersCanSend } ,
        controller.signal
      );
      onSuccess();
    }
    update();
    return () => controller.abort();
  }, [membersCanSend, onSuccess, chatroomId]);

  return <div style={{ padding: 8, marginTop: 20, display: 'flex' }}>
    <Row justify={'space-between'} style={{ width: '100%' }}>
      <Col>
        <Text>Allow members to send</Text>
      </Col>
      <Col>
        <Switch
          checked={membersCanSend}
          onChange={setMembersCanSend}
        />
      </Col>
    </Row>
  </div>
}