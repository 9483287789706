import { createContext, useContext, useEffect, useState } from "react";
import { UserRole } from "../../api/enums";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";

export interface ShareTradeContextState {
  canShare: boolean
  share: boolean
  setShare: (share: boolean) => void
}

const ShareTradeContext = createContext<ShareTradeContextState>({
  canShare: false,
  share: false,
  setShare: (share: boolean) => {}
});

export const ShareTradeContextProvider = ({
  children
} : {
  children?: React.ReactElement
}) => {

  const { user, initialized: userInit } = useAuthUser();
  const { broker, initialized: brokerInit } = useSelectedBroker();
  const canShare = userInit && brokerInit && user?.role === UserRole.analyst && broker?.paper !== true;
  const [share, setShare] = useState(false);

  useEffect(() => {
    if (canShare) {
      setShare(true);
    }
  }, [canShare]);

  const state : ShareTradeContextState = {
    share,
    canShare,
    setShare: (share: boolean) => {
      if (!canShare) {
        return;
      }
      return setShare(share);
    }
  }

  return <ShareTradeContext.Provider value={state}>
    { children }
  </ShareTradeContext.Provider>
}

export const useShareTradeContext = () => useContext(ShareTradeContext)