import { Alert, Card, Modal } from "antd";
import { useCandeFiMembershipsContext } from "./context"
import { CandeFiMembershipPlansList } from "./plans-list";

export function CandeFiMembershipsPlans() {
  const {
    myMembership,
    plans
  } = useCandeFiMembershipsContext();

  return <Card title="Available Plans" loading={plans.loading}>
    <CandeFiMembershipPlansErrorPopup />
    {
      plans.data && (
        <CandeFiMembershipPlansList />
      )
    }
  </Card>
}

function CandeFiMembershipPlansErrorPopup() {
  const { errorPopup } = useCandeFiMembershipsContext();
  const { message, onOpenChange, open } = errorPopup;
  return <Modal footer={null} open={open} onCancel={() => onOpenChange(false)}>
    <Alert
      type="error"
      message={message}
    />
  </Modal>
}