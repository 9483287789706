import { Card } from "antd";
import { TradePreferenceForm } from '../../trade-preference/components/TradePreferenceForm';
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const TradePreference = () => {

  const { user, initialized } = useAuthUser();
  const tradePreference = user?.trade_preference || null;

  return (
    <Card
      title="Trade Preference"
      loading={!initialized}
      style={{ height: "100%" }}
      bordered={false}
    >
      <div style={{ width: 'min(100%, 600px)' }}>
        <TradePreferenceForm  tradePreference={tradePreference} />
      </div>
    </Card>
  );
};
