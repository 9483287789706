import { Card, Col, Pagination, Row, Typography } from "antd";
import { useState } from "react";
import useSWR from "swr";
import api from "../../api";
import { MembershipStatus } from "../../api/enums";
import { ListMyMembershipRequest } from "../../api/membership";
import { MyMembershipCard } from "../../membership/components/MyMembershipCard";
import { MembershipTabs as MembershipTypeTabs } from "../components/MembershipTabs";
const { Text } = Typography;

enum MembershipTabs {
  Current = 'current',
  All = 'all',
}

export const MyMemberships = () => {
  const [tab, setTab] = useState<MembershipTabs>(MembershipTabs.Current);
  const [page, setPage] = useState(1);

  const { data, isLoading } = useSWR(
    `/api/membership/me/?tab=${tab}&page=${page}`,
    async () => {
      const request = new ListMyMembershipRequest();
      request.page = page;
      if (tab === MembershipTabs.Current) {
        request.status = MembershipStatus.Active;
      }
      const response = await api.membership.listMyMemberships(request);
      return response;
    }
  );

  const handleTabChange = (key: string) => {
    setPage(1);
    setTab(key as MembershipTabs);
  }

  const isEmpty = !isLoading && data && data.data.length == 0;

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <MembershipTypeTabs />
      </Col>
      <Col span={24}>
        <Card
          style={{ height: '100%' }}
          loading={isLoading}
          title="Your SocialTrade Memberships"
          tabList={[
            { key: MembershipTabs.Current, tab: 'Current' },
            { key: MembershipTabs.All, tab: 'All' },
          ]}
          activeTabKey={tab}
          onTabChange={handleTabChange}
          bordered={false}>
          <Row gutter={[10, 10]}>
            {
              isEmpty && <div style={{ margin: '12px 0', textAlign: 'center', width: '100%' }}>
                <Text type="secondary">No Membership</Text>
              </div>
            }
            {
              (data?.data || []).map((membership) => (
                <Col span={24} md={{ span: 12 }} xxl={{ span: 8 }} key={membership.id}>
                  <MyMembershipCard
                    style={{ height: '100%' }}
                    membership={membership} />
                </Col>
              ))
            }
            {
              !isEmpty &&
              data?.total !== undefined &&
              data.total > 0 &&
              <Col span={24} style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                  current={page}
                  total={data?.total}
                />
              </Col>
            }
          </Row>

        </Card>
      </Col>
    </Row>
  );
};
