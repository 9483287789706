import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Card, CardProps } from "antd";
import { forwardRef } from "react";

export const PremiumCard = forwardRef<HTMLDivElement, CardProps>((props, ref) => {

  const { type, children, className, ...rest } = props;

  const premiumCardClassName = useEmotionCss(() => ({
    background: 'var(--premium-yellow) !important',
    color: 'var(--premium-yellow-foreground) !important',
  }))

  const newClassName = [premiumCardClassName, className].filter(c => !!c).join(' ');

  return <Card ref={ref} {...rest} className={newClassName}>
    {children }
  </Card>
})
