import api from "../../../api";
import { CreateDMChatroomRequest } from "../../../api/chat";
import { createAppAsyncThunk } from "../../../redux/hooks";
import { closeCreateDMPopup, setSelectedChatroomId } from "../chat.slice";
import fetchMyChatrooms from "./fetch-my-chatrooms.thunk";

export const createDMChatroom = createAppAsyncThunk('chat/create-dm-chatroom', 
  async function(request: CreateDMChatroomRequest, thunkApi) {
    const { dispatch } = thunkApi;
    const response = await api.chat.createDmChatroom(request);
    await dispatch(fetchMyChatrooms()).unwrap();
    dispatch(setSelectedChatroomId(response.id));
    dispatch(closeCreateDMPopup());
  }
);