import { useEmotionCss } from "@ant-design/use-emotion-css"
import { TinyColor } from "@ctrl/tinycolor"

export const useGlobalStyles = () => {
  return useEmotionCss(({ theme, token }) => ({
    '&.theme-dark .ant-card .ant-card': {
      backgroundColor: (new TinyColor(token.colorBgContainer)).lighten(5).toHexString(),
      boxShadow: 'none !important',
    },
    '&.theme-light .ant-card .ant-card': {
      border: '1px solid #d9d9d9',
    },
    '&.theme-light .ant-layout .ant-layout-sider-trigger': {
      color: '#000',
    },
  }))
}