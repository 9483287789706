import { useCallback } from "react";
import { SignalProvider } from "../../api/signal-providers";
import { NewSignalEventPayload } from "../types";
import { useNewSignalNofication } from "./useNewSignalNotification";

export const useRefreshSignals = (
  refreshSignals: () => void,
  signalProviders?: SignalProvider[],
  selectedProviderId?: number | null,
) => {

  const onNewSignal = useCallback((payload : NewSignalEventPayload) => {
    if (!signalProviders) {
      return
    }

    if (!selectedProviderId) {
      const providerIds = signalProviders
        .map(p => p.id)
        .filter(id => payload.provider_ids.includes(id));
      if (providerIds.length > 0) {
        refreshSignals();
      }
      return;
    }

    if (payload.provider_ids.includes(selectedProviderId)) {
      refreshSignals();
    }

  }, [signalProviders, selectedProviderId, refreshSignals]);

  useNewSignalNofication(onNewSignal);
}