import { useEffect, useState } from "react";
import { useCent } from "../../cent/context/cent-context";
import { Subscription } from "centrifuge";
import api from "../../api";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { getUsersAutotradeChannelName } from "./utils";

/**
 * 
 * use this hook to get centrifugo subscription
 * for this channels chatroom.
 * 
 */
export const useAutotradeCentSubscription = () => {

  const cent = useCent();
  const user = useAuthUser();
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    if (!cent || !user.user) {
      return;
    }

    const channelName = getUsersAutotradeChannelName(user.user.id);

    // reuse or create subscription
    let sub = cent.getSubscription(channelName);

    if (!sub) {
      sub = cent.newSubscription(channelName, {
        getToken: async () => {
          const { token } = await api.autotradeCentToken.getSubscriptionToken();
          return token;
        }
      });
    }

    sub.subscribe();
    setSubscription(sub);

  }, [cent, user]);

  return subscription;
};
