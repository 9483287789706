import axios from "./axios"
import { BillingIntervalUnit, CandeFiMembershipStatus } from "./enums"

export type CandeFiMembershipPlanBasic = {
  id: number
  name: string
  price: number | null
  billing_interval : BillingIntervalUnit
  is_free:  boolean
  can_autotrade:  boolean
  can_buy_extra_coins: boolean
  max_autotrade_bots: number;
  stripe_price_id: string | null
  monthly_coins : number
  created_at: string
  updated_at: string
}

export type MyCandeFiMembership = {
    id: number
    plan: CandeFiMembershipPlanBasic
    status: CandeFiMembershipStatus
    current_billing_cycle_start: string | null
    current_billing_cycle_end: string | null
    purchase_date: string | null
    last_coins_renewed_at: string | null
    created_at: string
    updated_at: string
}


export type CandeFiMembershipPayment = {
  draft_id: number
  client_secret: string
  subscription_id: string
}

export type PurchaseCandeFiMembershipResponse = {
  requires_payment: boolean
  payment: CandeFiMembershipPayment | null
}

const api = {
  async getMyMembership() {
    const { data } = await axios.get<MyCandeFiMembership | null>('/candefi-memberships/me');
    return data;
  },

  async purchaseMembership(planId: string | number) {
    const { data } = await axios.post<PurchaseCandeFiMembershipResponse>(`/candefi-memberships/plans/${planId}/purchase`);
    return data;
  },

  async getPlans() {
    const { data } = await axios.get<CandeFiMembershipPlanBasic[]>('/candefi-memberships/plans');
    return data;
  },

  async findPlanById(id: string | number) {
    const { data } = await axios.get<CandeFiMembershipPlanBasic |null>(`/candefi-memberships/plans/${id}`);
    return data;
  },
}

export default api;