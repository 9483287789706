import { getImageUrl } from '../../../shared/image-utils';
import {  Image } from 'antd';

export function ImagePreview({ imagePath }: { imagePath: string; }) {
  const url = getImageUrl(imagePath);

  return <Image
    width={200}
    height={200}
    src={url}
    preview={{
      src: url,
    }}
    style={{ objectFit: 'cover', objectPosition: 'center' }}
  />
}
