import { useParams } from "react-router-dom";
import useSWR from 'swr';
import api from "../../api";
import { Button, Card, Typography } from "antd";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { CandeFiMembershipPlanBasic, PurchaseCandeFiMembershipResponse } from "../../api/candefi-memberships";
import { useState } from "react";
import { fixDecimalPlaces, formatMoneyAuto } from "../../shared/utils";
import { billingIntervalUnitLabels } from "../../api/enum-labels";
import { FeatureRow } from "../plan-feature-row";
import { ChangeMembershipSuccess } from "../change-membership-success";
import StripePayment from "../../stripe/components/StripePayment";
import { PlanFeatures } from "../plan-features";

export function PurchaseCandeFiMembershipPage() {
  const { planId } = useParams();
  const {
    data: plan,
    isLoading: loading,
    error: loadingError,
  } = useSWR(['/api/candefi-membership-plans', planId], (key) => {
    return api.candefiMemberships.findPlanById(key[1] || '');
  });

  return <Card loading={loading}>
    {
      !loading && !plan && <ErrorMessage error={loadingError || 'could not find plan'} />
    }
    {
      plan && (
        <PurchasePageInternal plan={plan} />
      )
    }
  </Card>
}

function PurchasePageInternal({ plan } : { plan: CandeFiMembershipPlanBasic }) {
  const [ state, setStatus ] = useState<
    'confirm' | 'success' | 'payment'
  >('confirm');

  const [error, setError] = useState<any | null>(null);
  const [changePlanResponse, setCchangePlanResponse] = useState<PurchaseCandeFiMembershipResponse | null>(null);

  // calling plan change api
  const [changingPlan, setChaningPlan] = useState(false);

  const onContinueClick : React.MouseEventHandler = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    try {

      setError(null);
      setChaningPlan(true);
      const response = await api.candefiMemberships.purchaseMembership(plan.id);
      setCchangePlanResponse(response);
      if (response.requires_payment) {
        setStatus('payment');
        return
      }
      setStatus('success');

    } catch (e) {

      setError(e);

    } finally {
      setChaningPlan(false);
    }
  }

  return <div style={{ 
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center'  
  }}>
    {
      (state === 'confirm') && <Card style={{ width: 'min(100%, 300px)', display: 'flex', flexDirection: 'column', gap: 12  }}>
        <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
          { plan.name }
        </Typography.Title>
        <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginBottom: 16 }}>
          <Typography.Title style={{ margin: 0 }}>
            { formatMoneyAuto(fixDecimalPlaces(plan.price || 0, 2)) }
          </Typography.Title>
          <Typography.Text type="secondary">
            /
          </Typography.Text>
          <Typography.Text type="secondary">
            { billingIntervalUnitLabels[plan.billing_interval] }
          </Typography.Text>
        </div>
        <div style={{ margin: '1rem 0' }} >
          <PlanFeatures plan={plan} />
        </div>
        <ErrorMessage error={error} />
        <Button 
          loading={changingPlan}
          disabled={changingPlan}
          onClick={onContinueClick} type="primary" size="large" block>
          Continue
        </Button>
      </Card>
    }
    {
      state === 'payment' && !!changePlanResponse && (
        <div style={{ width: 'min(100%, 600px)' }}>
          <MembershipPaymentForm response={changePlanResponse} />
        </div>
      )
    }
    {
      state === 'success' && (
        <div>
          <ChangeMembershipSuccess />
        </div>
      )
    }
  </div>
}

function MembershipPaymentForm({ response } : { response: PurchaseCandeFiMembershipResponse }) {
  const redirectUrl =  `${process.env.REACT_APP_CLIENT_URL}/purchase-candefi-membership-payment-status`;
  const clientSecret = response.payment!.client_secret;
  return <div>
    <StripePayment 
      redirectUrl={redirectUrl}
      clientSecret={clientSecret} />
  </div>
}