import axios from "./axios";
import { AnalystSignupRequestStatus } from "./enums";
import { GenericStatusResponse } from "./types";

export interface CreateAnalystSignupRequest {
  name: string
  email: string
  password: string
  username: string
  social_link: string | null
  about: string | null
  referral_code: string | null
}

export interface AnalystSignupRequest {
  id: number
  status: AnalystSignupRequestStatus
  social_link: string | null
  about: string | null
  created_at: string
  updated_at: string
}

export type CreateForExistingUserRequest = Pick<CreateAnalystSignupRequest, 'social_link' | 'about'>

const analystSignupRequestApi = {
  async create(request: CreateAnalystSignupRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/analyst-signup-request', request);
    return data;
  },

  async getLatest() {
    const { data } = await axios.get<AnalystSignupRequest | null>('/analyst-signup-request/latest');
    return data;
  },

  async createForExistingUser(request : CreateForExistingUserRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/analyst-signup-request/for-existing-user', request); 
    return data;
  }
}

export default analystSignupRequestApi;