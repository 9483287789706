import { ConfigProvider, Slider } from "antd";
import { useEffect, useState } from "react";
import { MFAImage } from "../../api/webull-connect";
import { useEmotionCss } from "@ant-design/use-emotion-css";

export const MFAImagePuzzle = ({
  image,
  setXPosPerc,
  containerWidth = 300,
}: {
  image: MFAImage,
  setXPosPerc: (x: number) => void,
  containerWidth?: number,
}) => {

  const [xPos, setXPos] = useState(0);
  const { backImage, slidingImage, yPos } = image;

  const imgPrefix = "data:image/png;base64,";
  let bgWidth = 566;
  const scaleFactor = containerWidth / bgWidth;
  const yPosInt = parseInt(yPos) * scaleFactor;
  bgWidth = bgWidth * scaleFactor;
  const bgHeight = 320 * scaleFactor;
  const slideWidth = 110 * scaleFactor;
  const slideHeight = 110 * scaleFactor;
  const bgImage = imgPrefix + backImage; // 566x320
  const slideImage = imgPrefix + slidingImage;

  useEffect(() => {
    setXPosPerc(Math.round((xPos / (bgWidth - slideWidth) * 100)));
  }, [xPos]);

  useEffect(() => {
    const listener = () => setXPos(0);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [])


  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: bgWidth,
    height: bgHeight,
    margin: '1rem 0',
  }

  const bgImageStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  }

  const slideImageStyle: React.CSSProperties = {
    top: yPosInt,
    left: xPos,
    position: 'absolute',
    width: slideWidth,
    height: slideHeight,
  }

  const sliderClass = useEmotionCss(({ token }) => ({
    '&:hover': {
      '.ant-slider-track': {
        backgroundColor: token.colorPrimary,
      }
    },
    '.ant-slider-rail': {
      borderRadius: 8,
    },
    '.ant-slider-track': {
      backgroundColor: token.colorPrimary,
      borderRadius: 8
    },
    '.ant-slider-handle:after': {
      backgroundColor: token.colorPrimary,
    }
  }));


  return <div style={{ maxWidth: '100%' }}>
    <div style={containerStyle}>
      <img src={bgImage} style={bgImageStyle} />
      <img style={slideImageStyle} src={slideImage} />
    </div>
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            railSize: 10,
            handleSize: 20,
            handleSizeHover: 20,
          }
        }
      }}
    >
      <Slider
        step={1}
        min={0}
        max={bgWidth - slideWidth}
        value={xPos}
        tooltip={{
          open: false
        }}
        onChange={(value) => setXPos(Math.round(value))}
      />
    </ConfigProvider>
  </div>
}