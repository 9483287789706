import { Avatar, Button, Card, Modal, Space, Typography } from "antd";
import { AutotradeBotResponse } from "../../../api/autotrade-bots";
import { brokerIcons } from "../../../brokers/components/broker-icons";
import { brokerTypeLabels } from "../../../api/enum-labels";
import { getBotWebhookUrl } from "../../utils";
import { TRADINGVIEW_CHART_URL } from "../../constants";
import { useMaskInfoContext } from "../../../shared/context/MaskInfoContext";

export function BotInfoModal({
  bot,
  open,
  onClose,
}: {
  bot: AutotradeBotResponse,
  open: boolean,
  onClose: () => void,
}) {
  const { masked } = useMaskInfoContext();

  return <Modal footer={null} title="Bot" open={open} onCancel={onClose}>
    <div style={{ padding: '16px 0' }}>
      <Space direction="vertical" size='large'>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          <div>
            <Avatar src={brokerIcons[bot.broker_account.type]} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              { brokerTypeLabels[bot.broker_account.type] }
            </Typography.Text>
            <Typography.Text type="secondary">
              { !masked ? bot.broker_account.username : '********' }
            </Typography.Text>
          </div>
        </div>
        <Card size="small" style={{ textAlign: 'left' }} >
          <Typography.Title level={5}>
            Webhook URL
          </Typography.Title>
          <Typography.Text copyable>
            { getBotWebhookUrl(bot.webhook_id) }
          </Typography.Text>
        </Card>
        <a target="_blank" href={TRADINGVIEW_CHART_URL}>
          <Button  block size="large" type="primary">
            Connect with tradingview
          </Button>
        </a>
      </Space>
    </div>
  </Modal>
}
