import { createContext, useContext, useEffect, useState } from "react"
import { maskInfoRepo } from "./mask-info-repo";

type MaskInfoContextState = {
  masked: boolean;
  setMasked: (masked: boolean) => void
}

const MaskInfoContext = createContext<MaskInfoContextState>({
  masked: false,
  setMasked: (masked: boolean) => {}
});

export const MaskInfoContextProvider = ({ children } : { children: React.ReactNode }) => {

  const [masked, setMasked] = useState(maskInfoRepo.getMasked);

  useEffect(() => {
    maskInfoRepo.setMasked(masked);
  }, [masked]);

  return <MaskInfoContext.Provider value={{
    masked,
    setMasked
  }}>
    { children }
  </MaskInfoContext.Provider>
}

export const useMaskInfoContext = () => useContext(MaskInfoContext);