import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const SignalProviderRedirector = () => {

  const { user } = useAuthUser();
  const navigate = useNavigate();

  useEffect(() => {

    if (!user || user.role !== UserRole.analyst) {
      return navigate('/app/dashboard');
    }

    const next = user.signal_providers.length <= 0
      ? '/app/account/analyst/register'
      : '/app/account/analyst/plans';

    navigate(next, { replace: true });
  }, [user]);

  return null;
}