import { Alert } from "antd";
import { useSearchParams } from "react-router-dom";

export function EmailVerifiedMessage() {

  const [searchParams] = useSearchParams();
  const emailVerified = !!(searchParams.get('emailVerified'));

  if (!emailVerified) {
    return null;
  }

  return <Alert
    showIcon
    style={{ marginTop: 16, marginBottom: 16 }}
    type='success'
    message={<span>Your email is verified.</span>}
  />
}