import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { useThemeContext } from "../../shared/context/ThemeContext";
import { Logo } from "../../shared/components/Logo";

const { Header, Content } = Layout;

export function StaticPagesLayout() {
  const { isDark } = useThemeContext();

  return <Layout>
    <Header
      style={{ 
        backgroundColor: isDark ? '#141414' : '#fff',
        display: 'flex',
      }}
    >
      <Logo />
    </Header>
    <Content>
      <Outlet />
    </Content>
  </Layout>
}