import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Collapse } from "antd";

export const CollapsibleSection = ({
  title,
  children,
  defaultOpen = true,
} : {
  title: string,
  children: React.ReactElement,
  defaultOpen?: boolean
}) => {

  const collapseStyle = useEmotionCss(({ token }) => ({
    '&.ant-collapse': {
      border: 'none',
      backgroundColor: token.colorBgContainer,
      '> .ant-collapse-item': {
        borderBottom: 'none',
      },
      '.ant-collapse-content': {
        borderTop: 'none',
        '> .ant-collapse-content-box': {
          padding: 0,
        }
      }
    }
  }))

  return <Collapse
    className={collapseStyle}
    defaultActiveKey={[defaultOpen ? '1' : '']} 
  >
    <Collapse.Panel  header={title} key={'1'}>
      { children }
    </Collapse.Panel>
  </Collapse>
}