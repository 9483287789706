import React, { useContext, useEffect, useState } from "react";

export enum Theme {
  Dark='dark',
  Light='light',
  System='system',
}

export interface ThemeContextState {
  theme: Theme
  setTheme: (theme : Theme) => void
  isDark: boolean
}

export const ThemeContext = React.createContext<ThemeContextState>({
  theme: Theme.System,
  setTheme: () => {},
  isDark: false
});

const THEME_STORAGE_KEY = 'theme';

export const ThemeContextProvider = ({ children } : { children: React.ReactElement }) => {

  const [systemDark, setSystemDark] = useState(false);
  const [theme, setTheme] = useState<Theme>(Theme.System);
  const isDark = theme === Theme.Dark ||
    theme === Theme.System && systemDark;

  // get system preference
  useEffect(() => {
    const handleSystemColorSchemeChange = (e: MediaQueryListEvent): void => {
      setSystemDark(e && e.matches);
    };

    let matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addEventListener("change", handleSystemColorSchemeChange);
    setSystemDark(matchMedia.matches);

    return () => {
      matchMedia.removeEventListener("change", handleSystemColorSchemeChange);
    }

  }, []);

  // parse theme stored in localstorage
  useEffect(() => {
    const loadInitialTheme = () => {
      let parsedTheme = localStorage.getItem(THEME_STORAGE_KEY) || 'system';
      if ((Object.values(Theme) as string[]).includes(parsedTheme)) {
        setTheme(parsedTheme as Theme);
      }
    }
    
    loadInitialTheme();
  }, []);

  const changeTheme = (theme: Theme) => {
    localStorage.setItem(THEME_STORAGE_KEY, theme);
    setTheme(theme);
  }

  return <ThemeContext.Provider value={{
    isDark,
    setTheme: changeTheme,
    theme,
  }}>
    { children }
  </ThemeContext.Provider>
}

export const useThemeContext = () => useContext(ThemeContext);
