import { BrokerType } from '../brokers/entities/broker-account.entity';
import axios from './axios';
import { GenericStatusResponse } from './types';

export type CreateBotRequest = {
  name: string;
  broker_account_id: number;
  password: string;
  paper: boolean;
}

export type CreateBotResponse = {
  id: number;
  webhook_id: string; 
}

export type ChangeBotPasswordRequest = {
  password: string;
}

export type AutotradeBotBrokerAccount = {
  id: number;
  type: BrokerType;
  username: string;
}

export type AutotradeBotResponse = {
  id: number;
  name: string | null;
  webhook_id: string;
  paper: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  broker_account: AutotradeBotBrokerAccount;
}

export type DeleteOverlimitBotsResponse = {
  deleted: boolean
}

const autotradeBotsApi = {

  async list() {
    const { data } = await axios.get<Array<AutotradeBotResponse>>('/autotrade-bots');
    return data;
  },

  async findById(id: number) {
    const { data } = await axios.get<AutotradeBotResponse | null>(`/autotrade-bots/${id}`);
    return data;
  },

  async create(request: CreateBotRequest) {
    const { data } = await axios.post<CreateBotResponse>('/autotrade-bots', request);
    return data;
  },

  async changePassword(id: number, request: ChangeBotPasswordRequest) {
    const { data } = await axios.patch<GenericStatusResponse>(`/autotrade-bots/${id}/change-password`, request);
    return data;
  },

  async softDelete(id: number) {
    const { data } = await axios.patch<GenericStatusResponse>(`/autotrade-bots/${id}/soft-delete`);
    return data;
  },

  async hardDelete(id: number) {
    const { data } = await axios.delete<GenericStatusResponse>(`/autotrade-bots/${id}`);
    return data;
  },

  async restore(id: number) {
    const { data } = await axios.patch<GenericStatusResponse>(`/autotrade-bots/${id}/restore`);
    return data;
  },

  async deleteOverLimit() {
    const { data } = await axios.post<DeleteOverlimitBotsResponse>(`/autotrade-bots/delete-overlimit`);
    return data;
  }
}

export default autotradeBotsApi;