import { Button, notification, Popconfirm } from "antd";
import { AutotradeBotResponse } from "../../../api/autotrade-bots";
import { DeleteOutlined, InfoCircleOutlined, LockOutlined } from '@ant-design/icons';
import api from "../../../api";
import { useCanAutotrade } from "../../../candefi-memberships/hooks/useCanAutotrade";
import { isBotActive, isBotArchived } from "./utils";
import { getErrorMessage } from "../../../shared/utils";
import { useMaxAutotradeBots } from "../../../candefi-memberships/hooks/useMaxAutotradeBots";
import { PremiumOutlinedButton } from "../../../shared/components/PremiumButton";
import { Link } from "react-router-dom";
import { CrownFilled } from '@ant-design/icons'

export function BotActions({
  bot, 
  setChangePasswordOpen, 
  setInfoOpen, 
  onUpdate,
  totalActiveBots,
}: {
  setInfoOpen: (v: boolean) => void;
  setChangePasswordOpen: (v: boolean) => void;
  bot: AutotradeBotResponse;
  onUpdate?: () => void;
  totalActiveBots: number
}) {
  const canAutotrade = useCanAutotrade();
  const isArchived = isBotArchived(bot);
  const isActive = isBotActive(bot);

  return <div style={{ display: 'flex', flexDirection: 'row', gap: 8, flexWrap: 'wrap' }}>
    
    {
      isActive && (
        <Button disabled={!canAutotrade} type="primary" onClick={() => { setInfoOpen(true); }}>
          <InfoCircleOutlined /> Info
        </Button>
      )
    }

    {
      isActive && (
        <Button
          disabled={!canAutotrade}
          onClick={() => setChangePasswordOpen(true)}
          title="Change Password">
          <LockOutlined /> Change Password
        </Button>
      )
    }

    {
      isActive && <ArchiveAction bot={bot} onUpdate={onUpdate} totalActiveBots={totalActiveBots} />
    }
    {
      isArchived && <RestoreAction bot={bot} onUpdate={onUpdate} totalActiveBots={totalActiveBots} />
    }
    {
      isArchived && <HardDeleteAction bot={bot} onUpdate={onUpdate} totalActiveBots={totalActiveBots} />
    }
  </div>;
}

function ArchiveAction({ bot, onUpdate, totalActiveBots } : { bot: AutotradeBotResponse, onUpdate?: () => void, totalActiveBots: number }) {
  const canAutotrade = useCanAutotrade();

  return <Popconfirm
    disabled={!canAutotrade}
    title="Archive this bot"
    description="Are you sure?"
    okText="Yes"
    cancelText="No"
    onConfirm={async () => {
      await api.autotradeBots.softDelete(bot.id);
      if (onUpdate !== undefined) {
        onUpdate();
      }
    }}
  >
    <Button disabled={!canAutotrade} danger>
      <DeleteOutlined /> Archive
    </Button>
  </Popconfirm>
}

function RestoreAction({ bot, onUpdate, totalActiveBots } : { bot: AutotradeBotResponse, onUpdate?: () => void, totalActiveBots: number }) {
  const canAutotrade = useCanAutotrade();
  const [noticationApi, contextHolder] = notification.useNotification();
  const maxBots = useMaxAutotradeBots();

  const overLimit = totalActiveBots >= maxBots;

  if (overLimit) {
    return <Link to='/app/account/candefi-memberships'>
      <PremiumOutlinedButton icon={<CrownFilled/>}>
        Upgrade To Restore
      </PremiumOutlinedButton>
    </Link>
  }

  return <>
    { contextHolder }  
    <Popconfirm
      disabled={!canAutotrade}
      title="Restore"
      description="Are you sure?"
      okText="Yes"
      cancelText="No"
      onConfirm={async () => {
        try {
          await api.autotradeBots.restore(bot.id);
          if (onUpdate !== undefined) {
            onUpdate();
          }
        } catch(e) {
          noticationApi.error({
            message: getErrorMessage(e),
          })
        }
      }}
    >
      <Button disabled={!canAutotrade}>
        Restore
      </Button>
    </Popconfirm>
  </>
}

function HardDeleteAction({ bot, onUpdate, totalActiveBots } : { bot: AutotradeBotResponse, onUpdate?: () => void, totalActiveBots: number }) {
  const canAutotrade = useCanAutotrade();
  
  return <Popconfirm
    disabled={!canAutotrade}
    title="Permenantly delete this bot"
    description="Are you sure?"
    okText="Yes"
    cancelText="No"
    onConfirm={async () => {
      try {
        await api.autotradeBots.hardDelete(bot.id);
        if (onUpdate !== undefined) {
          onUpdate();
        }
      } catch (e) {
        
      }
    }}
  >
    <Button disabled={!canAutotrade} danger>
      <DeleteOutlined /> Delete
    </Button>
  </Popconfirm>
}
