import { AxiosRequestConfig } from 'axios';
import { NO_TOKEN_PATHS, REFRESH_PATH } from '../constants';
import refreshInstance from '../refresh-axios';
import { Mutex } from 'async-mutex';
import getStore from '../../auth/store';

const mutex = new Mutex();

export default async function refreshAccessToken(config : AxiosRequestConfig<any> ) {

    // some api endpoints don't need access token
    let path = (config.url || '').replace(/\/$/, '');
    if (NO_TOKEN_PATHS.includes(path)) {
        return config;
    }

    return await mutex.runExclusive(async () => {
        // get access token expiry date
        const store = getStore();
        const tokens = store.getTokens();
        let accessTokenExpiry = null;
        
        if (tokens !== null && tokens.access_token_expires) {
            let expiryDate = new Date(tokens.access_token_expires);
            let isValidDate = !isNaN(expiryDate.getTime())
            if (isValidDate) {
                accessTokenExpiry = expiryDate;
            }
        }
        
        // expiry date is not set do nothing
        if (tokens === null || accessTokenExpiry === null) {
            return config;
        }
    
        let accessTokenExpired = accessTokenExpiry.getTime() < (new Date()).getTime();
        if (accessTokenExpired) {
            // refresh token before proceeding
            const { data: newTokens} = await refreshInstance.post(REFRESH_PATH, null, {
                headers: {
                    'Authorization': `Bearer ${tokens?.refresh_token}`
                }
            });

            tokens.access_token = newTokens.access_token;
            tokens.access_token_expires = newTokens.access_token_expires;
            await store.setTokens(tokens);
        }
    
        return config;
    })
};
