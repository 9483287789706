import {  Button, Card, Modal, Result, Typography } from "antd"
import { CreateBotForm } from "./create-bot-form"
import { CreateBotResponse } from "../../../api/autotrade-bots"
import { useEffect, useState } from "react";
import { getBotWebhookUrl } from "../../utils";
import { CONNECT_TUTORIAL_URL } from "../../constants";

export function CreateBotModal({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean,
  onClose: () => void,
  onSuccess?: () => void
}) {
  const [response, setResponse] = useState<CreateBotResponse | null>(null);

  useEffect(() => {
    if (open) {
      setResponse(null);
    }
  }, [open]);

  const onDone = (data: CreateBotResponse) => {
    setResponse(data);
    if (onSuccess !== undefined) {
      onSuccess();
    }
  }

  return <Modal
    open={open}
    onCancel={onClose}
    footer={null}
  >
    {
      !response && (
        <>
          <Typography.Title level={2}>Create Bot</Typography.Title>
          <CreateBotForm 
            onSuccess={onDone}
          />
        </>
      )
    }
    {
      response && (
        <div>
          <Result
            icon={null}
            title={<Typography.Title level={3}>Bot Created</Typography.Title>}
            subTitle={
                <Card size="small" style={{ textAlign: 'left' }} >
                  <Typography.Title level={5}>
                    Webhook URL
                  </Typography.Title>
                  <Typography.Text copyable>
                    { getBotWebhookUrl(response.webhook_id) }
                  </Typography.Text>
                </Card>
            }
            extra={[
              <a target="__blank" href={CONNECT_TUTORIAL_URL}>
                <Button type="primary">
                  Connect With Trading View
                </Button>
              </a>
            ]}
          />
        </div>
      )
    }
  </Modal>
}
