import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import wizardIcon from '../../shared/images/setup_wizard.svg';
import { BarChartOutlined } from '@ant-design/icons';

export const BecomeAnalystIntroSection = () => {
  return <div>
    <Result
      title="Become a Signal Provider"
      subTitle={(
        <div>
          Launch and manage your trade signal business on CandeFi. Monetize your insights with built-in subscription and signal management, supported by our cutting-edge technology.
        </div>
        // <div>
        //   Unlock your potential as a market expert! <br />
        //   Elevate your trading journey by becoming an analyst on our platform. <br />
        //   Create and monetize your unique subscription plans while sharing your market insights with a growing community of traders
        // </div>
      )}

      icon={<img src={wizardIcon} style={{ width: 'auto', maxWidth: 300 }} />}
      extra={<Link to={'/app/account/analyst-application/form'}>
        <Button
          icon={<BarChartOutlined />}
          type="primary"
          size="large">
          Become analyst
        </Button>
      </Link>} />
  </div>;
};
