import { Avatar, Button, Divider, List, Space, Typography } from "antd";
import { LinkOutlined } from '@ant-design/icons';
import { LockFilled } from '@ant-design/icons';
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { TinyColor } from "@ctrl/tinycolor";
import webullIcon from '../images/webull_icon.png';
import robinhoodIcon from '../images/robinhood_icon.png';
import { BlurredScreenshotBackground } from "./BlurredScreenshotBackground";

export function FirstTimeBrokerConnect({ openModal }: { openModal: () => void; }) {

  const contentContainer = useEmotionCss(({ theme, token }) => ({
    width: 'min(100%, 600px)',
    marginTop: '5rem',
    backgroundColor: (new TinyColor(token.colorBgBase)).setAlpha(0.5).toRgbString(),
    padding: '1.5rem',
    borderRadius: '0.25rem',
  }));

  const button = useEmotionCss(() => ({
    padding: '4px 20px',
  }));

  return (
    <BlurredScreenshotBackground>
      <div className={contentContainer}>
        <Typography.Title>
          Almost There!
        </Typography.Title>
        <p style={{
          fontSize: '1.3rem',
          // @ts-ignores
          textWrap: 'balance'
        }}
        >
          Link your broker then view the trading dashboards
        </p>
        <p>
          Supported Brokers. More integrations coming soon..
        </p>
        <List style={{ marginBottom: '1rem' }}>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar
                src={webullIcon}
                size='large' />}
              title='Webull & Webull Paper'
            ></List.Item.Meta>
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar
                src={robinhoodIcon}
                size='large' />}
              title='Robinhood'
            ></List.Item.Meta>
          </List.Item>
        </List>
        <Button
          onClick={() => openModal()}
          className={button}
          type="primary"
          size="large">
          <LinkOutlined />
          Connect Broker Account
        </Button>
        <Divider />
        <Space style={{ alignItems: 'center' }}>
          <LockFilled
              style={{ 
              fontSize: '1.5rem',
              color: 'gold'
            }}
          />
          <p style={{ marginBottom: 0 }}>
            Your data is securely encrypted, remains completely private and never shared with any entity
          </p>
        </Space>
      </div>
    </BlurredScreenshotBackground>
  );
}
