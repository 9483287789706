import { useAppSelector } from "../../redux/hooks";
import { Result } from "antd";
import dmImage from './dm_request_icon.svg';
import { currentChatroomSelector } from "../redux/chat.selectors";

export function DMRequestWaitingUI() {
  const chatroom = useAppSelector(currentChatroomSelector)!;

  return <div>
    <Result
      icon={<img
        src={dmImage}
        style={{ display: 'inline-block', maxHeight: 200 }} />}
      title={`Waiting for @${chatroom.dm_request_recipient!.username} to accept your request`}
    >
    </Result>
  </div>;
}
