import { message } from "antd";
import { useState } from "react";
import api from "../../api";
import { getErrorMessage } from "../../shared/utils";

export const useStripeConnect = () => {
  const [creating, setCreating] = useState(false);

  const createLink = async () => {
    try {
      setCreating(true);
      const result = await api.stripeConnect.createStripeConnectLink({
        refresh_url: window.location.href,
        return_url: window.location.href,
      });
      // @ts-ignore
      window.location = result.url;
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      message.error(errorMessage);
    } finally {
      setCreating(false);
    }
  }

  return {
    creating,
    createLink,
  }
}