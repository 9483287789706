import { WebullPaperAccountInfo } from "../../api/account-info";
import { fixDecimalPlaces, formatMoney, numberToAntTextType } from "../../shared/utils";
import styles from "./account-details.module.css";
import { Space, Typography, } from "antd";

const { Title, Text } = Typography;

export function WebullPaperAccountDetails({ info } : { info: WebullPaperAccountInfo }) {

  return <div>
    <Title level={5}>Paper Account Info</Title>
    <div className={styles.row}>
      <Text type="secondary">Net Account Value</Text>
      <Text type="success">
        {formatMoney(fixDecimalPlaces(info.capital.netLiquidationValue))}
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Overall P&L</Text>
      <Space>
        <Text type={numberToAntTextType(info.capital.unrealizedProfitLoss)}>
          { formatMoney(fixDecimalPlaces(info.capital.unrealizedProfitLoss)) }
        </Text>
        <Text type={numberToAntTextType(info.capital.unrealizedProfitLossRate)}>
          { fixDecimalPlaces(info.capital.unrealizedProfitLossRate * 100) + '%' }
        </Text>
      </Space>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Market Value</Text>
      <Text type="success">
        {formatMoney(fixDecimalPlaces(info.capital.totalMarketValue))}
      </Text>
    </div>
    <div className={styles.row}>
      <Text type="secondary">Day's P&L</Text>
      <Text type={numberToAntTextType(info.todaySummary.account.dayProfitLoss)}>
        { formatMoney(fixDecimalPlaces(info.todaySummary.account.dayProfitLoss)) }
      </Text>
    </div>
  </div>;
}