import axios from "./axios";
import { MembershipStatus } from "./enums";
import { MembershipPlanDetails } from "./membership-plans";
import { ListRequest, PaginatedResponse } from "./types";

export class ListSubscribersMembershipsRequest extends ListRequest {
  plan_ids?: Array<number>
}

export class ListSubscribersPaymentsRequest extends ListRequest {
  plan_ids?: Array<number>
}

export type SubscriberMembershipPlan = MembershipPlanDetails

export type SubscriberMembershipUser = {
  username: string
  name: string
}

export type SubscriberMembership = {
  id: number;
  status: MembershipStatus;
  membership_plan: SubscriberMembershipPlan;
  user: SubscriberMembershipUser
}

export type SubscriberPayment = {
  id: number;
  amount: number;
  commission: number;
  created_at: string;
  membership: SubscriberMembership;
}

const subscribersApi = {
  async listMemberships(request : ListSubscribersMembershipsRequest) {
    const { data } = await axios.get<PaginatedResponse<SubscriberMembership>>('/subscribers/memberships', {
      params: request,
    });
    return data;
  },

  async listPayments(request : ListSubscribersPaymentsRequest) {
    const { data } = await axios.get<PaginatedResponse<SubscriberPayment>>('/subscribers/payments', {
      params: request,
    });
    return data;
  },
}

export default subscribersApi;