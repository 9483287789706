import { Card, Segmented } from "antd";
import { useMatch, useMatches, useNavigate } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { useEmotionCss } from "@ant-design/use-emotion-css";

export function MembershipTabs() {
  const navigate = useNavigate();
  const matches = useMatches();

  const routes = [
    {
      label: 'AutoTrade Memberships',
      route: '/app/account/candefi-memberships'
    },
    {
      label: 'CopyTrade Memberships',
      route: '/app/account/memberships'
    },
  ];

  const currentRoute = routes.find(r => {
    return matches.some(m => m.pathname === r.route)
  }) || null;

  const options = routes.map(r => ({
    label: r.label,
    value: r.route,
  }));

  const className = useEmotionCss(({ token }) => ({
    '&.ant-segmented .ant-segmented-item-selected': {
      backgroundColor: token.colorPrimary
    }
  }));

  return <Card size="small">
    <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Segmented
          className={className}
          size="middle"
          value={currentRoute?.route || ''}
          options={options}
          onChange={(value) => {
            navigate(value as string);
          }} 
        />
    </div>
  </Card>;
}
