import { Button, Card } from "antd"
import { useState } from "react";
import OpenOrders from "../../orders/components/OpenOrders"
import OpenPositions from "../../positions/components/OpenPositions"
import { AssetType } from "../../api/enums";

export enum PositionOrderTab {
  Positions = "positions",
  Orders = "orders",
}

export enum PositionOrderType {
  All = "all",
  Options = "options",
  Stocks = "stocks",
}

export const positionOrderTypeLabels: Record<PositionOrderType, string> = {
  [PositionOrderType.All]: "All",
  [PositionOrderType.Options]: "Options",
  [PositionOrderType.Stocks]: "Stocks",
}


export const PositionsAndOrdersCard = () => {

  const [tab, setTab] = useState<PositionOrderTab>(
    PositionOrderTab.Positions
  );

  const [type, setType] = useState<PositionOrderType>(
    PositionOrderType.All
  );

  const assetTypesMap : Record<PositionOrderType, AssetType[]> = {
    [PositionOrderType.All]: Object.values(AssetType),
    [PositionOrderType.Options]: [ AssetType.Options ],
    [PositionOrderType.Stocks]: [ AssetType.Stocks ],
  };

  const assetTypes = assetTypesMap[type];

  return <Card size="small">
    <div style={{ marginBottom: 20, overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
      <Button
        type={tab === PositionOrderTab.Positions ? "primary" : "default"}
        onClick={() => setTab(PositionOrderTab.Positions)}
        size="large"
      >
        Positions
      </Button>
      <Button
        type={tab === PositionOrderTab.Orders ? "primary" : "default"}
        onClick={() => setTab(PositionOrderTab.Orders)}
        size="large"
      >
        Orders
      </Button>
    </div>
    <div style={{ marginBottom: 20, overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
      {
        Object.values(PositionOrderType).map(t => (
          <Button
            key={t}
            type={type === t ? "primary" : "default"}
            onClick={() => setType(t)}
            size="middle"
          >
            { positionOrderTypeLabels[t] }
          </Button>
        ))
      }
    </div>
    {tab === PositionOrderTab.Orders && <OpenOrders assetTypes={assetTypes} />}
    {tab === PositionOrderTab.Positions && <OpenPositions assetTypes={assetTypes} />}
  </Card>
}