import { Button, Result } from 'antd';
import { AnalystSignupRequestStatus } from '../../api/enums';
import { AnalystSignupRequest } from '../../api/analyst-signup-request';
import { Link } from 'react-router-dom';

export const AnalystSignupRequestStatusSection = ({
  request
}: {
  request: AnalystSignupRequest;
}) => {
  return <div>
    {request.status === AnalystSignupRequestStatus.Approved && <Result
      status={'success'}
      title='Your application is approved'
      subTitle='Relogin to continue' />}
    {request.status === AnalystSignupRequestStatus.Pending && <Result
      status={'info'}
      title='Your application under review'
      subTitle='You will get an email after we review your application' />}
    {request.status === AnalystSignupRequestStatus.Rejected && <Result
      status={'error'}
      title='Your application was rejected'
      extra={[
        <Link to={'/app/account/analyst-application/form'}>
          <Button>
            Submit again.
          </Button>
        </Link>
      ]} />}
  </div>;
};
