import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { logout } from "../utils";
import { useAuthUser } from "../hooks/useAuthUser";

export const InactivityLogout = () => {
  const { initialized, user } = useAuthUser();

  // timeout values
  const timeout = 30 * 60 * 1000; // 30 min
  const promptBeforeIdle = 0.5 * 60 * 1000; // 0.5 min

  // Modal open state
  const [open, setOpen] = useState(false)

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const disabled = !initialized || !user

  const onPrompt = () => {
    setOpen(true)
    setRemaining(Math.ceil(promptBeforeIdle / 1000))
  }

  const onIdle = () => {
    setOpen(false)
    setRemaining(0);
    logout();
  }

  const onActive = () => {
    setOpen(false)
    setRemaining(0)
  }

  const handleStillHere = () => {
    setOpen(false)
    activate()
  }

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
    crossTab: true,
    disabled: disabled,
  });

  useEffect(() => {
    if (disabled) {
      return;
    }
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted, disabled]);

  return <div>
    <Modal
      title="Are you still here?"
      open={open} 
      onOk={handleStillHere} 
      okText="Continue Session"
      cancelButtonProps={{ style: { display: 'none' } }}  
    >
      <p>Your session will logout in { remaining } seconds.</p>
    </Modal>
  </div>
}