import useSWR from 'swr';
import api from '../api';
import { MiscConfig } from '../api/misc-config';

const STORAGE_KEY = 'misc-config';

const getFromStorage = () => {
  if (localStorage.getItem(STORAGE_KEY) === null) {
    return null;
  }
  return JSON.parse(localStorage.getItem(STORAGE_KEY) || '') as MiscConfig
}

const saveToStorage = (config: MiscConfig) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(config));
}

const fallback = getFromStorage() || {
  analyst_color: '#4096ff',
  color: '#7260ff',
}

export function useMiscConfig() {
  const { data } = useSWR(
    '/misc-config',
    () => api.miscConfig.get(),
    { 
      fallbackData: fallback,
      onSuccess(config) {
        saveToStorage(config)
      }
    },
  );

  return data;
}