import { PublicationContext } from "centrifuge";
import { useEffect } from "react"
import { useGeneralChannelSubscription } from "../../cent/hooks/useGeneralChannelSubscription";
import { isNewSignalEvent, NewSignalEventPayload } from "../types";

export const useNewSignalNofication = (
  onNewSignal: (payload : NewSignalEventPayload) => void
) => {

  const subscription = useGeneralChannelSubscription();

  useEffect(() => {
    if (subscription === null) {
      return
    }
  
    const onPublication = (ctx : PublicationContext) => {
      if (isNewSignalEvent(ctx.data)) {
        onNewSignal(ctx.data.payload);
      }
    }
  
    subscription.addListener('publication', onPublication);

    return () => {
      subscription.removeListener('publication', onPublication);
    }

  }, [onNewSignal, subscription]);

  return;
}