import { Radio } from "antd"
import { directionLabels } from "../../../api/enum-labels";
import { Direction } from "../../../api/enums"
import { useRadioGroupStyle } from "../../../shared/styles/useRadioGroup";

export const DirectionInput = ({
  value,
  setValue,
  options,
  disabled = false,
} : {
  value: Direction | null,
  setValue: (value: Direction) => void,
  options: Direction[],
  disabled?: boolean
}) => {
  const selectOptions = options.map((option) => ({
    label: directionLabels[option],
    value: option,
  }));

  const radioClass = useRadioGroupStyle();
  
  return <Radio.Group
    disabled={disabled}
    className={radioClass}
    options={selectOptions}
    optionType="button"
    buttonStyle="solid"
    size="large"
    onChange={(event) => setValue(event.target.value as Direction)}
    value={value}
  />;

  // return <Select
  //   style={{ width: '100%' }}
  //   placeholder="Direction"
  //   options={selectOptions}
  //   onChange={(value) => setValue(value)}
  //   value={value}
  //   disabled={disabled}
  // />
}