import { Button } from "antd";
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { sendMessage } from "../redux/thunks/send-message.thunk";
import { Input } from 'antd';
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { currentChatroomSelector, sendingDraftsSelector } from "../redux/chat.selectors";
import { PictureOutlined, SendOutlined } from '@ant-design/icons';
import { openImageUploadPopup } from "../redux/chat.slice";

const { TextArea } = Input;

export function SendMessage() {

  const dispatch = useAppDispatch();

  const sending = useIsSendingDraft();
  const canSend = useCanSend();

  const [text, setText] = useState('');

  const handleSend = () => {

    if (!text || sending) {
      return;
    }

    dispatch(sendMessage({ text, image: null }))

    setText('');
  }

  const handleImageUploadClick = () => {
    dispatch(openImageUploadPopup());
  }

  const textAreaClass = useEmotionCss(({ theme, token }) => ({
    background: token.colorBgElevated,
    paddingRight: 30,
  }))

  if (!canSend) {
    return null;
  }

  return <div style={{ display: 'flex', gap: 8, alignItems: 'flex-end' }}>
    <div style={{ flex: 1, position: 'relative' }}>
      <TextArea
        className={ textAreaClass }
        placeholder="Type a message"
        value={text} 
        size="large"
        onChange={(e) => setText(e.target.value)} 
        autoSize />
      <Button
        style={{ 
          position: 'absolute', 
          right: 4,
          bottom: 4,
        }}
        type="text" 
        shape="circle"
        tabIndex={-1}
        onClick={handleImageUploadClick}
        icon={<PictureOutlined/>}>  
      </Button>
    </div>
    <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}>
      <Button
        type="primary"
        onClick={handleSend} 
        disabled={sending} 
        loading={sending}
        size="large"
        icon={<SendOutlined/>}
        shape="circle"
      ></Button>
    </div>
  </div>
}

function useCanSend() {
  const room = useAppSelector(currentChatroomSelector);
  return room?.permission_summary?.can_send || false;
}

function useIsSendingDraft() {
  const sendingDrafts = useAppSelector(sendingDraftsSelector);
  return sendingDrafts.length > 0;
}