import { Select } from "antd"

export const StrikePricesInput = ({
  prices,
  loading,
  value,
  setValue,
  disabled=false,
} : {
  prices?: number[],
  loading?: boolean,
  value: number | null,
  setValue: (value : number) => void,
  disabled?: boolean
}) => {
  return <Select
    style={{ width: '100%' }}
    placeholder="Strike Price"
    options={(prices || []).map(price => ({ label: price, value: price }))}
    onChange={(value) => setValue(value)}
    value={value}
    loading={loading}
    disabled={disabled}
    showSearch
/>
}