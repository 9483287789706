import { Preference } from "../api/user-trade-preference";
import { SelectedBroker } from "../brokers/repos/selected-broker-repo";

export function getDefaultBrokerInfo(pref?: Preference | null) : SelectedBroker | null {
  if (!pref) {
    return null;
  }
  const account = pref?.broker_account || null;
  const paper = pref?.broker_account_paper || false;

  if (!account) {
    return null;
  }

  return {
    account,
    paper,
  }
}