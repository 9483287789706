import { Button, Form, Input, InputNumber, Modal, Select, message, Row, Col, Checkbox, Typography, Table, Divider } from "antd";
import { BillingIntervalUnit } from "../../api/enums";
import { billingIntervalUnitLabels } from "../../api/enum-labels";
import { CreateMembershipPlanRequest } from "../../api/membership-plans";
import api from "../../api";
import { useState } from "react";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import {
  CiCircleOutlined
} from '@ant-design/icons';
import useSWR from 'swr';
import { FeesExplantion } from "./FeesExplantion";

const { Paragraph, Text, Title } = Typography

export const CreateMembershipPlanModal = ({
  signalProviderId,
  open,
  setOpen,
  onSuccess,
}: {
  signalProviderId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
}) => {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);
  const { data: fees } = useSWR('/fees', api.fees.get);

  const billingIntervalUnits = Object.values(BillingIntervalUnit).map((option) => ({
    label: billingIntervalUnitLabels[option],
    value: option,
  }));

  const onFinish = async (payload: CreateMembershipPlanRequest) => {
    try {
      
      setSubmitting(true);
      setError(null);
      payload.signal_provider_id = signalProviderId;
      await api.membershipPlans.create(payload);
      onSuccess();

    } catch (e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  };

  return (
    <>
      <Modal
        title="Create Membership Plan"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        {
          error && <ErrorMessage error={error} />
        }
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            billing_interval: 1,
            billing_interval_unit: BillingIntervalUnit.Week,
            billing_cycles: 1,
            amount: 1,
          }}
          autoComplete="off"
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Billing Interval"
                name="billing_interval"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Billing Interval",
                  },
                ]}
              >
                <InputNumber 
                  min={1}
                  style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                style={{ marginLeft: 5, flex: 1 }}
                label="Billing Interval Unit"
                name="billing_interval_unit"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Billing Interval Unit",
                  },
                ]}
              >
                <Select options={billingIntervalUnits} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Billing Cycles"
                name="billing_cycles"
                help='0 for infinite'
                rules={[
                  { required: true, message: "Please enter your Billing Cycles" },
                ]}
              >
                <InputNumber
                  addonBefore={<CiCircleOutlined/>}
                  min={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: "Please enter your amount" }]}
              >
                <Input
                  addonBefore="$"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row>
                <Form.Item
                  name="stocks"
                  valuePropName="checked"
                >
                  <Checkbox>Stocks</Checkbox>
                </Form.Item>
                <Form.Item
                  name="options"
                  valuePropName="checked"
                >
                  <Checkbox>Options</Checkbox>
                </Form.Item>
              </Row>
            </Col>
            
            {
              fees && (
                <Col span={24}>
                  <FeesExplantion fee={fees} />  
                </Col>
              )
            }

            <Col span={24}>
              <Button
                htmlType="submit"
                disabled={submitting}
                block
                size="large"
                type="primary"
              >
                Create Plan
              </Button>
            </Col>

          </Row>

        </Form>
      </Modal>
    </>
  );
};


