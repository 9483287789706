import { useEmotionCss } from "@ant-design/use-emotion-css"

export function ChatBubble({ 
  children,
  direction,
} : {
  children: React.ReactNode,
  direction: 'right' | 'left'
}) {

  const bubbleClass = useEmotionCss(({ theme, token }) => ({
    backgroundColor: token.colorPrimary,
    color: token.colorText,
    borderBottomRightRadius: direction === 'right' ? 0 : 16, 
    borderBottomLeftRadius: direction === 'left' ? 0 : 16,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    // maxWidth: '80%',
    // [`@media screen (min-width: ${token.screenLGMin}px)`]: {
    //   maxWidth: '60%',
    // },
    padding: 8,
  }));

  return <div className={bubbleClass}>
    { children }
  </div>
}