import { useState } from "react";
import api from "../../api";
import { AssetType } from "../../api/enums";
import { SignalItem } from "../../api/signals";
import { GenericIDResponse } from "../../api/types";
import { toPlaceOptionsOrderRequest, toPlaceStocksOrderRequest } from "../utils/signals";

type CopyReturnType = [() => Promise<GenericIDResponse | undefined>, boolean, unknown]

export const useCopySignal = (overriddenSignal: SignalItem) : CopyReturnType => {
  const [copying, setCopying] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const isStockSignal = overriddenSignal.asset_type === AssetType.Stocks;
  const isOptionsSignal = overriddenSignal.asset_type === AssetType.Options;

  const copy = async () => {
    try {
      setError(null);
      setCopying(true);
      
      // options order
      if (isOptionsSignal) {
        const request = toPlaceOptionsOrderRequest(overriddenSignal);
        if (!request) {
          return;
        }
        return api.orders.placeOptionsOrder(request);
      }
      
      // stocks order
      if (isStockSignal) {
        const request = toPlaceStocksOrderRequest(overriddenSignal);
        if (!request) {
          return;
        }
        return api.orders.placeStockOrder(request);
      }

    } catch(e) {

      setError(e);
      throw e;

    } finally {
      setCopying(false);
    }
  }

  return [copy, copying, error];
}