import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { SignalCopyDialog } from "../../../signals/components/SignalCopyDialog";
import { messageSignalToAppSignal, toPlaceOrderFormValues } from "../../../signals/utils/signals";
import { closeCopySignalPopup } from "../../redux/chat.slice";

export function CopyTradePopup() {

  const dispatch = useAppDispatch();

  const { open, signal } = useAppSelector(state => state.chat.copySignalPopup);

  const appSignal = signal
    ? messageSignalToAppSignal(signal)
    : null;

  const initialValues = appSignal
    ? toPlaceOrderFormValues(appSignal)
    : null;

  const onClose = () => {
    dispatch(closeCopySignalPopup());
  }

  if (!open || !appSignal || !initialValues) {
    return null;
  }

  return <SignalCopyDialog
    open={open}
    onClose={onClose}
    initialValues={initialValues}
  />
}
