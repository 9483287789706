import { useState } from "react";
import useSWR from 'swr';
import api from "../../../api";
import { Select, SelectProps } from "antd";
import { useSelectedBroker } from "../../../brokers/context/SelectedBrokerContext";

export const StockTickerAutocomplete = ({
  setValue,
  searchOptions = true,
  searchStocks = true,
  ...props
}: {
  setValue: (value: string | null) => void;
  searchOptions?: boolean,
  searchStocks?: boolean,
} & SelectProps) => {

  const { broker } = useSelectedBroker();
  
  const [query, setQuery] = useState<string>('');

  const {
    data: tickers, isValidating: tickersLoading
  } = useSWR(
    broker !== null && query !== null ? `/api/ticker/search?query=${query}&options=${searchOptions}&stocks=${searchStocks}` : null,
    () => api.tickerSearch.search({
      keyword: query!,
      stocks: searchStocks,
      options: searchOptions,
    })
  );

  const selectOptions = !!tickers
    ? tickers.map(t => ({ label: t.long_name, value: t.symbol, ticker: t }))
    : [];

  return <Select
    showSearch={true}
    searchValue={query}
    filterOption={false}
    onChange={(value) => setValue(value)}
    onSearch={(newValue) => setQuery(newValue || '')}
    options={selectOptions}
    loading={tickersLoading}
    {...props}
  />;
};
