import { Select } from "antd"

export const ExpirationDatesInput = ({
  dates,
  loading,
  value,
  setValue,
  disabled=false,
} : {
  dates?: string[],
  loading?: boolean,
  value: string | null,
  setValue: (value : string) => void,
  disabled?: boolean,
}) => {
  return <Select
    style={{ width: '100%' }}
    placeholder="Expiration Date"
    options={(dates || []).map(date => ({ label: date, value: date }))}
    onChange={(value) => setValue(value)}
    value={value}
    loading={loading}
    disabled={disabled}
    showSearch
  />
}