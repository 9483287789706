const STORAGE_KEY = 'candefi.masked';

export const maskInfoRepo = {
  getMasked: () => {
    const item = localStorage.getItem(STORAGE_KEY)
    // not masked by default
    if (item === null) {
      return false;
    }
    return  item === 'true';
  },

  setMasked: (masked: boolean) => {
    return localStorage.setItem(STORAGE_KEY, masked ? 'true' : 'false');
  }
}