import { useEmotionCss } from "@ant-design/use-emotion-css"
import { RESPONSIVE_TABLE_BREAKPOINT } from "../constants";

export const useResponsiveTable = () => {
  const height = '60px';

  return useEmotionCss(({ theme, token }) => ({
    overflowY: "auto",
    position: "relative",
    borderRadius: '8px',
    containerType: 'inline-size',
 
    '.ant-table-thead': {
      position: "sticky",
      top: 0,
      zIndex: 1,
    },

    '.ant-table-footer': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '&.end .ant-table .ant-table-footer': {
      display: 'none !important',
    },

    [`@container (max-width: ${RESPONSIVE_TABLE_BREAKPOINT}px)`]: {
      overflowX: "auto",

      '.ant-table': {
        display: 'flex',
        flexDirection: 'row',
        '.ant-table-footer': {
          flexDirection: 'row',
          width: 80,
        }
      },

      '.ant-table-content > table': {
        display: "flex",
        flexDirection: "row",
        // overflowX: "auto",

        '.ant-table-thead': {
          position: "sticky",
          left: 0,
        },

        '.ant-table-thead > tr': {
          display: "flex",
          flexDirection: 'column',
          height: '100%',
          '> *': {
            height: height,
            borderRadius: 0,
          }
        },

        '.ant-table-tbody': {
          display: "flex",
          flexGrow: 1,
          'tr.ant-table-placeholder': {
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            'td': {
              border: 'none !important',
            }
          },
          'tr:not(.ant-table-placeholder)': {
            display: "flex",
            flexDirection: 'column',
            height: '100%',
            '> *': {
              height: height,
              borderBottom: `1px solid ${token.colorBorderSecondary} !important`,
              borderTop: 'none !important',
            },
          }
        }
      },
    },
  }))
}

export const useResponsiveTableWrapper = ({
  columnsMobile,
  rowsDesktop
} : {
  columnsMobile: number,
  rowsDesktop: number,
}) => {
  return useEmotionCss(() => ({
    containerType: 'inline-size',
    '& > div': {
      maxHeight: 60 * columnsMobile,
      overflow: 'auto',
    },
    [`@container (min-width: ${RESPONSIVE_TABLE_BREAKPOINT}px)`]: {
      '& > div': {
        maxHeight: 60 * rowsDesktop,
      },
    }
  }));
}