import { Card } from "antd";
import { AccountInfo as AccountInfoComponent } from '../components/AccountInfo';

export const AccountInfo = () => {
  return (
    <Card
      title="Account Info"
      style={{ height: "100%" }}
      bordered={false}
    >
      <AccountInfoComponent />
    </Card>
  );
};
