import axios from './axios'

// only the fields we care about
export type AffiliateResponse = {
  id: number
  signal_provider: {
    id: number
    username: string
  }
}

const affiliateApi = {
  async findOne() {
    const { data } = await axios.get<AffiliateResponse | null>('affiliate');
    return data;
  },
}

export default affiliateApi;