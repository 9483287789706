import { useThemeContext } from "../context/ThemeContext"

import whiteIcon from '../images/white-logo-icon.png';
import whiteText from '../images/white-logo-text.png';
import blackIcon from '../images/black-logo-icon.png';
import blackText from '../images/black-logo-text.png';

export const LargeLogo = () => {
  const { isDark } = useThemeContext();

  const logoIcon = isDark
    ? whiteIcon
    : blackIcon

  const logoText = isDark
    ? whiteText
    : blackText
 
  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
  }}>
    <div>
      <img
        width={45}
        src={logoIcon}
      />
    </div>
    <div>
      <img
        style={{
          width: 160,
        }}
        src={logoText}
      />
    </div>
  </div>
}

export const Logo = () => {
  const { isDark } = useThemeContext();

  const logoIcon = isDark
    ? whiteIcon
    : blackIcon

  const logoText = isDark
    ? whiteText
    : blackText
 
  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
  }}>
    <div>
      <img
        width={30}
        src={logoIcon}
      />
    </div>
    <div>
      <img
        style={{
          width: 100,
        }}
        src={logoText}
      />
    </div>
  </div>
}