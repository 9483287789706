import { Button, Result } from "antd"
import wizardIcon from '../../shared/images/setup_wizard.svg';
import {
  PlusOutlined
} from '@ant-design/icons';

export const AddPlanIntro = ({
  onClick
} : {
  onClick: () => void
}) => {
  return <Result
    title="Add your first membership plan"
    subTitle="
    Create membership plan. Once you create membership plans,
    traders can subscribe to your membership plans to access your signals.
    You can set your desired price and frequency.
    "
    icon={<img src={wizardIcon} style={{ maxWidth: 300 }}/>}
    extra={
      <Button 
        icon={<PlusOutlined/>}
        type="primary" 
        size="large" 
        onClick={onClick}>
        Add Membership Plan
      </Button>
    }
  />
}