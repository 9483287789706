import styles from "./account-details.module.css";
import { Typography, } from "antd";
import { WebullAccount } from "../../api/account-info";
import { fixDecimalPlaces, formatMoney, formatMoneyAuto, numberToAntTextType } from "../../shared/utils";
import { useMaskInfoContext } from "../../shared/context/MaskInfoContext";
const { Title, Text } = Typography;

const WebullAccountDetails = ({ account } : { account: WebullAccount }) => {

  const accountMembers: Record<string, string | number> = {};
  const { masked } = useMaskInfoContext();

  if (account.accountMembers) {
    account.accountMembers.forEach(
      (member) => (accountMembers[member["key"]] = member["value"])
    );
  }

  const cashBalance = Number(accountMembers.cashBalance);
  const totalMarketValue = Number(accountMembers.totalMarketValue);

  return (
    <>
      <div>
        <Title level={5}>Account Info</Title>
        <div className={styles.row}>
          <Text type="secondary">Account Type</Text>
          <Text type="success">{account.accountType}</Text>
        </div>
        <div className={styles.row}>
          <Text type="secondary">Account Id</Text>
          <Text type="success">{ !masked ? account.brokerAccountId : '***' }</Text>
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <Title level={5}>Fund For Trading</Title>
        <div className={styles.row}>
          <Text type="secondary">Account Value</Text>
          <Text type="success"> 
            { formatMoneyAuto(account.netLiquidation) }
          </Text>
        </div>
        <div className={styles.row}>
          <Text type="secondary">Cash Balance</Text>
          {
            !isNaN(cashBalance) && (
              <Text type="success">
                { formatMoneyAuto(fixDecimalPlaces(cashBalance)) }
              </Text>
            )
          }
        </div>
        <div className={styles.row}>
          <Text type="secondary">ProfitLoss</Text>
          <Text  type={numberToAntTextType(account.unrealizedProfitLoss)}> 
            { formatMoney(fixDecimalPlaces(account.unrealizedProfitLoss)) }
          </Text>
        </div>
        <div className={styles.row}>
          <Text type="secondary">ProfitLoss</Text>
          <Text  type={numberToAntTextType(account.unrealizedProfitLossRate)}> 
            { fixDecimalPlaces(account.unrealizedProfitLossRate * 100) + '%' }
          </Text>
        </div>
        <div className={styles.row}>
          <Text type="secondary">Market Value</Text>
          {
            <Text  type="success">
              { formatMoney(fixDecimalPlaces(totalMarketValue)) }
            </Text>
          }
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <Title level={5}>Account RISK</Title>
        <div className={styles.row}>
          <Text type="secondary">Warning</Text>
          <Text type="success">{!account.warning ? "FALSE" : "TRUE"}</Text>
        </div>
        <div className={styles.row}>
          <Text type="secondary">Margin Warning</Text>
          <Text type="success">{accountMembers.riskStatus}</Text>
        </div>
        <div className={styles.row}>
          <Text type="secondary">Remaining Trade Times</Text>
          <Text type="success">{accountMembers.remainTradeTimes}</Text>
        </div>
      </div>
    </>
  );
};

export default WebullAccountDetails;
