import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { List } from 'antd';
import { ChatroomListItem } from "./chatroom-list-item";
import { setSelectedChatroomId } from "../redux/chat.slice";
import { chatroomsSelector, moderatorUserIdsSelector, unreadMessageCountSelector } from "../redux/chat.selectors";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const MyChatrooms = () => {

  const dispatch = useAppDispatch();

  const chatrooms = useAppSelector(chatroomsSelector);

  const unreadMessageCount = useAppSelector(unreadMessageCountSelector);

  const moderatorUserIds = useAppSelector(moderatorUserIdsSelector);

  const { user } = useAuthUser();

  if (!user) {
    return null
  }

  return <div style={{ 
    padding: 8, 
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'scroll',
    gap: 8,
  }}>
    {
      chatrooms.length > 0 && <div style={{  display: 'flex' }}>
        <List
          itemLayout="horizontal"
          style={{ flex: 1, overflowY: 'auto' }} 
          dataSource={chatrooms}
          renderItem={(chatroom, index) => (
            <ChatroomListItem
              onClick={() => {
                dispatch(setSelectedChatroomId(chatroom.id))
              }}
              chatroom={chatroom}
              user={user}
              unreadMessageCount={unreadMessageCount[chatroom.id] || 0}
              moderatorUserIds={moderatorUserIds}
            />
          )}
        />
      </div>
    }
  </div>
}