import { useEmotionCss } from "@ant-design/use-emotion-css";

/**
 * 
 * styles for a card with actions on top.
 * allows card head to wrap and hides button text on smaller screen size.
 * 
 * example
 * 
 * const className = useCardWithTopActions();
 * <Card 
 *    className={className}
 *    extra={
 *      <>
 *        <Button icon={<IconComponent/>}> Button Text </Button>
 *        <Button icon={<IconComponent/>}> Button Text 2 </Button>
 *      </>
 *    }
 * >
 * </Card>
 * 
 * @returns {string}
 */

export const useCardWithTopActions = () => {
  return useEmotionCss(({ token }) => ({
    '.ant-card-head': {
      paddingTop: 16,
      paddingBottom: 16,
      '.ant-card-head-wrapper': {
        flexWrap: 'wrap',
        gap: 16,
        '.ant-card-head-title': {
          'flex': 'none',
        },
        '.ant-card-extra': {
          display: 'flex',
          gap: 8,
          flexDirection: 'row',
          '.ant-btn': {
            flex: 1,
            'span:not(.anticon)': {
              display: 'none',
              [`@media (min-width: ${token.screenSMMin}px)`]: {
                display: 'inline-block',
              }
            }
          }
        }
      }
    }
  }));
};
