export enum BrokerType {
  webull='webull',
  robinhood='robinhood',
  tda='tda'
}

export interface BrokerAccount {
  id: number
  type: BrokerType
  username: string
}