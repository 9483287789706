// TradingViewWidget.jsx

import { theme } from 'antd';
import { useEffect, useRef } from 'react';
import { useThemeContext } from '../../shared/context/ThemeContext';

let tvScriptLoadingPromise : Promise<any>;

export interface TradeChartOptions {
  containerId?: string;
  symbol?: string
}

export const TradeChart = ({
  containerId = 'tradingview_3760a',
  symbol = 'AAPL'
} : TradeChartOptions) => {

  const onLoadScriptRef = useRef();
  const { token } = theme.useToken();
  const { isDark } = useThemeContext();

  useEffect(
    // @ts-ignore
    () => {
      // @ts-ignore
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      // @ts-ignore
      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      // @ts-ignore
      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById(containerId) && 'TradingView' in window) {
          // @ts-ignore
          let tv = new window.TradingView.widget({
            autosize: true,
            symbol: `${symbol}`,
            interval: "D",
            timezone: "Etc/UTC",
            theme: isDark ? 'dark' : 'light',
            style: "1",
            locale: "en",
            backgroundColor: token.colorBgContainer,
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: containerId
          });
          // @ts-ignore
          window.tv = tv;
        }
      }
    },
    [containerId, symbol, token.colorBgContainer]
  );

  return (
    <div>
      <div className='tradingview-widget-container'>
        <div id={containerId} style={{ height: 600 }} />
        <div className="tradingview-widget-copyright">
          <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
        </div>
      </div>
    </div>
  );
}
