export interface GenericStatusResponse {
  success: boolean
  message: string | null
}

export interface GenericIDResponse {
  id: number
}

export interface GenericStrIDResponse {
  id: string
}

export interface PaginatedResponse<T> {
  data: T[];
  total: number;
  page: number;
  per_page: number;
  pages: number;
}

export class ListRequest {
  page: number = 1
  per_page: number = 10;
}