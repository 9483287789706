import { useState } from "react";
import { useCandeFiMembershipsContext } from "./context";
import { BillingIntervalUnit } from "../api/enums";
import { Col, Row, Segmented } from "antd";
import { PlanTile } from "./plan-tile";

type Unit = BillingIntervalUnit.Year | BillingIntervalUnit.Month

export function CandeFiMembershipPlansList() {
  const { myMembership, plans } = useCandeFiMembershipsContext();

  const [selectedUnit, setSelectedUnit] = useState<Unit>(
    BillingIntervalUnit.Year
  );

  const filteredPlans = (plans.data || []).filter(p => p.is_free || p.billing_interval == selectedUnit);

  if (plans.loading) {
    return null;
  }

  return <div style={{ width: '100%' }}>
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '32px' }}>
        <Segmented
          size="large"
          options={[
            { label: 'Monthly Billing', value: BillingIntervalUnit.Month },
            { label: 'Yearly Billing', value: BillingIntervalUnit.Year },
          ]}
          value={selectedUnit}
          onChange={(e) => setSelectedUnit(e as Unit)}
        />
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div style={{ width: 'min(100%, 1024px)' }}>
        <Row gutter={[ 16, 16 ]}>
          {
            filteredPlans.map(plan => (
              <Col key={plan.id} span={24} md={{ span: 8 }}>
                <PlanTile plan={plan} />
              </Col>
            ))
          }  
        </Row>
      </div>
    </div>
  </div>
}
