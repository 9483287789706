import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { fetchInitialMessages } from "../redux/thunks/fetch-initial-messages.thunk";
import { Skeleton } from "antd";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { ChatroomDetailPageHeader } from "./chatroom-detail-page-header";
import { ChatroomDetailPageBody } from "./chatroom-detail-page-body";
import { currentChatroomIdSelector } from "../redux/chat.selectors";

export const ChatroomDetailPage = () => {
  const dispatch = useAppDispatch();
  const chatroomId = useAppSelector(currentChatroomIdSelector);
  const initialFetchRequest = useAppSelector(state => state.chat.selectedChatroomMessages?.initial);
  const initialFetchLoading = initialFetchRequest?.loading || false;
  const initialFetchError = initialFetchRequest?.error || false;

  useEffect(() => {
    dispatch(fetchInitialMessages());
  }, [chatroomId]);

  return <div>
    {
      initialFetchLoading && <Skeleton />
    }
    {
      initialFetchError && <ErrorMessage error={initialFetchError} />
    }
    {
      !initialFetchError && !initialFetchLoading && (
        <div style={{ height: '500px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}>
            <ChatroomDetailPageHeader />
          </div>
          <ChatroomDetailPageBody />
        </div>
      )
    }
  </div>
}