import { AxiosRequestConfig } from 'axios';
import selectedBrokerRepo from '../../brokers/repos/selected-broker-repo';

export default async function addBrokerHeader(config : AxiosRequestConfig<any> ) {
    if (config.needsBrokerId) {
        config.headers = config.headers || {};
        const broker = await selectedBrokerRepo.getBroker()
        config.headers['Broker-Id'] = broker?.account?.id;
        config.headers['Broker-Paper'] = broker?.paper ? 'paper' : '';
    }

    return config;
};
