import { Button } from "antd";
import { useWatchList } from "../context/watchlist-context";
import { DeleteOutlined } from '@ant-design/icons';

export function ClearWatchlistButton() {
  const { watchlist, clear } = useWatchList();
  const { items, loading, error } = watchlist;

  if (loading || error || items.length <= 1) {
    return null;
  }

  return <Button
    loading={clear.isMutating}
    type='dashed'
    danger
    icon={<DeleteOutlined />}
    onClick={(e) => {
      e.preventDefault();
      clear.trigger();
    }}
  >
    Clear
  </Button>
}
