import { PayloadAction } from "@reduxjs/toolkit";
import { BasicChatroomMessage } from "../../../api/chat";
import { updateCursors } from "../utils/cursor.util";
import { ChatState, MessagesListItem } from "../chat-state";

export function prependMessagesInternalReducer(state: ChatState, action: PayloadAction<BasicChatroomMessage[]>) {
  if (state.selectedChatroomMessages === null) {
    return;
  }

  // save items in dictionary
  action.payload.forEach(item => {
    state.selectedChatroomMessages!.messages[item.id] = item;
  })

  // get pointers
  const newItems = action.payload.map<MessagesListItem>(item => ({
    id: item.id,
    type: 'persisted',
    cursor: { timestamp: item.created_at, uuid: item.id },
  }));

  // update list
  const list = state.selectedChatroomMessages.messagesList;
  list.unshift(...newItems);

  updateCursors(list, state);
}