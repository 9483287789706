import { Card } from "antd";
import { ContactInquiryForm } from "../../contact-inquiry/components/ContactInquiryForm";

export const ContactInquiry = () => {
  return (
    <Card title="Contact Us" style={{ minHeight: '100%' }}>
      <ContactInquiryForm />
    </Card>
  );
};
