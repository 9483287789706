import { Button, Form, InputNumber, Modal, Alert } from "antd";
import { useState } from "react";
import { InfoCircleOutlined } from '@ant-design/icons';
import api from "../../api";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

type FormDataType = { new_price: number }

export function EditPlanPriceModal({
  open,
  planId,
  currentPrice,
  onSuccess,
  onClose
} : {
  open: boolean,
  planId: number,
  currentPrice: number;
  onClose: () => void,
  onSuccess?: (planId: number, newPrice: number) => void | Promise<void>
}) {

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);

  return <Modal 
    title="Change Plan Price"
    open={open} 
    onCancel={() => {
      onClose();
    }}
    footer={null}
  >
    <Alert 
      style={{ marginTop: '2rem' }}
      type="info"
      showIcon={true}
      icon={<InfoCircleOutlined/>}
      message={
        `
          Changing price of plan will change price of plan for current subscribers as well.
          For current subscribers their price will be changed in next billing cycle.
          They will be notified via email about the price change.
        `
      }
    />
    {
      !!error && <ErrorMessage error={error} />
    }
    <Form<FormDataType>
      style={{ marginTop: '2rem' }}
      onFinish={async (data) => {
        try {

          setSubmitting(true);
          setError(null);

          await api.membershipPlans.editPrice(planId, data);
          if (onSuccess !== undefined) {
            await onSuccess(planId, data.new_price);
          }

        } catch (e) {
          setError(e);
        } finally {
          setSubmitting(false);
        }
      }}
      initialValues={{
        new_price: currentPrice,
      }}
      layout='vertical'
    >
      <Form.Item
        label="New Price"
        name="new_price"
        rules={[ { required: true, message: 'New Price Is Required' } ]}
      >
        <InputNumber 
          addonBefore="$"
          style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item>
        <Button disabled={submitting} loading={submitting} block type="primary" htmlType="submit">
          Change Price
        </Button>
      </Form.Item>
    </Form>
  </Modal>
}