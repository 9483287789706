import { TIFInput } from "./inputs/TIFInput";
import { SignalItem } from "../../api/signals";
import { QuantityInput } from "./inputs/QuantityInput";
import { SignalCopyDialog } from "./SignalCopyDialog";
import { TimeInForce } from "../../api/enums";
import { PriceInput } from "./inputs/PriceInput";
import { formatDate, formatMoney } from "../../shared/utils";
import { useSignalRowContext } from "../hooks/useSignalRowContext";
import { assetTypeLabels, sideLabels } from "../../api/enum-labels";
import { formatSignalSymbol } from "../utils/signals";
import { SignalCopyButton } from "./SignalCopyButton";
import { Empty, Space } from "antd";
import { CreatedByBotIcon } from "./CreatedByBotIcon";
import { signalsDateFormat } from "../../shared/constants";
export const SignalRow = ({ record }: { record?: SignalItem; }) => {
  if (!record) {
    return <tr className="ant-table-placeholder">
      {/* colspan must match number of rows */}
      <td className="ant-table-cell" colSpan={8}>
        <Empty description="No signals" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </td>
    </tr>
  }
  return <SignalRowInternal record={record} />
}

const SignalRowInternal = ({ record }: { record: SignalItem; }) => {


  const {
    tif, setTif,
    price, setPrice,
    quantity, setQuantity,
    copyModal,
    total,
    copy,
    overriddenSignal
  } = useSignalRowContext(record);

  return <tr>
    {
      (!!copyModal && !!copyModal.initialValues) && (
        <SignalCopyDialog
          open={copyModal.open}
          initialValues={copyModal.initialValues}
          onClose={copyModal.close}
        />
      )
    }
    <td>
      <span style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>
        { formatDate(record.created_at!, signalsDateFormat) }
      </span>
    </td>
    <td>
      { assetTypeLabels[record.asset_type] }
    </td>
    <td>
      { record.side ? sideLabels[record.side] : null }
    </td>
    <td style={{ whiteSpace: 'nowrap' }}>
      <Space>
        {
          record.created_by_bot && (
              <CreatedByBotIcon />
          )
        }
        { formatSignalSymbol(record) }
      </Space>
    </td>
    <td>
      <TIFInput
        value={tif}
        setValue={(value) => setTif(value)}
        options={Object.values(TimeInForce)} />
    </td>
    <td>
      <PriceInput
        value={price}
        setValue={(value) => setPrice(value)} />
    </td>
    <td>
      <QuantityInput
        precision={0}
        value={quantity}
        setValue={(value) => setQuantity(value || 1)} />
    </td>
    <td>
      {total !== null && <>{formatMoney(total)}</>}
    </td>
    <td>
      <SignalCopyButton overriddenSignal={overriddenSignal} />
    </td>
  </tr>
}
