import { Button, DatePicker, Form, InputNumber, message, Radio, Space } from "antd"
import { useState } from "react"
import { directionLabels, expiryPreferenceLabels, orderTypeLabels, strikePreferenceLabels, timeInForceShortLabels } from "../../api/enum-labels"
import { Direction, OptionsExpiryPreference, StrikePreference, TimeInForce } from "../../api/enums"
import { Preference } from "../../api/user-trade-preference"
import { PercentageOutlined } from '@ant-design/icons';
import api from "../../api"
import { ErrorMessage } from "../../shared/components/ErrorMessage"
import { SpendLimitInput } from "./SpendLimitInput"
import { PreferenceFormValues } from "./types"
import { dayJSDateFormat, toEditTradePreferenceRequest, toTradePreferenceFormValues } from "./utils"
import { spendLimitValidator } from "./spend-limit-validator"
import { useRefreshUser } from "../../auth/hooks/useRefreshUser"
import { VALID_ORDER_TYPES } from "../constants"
import { BrokerAccount } from "../../brokers/entities/broker-account.entity"
import { SelectBrokerFormInput } from "../../brokers/components/SelectBrokerFormInput"


export const TradePreferenceForm = ({
  tradePreference
}: {
  tradePreference: Preference | null
}) => {

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const refresh = useRefreshUser();
  const [form] = Form.useForm<PreferenceFormValues>();
  const expiryPref = Form.useWatch('expiry_preference', form);
  const prefersManualExpirationDate = expiryPref === OptionsExpiryPreference.Manual;

  const initialValues : PreferenceFormValues = toTradePreferenceFormValues(tradePreference)

  const onFinish = async (formValues: PreferenceFormValues) => {
    try {

      setError(null);
      setSubmitting(true);
      const request = toEditTradePreferenceRequest(formValues);
      await api.tradePreference.editTradePreference(request);
      messageApi.success("Updated Trade Preference");
      refresh();

    } catch (e) {

      messageApi.error("somthing went wrong.");
      setError(e);

    } finally {

      setSubmitting(false);

    }
  }

  return <>
    {contextHolder}
    {
      error && <ErrorMessage error={error} />
    }
    <Form
      // labelCol={{ span: 6 }}
      // wrapperCol={{ span: 16 }}
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      autoComplete="off"
    >

      <Form.Item<BrokerAccount>
          name="broker"
          label="Broker Account"
          
        >
        {/* @ts-ignore */}
        <SelectBrokerFormInput />
      </Form.Item>

      <Form.Item 
        label="Quantity" 
        name='spend_limit'
        required
        rules={[
          { validator: spendLimitValidator }
        ]}
      >
        {/* @ts-ignore */}
        <SpendLimitInput />
      </Form.Item>

      <Form.Item
        label="Stop Loss"
        name="stop_loss"
        rules={[
          { required: true, message: 'required' },
        ]}
      >
        <InputNumber
          precision={2}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        label="Take Profit"
        name="take_profit"
        rules={[
          { required: true, message: 'required' },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          addonAfter={<PercentageOutlined />} />
      </Form.Item>

      <Form.Item
        label="Trailing Stop"
        name="trailing_stop"
        rules={[
          { required: true, message: 'required' },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          addonAfter={<PercentageOutlined />} />
      </Form.Item>

      <Form.Item
        label="Time In Force"
        name="time_in_force"
        rules={[
          { required: true, message: 'required' },
        ]}
      >
        <Radio.Group name="tif">
          {Object.values(TimeInForce).map((tif) => (
            <Radio key={tif} value={tif}>
              {timeInForceShortLabels[tif]}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Strike"
        name="strike_preference"
      >
        <Radio.Group name="strike_preference">
          {Object.values(StrikePreference).map((pref) => (
            <Radio key={pref} value={pref}>
              {strikePreferenceLabels[pref]}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Expiry"
        name="expiry_preference"
      >
        <Radio.Group name="expiry_preference">
          {Object.values(OptionsExpiryPreference).map((pref) => (
            <Radio key={pref} value={pref}>
              {expiryPreferenceLabels[pref]}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      
      {
        prefersManualExpirationDate && (
          <Form.Item 
            label="Expiry Date" 
            name="manual_expiry_date"
            dependencies={['expiry_preference']}
            rules={[ { required: prefersManualExpirationDate } ]}
          >
            <DatePicker
              format={dayJSDateFormat}
              allowClear
            />
          </Form.Item>
        )
      }

      <Form.Item
        label="Order Type"
        name="order_type_preference"
      >
        <Radio.Group name="order_type_preference">
          {VALID_ORDER_TYPES.map((orderType) => (
            <Radio key={orderType} value={orderType}>
              {orderTypeLabels[orderType]}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Options Direction"
        name="options_direction_preference"
      >
        <Radio.Group name="options_direction_preference">
          {Object.values(Direction).map((direction) => (
            <Radio key={direction} value={direction}>
              {directionLabels[direction]}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>


      <Form.Item

      >
        <Button
          block
          htmlType="submit"
          disabled={submitting}
          loading={submitting}
          size="large"
          type="primary"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  </>
}