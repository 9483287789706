import { Button, Form, Input, message } from "antd";
import { useState } from "react";
import api from "../../api";
import { CreateSignalProviderRequest } from "../../api/signal-providers";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

export const RegisterForm = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {

  const [error, setError] = useState<any>(null);
  const [submitting, setIsSubmitting] = useState(false);

  const onFinish = async (data: CreateSignalProviderRequest) => {
    try {

      setError(null);
      setIsSubmitting(true);
      await api.signalProviders.createSignalProvider(data);
      onSuccess();

    } catch (e) {

      setError(e);

    } finally {

      setIsSubmitting(false);

    }
  };
  return <div style={{ width: 'min(600px, 100%)' }}>
    {
      error && <div>
        <ErrorMessage error={error} />
      </div>
    }
    <Form
      name="basic"
      style={{ width: '100%' }}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input />
      </Form.Item>
  
      <Form.Item
        label="Bio"
        name="bio"
        rules={[{ required: true, message: "Please enter your bio" }]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item>
        <Button 
          block 
          size="large" 
          disabled={submitting} 
          htmlType="submit" 
          type="primary"
        >
          Register
        </Button>
      </Form.Item>
    </Form>
  </div>
}