import React, { useState } from "react";
import { Button, message, Popconfirm, Typography } from "antd";
import { MembershipListItem } from "../../api/membership";
import { MembershipStatus } from "../../api/enums";
import { useCancelMembership } from "../hooks/useCancelMembership";
import { usePauseMembership } from "../hooks/usePauseMembership";
import { useResumeMembership } from "../hooks/useResumeMembership";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { formatDate } from "../../shared/utils";

const { Text } = Typography;

export const MyMembershipCardActions = ({ membership }: { membership: MembershipListItem; }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState<any>(null);

  const showPause = membership.status === MembershipStatus.Active
    && !membership.payment_collection_paused;

  const showResume = membership.status === MembershipStatus.Active
    && membership.payment_collection_paused;

  const showCancel = membership.status === MembershipStatus.Active;

  const { cancel } = useCancelMembership(membership.id);
  const { pause } = usePauseMembership(membership.id);
  const { resume } = useResumeMembership(membership.id);

  const pauseMembership = async () => {
    try {
      await pause();
      messageApi.success("Membership paused");
    } catch (e) {
      messageApi.error("Could not pause membership");
      setError(e);
    }
  };

  const resumeMembership = async () => {
    try {
      await resume();
      messageApi.success("Membership resumed");
    } catch (e) {
      messageApi.error("Could not resume membership");
      setError(e);
    }
  };

  const cancelMembership = async () => {
    try {
      await cancel();
      messageApi.success("Membership Cancelled");
    } catch (e) {
      messageApi.error("Could not cancel membership");
      setError(e);
    }
  };

  return <>
    <div style={{ display: 'flex', gap: 16 }}>
      {contextHolder}
      {showPause && <Popconfirm
        title="Pause payment collection?"
        description="Your membership would still be active but payment collection would be paused"
        onConfirm={pauseMembership}
      >
        <Button
          style={{ flex: 1 }}
          type="primary"
        >
          Pause
        </Button>
      </Popconfirm>}
      {showResume && <Popconfirm
        title="Resume payment collection?"
        onConfirm={resumeMembership}
      >
        <Button
          style={{ flex: 1 }}
          type="primary"
        >
          Resume
        </Button>
      </Popconfirm>}
      {showCancel && <Popconfirm
        title="Cancel membership?"
        description={
          <div style={{ maxWidth: 'min(100vw, 20rem)' }}>
            <Text style={{ margin: '16px 0' }}>
              Membership will be cancelled immediately.&nbsp;
              Your current cycle ends on&nbsp;
              <code>{ formatDate(membership.current_period_end) }</code>&nbsp;
              it's recommended to cancel only a few hours before current cycle ends.
            </Text>
            <ul style={{ marginTop: '16px' }}>
              <li>You will lose access to signals.</li>
              <li>You will lose access to other service provided by analyst</li>
            </ul>
          </div>
        }
        onConfirm={cancelMembership}
      >
        <Button
          danger
          style={{ flex: 1 }}
          type="default"
        >
          Cancel
        </Button>
      </Popconfirm>}
    </div>
    {error && <ErrorMessage error={error} />}
  </>;
};
