import { Card, Col, Descriptions, Divider, Row, Space, Tag, TagProps, Typography } from "antd";
import { useCandeFiMembershipsContext } from "./context";
import { fixDecimalPlaces, formatDate, formatMoneyAuto, formatTZDate } from "../shared/utils";
import { billingIntervalUnitLabels, candeFiMembershipStatusLabels } from "../api/enum-labels";
import { PlanFeatures, PlanFeaturesGrid } from "./plan-features";
import { CandeFiMembershipStatus } from "../api/enums";
const { Text, Title } = Typography;

export function CandeFiCurrentMembershipInfo() {
  const { myMembership: { loading, data } } = useCandeFiMembershipsContext();

  return <Card loading={loading}>
    {
      !loading && !data && (
        <Text>No plan is currently active</Text>
      )
    }
    {
      !loading && data && (
        <div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 24, flexWrap: 'wrap' }}>
              <Title style={{ fontWeight: 'normal', margin: 0 }} level={3}>{ data.plan.name }</Title>
              <Tag color={membershipStatusTagColorMap[data.status]}>
                { candeFiMembershipStatusLabels[data.status] }
              </Tag>
            </div>
          </div>
          <Divider />
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Title level={4} style={{ fontWeight: 'normal' }}>Pricing and renewal</Title>
              <Descriptions
                bordered
                column={{ xs: 1, sm: 1, md: 2 }}
                style={{ maxWidth: 1024 }}
              >
                {
                  data.purchase_date && (
                    <Descriptions.Item label="Coins Renewal Date">
                      { formatTZDate(data.purchase_date, 'do') } Every Month
                    </Descriptions.Item>
                  )
                }
                {
                  data.purchase_date && (
                    <Descriptions.Item label="Pricing">
                      { data.plan.is_free && <Text>Free</Text> }
                      { 
                        !data.plan.is_free && (
                          <div>
                            { formatMoneyAuto(fixDecimalPlaces(data.plan.price!)) } / &nbsp; 
                            { billingIntervalUnitLabels[data.plan.billing_interval] }
                          </div>
                        )
                      }
                    </Descriptions.Item>
                  )
                }
                {
                  !data.plan.is_free && data.current_billing_cycle_end && (
                    <Descriptions.Item label="Next Invoice Date">
                      { formatTZDate(data.current_billing_cycle_end) }
                    </Descriptions.Item>
                  )
                }
                {
                  data.last_coins_renewed_at && (
                    <Descriptions.Item label="Last Coins Renewal Date">
                      { formatTZDate(data.last_coins_renewed_at, "do MMM yyyy HH:mm") }
                    </Descriptions.Item>
                  )
                }
              </Descriptions>
            </Col>
            <Col span={24}>
              <Title level={4} style={{ fontWeight: 'normal' }}>Features</Title>
              <div style={{ maxWidth: 350 }}>
                <PlanFeaturesGrid  plan={data.plan}/>
              </div>
            </Col>
          </Row>
        </div>
      )
    }
  </Card>
}

const membershipStatusTagColorMap : Record<CandeFiMembershipStatus, TagProps['color']> = {
  'active': 'green',
  'past_due': 'red',
}
