import { useEffect, useState } from "react";
import { AutotradeBotResponse } from "../../../api/autotrade-bots";
import { Button, Divider, notification, Popover, Space, Typography } from "antd";
import { useCanAutotrade } from "../../../candefi-memberships/hooks/useCanAutotrade";
import { CreateBotModal } from "./create-bot-modal";
import { AutotradePreferenceFormModal } from "../preference/form-modal";
import { PremiumButton } from "../../../shared/components/PremiumButton";
import { SetPreferenceAlert } from "../preference/set-preference-alert";
import { CrownFilled, SettingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { BotsListItem } from "./bots-list-item";
import { isBotActive, isBotArchived } from "./utils";
import { useMaxAutotradeBots } from "../../../candefi-memberships/hooks/useMaxAutotradeBots";
import { Link } from "react-router-dom";

export type BotTabType = 'active' | 'archived';

export function BotsListSection({ bots, mutate }: { bots: AutotradeBotResponse[], mutate: () => void }) {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const canAutotrade = useCanAutotrade();
  const [tab, setTab] = useState<BotTabType>('active');
  const [createOpen, setCreateOpen] = useState(false);
  const maxBots = useMaxAutotradeBots();

  const activeBots = bots.filter(isBotActive);
  const archivedBots = bots.filter(isBotArchived)

  const filteredBots = tab === 'archived'
    ? archivedBots
    : activeBots;

  const totalActiveBots = activeBots.length;

  const onPreferenceUpdated = () => {
    notificationApi.success({ message: 'Preference updated' });
    mutate();
    setSettingsOpen(false);
  }

  const infoPopoverContent = <div style={{ maxWidth: 300 }}>
    <Typography.Title level={4}>
      {totalActiveBots} / {maxBots} bot active
    </Typography.Title>
    <Typography.Text style={{ display: 'inline-block', marginBottom: '1rem' }}>
      Your plan allows upto {maxBots} active bots.
      Upgrade to increase your limit.
    </Typography.Text>
    <div>
      <Link to='/app/account/candefi-memberships'>
        <PremiumButton
          block
          size='large'
          type="default"
          icon={<CrownFilled />}
        >
          Upgrade
        </PremiumButton>
      </Link>
    </div>
  </div>


  return <div>
    {contextHolder}
    <CreateBotModal
      open={createOpen}
      onClose={() => setCreateOpen(false)}
      onSuccess={mutate}
    />
    <AutotradePreferenceFormModal
      onSuccess={onPreferenceUpdated}
      isOpen={settingsOpen}
      onClose={() => setSettingsOpen(false)}
    />

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 16 }}>
      <div>
        <div style={{ overflow: "auto", display: "flex", flexWrap: 'nowrap', gap: 8 }}>
          <Button
            type={tab === 'active' ? "primary" : "default"}
            onClick={() => setTab('active')}
          >
            Active
          </Button>
          <Button
            type={tab === 'archived' ? "primary" : "default"}
            onClick={() => setTab('archived')}
          >
            Archived
          </Button>
        </div>
      </div>
      <Space wrap>
        {
          canAutotrade && (
            <Popover content={infoPopoverContent}>
              <Button>
                Active Bots: {totalActiveBots} / {maxBots}
                <InfoCircleOutlined />
              </Button>
            </Popover>
          )
        }
        {
          canAutotrade ? (
            <Button
              type="primary" onClick={() => setCreateOpen(true)}>
              Create
            </Button>
          ) : (
            <PremiumButton
              icon={<CrownFilled />}
              type="primary" onClick={() => setCreateOpen(true)}>
              Create
            </PremiumButton>
          )
        }
        <Button onClick={() => setSettingsOpen(true)}>
          <SettingOutlined />
        </Button>
      </Space>
    </div>
    <Divider />
    <SetPreferenceAlert />
    <div>
      {
        filteredBots.map((bot) => (
          <BotsListItem
            totalActiveBots={totalActiveBots}
            onUpdate={mutate}
            bot={bot}
            key={bot.id}
          />
        ))
      }
    </div>
  </div>
}