import { Button, Result } from 'antd';
import setupWizard from '../../shared/images/setup_wizard.svg';

export const RegisterIntro = ({
  onFinish
}: {
  onFinish: () => void,
}) => {
  return <Result
    style={{ maxWidth: 600, margin: '0 auto' }}
    title="Create Your Signal Provider Profile"
    icon={<img style={{ maxWidth: 300 }} src={setupWizard} />}
    subTitle="
    Setup your Analyst profile and create subscription plans. 
    Become an independent business owner supported by our cutting-edge technology and innovative.
    "
    extra={
      <Button onClick={onFinish} size="large" type="primary">
        Get Started
      </Button>
    }
  />
}