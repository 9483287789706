import api from "../../../api";
import { SortOrder } from "../../../api/enums";
import { createAppAsyncThunk } from "../../../redux/hooks";
import {
  appendMessages,
  initialMessagesError,
  initialMessagesLoading,
  initialMessagesSuccess,
  resetUnreadMessageCount,
  setNextHasMore,
  setPrevHasMore
} from "../chat.slice";
import { PAGE_SIZE } from "../constants";
import mutex from "./mutex";

export const fetchInitialMessages = createAppAsyncThunk(
  'chat/fetchInitialMessages',
  async function (_, { dispatch, getState }) {
    // Must run exclusively
    // See note on mutex variable for reason.
    mutex.runExclusive(async () => {

      const roomId = getState().chat.selectedChatroomId;
      if (!roomId) {
        return;
      }

      dispatch(initialMessagesLoading());

      try {

        const messages = await api.chat.listMessages(roomId, {
          cursor_timestamp: null,
          cursor_uuid: null,
          limit: PAGE_SIZE,
          order: SortOrder.Desc
        });

        const reachedEnd = messages.length < PAGE_SIZE;
        if (reachedEnd) {
          dispatch(setPrevHasMore(false));
        }
        dispatch(setNextHasMore(false));

        dispatch(appendMessages(messages.reverse()))
        dispatch(initialMessagesSuccess());
        dispatch(resetUnreadMessageCount({ chatroomId: roomId || '' }))

      } catch (e) {

        dispatch(initialMessagesError(e));

      }
    });
  }
);
