import { Card, Space, Typography } from "antd";
import { RechargeAccount } from "../../recharge/components/RechargeAccount";
import { useCanBuyExtraCoins } from "../../candefi-memberships/hooks/useCanBuyExtraCoins";
import { CrownFilled } from '@ant-design/icons';
import { PremiumCard } from "../../shared/components/PremiumCard";
import { WhiteButton } from "../../shared/components/WhiteButton";
import { Link } from "react-router-dom";
import { PageContainer } from "../../shared/components/PageContainer";
const { Paragraph } = Typography;

export const BuyCoins = () => {
  return (
    <PageContainer>
      <Card title="Buy Extra Coins" style={{ minHeight: '100%'}}>
        <Space size='large' direction="vertical">
          <Paragraph type="secondary" style={{ margin: 0 }}>
            {/* To place trade recharge your candefi account. 
            Each trade you copy or place through candefi charges a minimum transaction fee. */}
            Recharge your CandeFi Coin balance.
            CandeFi charge 1 coin as platform fee for trades you copy or place to Robinhood and Webull. Free for Signal Providers when a trade is auto shared.
          </Paragraph>
          <ExtraCoinsBanner />
          <RechargeAccount />
        </Space>
      </Card>
    </PageContainer>
  );
};

function ExtraCoinsBanner() {
  const canBuyExtraCoins = useCanBuyExtraCoins();
  if (canBuyExtraCoins) {
    return null;
  }
  return <PremiumCard>
    <div style={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      alignItems: 'center', 
      justifyContent: 'space-between' 
    }}>
      <div style={{ fontSize: '1rem' }}>
        Your plan does not allow buying extra coins.  
      </div>
      <Link to='/app/account/candefi-memberships'>
        <WhiteButton size="large">
          <CrownFilled /> Upgrade
        </WhiteButton>
      </Link>
    </div>
  </PremiumCard>
}