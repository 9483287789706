import { Button, Card, Space, Typography } from "antd";
import { useCandeFiMembershipsContext } from "./context";
import { CandeFiMembershipPlanBasic } from "../api/candefi-memberships";
import { fixDecimalPlaces, formatMoneyAuto } from "../shared/utils";
import { billingIntervalUnitLabels } from "../api/enum-labels";
import { FeatureRow } from "./plan-feature-row";
import { PlanFeatures } from "./plan-features";

export function PlanTile({ plan } : { plan: CandeFiMembershipPlanBasic }) {
  const { myMembership, onPurchaseClick, checking, checkingPlanId } = useCandeFiMembershipsContext();
  const isCurrent = myMembership?.data?.plan?.id === plan.id;
  const buttonLoading = checking  && checkingPlanId === plan.id;

  const onClick : React.MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await onPurchaseClick(plan);
    } catch(e) {}
  }

  return <Card>
    <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
      { plan.name }
    </Typography.Title>
    <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginBottom: 16 }}>
      <Typography.Title style={{ margin: 0 }}>
        { formatMoneyAuto(fixDecimalPlaces(plan.price || 0, 2)) }
      </Typography.Title>
      <Typography.Text type="secondary">
        / { billingIntervalUnitLabels[plan.billing_interval] }
      </Typography.Text>
    </div>
    {
      !isCurrent && (
        <Button onClick={onClick} block size="large" type="primary"  loading={buttonLoading} disabled={buttonLoading}>
          Activate
        </Button>
      )
    }
    {
      isCurrent && (
        <Button  block size="large" type="default" disabled>
          Current
        </Button>
      ) 
    }
    <div style={{ margin: '1rem 0' }}>
      <PlanFeatures plan={plan} />
    </div>
  </Card>
}

