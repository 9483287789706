import { CentEvent } from "../../cent/types"

// enum containing event types
export enum AutotradeEventTypes {
  AutotradeAlertCreated = 'autotrade_alert_created',
  AutotradeAlertUpdated = 'autotrade_alert_updated'
}

// payload for alert related events
export type AutotradeAlertEventPayload = {
  alert_id: number
}

// cent event for alert created notification
export type AutotradeAlertCreatedEvent = CentEvent<AutotradeEventTypes.AutotradeAlertCreated, AutotradeAlertEventPayload>;

// cent event for alert updated notification
export type AutotradeAlertUpdatedEvent = CentEvent<AutotradeEventTypes.AutotradeAlertUpdated, AutotradeAlertEventPayload>;

// helper that checks if event is of type AutotradeAlertCreatedEvent
export const isAutotradeAlertCreatedEvent = (event: any) : event is AutotradeAlertCreatedEvent => {
  return !!event
    && (!!event.event)
    && typeof event.event === 'string'
    && event.event === AutotradeEventTypes.AutotradeAlertCreated
    && event.payload.alert_id !== null;
}

// helper that checks if event is of type AutotradeAlertUpdatedEvent
export const isAutotradeAlertUpdatedEvent = (event: any) : event is AutotradeAlertCreatedEvent => {
  return !!event
    && (!!event.event)
    && typeof event.event === 'string'
    && event.event === AutotradeEventTypes.AutotradeAlertUpdated
    && event.payload.alert_id !== null;
}