import { Descriptions, Divider, Space, Table, Typography } from "antd";
import { BotAlertWithOrders } from "../../../api/autotrade-alerts";
import { DBOrder } from "../../../api/orders";
import Column from "antd/es/table/Column";
import { assetTypeLabels, orderTypeLabels, sideLabels, timeInForceShortLabels } from "../../../api/enum-labels";
import * as orderUtils from '../../../orders/utils/orders';
import { formatDate, formatMoney } from "../../../shared/utils";
import { Alert } from "antd";
import { wasAlertExecuted } from "../../utils/alert-utils";
import { useEmotionCss } from "@ant-design/use-emotion-css";

export function AlertInfo({ alert } : { alert: BotAlertWithOrders }) {
  const autotraded = wasAlertExecuted(alert);

  const dotClass = useEmotionCss(({ token }) => ({
    backgroundColor: autotraded ? token.colorSuccess : token.colorError,
    width: 8,
    height: 8,
    borderRadius: '100%',
    display: 'inline-block'
  }));

  const dateFormat = "do MMM yyyy HH:mm"

  return <div style={{ overflow: 'auto' }}>
    <Typography.Title level={4}>Alert Info</Typography.Title>
    <Divider />
    <Descriptions size="small" column={{ xs: 2, sm: 2, lg: 2, xl: 2, xxl: 2 }}>
      <Descriptions.Item label="Autotraded">
        <Space size='small'>
          <span className={dotClass}></span>{ autotraded ? 'Yes' : 'No' } 
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="Created At">
        { formatDate(alert.created_at!, dateFormat) }
      </Descriptions.Item>
      {
        alert.calculated_at && (
          <Descriptions.Item label="Calculated At">
            { formatDate(alert.calculated_at, dateFormat) }
          </Descriptions.Item>
        )
      }
      {
        alert.executed_at && (
          <Descriptions.Item label="Executed">
            { formatDate(alert.executed_at, dateFormat) }
          </Descriptions.Item>
        )
      }
    </Descriptions>
    <Divider />
    {
      (alert.errors) && (alert.errors.length > 0) && <>
        {/* <Typography.Title level={5}>Errors</Typography.Title> */}
        <Typography.Title level={5}>Reason</Typography.Title>
        <Alert
          type="error"
          message={<ul style={{ margin: 0 }}>
            {
              alert.errors.map((err, i) => (
                <li key={i}>
                  { err.error }
                </li>
              ))
            }
          </ul>}
        />
      </>
    }
    {
      alert.orders && alert.orders.length > 0 && <>
        <Typography.Title level={5}>Orders Placed</Typography.Title>
        <AlertOrdersTable orders={alert.orders} />
      </>
    }
  </div>
}

export function AlertOrdersTable({ orders } : { orders: DBOrder[] }) {
  return <Table
    dataSource={orders}
    rowKey={(record) => record.broker_order_id}
    pagination={false}
    size="small"
  >
        <Column 
          title="Asset" 
          dataIndex="asset_type" 
          key="asset_type"
          render={(_, order : DBOrder) => assetTypeLabels[order.asset_type]}
        />
        <Column 
          title="Side" 
          dataIndex="side" 
          key="side" 
          render={(_, order: DBOrder) => order.side !== null ? sideLabels[order.side] : ''} 
        />
        <Column 
          title="Symbol" 
          dataIndex="stock" 
          key="stock"
          render={(_, record: DBOrder) => {
            return (
              <span style={{ whiteSpace: 'nowrap' }}>
                {orderUtils.getSymbol(record)}
              </span>
            );
          }}
        />
        <Column 
          title="Type" 
          dataIndex="order_type" 
          key="order_type" 
          render={(_, order: DBOrder) => !!order.order_type ? orderTypeLabels[order.order_type] : ''}  
        />
        <Column 
          title="Qty" 
          dataIndex="quantity" 
          key="quantity" 
          render={(_, order: DBOrder) => orderUtils.getQuantity(order)}    
        />
        <Column 
          title="TIF" 
          dataIndex="time_in_force" 
          key="time_in_force" 
          render={(_, order : DBOrder) => !!order.time_in_force ? timeInForceShortLabels[order.time_in_force] : ''}  
        />
        <Column 
          title="Limit Price" 
          dataIndex="limit_price" 
          key="limit_price" 
          render={(_, order : DBOrder) => !!order.limit_price ? formatMoney(order.limit_price) : ''}    
        />
        <Column 
          title="Stop Price" 
          dataIndex="stop_price" 
          key="stop_price" 
          render={(_, order : DBOrder) => !!order.stop_price ? formatMoney(order.stop_price) : ''}      
        />
  </Table>
}

// bot name
// Options / Stocks
// BUY
// AAPL C strike date
// limit price
// quantity
// more info, place order

// more info
// - timestamps
// - autotraded or not
// - error message
// - calcluated params
// - orders (details)
