import { OptionFormInitialValues } from "../hooks/useOptionsForm";
import { StockFormInitialValues } from "../hooks/useStocksForm";

export type PlaceOrderFormInitialValues = OptionFormInitialValues | StockFormInitialValues;

export function isStocks (data: PlaceOrderFormInitialValues) : data is StockFormInitialValues {
  return (data as OptionFormInitialValues).direction === undefined;
}

export function isOptions (data: PlaceOrderFormInitialValues) : data is OptionFormInitialValues {
  return (data as OptionFormInitialValues).direction !== undefined;
}