import { Card, Space, Typography } from "antd";
import api from "../../api";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import SignalProviderPlans from "../components/SignalProviderPlans";
import { EmailVerifiedMessage } from "../../auth/components/EmailVerifiedMessage";
const { Title, Paragraph, Text } = Typography;

export const SignalProviderDetails = () => {
  const { username } = useParams();


  const { data : signalProvider, isLoading } = useSWR(`/api/signal-providers/${username}`, () =>
    api.signalProviders.findByUsername(username || '')
  );

  return (
    <Space 
      direction="vertical" 
      style={{ width: '100%' }} 
      size="small"
    >
      <EmailVerifiedMessage />
      <Card loading={isLoading}>
        <Space size="small" direction="vertical">
          <Title level={3} style={{ marginBottom: 0 }}>{signalProvider?.name}</Title>
          <Text type="secondary">@{ signalProvider?.username }</Text>
          <Paragraph type="secondary" style={{ marginBottom: 0 }}>{signalProvider?.bio}</Paragraph>
        </Space>
      </Card>
      <Card title="Plans" loading={isLoading}>
        {
          signalProvider?.membership_plans && (
            <SignalProviderPlans
              signalProvider={signalProvider}
            />
          )
        }
      </Card>
    </Space>
  );
};
