import { Col, Row } from "antd";
import { CandeFiCurrentMembershipInfo } from "../../candefi-memberships/candefi-current-membership-info";
import { CandeFiMembershipsPlans } from "../../candefi-memberships/candefi-memberships-plans";
import { CandeFiMembershipsContextProvider } from "../../candefi-memberships/context";
import { MembershipTabs } from "../components/MembershipTabs";

export function CandeFiMembership() {
  return <CandeFiMembershipsContextProvider>
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <MembershipTabs />
      </Col>
      <Col span={24}>
        <CandeFiCurrentMembershipInfo />
      </Col>
      <Col span={24}>
        <CandeFiMembershipsPlans />
      </Col>
    </Row>
  </CandeFiMembershipsContextProvider>
}

