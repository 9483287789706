import { ContactReason } from "./enums";
import axios from "./axios";
import { GenericStatusResponse } from "./types";

export interface CreateContactInquiryRequest {
  name?: string | null
  email?: string | null
  reason: ContactReason,
}

const contactInquiry = {
  async create(request: CreateContactInquiryRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/contact-inquiries', request);
    return data;
  }
}

export default contactInquiry;