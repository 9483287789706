import { Button, Card, Col, Result, Row, Space, Typography } from "antd";
import wizardImage from '../../shared/images/setup_wizard.svg';
import {
  LinkOutlined
} from '@ant-design/icons';
import { ConnectBrokerModal } from "../components/ConnectBrokerModal";
import { useState } from "react";
import { BrokerAccountCard } from "../components/BrokerAccountCard";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { PageContainer } from "../../shared/components/PageContainer";

export const BrokerConnections = () => {
  const { user } = useAuthUser();

  const brokerAccounts = user?.broker_accounts || [];
  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const openConnectModal = () => setConnectModalOpen(true);

  return (
    <PageContainer>
      <Card
        style={{ minHeight: '100%' }}
        title="Broker Connections"
        bordered={false}>
        <ConnectBrokerModal 
          open={connectModalOpen}
          setOpen={setConnectModalOpen}
        />
        {
          brokerAccounts.length <= 0 && <Result
            style={{ maxWidth: 600, margin: '0 auto' }}
            icon={<img style={{ maxWidth: 300 }} src={wizardImage}/>}
            title="Connect your broker"
            // subTitle="To place trade connect a broker account. You can add as many broker accounts as you want"
            subTitle="Unlock trading privileges by connecting your broker to access the Auto & Copy dashboards. Multiple broker accounts can be connected."
            extra={
              <Button onClick={openConnectModal} type="primary" size="large" icon={<LinkOutlined/>}>
                Connect Broker
              </Button>
            }
          />
        }

        {
          brokerAccounts.length > 0 && <Space 
            direction="vertical" 
            style={{ width: '100%' }}
            size='large'
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                onClick={openConnectModal} 
                type="primary" 
                icon={<LinkOutlined/>}>
                Connect Broker
              </Button>
            </div>

            <Row gutter={[16, 16]}>
              {
                brokerAccounts.map(account => (
                  <Col span={24} xl={{ span: 12 }} xxl={{ span: 8 }}>
                    <BrokerAccountCard account={account}/>
                  </Col>
                ))
              }
            </Row>
          </Space>
        }

        <div style={{ marginTop: '24px', textAlign: 'center' }}>
          <Typography.Text type="secondary">
            CandeFi operates independently with no formal affiliations or endorsements from the mentioned companies. The trade names are cited solely to demonstrate CandeFi’s compatibility.
          </Typography.Text>
        </div>

      </Card>
    </PageContainer>
  );
};

