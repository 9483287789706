import { BrokerAccount } from "../entities/broker-account.entity";

export type SelectedBroker = {
  account: BrokerAccount;
  paper: boolean;
}

class SelectedBrokerRepo {

  static LOCAL_STORAGE_KEY = "cl.selected-broker";

  async getBroker() : Promise<SelectedBroker|null> {
    const broker = localStorage.getItem(SelectedBrokerRepo.LOCAL_STORAGE_KEY);
    if (broker == null) {
      return broker;
    }
    const parsed = JSON.parse(broker);
    if (!isSelectedBrokerInfo(parsed)) {
      return null;
    }
    return parsed;
  }

  async setBroker(info: SelectedBroker) {
    localStorage.setItem(
      SelectedBrokerRepo.LOCAL_STORAGE_KEY, 
      JSON.stringify(info)
    );
  }

  async removeBroker() {
    localStorage.removeItem(SelectedBrokerRepo.LOCAL_STORAGE_KEY);  
  }
}

function isSelectedBrokerInfo(info: any) : info is SelectedBroker {
  if (!info || 
      !info.account || 
      !info.account.id || 
      !info.account.type
  ) {
    return false;
  }
  return true;
}

const instance = new SelectedBrokerRepo();

export default instance;