import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ScrollableContainer from "./scrollable-container";
import { fetchPrevMessages } from "../redux/thunks/fetch-prev-messages";
import { MessageItem } from "./message-item/message-item";
import { currentChatroomMessagesListSelector, prevCursorSelector } from "../redux/chat.selectors";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const MessagesList = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector(currentChatroomMessagesListSelector);
  const prev = useAppSelector(prevCursorSelector);
  const { user } = useAuthUser();

  if (!user) {
    return null;
  }

  const onTopReached = () => {
    if (!prev || !prev.hasMore || prev.loading) {
      return;
    }
    dispatch(fetchPrevMessages());
  };

  return <ScrollableContainer
    style={{
      flex: 1,
      height: '100%',
      overflowY: 'scroll',
      padding: 8,
      gap: 8,
    }}
    onTopReached={onTopReached}
    dataLength={messages?.length || 0}
  >
    <div>
      {messages && messages.map(message => (
        <MessageItem
          key={message.id}
          item={message}
          currentUser={user} />
      ))}
    </div>
  </ScrollableContainer>;
};
