import { Radio } from "antd";
import { sideLabels } from "../../../api/enum-labels";
import { Side } from "../../../api/enums";
import { useRadioGroupStyle } from "../../../shared/styles/useRadioGroup";

export const SideReadonlyInput = ({
  options,
  value,
} : {
  value: Side | null,
  options : Side[],
}) => {

  const selectOptions = options.map((option) => ({
    label: sideLabels[option],
    value: option,
  }))

  const radioClass = useRadioGroupStyle();

  return <Radio.Group
    disabled
    className={radioClass}
    options={selectOptions}
    optionType="button"
    buttonStyle="solid"
    size="large"
    value={value}
  />;
}