import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Button, ButtonProps } from "antd";
import { forwardRef } from "react";

export const WhiteButton = forwardRef<HTMLElement, ButtonProps>((props, ref) => {

  const { type, children, className, ...rest } = props;

  const premiumButtonClassName = useEmotionCss(() => ({
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: '#ffffff !important',
      color: 'black !important',
      borderColor: 'black !important',
    }
  }))

  const newClassName = [premiumButtonClassName, className].filter(c => !!c).join(' ');

  return <Button ref={ref} {...rest} className={newClassName}>
    {children }
  </Button>
})

