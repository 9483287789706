import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Button, Space } from "antd";
import { UserAddOutlined, SettingOutlined } from '@ant-design/icons';
import { useAppDispatch } from "../../redux/hooks";
import { openCreateDMPopup, openDmPrefsPopup } from "../redux/chat.slice";

export function MyChatroomsPageHeader() {

  const dispatch = useAppDispatch();

  const headerClass = useEmotionCss(({ theme, token }) => ({
    backgroundColor: token.colorBgElevated,
    padding: 8,
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    gap: 8,
    minHeight: 56,
  }));

  const onDMClick = () => {
    dispatch(openCreateDMPopup());
  }

  const onSettingsClick = () => {
    dispatch(openDmPrefsPopup());
  }

  return <div className={headerClass}>
    <div style={{ flex: 1 }}>
      <div>
        CandeFi Chat
      </div>
    </div>
    <div style={{ justifySelf: 'flex-end' }}>
      <Space direction="horizontal">
        <Button
          onClick={onDMClick}
          size="large"
          type="text"
          title="DM User"
          icon={<UserAddOutlined />}
        />
        <Button
          onClick={onSettingsClick}
          size="large"
          type="text"
          title="DM Perfs"
          icon={<SettingOutlined />}
        />
      </Space>
    </div>
  </div>
}