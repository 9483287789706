import { createContext, useCallback, useContext, useEffect, useState } from "react"
import api from "../../api";
import { GenericStatusResponse } from "../../api/types";
import { MFAImage, SecurityQuestion } from "../../api/webull-connect";
import { useRefreshUser } from "../../auth/hooks/useRefreshUser";

export enum WebullConnectStep {
  Email = 'email',
  MFAImage = 'check_mfa_image',
  SecurityQuestion = 'security_question',
  PasswordAndPin = 'password_and_pin',
  Success = 'success'
}

export interface WebullConnectContextState {
  currentStep: WebullConnectStep
  sessionId: number | null,
  newDevice: boolean | null,
  email: string | null,
  mfaImage: MFAImage | null,
  securityQuestion: SecurityQuestion | null,
  changeEmail: (email : string) => Promise<void>,
  checkMFAImage: (xPos : number) => Promise<boolean>,
  fetchAlternativeSecurityQuestion: () => Promise<void>,
  checkSecurityQuestionAnswer: (answer: string) => Promise<boolean>,
  login: (tradingPin: string, password: string) => Promise<GenericStatusResponse>,
}

export const WebullConnectContext = createContext<WebullConnectContextState>({
  currentStep: WebullConnectStep.Email,
  sessionId: null,
  newDevice: null,
  email: null,
  mfaImage: null,
  securityQuestion: null,
  changeEmail: async () => {},
  checkMFAImage: async () => { return false },
  fetchAlternativeSecurityQuestion: async () => {},
  checkSecurityQuestionAnswer: async (answer: string) => { return false },
  login: async (tradingPin: string, password: string) => ({ success: false, message: 'not implemented' }),
});

export const WebullConnectContextProvider = ({ children } : {
  children: React.ReactNode
}) => {

  const [step, setStep] = useState<WebullConnectStep>(WebullConnectStep.Email);
  const [sessionId, setSessionId] = useState<number | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [mfaImage, setMFAImage] = useState<MFAImage | null>(null);
  const [securityQuestion, setSecurityQuestion] = useState<SecurityQuestion | null>(null);
  const [securityQuestionAnswer, setSecurityQuestionAnswer] = useState<string | null>(null);
  const [xPos, setXPos] = useState<number | null>(null);
  const [newDevice, setNewDevice] = useState<boolean | null>(null);
  const refetchUser = useRefreshUser();


  // fetch mfa jigsaw puzzle image
  const fetchImage = useCallback(async () => {
    if (sessionId === null || email === null) {
      return;
    }
    const image = await api.webullConnect.getMFAImage(sessionId, { email });
    setMFAImage(image);
  }, [sessionId, email]);

  // check mfa jigsaw puzzle image solution
  const checkMFAImage = async (xPos : number) => {
    if (sessionId === null || email === null) {
      return false;
    }
    const resopnse = await api.webullConnect.checkMFAImage(sessionId, {
      email,
      xPos
    });
    if (resopnse.status === false) {
      if (resopnse.image !== null) {
        setMFAImage(resopnse.image)
      }
      return false;
    }
    setXPos(xPos);
    setStep(WebullConnectStep.SecurityQuestion);
    return true;
  }

  // fetch mfa security question
  const fetchSecurityQuestion = useCallback(async (alternate : boolean = false) => {
    if (sessionId === null || email === null) {
      return;
    }
    const question = await api.webullConnect.getSecurityQuestion(sessionId, { 
      email,
      alternate: alternate
    });
    setSecurityQuestion(question);
  }, [sessionId, email]);

  // fetch alternate mfa security question
  const fetchAlternativeSecurityQuestion = useCallback(() => {
    return fetchSecurityQuestion(true);
  }, [fetchSecurityQuestion]);

  // check security question answer 
  const checkSecurityQuestionAnswer = useCallback(async (answer: string) => {
    if (sessionId === null || email === null || securityQuestion === null) {
      return false;
    }
    const response = await api.webullConnect.checkSecurityAnswer(sessionId, {
      email,
      question_id: securityQuestion!.questionId,
      answer: answer,
    });
    if (response.checkResult) {
      setSecurityQuestionAnswer(answer);
      setStep(WebullConnectStep.PasswordAndPin);
    }
    return response.checkResult; 
  }, [sessionId, email, securityQuestion]);

  // set email for the session
  const changeEmail = async (email : string) => {
    const { id, new_device } = await api.webullConnect.creteSession({ email });
    setEmail(email);
    setSessionId(id);
    setNewDevice(new_device)
    const nextStep = new_device  ? WebullConnectStep.MFAImage : WebullConnectStep.PasswordAndPin
    setStep(nextStep)
  }

  // login
  const login = async (password: string, tradingPin: string) : Promise<GenericStatusResponse> => {
    // session id and email are required
    if (sessionId === null || 
      email === null
    ) {
      return { success: false, message: 'Please complete all the steps' };
    }
    
    // login for new device
    if (newDevice) {
      if (securityQuestion === null 
          || securityQuestionAnswer === null 
          || xPos === null
      ) {
        return { success: false, message: 'Please complete all the steps' };
      }
      let response = await api.webullConnect.login(sessionId, {
        email,
        password,
        security_question_answer: securityQuestionAnswer,
        security_question_id: securityQuestion.questionId,
        trading_pin: tradingPin,
        x_pos: xPos.toFixed(0)
      });
      if (response.success) {
        refetchUser();
        setStep(WebullConnectStep.Success);
      }
      return response;
    }

    // login for existing devices
    let response = await api.webullConnect.loginShort(sessionId, {
      email: email,
      password: password,
      trading_pin: tradingPin
    })
    if (response.success) {
      refetchUser();
      setStep(WebullConnectStep.Success);
    }
    return response;
  }

  // perform actions on when current step changes
  useEffect(() => {
    if (step === WebullConnectStep.MFAImage) {
      fetchImage();
    }

    if (step === WebullConnectStep.SecurityQuestion) {
      fetchSecurityQuestion();
    }
  }, [step, fetchImage, fetchSecurityQuestion]);

  const value = {
    currentStep: step,
    sessionId,
    email,
    securityQuestion,
    mfaImage,
    changeEmail,
    checkMFAImage,
    fetchAlternativeSecurityQuestion,
    checkSecurityQuestionAnswer,
    login,
    newDevice
  }

  return <WebullConnectContext.Provider value={value}>
    {children}
  </WebullConnectContext.Provider>
}

export const useWebullConnectContext = () => {
  return useContext(WebullConnectContext);
}