import {
  AuthStore,
  LocalStoragePersistance
} from 'reactive-auth-store';
import { CurrentUserInfo, LoginResponse } from '../../api/auth';
import isEqual from "react-fast-compare";


// Define type for tokens
export type AuthTokens = LoginResponse
export type AuthUser = CurrentUserInfo


let store: AuthStore<AuthUser, AuthTokens> | null = null;

const AUTH_STORAGE_KEY = '__auth__'

// function to lazily create store when required
export default function getStore() {
  if (store === null) {
      store = new AuthStore(
          // create persistor to save state
          new LocalStoragePersistance(AUTH_STORAGE_KEY),
          // a function to compares user equality
          (userA, userB) => isEqual(userA, userB)
      )
  }
  store.setMaxListeners(1000);
  return store;
}