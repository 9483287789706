import { TimeInForce } from "../api/enums"
import { CentEvent } from "../cent/types"
import { NEW_SIGNAL_EVENT_NAME } from "./constants"

export type NewSignalEventPayload = {
  signal_id: number
  provider_ids: number[]
}

export type NewSignalEvent = CentEvent<typeof NEW_SIGNAL_EVENT_NAME, NewSignalEventPayload>

export const isNewSignalEvent = (event : any) : event is NewSignalEvent => {
  event = event || {}
  if (!event.event || typeof event.event !== 'string' || event.event !== NEW_SIGNAL_EVENT_NAME) {
    return false;
  }
  return true;
}

export interface SignalOverrides {
  price: number | null;
  quantity: number;
  tif: TimeInForce;
}
