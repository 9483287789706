import { Descriptions, Modal, Typography } from "antd";
import { orderTypeLabels, sideLabels, timeInForceShortLabels, trailTypeLabels } from "../../api/enum-labels";
import { OrderType, Side, TimeInForce, TrailType } from "../../api/enums";
import { formatMoney, formatMoneyAuto } from "../../shared/utils";
import { useStocksEstimate } from "../hooks/useStocksEstimate";

const { Title } = Typography;

export const StocksOrderConfirmationDialog = ({
  open,
  setOpen,
  onOk,
  stock,
  side,
  limitPrice,
  quantity,
  stopPrice,
  timeInForce,
  orderType,
  trailType,
  trailValue,
  outSideRegularTradingHours,
} : {
  open: boolean,
  setOpen: (open: boolean) => void,
  onOk: () => void,
  stock: string | null,
  side: Side | null,
  limitPrice: number | null,
  quantity: number | null,
  stopPrice: number | null,
  timeInForce: TimeInForce | null,
  orderType: OrderType | null,
  trailType: TrailType | null,
  trailValue: number | null,
  outSideRegularTradingHours: boolean,
}) => {

  const estimate = useStocksEstimate({
    quantity,
    limitPrice
  })

  return <Modal 
    title="Place Stocks Order?" 
    open={open} 
    onOk={onOk}
    onCancel={() => setOpen(false)}
    okText="Yes"
    cancelText="No"
  >
    {
      estimate && <div style={{ marginTop: 16, marginBottom: 16 }}>
        <Title level={5}>Estimate</Title>
        <span style={{ fontSize: '1.5rem' }}>
          { formatMoney(estimate) }
        </span>
      </div>
    }
    <Descriptions title="Details" size="small" column={{ xs: 2, sm: 2, lg: 2, xl: 2, xxl: 2 }}>
      <Descriptions.Item label="Stock">
        { stock }
      </Descriptions.Item>
      {
        !!side && <Descriptions.Item label="Side">
          { sideLabels[side] }
        </Descriptions.Item>
      }
      {
        !!orderType && <Descriptions.Item label="Order Type">
          { orderTypeLabels[orderType] }
        </Descriptions.Item>
      }
      {
        !!limitPrice && <Descriptions.Item label="Limit Price">
          { formatMoneyAuto(limitPrice) }
        </Descriptions.Item>
      }
      {
        !!stopPrice && <Descriptions.Item label="Stop Price">
          { formatMoneyAuto(stopPrice) }
        </Descriptions.Item>
      }
      {
        !!quantity && <Descriptions.Item label="Quantity">
          { quantity }
        </Descriptions.Item>
      }
      {
        !!timeInForce && <Descriptions.Item label="Time In Force">
          { timeInForceShortLabels[timeInForce] }
        </Descriptions.Item>
      }
      {
        !!trailType && <Descriptions.Item label="Trail Type">
          { trailTypeLabels[trailType] }
        </Descriptions.Item>
      }
      {
        !!trailValue && <Descriptions.Item label="Trail Value">
          { formatMoneyAuto(trailValue) }
        </Descriptions.Item>
      }
       <Descriptions.Item label="Outside Regular Hours">
        { outSideRegularTradingHours ? 'Yes': 'No' }
      </Descriptions.Item>
    </Descriptions>

  </Modal>;
}