import { Button, Form, Input, Result, Typography } from "antd";
import { CreateForExistingUserRequest } from "../../api/analyst-signup-request";
import api from "../../api";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { useState } from "react";
import { Link } from "react-router-dom";

const { Text } = Typography;

export const ConvertToAnalystForm = () => {

  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);


  const submitSignupRequest = async (data: CreateForExistingUserRequest) => {
    try {

      data.social_link = data.social_link || null;
      setSubmitting(true);
      await api.analystSignupRequest.createForExistingUser(data);
      setSuccess(true);

    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  if (success) {
    return <Result
      title='Your request is submitted'
      subTitle='Admin will review your request. Approval message would be sent to your email'
      status={'success'}
      extra={[
        <Link to='/app/account/referral'>
          <Button>
            Check Status
          </Button>
        </Link>
      ]} />;
  }

  return <div>
    <Form<CreateForExistingUserRequest>
      onFinish={submitSignupRequest}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      autoComplete="off"
    >
      {!!error && <Form.Item
        wrapperCol={{ sm: { offset: 8, span: 16 } }}
      >
        <ErrorMessage error={error} />
      </Form.Item>}

      <Form.Item
        label="About You"
        help="Tell us a bit about your market analysis expertise"
        name="about"
      >
        <Input.TextArea 
          autoSize
        />
      </Form.Item>

      <Form.Item
        label="Social Link"
        name="social_link"
        extra={<Text type="secondary">Share a link to a page that showcases your trading expertise</Text>}
        rules={[
          { required: false },
          { type: 'url', message: 'must be valid url' },
          { message: 'must be http(s) url', pattern: /^https?/ }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{ sm: { offset: 8, span: 16 } }}
      >
        <Button
          style={{ width: '100%' }}
          loading={submitting}
          disabled={submitting}
          type="primary"
          size='large'
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  </div>;
};
