
import axios from './axios';
import { BillingIntervalUnit, MembershipStatus } from './enums';
import { SignalProvider } from './signal-providers';
import { GenericIDResponse } from './types';
import { GenericStatusResponse, ListRequest, PaginatedResponse } from './types';

export interface PurchaseMembershipRequest {
  plan_id: number
}

export interface PurchaseMembershipResponse {
  membership_id: number;
  client_secret: string | null;
  subscription_id: string;
  requires_payment: boolean
}

export class ListMyMembershipRequest extends ListRequest {
  status?: MembershipStatus
  payment_collection_paused?: boolean
  signal_provider_id?: number
  membership_plan_id?: number
}

export interface MembershipListItem {
  id: number;
  status: MembershipStatus;
  billing_interval: number;
  billing_interval_unit: BillingIntervalUnit;
  billing_cycles: number;
  billing_cycles_charged: number;
  payment_amount: number;
  current_period_start: string;
  current_period_end: string;
  payment_collection_paused: boolean;
  cancelled_after_payment_failure: boolean;
  membership_plan: MembershipPlan;
  stocks: boolean;
  options: boolean;
  created_at: string;
  updated_at: string;
}


interface MembershipPlan {
  id: number;
  name: string;
  active: boolean;
  amount: number;
  billing_interval: number;
  billing_interval_unit: BillingIntervalUnit;
  billing_cycles: number;
  created_at: string;
  updated_at: string;
  signal_provider: SignalProvider;
}

const membershipApi = {
  async purchaseMembership(request: PurchaseMembershipRequest){
    const {data} = await axios.post<PurchaseMembershipResponse>(`/membership`, request);
    return data
  },

  async listMyMemberships(request: ListMyMembershipRequest) {
    const { data } = await axios.get<PaginatedResponse<MembershipListItem>>('/membership/me', { params: request });
    return data;
  },


  async cancelMembership(membership_id: number){
    const { data } = await axios.patch<GenericStatusResponse>(`/membership/${membership_id}/cancel`);
    return data;
  },

  async resumeMembership(membership_id: number){
    const { data } = await axios.patch<GenericStatusResponse>(`/membership/${membership_id}/resume`);
    return data;
  },

  async pauseMembership(membership_id: number){
    const { data } = await axios.patch<GenericStatusResponse>(`/membership/${membership_id}/pause`);
    return data;
  },
}

export default membershipApi
