import styles from './message-item.module.scss';
import { useAppSelector } from "../../../redux/hooks";
import { ChatBubble } from "./chat-bubble";
import { MessageItemProps } from './message-item';
import { Typography } from 'antd';
import { draftByIdSelector } from '../../redux/chat.selectors';
import { ImagePreview } from './image-preview';
import { getErrorMessage } from '../../../shared/utils';
const { Text } = Typography;

export function DraftMessageItem(props: MessageItemProps) {
  const draft = useAppSelector(state => draftByIdSelector(state, props.item.id));

  if (draft === null) {
    return null;
  }

  const itemWrapperClass = `${styles.messageItemWrapper} ${styles.right}`;
  const messageWrapperClass = `${styles.messageWrapper} ${styles.right}`;

  // draft has been sent
  // so there is other persisted message with same info
  if (draft.status === 'success') {
    return null;
  }

  return <div className={itemWrapperClass}>
    <div className={messageWrapperClass}>
      <ChatBubble direction={'right'}>
        {
          draft.image !== null && <ImagePreview
            imagePath={draft.image}
          />
        }
        {draft.text || ''}       
      </ChatBubble>
      {draft.status === 'sending' && (
        <Text type="secondary" style={{ fontSize: 10 }}>
          Sending...
        </Text>
      )}
      {draft.status === 'failed' && (
        <Text type="danger" style={{ fontSize: 10 }}>
          { getErrorMessage(draft.error) }
        </Text>
      )}
    </div>
  </div>;
}
