import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store"

const selectChatState = (state: RootState) => state.chat;

export const chatroomsSelector = createSelector(
  selectChatState, 
  (chat) => chat.myChatrooms.data || []
);

export const currentChatroomIdSelector = createSelector(
  selectChatState,
  (chat) => chat.selectedChatroomId || null
);

export const currentChatroomSelector = createSelector(
  chatroomsSelector, currentChatroomIdSelector,
  (rooms, roomId) => rooms.find(room => room.id === roomId) || null
);

export const unreadMessageCountSelector = createSelector(
  selectChatState,
  (chat) => chat.unreadMessageCount || {}
);

export const moderatorUserIdsSelector = createSelector(
  selectChatState,
  (chat) => (chat.moderators || []).map(m => m.user_id)
);

export const currentChatroomMessgeStateSelector = createSelector(
  selectChatState,
  (chat) => chat.selectedChatroomMessages
);

export const currentChatroomMessagesListSelector = createSelector(
  currentChatroomMessgeStateSelector,
  (messageState) => messageState?.messagesList || []
);

export const prevCursorSelector = createSelector(
  currentChatroomMessgeStateSelector,
  (messageState) => messageState?.prev || null
);

export const draftsSelector = createSelector(
  currentChatroomMessgeStateSelector,
  (messageState) => messageState?.drafts || {}
);

export const sendingDraftsSelector = createSelector(
  draftsSelector,
  (drafts) => {
    return Object
      .entries(drafts)
      .filter((([id, draft]) => {
        return draft.status === 'sending'
      }))
      .map(([id, draft]) => draft);
  }
);

export const draftByIdSelector = createSelector(
  [
    draftsSelector,
    (state, id : string) => id,
  ],
  (drafts, id) => drafts[id] || null,
);

export const messageByIdSelector = createSelector(
  [
    currentChatroomMessgeStateSelector,
    (state, id : string) => id,
  ],
  (messageState, id) => (messageState?.messages || {})[id] || null,
);
