import { Button, Table, message, Modal, Typography } from "antd";
import api from "../../api";
import useSWR from "swr";
import Column from "antd/es/table/Column";
import { OpenOrder } from "../../api/orders";
import { useResponsiveTable } from "../../shared/styles/useResponsiveTable";
import { assetTypeLabels, orderTypeLabels, sideLabels, timeInForceShortLabels } from "../../api/enum-labels";
import { formatMoney, sideToAntTextType } from "../../shared/utils";
import { AssetType } from "../../api/enums";
import { getErrorMessage } from "../../shared/utils";
import { useState } from "react";
import { EditOrderModal } from "./EditOrderModal";
import * as orderUtils from '../utils/orders';
import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";
import { ErrorMessage } from "../../shared/components/ErrorMessage";

const OpenOrders = ({
  assetTypes = Object.values(AssetType)
} : {
  assetTypes?: AssetType[],
}) => {
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, contextHolder] = message.useMessage();

  const [order, setOrder] = useState<OpenOrder|null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { data, isLoading, mutate, error } = useSWR("/api/orders/open", () =>
    api.orders.getOpenOrders()
  );

  const responsiveTable = useResponsiveTable();

  const { broker } = useSelectedBroker();
  const supportsReplace = orderUtils.isReplaceOrderSupported(broker?.account?.type || null, broker?.paper || false);

  const filteredData = data === undefined
    ? data
    : data.filter(order => assetTypes.includes(order.asset_type))

  return (
    <div>
      {modalContextHolder}
      {contextHolder}
      <ErrorMessage error={error}/>
      <Table
        dataSource={filteredData}
        loading={isLoading}
        pagination={false}
        className={responsiveTable}
        rowKey={(record) => record.broker_order_id}
        style={{ overflow: "auto", maxHeight: 60 * 10 }}
      >
        <Column 
          title="Asset" 
          dataIndex="asset_type" 
          key="asset_type"
          render={(_, order : OpenOrder) => assetTypeLabels[order.asset_type]}
        />
        <Column 
          title="Side" 
          dataIndex="side" 
          key="side" 
          render={(_, order: OpenOrder) => {
            if (order.side === null) {
              return null;
            }

            const type = sideToAntTextType(order.side);

            return <Typography.Text type={type} >
              { sideLabels[order.side] }
            </Typography.Text>
          }} 
        />
        <Column 
          title="Symbol" 
          dataIndex="stock" 
          key="stock"
          render={(_, record: OpenOrder) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {orderUtils.getSymbol(record)} 
            </span>
          )}
        />
        <Column 
          title="Type" 
          dataIndex="order_type" 
          key="order_type" 
          render={(_, order: OpenOrder) => !!order.order_type ? orderTypeLabels[order.order_type] : ''}  
        />
        <Column 
          title="Qty" 
          dataIndex="quantity" 
          key="quantity" 
          render={(_, order: OpenOrder) => orderUtils.getQuantity(order)}    
        />
        <Column 
          title="TIF" 
          dataIndex="time_in_force" 
          key="time_in_force" 
          render={(_, order : OpenOrder) => !!order.time_in_force ? timeInForceShortLabels[order.time_in_force] : ''}  
        />
        <Column 
          title="Limit Price" 
          dataIndex="limit_price" 
          key="limit_price" 
          render={(_, order : OpenOrder) => !!order.limit_price ? formatMoney(order.limit_price, 3) : ''}    
        />
        <Column 
          title="Stop Price" 
          dataIndex="stop_price" 
          key="stop_price" 
          render={(_, order : OpenOrder) => !!order.stop_price ? formatMoney(order.stop_price, 3) : ''}      
        />

        <Column
          title=""
          key="cancel"
          render={(_, order: OpenOrder) => {
            return (
              <Button
                block
                type="primary"
                onClick={() => {
                  modal.confirm({
                    title: 'Are you sure you want to cancel the order',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      try {
                        if (order.asset_type === AssetType.Stocks) {
                          await api.orders.cancelStocksOrder(order.broker_order_id);
                        }
                        if (order.asset_type === AssetType.Options) {
                          await api.orders.cancelOptionsOrder(order.broker_order_id);
                        }
                        mutate(undefined, true);
                        messageApi.success("success");
                      } catch (error) {
                        const errorMessage = getErrorMessage(error) || 'Something went wrong';
                        messageApi.error(errorMessage);
                      }
                    }
                  })
                }}
              >
                Cancel
              </Button>
            );
          }}
        />

        {
          supportsReplace && <Column
            title=""
            key='edit'
            render={(_, order: OpenOrder) => {
              return <Button
                block
                type="primary"
                onClick={() => {
                  setOrder(order);
                  setEditModalOpen(true);
                }}>
                Edit
              </Button>;
            }}
          />
        }

      </Table>

      {
        order && <EditOrderModal
          order={order}
          open={editModalOpen}
          setOpen={setEditModalOpen}
          onSuccess={() => {
            messageApi.success("Order Edited");
            mutate(undefined, true);
          }}
        />
      }
    </div>
  );
};

export default OpenOrders;
