import axios from "./axios"

export type MiscConfig = {
  color: string
  analyst_color: string
}

const miscConfigApi = {
  async get() {
    const { data } = await axios.get<MiscConfig>('/misc-config');
    return data;
  }
}

export default miscConfigApi;