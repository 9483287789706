import { Button, Result } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { mutate } from "swr";

export function ChangeMembershipSuccess() {

  useEffect(() => {
    mutate('/api/candefi-memberships/me')
  }, []);

  return <div>
    <Result
      title='Membership purchased'
      subTitle='Membership purchased successfully'
      status={'success'}
      extra={
        <Link replace={true} to='/app/dashboard'>
          <Button type="primary">
            Back To Dashboard
          </Button>
        </Link>
      }
    />
  </div>
}