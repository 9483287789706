import { BasicChatroom, BasicChatroomMessage, DirectMessageRequestStatus } from "../../api/chat";
import { CentEvent } from "../../cent/types";

export enum ChatroomEventTypes {
  NewMessage='new_message',
  DMRequestStatusChanged='dm_request_status_changed'
}

export interface ChatroomMessageWithChatroom extends BasicChatroomMessage {
  chatroom: BasicChatroom
}

export interface NewChatroomMessagePayload {
  message: ChatroomMessageWithChatroom
}

export interface DMRequestStatusChangedEventPayload {
  status: DirectMessageRequestStatus
}

export type ChatroomNewMessageEvent = CentEvent<ChatroomEventTypes.NewMessage, NewChatroomMessagePayload>;

export type DMRequestStatusChangedEvent = CentEvent<ChatroomEventTypes.DMRequestStatusChanged, DMRequestStatusChangedEventPayload>;

export const isChatroomNewMessageEvent = (event: any) : event is ChatroomNewMessageEvent => {
  return !!event
      && !!(event.event)
      && typeof event.event === 'string'
      && event.event === ChatroomEventTypes.NewMessage
      && event.payload.message !== null;
}

export const isDMRequestStatusChangedEvent = (event: any) : event is DMRequestStatusChangedEvent => {
  return !!event
      && !!(event.event)
      && typeof event.event === 'string'
      && event.event === ChatroomEventTypes.DMRequestStatusChanged
      && event.payload.status !== null;
}