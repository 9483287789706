import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { closeImageUploadPopup } from "../../redux/chat.slice";
import { ImageUploadPopupContent } from "./image-upload-popup-content";
import { useEffect, useState } from "react";


export function ImageUploadPopup() {
  const open = useAppSelector(state => state.chat.imageUploadPopup.open);
  const dispatch = useAppDispatch();

  const [key, setKey] = useState(0);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setKey(k => k +1);
  }, [open]);

  const handleClose = () => {
    if (uploading) {
      return
    }
    dispatch(closeImageUploadPopup())
  }

  return <Modal
    open={open}
    footer={null}
    onCancel={handleClose}
    title="Upload Image"
  >
    <div style={{ margin: '20px 0' }}>
      {
        open && <ImageUploadPopupContent 
          uploading={uploading}
          setUploading={setUploading}
          key={key}
        />
      }
    </div>
  </Modal>
}

