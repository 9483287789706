
import axios from './axios';
import { AssetType, Side, Direction } from './enums';

export interface OpenPosition {
  id: string;
  asset_type: AssetType;
  short_name: string;
  long_name: string;
  options_strike_price: number | null;
  options_expiration_date: string | null;
  options_direction: Direction | null;
  options_multiplier: number | null;
  side: Side | null;
  quantity: number;
  unit_cost: number;
  total_cost: number;
  market_price: number | null;
  market_value: number | null;
  holding_proportion: number | null;
  unrealized_profit_loss: number | null;
  unrealized_profit_loss_rate: number | null;
}

const positionsApi = {
  async getOpenPositions() {
    const { data } = await axios.get<OpenPosition[]>('/positions/open', {
      needsBrokerId: true
    });
    return data;
  },
}


export default positionsApi;