import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Button, Result, message } from "antd";
import dmImage from './dm_request_icon.svg';
import { acceptDmRequest } from "../redux/thunks/accept-dm-request.thunk";
import { getErrorMessage } from "../../shared/utils";
import { currentChatroomSelector } from "../redux/chat.selectors";

export function DMAcceptRequestUI() {
  const dispatch = useAppDispatch();
  const chatroom = useAppSelector(currentChatroomSelector)!;
  const [processing, setProcessing] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const handleAcceptClick: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setProcessing(true);
      await dispatch(acceptDmRequest(chatroom.id)).unwrap();
      messageApi.success("Request accepted");
    } catch (e) {
      const message = getErrorMessage(e) || 'Something went wrong';
      messageApi.error(message);
    } finally {
      setProcessing(false);
    }
  };

  const acceptButton = (
    <Button onClick={handleAcceptClick} disabled={processing} loading={processing}>
      Accept
    </Button>
  );

  return <div>
    {contextHolder}
    <Result
      icon={<img
        src={dmImage}
        style={{ display: 'inline-block', maxHeight: 200 }} />}
      title="You have DM Request"
      subTitle={<p>
        @ {chatroom.dm_request_sender!.username} <br />
        If you don't wish to communicate you can ignore their request.
      </p>}
      extra={[acceptButton]}
    >
    </Result>
  </div>;
}
