import { Avatar, Button, Dropdown, List, Space } from "antd";
import { BrokerType } from "../entities/broker-account.entity";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { brokerTypeLabels } from "../../api/enum-labels";
import { Typography } from 'antd';
import { brokerIcons } from "./broker-icons";
import { SelectedBroker } from "../repos/selected-broker-repo";
import { DownOutlined } from '@ant-design/icons';
import { useMaskInfoContext } from "../../shared/context/MaskInfoContext";

const { Text, Title } = Typography;

export function SelectBrokerFormInput({
  value,
  onChange,
} : {
  value: SelectedBroker | null,
  onChange: (broker: SelectedBroker) => void,
}) {

  const { user } = useAuthUser();
  const { masked } = useMaskInfoContext();


  if (!user) {
    return null;
  }

  const options = (user.broker_accounts || []).reduce<SelectedBroker[]>((arr, curr) => {
    if (curr.type === BrokerType.webull) {
      return [
        ...arr,
        {
          account: curr,
          paper: false,
        },
        {
          account: curr,
          paper: true,
        }
      ]
    }
    return [
      ...arr,
      {
        account: curr,
        paper: false,
      }
    ]
  }, []);

  return <Dropdown
    arrow={{
      pointAtCenter: false,
    }}
    menu={{
      items: options.map(({ account, paper }) => ({
        key: `${account.id}:${paper?'paper':''}`,
        label: <div>
          {brokerTypeLabels[account.type]}&nbsp;{paper ? 'Paper' : ''} <br />
          <Text type="secondary">
            { !masked ? account.username : '******' }
          </Text>
        </div>,
        icon: <Avatar src={brokerIcons[account.type]} />,
      })),
      onClick: (info) => {
        const parts = info.key.split(":");
        const accountId = parseInt(parts[0]);
        const paper = parts[1] === 'paper';
        let account = user.broker_accounts.find(account => account.id === accountId);
        if (!account) {
          return
        }
        onChange({
          account,
          paper,
        });
      },
      selectedKeys: [`${value?.account.id || ''}:${value?.paper ? 'paper' : ''}`],
    }}
    placement="bottomLeft">
    <Button 
      type={'ghost'} 
      style={{ 
        display: 'flex', 
        height: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 16
      }}
    >
      {
        value && <Space direction="horizontal">
          <Avatar src={brokerIcons[value.account.type]} />
          <div style={{ textAlign: 'start' }}>
            <Title level={4} style={{ marginBottom: 0, fontWeight: 300 }}>
              {brokerTypeLabels[value.account.type]}&nbsp;{value.paper ? 'Paper' : ''}
            </Title>
            <Text type="success">Connected</Text>
          </div>
        </Space>
      }
      {
        !value && <Space>
          {
            user.broker_accounts.length > 0
              ? 'Select Broker'
              : 'No Brokers'
          }
        </Space>
      }
      <Text type="secondary">
        <DownOutlined 
          size={16}
        />
      </Text>
    </Button>
  </Dropdown>
  
  // return <Dropdown 
  //   dropdownRender={(node) => <List>{node}</List>}
  //   menu={{
  //     items: user.broker_accounts.map(account => ({
  //       key: account.id,
  //       label: <div>
  //         { brokerTypeLabels[account.type] }<br/>
  //         <Text type="secondary">
  //           { account.username }
  //         </Text>
  //       </div>,
  //       icon: <Avatar src={brokerIcons[account.type]} />,
  //     })),
  //     onClick: (info) => {
  //       let account = user.broker_accounts.find(account => account.id === parseInt(info.key));
  //       if (!account) {
  //         return
  //       }
  //       onChange(account);
  //     },
  //     selectedKeys: [`${value?.id || ''}`],
  //   }}
  //   placement="bottomLeft">
  //     <Button type={'ghost'} style={{ height: 'auto', width: '100%', textAlign: 'left' }}>
  //       {
  //         value && <Space direction="horizontal">
  //           <Avatar src={brokerIcons[value.type]} />
  //           <div style={{ textAlign: 'start' }}>
  //             <Title level={4} style={{ marginBottom: 0, fontWeight: 300 }}>
  //               { brokerTypeLabels[value.type] }
  //             </Title>
  //             <Text type="secondary">
  //               { value.username }
  //             </Text>
  //           </div>
  //         </Space>
  //       }
  //       {
  //         !value && <Space>
  //           {
  //             user.broker_accounts.length > 0 
  //               ? 'Select Broker'
  //               : 'No Brokers'
  //           }
  //         </Space>
  //       }
  //     </Button>
  // </Dropdown>
}