import { Button, Modal, notification } from "antd"
import { ReloadOutlined } from '@ant-design/icons';
import { useSelectedBroker } from "../context/SelectedBrokerContext";
import { BrokerType } from "../entities/broker-account.entity";
import { useState } from "react";
import { ResetWebullPaperAccountForm } from "./ResetWebullPaperAccountForm";

export function ResetWebullPaperAccountButton({ 
  style,
} :{
  style?: React.CSSProperties
}) {
  const info = useSelectedBroker();
  if (
    !info.initialized || 
    !info.broker || 
    !info.broker.paper || 
    info.broker.account.type !== BrokerType.webull
  ) {
    return null;
  }
  return <ResetWebullPaperButtonInternal style={style} />
}

function ResetWebullPaperButtonInternal({
  style,
} :{
  style?: React.CSSProperties
}) {
  const [notificationApi, contextHolder] = notification.useNotification();

  const [showModal, setShowModal] = useState(false);
  
  const onSuccess = () => {
    setShowModal(false);
    notificationApi.success({
      message: "Webull paper account has been reset"
    })
  }

  return <>
    { contextHolder }
    <Modal 
      open={showModal} 
      onCancel={() => setShowModal(false)} 
      footer={null}
    >
      <ResetWebullPaperAccountForm onSuccess={onSuccess} />
    </Modal>
    <Button style={style} onClick={() => {
      setShowModal(true);
    }}>
      Reset <ReloadOutlined />
    </Button>
  </>
}