import { Button, Card, Col, Empty, Row, Skeleton, Spin } from "antd";
import { useState } from "react";
import api from "../../api";
import { RechargeResponse } from "../../api/recharges";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import { formatMoneyAuto } from "../../shared/utils";
import useSWR from 'swr';
import { Typography } from 'antd';
import { useCanBuyExtraCoins } from "../../candefi-memberships/hooks/useCanBuyExtraCoins";
import { CrownFilled } from '@ant-design/icons';

const { Title, Text } = Typography;

export const RechargeForm = ({
  onSuccess
}: {
  onSuccess: (response: RechargeResponse) => void
}) => {

  const [error, setError] = useState<unknown>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (planId: number) => {
    try {

      setError(null);
      setSubmitting(true);
      const response = await api.rechargesApi.recharge({ 
        plan_id: planId,
      });
      onSuccess(response);

    } catch (e) {

      setError(e);

    } finally {

      setSubmitting(false);

    }
  }

  return <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
    <>
      {
        error && <ErrorMessage error={error} />
      }
      {
        !submitting &&  <PlansSelector 
          onSelect={(planId) => {
            onSubmit(planId)
          }}
        />
      }
      {
        submitting && <div style={{ textAlign: 'center', margin: '24px 0' }}>
          <Spin size="large" />
        </div>
      }
    </>
  </div>
}

function PlansSelector({
  onSelect
}: {
  onSelect: (planId: number) => void,
}) {
  const {
    data: plans,
    isLoading,
    error,
  } = useSWR('/account-balance/recharge-plans', api.rechargesApi.listPlans);

  const canBuy = useCanBuyExtraCoins();

  if (isLoading) {
    return <Skeleton />
  }

  if (!!error) {
    return <ErrorMessage
      error={error}
    />
  }

  return <Row gutter={[16, 16]}>
    {
      plans?.map(plan => (
        <Col span={24} sm={{ span: 12 }} md={{ span: 8 }}>
          <Card 
            size="small"
            title={<span style={{ textAlign: 'center', display: 'block' }}>{plan.title}</span>}>
              <div style={{ textAlign: 'center', marginBottom: 16, marginTop: 16 }}>
                <Title style={{ marginBottom: 0 }}>
                  { plan.coins}
                </Title>
                <Text>Coins</Text>
              </div>
              <Button
                disabled={!canBuy}
                onClick={() => {
                  onSelect(plan.id)
                }}
                type="primary" 
                block 
                size="large"
                icon={canBuy ? undefined : <CrownFilled /> }
              >
                Pay { formatMoneyAuto(plan.amount) }  
              </Button>
          </Card>
        </Col>
      ))
    }
    {
      plans !== undefined && plans.length <= 0 && <Empty
        style={{ marginLeft: 'auto', marginRight: 'auto' }} 
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No plans"
      />
    }
  </Row>
}