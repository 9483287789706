import { useEffect } from "react";
import { AutotradeAlertEventPayload, isAutotradeAlertCreatedEvent, isAutotradeAlertUpdatedEvent } from "./events";
import { useAutotradeCentSubscription } from "./use-autotrade-cent-subscription";
import { PublicationContext } from "centrifuge";

export const useAutotradeCentEvents = (
  listeners: {
    onAlertCreated?: (payload: AutotradeAlertEventPayload) => void | Promise<void>,
    onAlertUpdated?: (payload: AutotradeAlertEventPayload) => void | Promise<void>,
  }
)  => {
  const subscription = useAutotradeCentSubscription();
  
  useEffect(() => {

    if (subscription === null) {
      return;
    }

    const onPublish = (ctx: PublicationContext) => {
      if (
        listeners.onAlertCreated !== undefined 
        && isAutotradeAlertCreatedEvent(ctx.data)
      ) {
        listeners.onAlertCreated(ctx.data.payload);
        
      }

      if (
        listeners.onAlertUpdated !== undefined 
        && isAutotradeAlertUpdatedEvent(ctx.data)
      ) {
        listeners.onAlertUpdated(ctx.data.payload);
      }
    }
  
    subscription.addListener('publication', onPublish);
  
    return () => {
      subscription.removeListener('publication', onPublish)
    };

  }, [subscription, listeners]);

  return null;
}