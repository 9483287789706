import { Button } from "antd";
import { useStripeConnectAccount } from "../hooks/useStripeConnectAccount"
import { useStripeLogo } from "../hooks/useStripeLogo";
import styles from './account-info.module.scss';
import { Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useStripeConnect } from "../hooks/useStripeConnect";

const { Text } = Typography;

export const StripeConnectAccountInfo = () => {
  const { setupComplete } = useStripeConnectAccount();
  const stripeLogo = useStripeLogo();

  return <div className={styles.container}>
    <div>
      <div>
        <img
          style={{ marginLeft: -12 }}
          width={100}
          src={stripeLogo}
          alt="stripe logo"  
        />
      </div>
      {
        setupComplete && <Text  type="success">
          Your stripe account is connected
        </Text>
      }
      {
        !setupComplete && <Text type="warning">
          Your stripe setup is incomplete.
        </Text>
      }
    </div>
    <div>
      <StripeConnectButton />
    </div>
  </div>
}

const StripeConnectButton = () => {
  const {
    createLink,
    creating,
  } = useStripeConnect();

  return <Button
    loading={creating}
    icon={<EditOutlined/>} 
    onClick={() => createLink()}>
    Edit
  </Button>
}