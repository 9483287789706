import { BotAlertWithOrders, OptionsAlertCalculatedParams } from "../../api/autotrade-alerts";
import { BotAction, BotAssetType, BotEnforce, Direction, Side, TimeInForce } from "../../api/enums";
import { OptionFormInitialValues } from "../../trade/hooks/useOptionsForm";
import { StockFormInitialValues } from "../../trade/hooks/useStocksForm";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";

export const getFormInitialValuesFromAlert = (alert: BotAlertWithOrders): PlaceOrderFormInitialValues | null => {
  const params = (alert.parameters && alert.parameters.length > 0)
    ? alert.parameters[0]
    : null;

    // side
    let side = undefined;
    if (alert.action === BotAction.buy) {
      side = Side.Buy
    }
    if (alert.action === BotAction.sell) {
      side = Side.Sell;
    }

    const orderType = params?.order_type || undefined;

    let tif = params?.time_in_force;
    if (tif === undefined || tif === null) {
      if (alert.enforce === BotEnforce.day) {
        tif = TimeInForce.Day
      }
      if (alert.enforce === BotEnforce.gtc) {
        tif = TimeInForce.GoodTillCancelled
      }
    }

    const limitPrice = params?.limit_price || alert.price || undefined;

    const quantity = params?.quantity || alert.quantity || undefined;

    const stopPrice = params?.stop_price || alert?.stop_price || undefined;
  

  if (alert.asset_type === BotAssetType.stocks) {
  
    const initialValues: StockFormInitialValues = {
      stock: alert.asset_name,
      side: side,
      orderType: orderType,
      tif: tif, 
      limitPrice: limitPrice,
      quantity: quantity,
      stopPrice: stopPrice,
    };
    return initialValues;
  }

  if (alert.asset_type === BotAssetType.options) {
    let optionsParams = params as OptionsAlertCalculatedParams;

    let direction = undefined;
    if (alert.action === BotAction.call) {
      direction = Direction.Call
    }
    if (alert.action === BotAction.put) {
      direction = Direction.Put;
    }

    let strike = optionsParams?.strike_price || alert.strike_price || undefined;
    
    let expiration = optionsParams?.expiration_date || alert.expiration_date || undefined;

    const initialValues: OptionFormInitialValues = {
      stock: alert.asset_name,
      side: side,
      orderType: orderType,
      limitPrice: limitPrice,
      stopPrice: stopPrice,
      tif: tif,
      quantity: quantity,
      direction: direction,
      strikePrice: strike,
      expirationDate: expiration,
      // stock: position.short_name,
      // direction: position.options_direction || undefined,
      // side: position.side === Side.Buy ? Side.Sell : Side.Buy,
      // quantity: position.quantity,
      // expirationDate: position.options_expiration_date || undefined,
      // strikePrice: position.options_strike_price || undefined,
    };
    return initialValues;
  }

  return null;
};
