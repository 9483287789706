import { Divider, Modal } from "antd"
import { AutotradePreferenceFormSection } from "./form-section"

export function AutotradePreferenceFormModal({
  isOpen,
  onClose,
  onSuccess
} : {
  isOpen: boolean,
  onClose: () => void,
  onSuccess?: () => void
}) {
  return <Modal 
    width={600}
    title="Edit Your AutoTrade Preference" 
    open={isOpen} 
    onCancel={onClose} 
    footer={null}
  >
    <Divider />
    <AutotradePreferenceFormSection 
      onSuccess={onSuccess}
    />
  </Modal>
}