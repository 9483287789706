
import axios from './axios';

export interface RechargeRequest {
  plan_id: number
}

export interface RechargeResponse { 
  recharge_id: number,
  client_secret: string
}

export interface RechargePlan {
  id: number
  title: string
  amount: number
  coins: number
}

const rechargesApi = { 
  async recharge(request: RechargeRequest){
    const {data} = await axios.post<RechargeResponse>('/account-balance/recharges', request)
    return data
  },

  async listPlans() {
    const { data } = await axios.get<RechargePlan[]>('/account-balance/recharge-plans');
    return data;
  },
}

export default rechargesApi;