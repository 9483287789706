// api base url
export const BASE_URL = process.env.REACT_APP_API_ORIGIN;

// auth related routes
export const LOGIN_PATH = "/auth/login";
export const REFRESH_PATH = "/auth/refresh-token";
export const SIGNUP_PATH = '/auth/signup'
export const PASSWORD_RESET_PATH = "/auth/password-reset";
export const PASSWORD_RESET_SEND_OTP_PATH = "/auth/password-reset/send-otp";
export const PASSWORD_RESET_VERIFY_OTP_PATH = "/auth/password-reset/send-otp";

// Paths that require no access token
// IMPORTANT
// Every path here must include leading slash, 
// and must omit trailing slash
export const NO_TOKEN_PATHS = [
  LOGIN_PATH,
  SIGNUP_PATH,
  PASSWORD_RESET_PATH,
  PASSWORD_RESET_SEND_OTP_PATH,
  PASSWORD_RESET_VERIFY_OTP_PATH
];