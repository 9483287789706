import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { getDefaultBrokerInfo } from "../../trade-preference/utils";
import selectedBrokerRepo, { SelectedBroker } from "../repos/selected-broker-repo";

export interface SelectedBrokerContextState {
  initialized: boolean;
  broker: SelectedBroker | null;
  selectBroker: (currentBroker: SelectedBroker | null) => Promise<void>;
}

const SelectedBrokerContext = createContext<SelectedBrokerContextState>({
  initialized: false,
  broker: null,
  selectBroker: async () => {},
});

export const SelectedBrokerProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const user = useAuthUser();

  // fetch selected broker
  const {
    data: broker,
    mutate,
    isLoading,
  } = useSWR(["/selected-broker", user.user?.trade_preference], async () => {
    
    const defaultBrokerInfo = getDefaultBrokerInfo(user?.user?.trade_preference);

    // broker from storage
    const selected = await selectedBrokerRepo.getBroker();
    if (selected !== null) {
      return selected;
    }

    // no default broker set in trade preference
    if (defaultBrokerInfo === null) {
      return null;
    }

    // also update default broker in repo
    // because outside react we use this repo
    await selectedBrokerRepo.setBroker(defaultBrokerInfo);

    return defaultBrokerInfo;
  });

  const navigate = useNavigate();

  // select broker
  const selectBroker = async (info: SelectedBroker | null) => {
    if (info === null) {
      // remove broker
      await selectedBrokerRepo.removeBroker();
    } else {
      // set new broker
      await selectedBrokerRepo.setBroker(info);
    }

    // sync state with repo
    mutate();

    // refresh page
    navigate(0);
  };

  const initialized = !isLoading;

  return (
    <SelectedBrokerContext.Provider
      value={{
        broker: broker || null,
        initialized,
        selectBroker,
      }}
    >
      {children}
    </SelectedBrokerContext.Provider>
  );
};

export const useSelectedBroker = () => {
  return useContext(SelectedBrokerContext);
};
