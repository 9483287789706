import { BasicChatroomMessage } from '../../../api/chat';
import { CurrentUserInfo } from '../../../api/auth';
import { ImagePreview } from './image-preview';
import { MessageContentSignal } from './message-content-signal';

export function MessageContent({
  message, currentUser,
}: {
  message: BasicChatroomMessage;
  currentUser: CurrentUserInfo;
}) {

  return <div>
    {message.text}
    {
      message.image && <ImagePreview
        imagePath={message.image}
      />
    }
    <MessageContentSignal message={message} />
  </div>;
}