import styles from './message-item.module.scss';
import { format, fromUnixTime } from "date-fns";
import { useAppSelector } from "../../../redux/hooks";
import { ChatAvatar } from "./chat-avatar";
import { ChatBubble } from "./chat-bubble";
import { MessageItemProps } from './message-item';
import { Typography } from 'antd';
import { MessageContent } from './message-content';
import { messageByIdSelector } from '../../redux/chat.selectors';

const { Text } = Typography;

export function PersistedMessageItem(props: MessageItemProps) {
  const { item } = props;
  const message = useAppSelector(state => messageByIdSelector(state, item.id));
 
  const sender = message.sender!;
  const isSender = sender.id === props.currentUser.id.toString();
  const direction = isSender ? 'right' : 'left';

  const itemWrapperClass = `${styles.messageItemWrapper} ${direction === 'right' ? styles.right : styles.left}`;
  const messageWrapperClass = `${styles.messageWrapper} ${direction === 'right' ? styles.right : styles.left}`;

  return <div className={itemWrapperClass}>
    {!isSender && <ChatAvatar user={sender} />}
    <div className={messageWrapperClass}>
      <ChatBubble direction={direction}>
        <MessageContent 
          message={message} 
          currentUser={props.currentUser} 
        />
      </ChatBubble>
      <Text type="secondary" style={{ fontSize: 10 }}>
        {format(fromUnixTime(message.created_at), 'PPp')}
      </Text>
    </div>
  </div>
}