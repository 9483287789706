import { useEmotionCss } from "@ant-design/use-emotion-css"

export const useRadioGroupStyle = () => {
  return useEmotionCss(({ theme }) => ({
    display: 'flex',
    alignItems: 'stretch',
    gap: 8,
    '.ant-radio-button-wrapper': {
      flex: '1',
      textAlign: 'center',
      borderRadius: 8,
      borderWidth: 1,
      '::before': {
        display: 'none',
      }
    }
  }))
}

export const useGreenRedRadioGroupStyle = () => {
  return useEmotionCss(({ theme, token }) => ({
    display: 'flex',
    alignItems: 'stretch',
    gap: 8,
    '.ant-radio-button-wrapper': {
      flex: '1',
      textAlign: 'center',
      borderRadius: 8,
      borderWidth: 1,
      '&:first-of-type': {
        '&:hover': {
          color: token.colorSuccess,
          borderColor: token.colorSuccess,
        },
        '&.ant-radio-button-wrapper-checked': {
          '&:hover': {
            backgroundColor: token.colorSuccessActive,
            color: 'white',
          },
          backgroundColor: token.colorSuccessActive,
          borderColor: token.colorSuccess,
        }
      },
      '&:last-of-type': {
        '&:hover': {
          color: token.colorError,
          borderColor: token.colorError,
        },
        '&.ant-radio-button-wrapper-checked': {
          '&:hover': {
            backgroundColor: token.colorErrorActive,
            color: 'white',
          },
          backgroundColor: token.colorErrorActive,
          borderColor: token.colorError,
        }
      },
      '::before': {
        display: 'none',
      }
    }
  }))
}

export const useRadioGroupRoundedStyle = () => {
  return useEmotionCss(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    '.ant-radio-button-wrapper': {
      textAlign: 'center',
      borderRadius: '30px !important',
      paddingLeft: 32,
      paddingRight: 32,
      borderWidth: 1,
      '::before': {
        display: 'none',
      }
    }
  }))
}