import useSWR from 'swr';
import api from "../../api";
import { Direction, OrderType } from "../../api/enums";
import { optionsLimitPriceRequired } from '../utils/options';

// Custom Hook For Options Chain
// Returns data and loading state of various entities
export function useOptionsChain({
  stock, expirationDate, direction, strikePrice,
  orderType,
}: {
  stock: string | null;
  expirationDate: string | null;
  direction: Direction | null;
  strikePrice: number | null;
  orderType: OrderType | null,
}) {

  const limitPriceRequired = orderType !== null && 
    optionsLimitPriceRequired(orderType)

  const {
    data: optionable, isLoading: optionableLoading,
  } = useSWR(
    stock ? `/api/options-chain/is-optionable?stock=${stock}` : null,
    async () => {
      let response = await api.optionsChain.isOptionable({
        ticker_symbol: stock!
      });
      return response.is_optionable;
    }
  );
  
  const {
    data: assetLastPriceQuote,
    isLoading: assetLastPriceQuoteLoading,
  } = useSWR(
    stock ? ['/api/stocks-quote/last-price', stock] : undefined,
    async (key) => {
      const stock = key[1];
      return api.stocksQuote.getAssetLastPrice({ ticker_symbol: stock });
    },
  )

  // expiration dates for the selected stock
  const {
    data: expirationDates, isLoading: expirationDatesLoading,
  } = useSWR(
    stock ? `/api/options-chain/strike-prices?stock=${stock}` : null,
    async () => {
      let response = await api.optionsChain.getExpirationDates({
        ticker_symbol: stock!
      });
      return response;
    }
  );

  // strike prices
  const {
    data: strikePrices, isLoading: strikePricesLoading,
  } = useSWR(
    !!stock && !!expirationDate && !!direction
      ? `/api/options-chain/strike-prices?stock=${stock}&expiration_date=${expirationDate}&direction=${direction}`
      : null,
    async () => {
      let response = await api.optionsChain.getStrikePrices({
        direction: direction!,
        expiration_date: expirationDate!,
        ticker_symbol: stock!,
      });
      return response;
    }
  );

  // quote / mid price / ask price (not sure what this actually is)
  const {
    data: optionsQuote, 
    isLoading: optionsQuoteLoading,
    isValidating: optionsQuoteValidating,
  } = useSWR(
    !!stock && !!expirationDate && !!direction && !!strikePrice && limitPriceRequired
      ? `/api/options-chain/quote?stock=${stock}&expiration_date=${expirationDate}&direction=${direction}&strike_price=${strikePrice}`
      : null,
    async () => {
      let response = await api.optionsChain.getOptionsQuoteResponse({
        direction: direction!,
        expiration_date: expirationDate!,
        strike_price: strikePrice!,
        ticker_symbol: stock!,
      });
      return response;
    }
  );

  return {
    assetLastPriceQuote: assetLastPriceQuote,
    optionable: {
      data: optionable,
      loading: optionableLoading,
    },
    expirationDates: {
      data: expirationDates,
      loading: expirationDatesLoading
    },
    strikePrices: {
      data: strikePrices,
      loading: strikePricesLoading,
    },
    optionsQuote: {
      data: optionsQuote,
      loading: optionsQuoteLoading || optionsQuoteValidating,
    }
  };
}
