import { configureStore } from "@reduxjs/toolkit";
import chat from '../chat/redux/chat.slice';

const store = configureStore({
  reducer: {
    chat
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;