import { useState } from "react";
import api from "../../api";

export const useCancelMembership = (membershipId: number) => {
  const [isCancelling, setIsCancelling] = useState(false);

  const cancel = async () => {
    setIsCancelling(true);
    try {
      await api.membership.cancelMembership(membershipId);
    } finally {
      setIsCancelling(false);
    }
  };

  return {
    cancel,
    isCancelling,
  };
};
