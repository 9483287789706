import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { AlreadyPurchasedAlert } from "../components/AlreadyPurchasedAlert";
import { PlanInfo } from "../../membership-plans/components/PlanInfo";
import { PurchaseForm } from "../components/PurchaseForm";

const PurchaseMembershipPage = () => {

  const params = useParams();
  const planId = Number(params.planId);

  return (
    <div style={{ padding: "1rem", maxWidth: '1024px', margin: '16px auto' }}>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>

        <Col span={24} md={{ span: 8 }}>
          <PlanInfo planId={planId} />
        </Col>

        <Col span={24} md={{ span: 16 }}>
          <AlreadyPurchasedAlert planId={planId}/>
          <PurchaseForm planId={planId} />
        </Col>

      </Row>
    </div>
  );
};

export default PurchaseMembershipPage;
