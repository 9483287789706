import { useEffect, useState } from "react";
import { Card, Col, Pagination, Row, Space, Typography } from "antd";
import useSWR from "swr";
import api from "../../api";
import SignalProviderCard from "../../signal-providers/components/SignalProviderCard";
import { ListSignalProvidersRequest } from "../../api/signal-providers";
import { MarketplaceServiceFilter } from "../pages/MarketPlace";
import { Link } from "react-router-dom";

const { Paragraph } = Typography;

const SignalProvidersList = ({ 
  filter, 
}: { 
  filter: MarketplaceServiceFilter, 
}) => {

  const [page, setPage] = useState(1);

  const { data, isLoading } = useSWR(`/signal-providers?page=${page}&filter=${filter}`, () => {
    const request = new ListSignalProvidersRequest();
    if (filter === MarketplaceServiceFilter.Options) {
      request.options = true;
    }
    if (filter === MarketplaceServiceFilter.Stocks) {
      request.stocks = true;
    }
    request.page = page;
    return api.signalProviders.index(request);
  });

  useEffect(() => {
    setPage(1);
  }, [filter]);

  const signalProviders = data?.data || [];

  const showReferMessage = !isLoading 
    && filter === MarketplaceServiceFilter.Any 
    && signalProviders.length <= 0;

  return (
    <div style={{ background: 'none' }}>
      {
        showReferMessage && <div style={{ padding: 16 }}>
          <Paragraph style={{ fontSize: '1.2rem' }}>
            Thank you for joining!
            <br/>
            As we onboard Signal Providers, they will be featured on this page. Would you like to refer an <Link to={"/app/account/referral"}>Analyst</Link> to join the platform?
            { /* CandeFi just went live, you're one of our earliest members. Once we onboard Signal Providers, they will appear here.
            <br/>
            Would you like to refer a <Link to={"/app/account/referral"}>
               Signal Provider?
            </Link> */}
          </Paragraph>
        </div>
      }
      {
        !isLoading && signalProviders.length > 0 && <Space direction="vertical" style={{ width: '100%' }}>
          <Row gutter={[8, 8]}>
            {signalProviders.map((signalProvider, i) => (
              <Col
                span={24}  
                sm={{ span: 12 }}
                md={{ span: 12 }} 
                // lg={{ span: 8 }} 
                xl={{ span: 8 }}
                key={signalProvider.signal_provider.id}>
                <div style={{ display: 'flex', alignItems: 'stretch', height: '100%' }}>
                  <SignalProviderCard signalProvider={signalProvider} />
                </div>
              </Col>
            ))}
          </Row>
          <div
            style={{ padding: '16px 0', display: 'flex' }}>
            <Pagination
              showSizeChanger={false}
              total={data?.total}
              current={page}
              onChange={setPage}
            />
          </div>
        </Space>
      }
    </div>
  );
};

export default SignalProvidersList;
