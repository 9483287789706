
import { BrokerType } from '../brokers/entities/broker-account.entity';
import axios from './axios';


export interface WebullAccount {
  accountType: string;
  brokerAccountId: string;
  totalCost: number;
  netLiquidation: number;
  unrealizedProfitLoss: number;
  unrealizedProfitLossRate: number;
  unrealizedProfitLossBase: number;
  warning: boolean;
  accountMembers: {
    key: string;
    value: number;
  }[];
}

export interface RobinhoodPortfolio {  
  url: string
  account: string
  start_date: string

  // market value
  market_value: number
  
  //account value
  equity: number
  
  //cash balance
  withdrawable_amount: number
  
  //previous day close
  adjusted_portfolio_equity_previous_close: number
  
  // profit / loss number
  pl_day : number

  // profit / loss percent normalized (between 0-1)
  pl_day_percent_normalized: number
}

export interface RobinhoodAccountAggregate {
  account: RobinhoodAccount
  portfolio: RobinhoodPortfolio
}

export interface RobinhoodAccount {  
  account_number: string
  type: string
  buying_power: number
  cash: number
  option_trading_on_expiration_enabled: boolean
  equity_trading_lock: string
  option_trading_lock: string
  option_level: string
}

export type WebullPaperCapital = {
  netLiquidationValue: number
  unrealizedProfitLoss: number
  unrealizedProfitLossRate: number
  buyingPower: number
  totalMarketValue: number
}

export type WebullPaperTodaySummaryAccount = {
  dayProfitLoss: number
}

export type WebullPaperTodaySummary = {
  account: WebullPaperTodaySummaryAccount
}

export type WebullPaperAccountInfo = {
  capital: WebullPaperCapital
  todaySummary: WebullPaperTodaySummary
}

export type AccountInfo = { type: BrokerType.webull, info: WebullAccount | null, paper_info: WebullPaperAccountInfo | null }
  | { type: BrokerType.robinhood, info: RobinhoodAccountAggregate }


const acocuntInfoApi = {
  async getAccountInfo(){
    const {data} = await axios.get<AccountInfo>('/account-info', {
      needsBrokerId: true
    })
    return data;
  }

}

export default acocuntInfoApi;