import { Typography } from 'antd';

export function TermsOfUse() {
  return <div style={{ padding: '16px 30px' }}>
    <div style={{ padding: 16 }}>
      <Typography>
        <h4>
          CandeFi Social Trading Platform:
        </h4>
        <p>
          CandeFi social trading platform enables users to implement strategies for entering and exiting trades based on trading signals from Social Media, Newsletters or the CandeFi Analyst Portal.
        </p>
        <p>
          CandeFi supports trading in Options, Stocks, Cryptos, and ETFs etc. the availability of any of these securities is dependent on whether the chosen brokerage and CandeFi supports it. All trade execution is self-directed, executed and managed by you, the account holder. CandeFi allows you to set default trade instructions and parameters for faster trade entry and exit and to simplify account management.
        </p>
        <p>
          CandeFi offers the best social trading platform for you to perform self-directed social trade execution based on trade signals and trade alerts of investment newsletter publications, analysts, trade signal alert services you are subscribing to independently of CandeFi and self-directed trades.
        </p>
        <p>
          Options involve a high degree of risk and are not suitable for all investors as the special risks inherent to options trading may expose investors to potentially significant losses. Please read the <a href='https://www.theocc.com/components/docs/riskstoc.pdf'>Characteristics and Risks of Standardized Options</a> before deciding to invest in options.
        </p>
        <h4>
          Terms Of Use:
        </h4>
        <p>
          I agree to indemnify and hold CandeFi, its affiliates, successors, assigns, and its and their directors, officers and employees harmless from and against any and all claims, liabilities, actions, and costs, including attorney's fees, resulting or arising directly or indirectly from the trading in my CandeFi account or third party signal provider, recommendations, trade signals or actions. This Agreement is in addition to (and in no way limits or restricts) any rights that the Brokers supported on the CandeFi SaaS platform, its affiliates or successors or assigns may have under any other agreement or agreements I have previously agreed to or accepted. By utilizing any service with your CandeFi Account you assent that all orders, trade transactions, and trade executions are self-directed by you at your discretion, and that all orders and trades submitted are executed at your own risk and liability. You agree to be held entirely liable for all orders and trades executed using your CandeFi account. If you effect trades based-off of any information on this website, you are choosing to do so at your own risk and discretion. CandeFi does not provide any financial or investment advice. Any investment decision and/or strategy that you make or utilize, whether or not such decision or strategy derives from or relies upon material accessed or provided through this website, is done so at your sole discretion and your own risk. Before making any investment decisions, please consult additional sources of information and/or your legal or tax advisor.
        </p>
        <p>
          CandeFi does not recommend or endorse any investment instruments or trading strategy. The material on this website is provided for informational and educational purposes only and shall not in any manner be considered a recommendation or endorsement of any strategy or investment. No content published by a trade-analyst or publisher as part of trade alerts constitutes a recommendation or that any particular investment, security, portfolio of securities, transaction or investment strategy is suitable for any specific person. Neither a publisher, analysts or CandeFi will advise you personally concerning the nature, potential, value or suitability of any particular investment, security, portfolio of securities, transaction, or investment strategy. Accordingly, do not attempt to contact publishers, analysts or CandeFi for guidance or for seeking personalized investment advice, which they cannot provide. To the extent any of the content published as part of the Services may be deemed to be investment advice, such information is impersonal and not tailored to the investment needs of any specific person. Any opinion, recommendation or alert of any independent third-party provider is the sole opinion of the publisher and does not express the opinion of CandeFi. Any investment decision and/or strategy that you make or utilize, whether or not such decision or strategy derives from or relies upon material accessed or provided through this website or social-media accounts belonging to CandeFi, is done so at your sole discretion and your own risk. Please consult additional sources of information and/or your legal or tax advisor.
        </p>
        <p>
          No commission is paid to CandeFi by any Broker based on your trading activity. CandeFi does not endorse and is not endorsed by any Broker. CandeFi uses In-house and third-party software not endorsed by any of the Brokers and any action(s) done within this website has no bearing on the Brokers we support. Using CandeFi trading tools does not absolve you from the rules, fees and the terms and conditions of your brokerage. CandeFi reserves the right to partner or enter into agreement with any third party entity to grow or further its business.
        </p>
        <p>
          System response, trade executions and account access may be affected by market conditions, system performance, quote delays and other factors. The risk of loss in electronic trading can be substantial. You should therefore consider whether such trading is suitable for you in light of your financial resources and circumstances. Fees and commission rates can change at the sole discretion of CandeFi. Other fees and conditions may apply.
        </p>
        <h4>
          CandeFi Disclaimers:
        </h4>
        <ul>
          <li>
            Third-Party Publishers, Trade Analysts, Brokers and Analysts in our Marketplace are unaffiliated companies and are not responsible for each other's products and services.
          </li>
          <li>
            Market Research, Tools, and Opinions are Provided by Third Party Independent Providers.
          </li>
          <li>
            Using CandeFi trading tools does not absolve you from the Rules, Fees and the Terms and Conditions of your Brokerage
          </li>
          <li>
            We reserve the right to debit your account for any trading or platform fees without prior notice.
          </li>
          <li>
            CandeFi Services are not a solicitation or offer to buy or sell any financial products, and the Services are not intended to provide money management advice or services
          </li>
          <h5>No Recommendations or Investment Advice:</h5>
          <p>
            Under no circumstances should any information or materials presented on this Site be used or construed as an offer to sell, or a solicitation of an offer to buy, any securities, financial instruments, investments, or other services. CandeFi does not provide recommendations or offer investment advice of any kind. You are solely responsible for evaluating the merits and risks associated with the use of any content provided through the Service (generated by our users, third-party providers, or other third-party websites or services) before making any decisions based on such content. You agree not to hold CandeFi or its third-party providers liable for any possible claim for damages arising from any decision you make based on the content or other information made available to you through the Service or third-party provider websites. Past performance data should not be construed as indicative of future results.
          </p>
          <h5>Indemnification:</h5>
          <p>
            You agree to defend, indemnify and hold harmless CandeFi and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms or c) Content posted on the Service.
          </p>
          <h5>Limitation of Liability:</h5>
          <p>
            In no event shall CandeFi nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
          </p>
          <h5>
            Service Provided “AS IS” and “AS AVAILABLE” Disclaimer:
          </h5>
          <p>
            Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance. CandeFi - its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure, or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components, or d) the results of using the Service will meet your requirements.
          </p>
        </ul>
      </Typography>
    </div>
  </div>
}