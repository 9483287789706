import { Button, Card, Result } from "antd";
import { Link } from "react-router-dom";
import { ErrorMessage } from "../../shared/components/ErrorMessage";
import StripePayment from "../../stripe/components/StripePayment";
import { usePurchaseMembership } from "../hooks/usePurchaseMembership";

export const PurchaseForm = ({ planId }: { planId: number; }) => {
  const {
    response, submitting, error
  } = usePurchaseMembership(Number(planId));

  const redirectUrl = `${process.env.REACT_APP_CLIENT_URL}/purchase-membership-payment-status`;

  return <Card loading={submitting}>
    <>
      {
        error && <ErrorMessage error={error} />
      }
      {response != null && (
        <div style={{ marginTop: 10 }}>
          {
            response.requires_payment && response.client_secret !== null && (
              <StripePayment
                redirectUrl={redirectUrl}
                clientSecret={response.client_secret} />
            )
          }
          {
            !response.requires_payment && (
              <Result
                status={"success"}
                title={"Congratulations!"}
                subTitle={'Memebership purchased'}
                extra={[
                  <Link to="/app/dashboard" replace>
                    <Button type="primary" key="console">
                      Go Dashboard
                    </Button>,
                  </Link>
                ]}
              />
            )
          }
        </div>
      )}
    </>
  </Card>;
};
