import { AssetType, Side } from "../../api/enums";
import { OpenPosition } from "../../api/positions";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";
import { StockFormInitialValues } from "../../trade/hooks/useStocksForm";
import { OptionFormInitialValues } from "../../trade/hooks/useOptionsForm";

export const getClosePositionFormValues = (position: OpenPosition): PlaceOrderFormInitialValues | null => {

  if (position.asset_type === AssetType.Stocks) {
    const initialValues: StockFormInitialValues = {
      stock: position.short_name,
      side: position.side === Side.Buy ? Side.Sell : Side.Buy,
      quantity: position.quantity,
    };
    return initialValues;
  }

  if (position.asset_type === AssetType.Options) {
    const initialValues: OptionFormInitialValues = {
      stock: position.short_name,
      direction: position.options_direction || undefined,
      side: position.side === Side.Buy ? Side.Sell : Side.Buy,
      quantity: position.quantity,
      expirationDate: position.options_expiration_date || undefined,
      strikePrice: position.options_strike_price || undefined,
    };
    return initialValues;
  }

  return null;
};
