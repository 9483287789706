import { useState } from "react";
import api from "../../api";

export const useResumeMembership = (membershipId: number) => {
  const [isResuming, setIsResuming] = useState(false);

  const resume = async () => {
    setIsResuming(true);
    try {
      await api.membership.resumeMembership(membershipId);
    } finally {
      setIsResuming(false);
    }
  };

  return {
    resume,
    isResuming,
  };
};
