import { InputNumber, Spin } from "antd"
import { DatabaseOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;


export const QuantityInput = ({
  value,
  setValue,
  precision = 0,
  disabled = false,
  loading = false,
} : {
  value: number | null,
  setValue: (value : number | null) => void,
  precision?: number,
  disabled?: boolean,
  loading?: boolean
}) => {
  return <InputNumber
    addonBefore={<DatabaseOutlined/>}
    style={{ display: 'block', width: '100%' }}
    placeholder="Quantity"
    value={value}
    onChange={(value) => setValue(value)}
    min={1}
    precision={precision}
    disabled={disabled}
    addonAfter={
      loading 
        ? <Spin indicator={antIcon} />
        : undefined
    }
  />
}