import { Button, Dropdown, Tag } from 'antd';
import { WatchlistItem as WatchlistItemSchema } from '../../api/watchlist';
import { useWatchList } from '../context/watchlist-context';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './watchlist.module.scss';
import ButtonGroup from 'antd/es/button/button-group';
import { useState } from 'react';

export const WatchlistItem = ({
  item,
} :{
  item: WatchlistItemSchema,
}) => {
  const { select, selected, remove } = useWatchList();
  const isSelected = selected?.symbol === item.symbol;
  const [removing, setRemoving] = useState(false);

  const selectItem = () => {
    select(item.symbol);
  }

  const removeItem = async () => {
    try {
      setRemoving(true)
      await remove(item.symbol)
    } finally {
      setRemoving(false);
    }
  }

  return <ButtonGroup className={styles.group}>
    <Button
      onClick={selectItem}
      type={isSelected ? 'primary' : 'default'}
      className={styles.selectButton}  
    > 
      { item.symbol } 
    </Button>
    <Button
      className={styles.removeButton}
      onClick={removeItem}
      loading={removing}
      disabled={removing}
      icon={<DeleteOutlined/>} 
      type='default'
    />
  </ButtonGroup>
}