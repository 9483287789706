import axios from "./axios";
import { BotAction, BotAssetType, BotEnforce, BotOrderType, OrderType, Side, TimeInForce } from "./enums";
import { DBOrder } from "./orders";
import { ListRequest, PaginatedResponse } from "./types";

export class ListBotAlertRequest extends ListRequest {
  max_age_days?: number
}

export type BotAlertError = {
  error?: string
}

export type OptionsAlertCalculatedParams = {
  order_type: OrderType
  time_in_force: TimeInForce
  limit_price: number | null
  stop_price: number | null
  premium: number | null
  quantity: number
  ticker_symbol: string
  strike_price: number
  expiration_date: string
  side: Side
}

export type  StockAlertCalculatedParams = {
  ticker_symbol: string
  order_type: OrderType
  side: Side
  time_in_force: TimeInForce
  quantity: number
  limit_price: number | null
  stop_price: number | null
  mid_price: number | null
  per_unit_price: number
}

export type AutotradeBotBasic = {
  id: number
  name: string | null
}

export type BotAlertWithOrders = {
  id: number
  bot: AutotradeBotBasic
  asset_type: BotAssetType
  asset_name: string
  action: BotAction
  order_type: BotOrderType
  enforce: BotEnforce
  price: number | null
  stop_price: number | null
  strike_price: number | null
  quantity: number | null
  expiration_date: string | null
  calculated_at: string | null
  executed_at: string | null
  created_at: string | null
  updated_at: string | null
  orders: DBOrder[]
  errors: BotAlertError[] | null
  parameters: (StockAlertCalculatedParams | OptionsAlertCalculatedParams)[] | null
}

const autotradeAlertsApi = {
  async list(request: ListBotAlertRequest) {
    const { data } = await axios.get<PaginatedResponse<BotAlertWithOrders>>(
      '/autotrade-alerts', 
      { params: request, needsBrokerId: true }
    );
    return data;
  }
}

export default autotradeAlertsApi;