import { PoweroffOutlined, SettingOutlined, UserOutlined, LoginOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { logout } from "../../auth/utils";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export const AccountDropdown = () => {

  const { user, initialized } = useAuthUser();

  const onClick = ({ key }: { key: string; }) => {
    if (key === 'logout') {
      logout();
    }
  };

  const items : MenuProps['items'] = [];

  if (initialized && user) {
    items.push({ key: 'my-account', label: <Link to={"/app/account"}>My Account</Link>, icon: <SettingOutlined /> })
    items.push({ key: 'membership', label: <Link to={"/app/account/candefi-memberships"}>My Membership</Link>, icon: <TeamOutlined/> });
    items.push({ key: 'logout', label: 'Logout', icon: <PoweroffOutlined /> });
  }

  if (initialized && user === null) {
    items.push({ key: 'login', label: <Link to={'/auth/login'}>Login</Link>, icon: <LoginOutlined /> });
  }

  return <Dropdown menu={{ onClick, items }}>
    <Button
      size="large"
      type="text"
      icon={<UserOutlined />} />
  </Dropdown>;
};
