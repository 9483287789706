import { useEffect, useState } from "react";
import api from "../../api";
import { PurchaseMembershipResponse } from "../../api/membership";

export const usePurchaseMembership = (planId: number) => {

  const [
    response, setResponse
  ] = useState<PurchaseMembershipResponse | null>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {

    const purchaseMembership = async () => {
      try {
        setError(null);
        setSubmitting(true);
  
        const response = await api.membership.purchaseMembership({
          plan_id: Number(planId)
        });
  
        if (response) {
          setResponse(response);
        }

      } catch(e) {

        setError(e);

      } finally {
        setSubmitting(false);
      }
      
    };

    purchaseMembership();
  }, [planId]);

  return {
    response,
    submitting,
    error,
  };
};
