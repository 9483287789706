import { Card, Switch } from "antd";
import { useShareTradeContext } from "../../trade/context/share-trade-context"

export const ShareTradeCard = () => {
  const { share, setShare, canShare } = useShareTradeContext();

  if (!canShare) {
    return null;
  }

  return <Card>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span>Share Trade</span>
      <Switch
        checked={share}
        onChange={(checked) => setShare(checked)}
      />
    </div>
  </Card>
}