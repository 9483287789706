import { Space, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export function FeatureRow({ included, text } : { included: boolean, text: string }) {
  return <div style={{ display: 'flex', gap: '1rem', fontSize: '1rem', padding: '0.875rem 0' }} >
    {
      included 
        ? (
          <Typography.Text style={{ fontSize: '1rem' }} type="success">
            <CheckCircleOutlined />
          </Typography.Text>
        )
        : (
          <Typography.Text style={{ fontSize: '1rem' }} type="danger">
            <CloseCircleOutlined />
          </Typography.Text>
        )
    }
    <div>
      { text }
    </div>
  </div>
}