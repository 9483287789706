import { tab } from '@testing-library/user-event/dist/tab';
import { useEffect, useRef, useCallback } from 'react';
import { RESPONSIVE_TABLE_BREAKPOINT } from '../constants';

const useResponsiveTableInfiniteScroll = (
  loadMore: () => void | Promise<void>, 
  isLoadingMore: boolean,
  hasMore: boolean,
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!containerRef.current || isLoadingMore || !hasMore) return;

    const scrollDirection = containerRef.current.clientWidth < RESPONSIVE_TABLE_BREAKPOINT
    ? 'horizontal'
    : 'vertical';

  
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = containerRef.current;

    if (
      (scrollDirection === 'vertical' && scrollTop + clientHeight >= scrollHeight - 10) ||
      (scrollDirection === 'horizontal' && scrollLeft + clientWidth >= scrollWidth - 10)
    ) {
      if (isLoadingMore) {
        return;
      }
      loadMore(); // Call the function to load more data
    }
  }, [isLoadingMore, loadMore, hasMore]);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener('scroll', handleScroll);
    }
    // const tableElement = containerElement?.querySelector("table");
    // if (tableElement) {
    //   tableElement.addEventListener("scroll", handleScroll);
    // }

    // Cleanup on unmount
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
      // if (tableElement) {
      //   tableElement.removeEventListener('scroll', handleScroll);
      // }
    };
  }, [handleScroll]);

  return containerRef;
};

export default useResponsiveTableInfiniteScroll;