import { useEffect, useMemo, useState } from "react";
import api from "../../api";
import {
  OrderType,
  Side,
  TimeInForce,
  TrailType
} from "../../api/enums";
import { PlaceStocksOrderRequest } from "../../api/orders";
import {
  stocksGetMidPrice,
  stocksGetQuantity,
  stocksGetSupportedOrderTypes,
  stocksGetSupportedSides,
  stocksGetSupportedTIFS,
  stocksGetSupportedTrailTypes,
  stocksLimitPriceRequired,
  stocksStopPriceRequired,
  stocksTrailRequired,
} from "../utils/stocks";
import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import useSWR from 'swr';
import { useShareTradeContext } from "../context/share-trade-context";
import { fixDecimalPlaces } from "../../shared/utils";

export interface StockFormInitialValues {
  stock?: string;
  side?: Side;
  quantity?: number;
  tif?: TimeInForce;
  limitPrice?: number;
  orderType?: OrderType;
  stopPrice?: number;
}

export const useStocksForm = (initialValues?: StockFormInitialValues) => {
  const [stock, setStock] = useState<string | null>(null);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Limit);
  const [side, setSide] = useState<Side | null>(Side.Buy);
  const [tif, setTif] = useState<TimeInForce>(TimeInForce.GoodTillCancelled);
  const [quantity, setQuantity] = useState<number | null>(1);
  const [limitPrice, setLimitPrice] = useState<number | null>(null);
  const [stopPrice, setStopPrice] = useState<number | null>(null);
  const [trailType, setTrailType] = useState<TrailType | null>(null);
  const [trailValue, setTrailValue] = useState<number | null>(null);
  const [outSideRegularTradingHours, setOutsideRegularTradingHours] =
    useState<boolean>(false);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  // which prices are required
  const limitRequired = stocksLimitPriceRequired(orderType);
  const stopRequired = stocksStopPriceRequired(orderType);
  const trailRequired = stocksTrailRequired(orderType);

  const { canShare, share } = useShareTradeContext();

  const {
    data: quote,
    isLoading: quoteLoading,
    isValidating: quoteValidating,
  } = useSWR(
    (stock !== null) && limitRequired
      ? ['stocks', stock]
      : null,
    (key) => {
      return api.stocksQuote.getQuote({ ticker_symbol: key[1] })
    }
  )

  const limitPriceLoading = quoteLoading || quoteValidating;
  
  const { broker } = useSelectedBroker();
  
  const { user } = useAuthUser();
  
  const tifOptions = useMemo(() => {
    return stocksGetSupportedTIFS(broker?.account?.type, orderType)
  }, [broker?.account?.type, orderType]);

  const showTif = orderType !== OrderType.Market;

  useEffect(() => {
    if (!initialValues) {
      return;
    }

    if (initialValues.stock) {
      setStock(initialValues.stock);
    }

    if (initialValues.side) {
      setSide(initialValues.side);
    }

    if (initialValues.quantity) {
      setQuantity(initialValues.quantity);
    }

    if (initialValues.tif) {
      setTif(initialValues.tif);
    }

    if (initialValues.limitPrice) {
      setLimitPrice(fixDecimalPlaces(initialValues.limitPrice));
    }

    if (initialValues.orderType) {
      setOrderType(initialValues.orderType);
    }

    if (initialValues.stopPrice) {
      setStopPrice(initialValues.stopPrice);
    }

  }, [initialValues]);

  // get defaults from trade preference
  useEffect(() => {
    if (user?.trade_preference) {
      if (
        initialValues?.quantity === undefined 
        && user.trade_preference.default_quantity !== undefined
      ) {
        setQuantity(user.trade_preference.default_quantity);
      }
      setStopPrice(user.trade_preference.stop_loss);
    }
  }, [user?.trade_preference, initialValues]);

  // set order type according to trade preference
  useEffect(() => {
      if (
        initialValues?.orderType === undefined &&
        user?.trade_preference?.order_type_preference !== undefined &&
        user?.trade_preference?.order_type_preference !== null
      ) {
        setOrderType(user.trade_preference.order_type_preference);
      }
  }, [user?.trade_preference?.order_type_preference, initialValues?.orderType]);

  // prefill quantity from trade preference
  useEffect(() => {
    const budget = user?.trade_preference?.max_amount || null;
    const prefilledQuantity = initialValues?.quantity || null;

    // if quote is there, and you set max_amount in trade prefs
    // and you dont have quantity in initial values
    if (budget !== null
      && prefilledQuantity === null
      && limitPrice !== null
      && limitPrice > 0
    ) {
      const quantity = stocksGetQuantity(limitPrice, budget);
      setQuantity(quantity);
    }
  }, [user?.trade_preference, limitPrice, initialValues]);

  // perfill time in force from trade preference
  useEffect(() => {
    if (user?.trade_preference?.time_in_force !== undefined
      && tifOptions.includes(user?.trade_preference?.time_in_force)
    ) {
      setTif(user?.trade_preference?.time_in_force);
    }
  }, [user?.trade_preference, tifOptions, initialValues]);

  // prefill limit price with mid price
  useEffect(() => {
    // quote not available
    if (quote === null || quote === undefined) {
      return
    }

    // form was opened with prefilled limitPrice so dont update limit price
    const prefilledLimitPrice = initialValues?.limitPrice !== undefined;
    if (prefilledLimitPrice) {
      return
    }

    // update limit price with mid price
    let midPrice = fixDecimalPlaces(stocksGetMidPrice(quote));
    setLimitPrice(midPrice);

  }, [ quote, initialValues?.limitPrice ]);

  // reset limit price
  useEffect(() => {
    if (!limitRequired) {
      setLimitPrice(null);
    }
  }, [limitRequired]);

  // reset stop price
  useEffect(() => {
    if (!stopRequired) {
      setStopPrice(null);
    }
  }, [stopRequired]);

  // set initial values and reset trail price
  useEffect(() => {
    if (!trailRequired) {
      setTrailType(null);
      setTrailValue(null);
    } else {
      setTrailType(TrailType.Dollar);
      setTrailValue(0);
    }
  }, [trailRequired]);

  useEffect(() => {
    if (tifOptions.length === 1 && tif !== tifOptions[0]) {
      setTif(tifOptions[0]);
    } 
  }, [tifOptions, tif]);

  const validate = () => {
    setError(null);
    if (!side) {
      setError("Side is required");
      return false;
    }
    if (!stock) {
      setError("Stock is required");
      return false;
    }
    if (!orderType) {
      setError("Order Type is required");
      return false;
    }
    if (!quantity) {
      setError("Quantity is required");
      return false;
    }
    if (limitRequired && !limitPrice) {
      setError("Limit Price is required");
      return false;
    }
    if (stopRequired && !stopPrice) {
      setError("Stop Price is required");
      return false;
    }
    if (trailRequired && !trailType) {
      setError("Trail Type is required");
      return false;
    }
    if (trailRequired && !trailValue) {
      setError("Trail Value is required");
      return false;
    }
    if (!tif) {
      setError("Time in force is required");
      return false;
    }
    return true;
  };

  // show confirmation dialog when submit button is clicked
  const handleSubmitClick = () => {
    const valid = validate();
    if (!valid) {
      return;
    }
    setShowConfirmation(true);
  };

  // submit the form when confirm button is clicked
  const handleConfirmClick = async () => {
    // hide confirmation dialog
    setShowConfirmation(false);

    const valid = validate();
    if (!valid) {
      return;
    }

    setSubmitting(true);
    try {
      const requestPayload: PlaceStocksOrderRequest = {
        order_type: orderType,
        quantity: quantity!,
        side: side!,
        ticker_symbol: stock!,
        time_in_force: tif,
        limit_price: limitPrice ? limitPrice.toString() : undefined,
        stop_price: stopPrice ? stopPrice.toString() : undefined,
        trail_value: trailValue ? trailValue.toString() : undefined,
        trail_type: trailType ? trailType : undefined,
        ouside_regular_trading_hour: outSideRegularTradingHours,
        share: canShare && share,
      };
      return await api.orders.placeStockOrder(requestPayload);
    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    // form fields
    stock,
    setStock,
    orderType,
    setOrderType,
    side,
    setSide,
    tif,
    setTif,
    quantity,
    setQuantity,
    limitPrice,
    setLimitPrice,
    stopPrice,
    setStopPrice,
    trailType,
    setTrailType,
    trailValue,
    setTrailValue,
    outSideRegularTradingHours,
    setOutsideRegularTradingHours,

    // required fields related state
    limitRequired,
    stopRequired,
    trailRequired,
    showTif,

    // form related state
    showConfirmation,
    setShowConfirmation,
    handleSubmitClick,
    handleConfirmClick,
    submitting,
    error,

    limitPriceLoading,

    // options for select
    options: {
      orderTypes: {
        data: stocksGetSupportedOrderTypes(broker?.account?.type),
        loading: false,
      },
      sides: {
        data: stocksGetSupportedSides(broker?.account?.type),
        loading: false,
      },
      timeInForces: {
        data: tifOptions,
        loading: false,
      },
      trailTypes: {
        data: stocksGetSupportedTrailTypes(broker?.account?.type),
        loading: false,
      },
    },
  };
};
