import axios from "./axios";
import { CentTokenResponse } from "./cent";

const autotradeCentTokenApi = {
  async getSubscriptionToken() {
    const { data } = await axios.post<CentTokenResponse>(
      '/autotrade-cent-tokens'
    );
    return data;
  },
}

export default autotradeCentTokenApi;