import { createContext, useContext, useState } from "react"
import { CandeFiMembershipPlanBasic, MyCandeFiMembership } from "../api/candefi-memberships"
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr';
import api from "../api";
import { checkCanDowngradeNow, DOWNGRADE_TIME_WINDOW_HOURS } from "./utils";

export type CandeFiMembershipsContextData = {
  plans: {
    loading: boolean,
    data?: CandeFiMembershipPlanBasic[],
  },
  myMembership: {
    loading: boolean,
    data?: MyCandeFiMembership | null,
  },
  onPurchaseClick: (plan: CandeFiMembershipPlanBasic) => void | Promise<void>,
  errorPopup: {
    open: boolean,
    message: string | null,
    onOpenChange: (open : boolean) => void,
  },
  checking: boolean,
  checkingPlanId: number | null,
}

const CandeFiMembershipsContext = createContext<CandeFiMembershipsContextData>({
  myMembership: {
    loading: true,
    data: undefined,
  },
  plans: {
    loading: true,
    data: undefined,
  },
  onPurchaseClick: (plan) => {},
  errorPopup: {
    open: false,
    message: null,
    onOpenChange: (o) => {}
  },
  checking: false,
  checkingPlanId: null,
});

export const CandeFiMembershipsContextProvider = ({ 
  children 
} : {
  children: React.ReactNode,
}) => {
  const navigate = useNavigate();

  const { 
    data: plans,
    isLoading: plansLoading,
  } = useSWR('/api/candefi-memberships/plans', api.candefiMemberships.getPlans);

  const { 
    data: myMembership,
    isLoading: myMembershipLoading,
  } = useSWR('/api/candefi-memberships/me', api.candefiMemberships.getMyMembership);


  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState<string | null>(null);
  const [checking, setChecking] = useState(false);
  const [checkingPlanId, setCheckingPlanId] = useState<number|null>(null);

  const onPurchaseClick = async (plan: CandeFiMembershipPlanBasic) => {
      setErrorPopupMessage(null);
      setErrorPopupOpen(false);
      setChecking(true);
      setCheckingPlanId(plan.id);      

      const currentPlan = myMembership?.plan || null;

      const isUpgrading = currentPlan === null
        || currentPlan.is_free
        || (plan.price || 0) >= (currentPlan.price || 0)
      
      const isDowngrading = !isUpgrading;
      
      if (isDowngrading && myMembership) {
        const canDowngrade = checkCanDowngradeNow(myMembership)
        if (!canDowngrade) {
          setErrorPopupMessage(`Downgrade can only be done ${DOWNGRADE_TIME_WINDOW_HOURS} hours before plan billing cycle end`);
          setErrorPopupOpen(true);
          setChecking(false);
          setCheckingPlanId(null);
          return
        }
      }

      // redirect to purchase page
      navigate(`/app/account/candefi-memberships/plans/${plan.id}/purchase`);
      return
  }

  const value : CandeFiMembershipsContextData = {
    myMembership: {
      loading: myMembershipLoading,
      data: myMembership,
    },
    plans: {
      loading: plansLoading,
      data: plans,
    },
    onPurchaseClick,
    errorPopup: {
      open: errorPopupOpen,
      onOpenChange: setErrorPopupOpen,
      message: errorPopupMessage,
    },
    checking,
    checkingPlanId,
  };

  return <CandeFiMembershipsContext.Provider value={value}>
    { children }
  </CandeFiMembershipsContext.Provider>
}

export const useCandeFiMembershipsContext = () => {
  return useContext(CandeFiMembershipsContext);
}