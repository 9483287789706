import { createContext, useContext, useState } from "react"
import { WatchlistItem } from "../../api/watchlist"
import useSWR from 'swr';
import api from "../../api";
import { useSelectedBroker } from "../../brokers/context/SelectedBrokerContext";
import useSWRMutation from 'swr/mutation'

export interface WatchlistContextState {
  selected: WatchlistItem | null,
  watchlist: {
    items: WatchlistItem[],
    loading: boolean,
    error: any,
  },
  add: (symbol: string) => Promise<void>,
  remove: (symbol: string) => Promise<void>,
  select: (symbol: string) => Promise<void>,
  clear: {
    isMutating: boolean,
    trigger: () => Promise<unknown>,
  },
  refresh: () => Promise<void>,
}

const WatchlistContext = createContext<WatchlistContextState>({
  selected: null,
  watchlist: {
    items: [],
    error: null,
    loading: false,
  },
  add: async (symbol: string) => {},
  remove: async (symbol: string) => {},
  clear: {
    isMutating: false,
    trigger: async () => {},
  },
  select: async () => {},
  refresh: async () => {}
});

export const WatchlistContextProvider = ({
  children
} : {
  children: React.ReactElement | undefined
}) => {

  const {
    broker
  } = useSelectedBroker()

  const {
    data: watchlist,
    isLoading,
    error,
    mutate
  } = useSWR('/api/watchlist', () => api.watchlist.list());

  const clearMutation = useSWRMutation('/api/watchlist', () => api.watchlist.deleteAll());

  const [selected, setSelected] = useState<WatchlistItem|null>(null);

  const add = async (symbol: string) => {
    if (!broker) {
      throw Error('broker not selected');
    }
    await api.watchlist.add({ symbol });
    mutate()
  }

  const remove = async (symbol: string) => {
    await api.watchlist.delete(symbol);
    mutate();
  }

  const select = async (symbol: string) => {
    const item = (watchlist || []).find(item => item.symbol === symbol);
    if (!item) {
      throw Error('symbol not in watchlist');
    }
    setSelected(item);
  }

  const state : WatchlistContextState = {
    add,
    remove,
    refresh: async () => {
      mutate()
    },
    clear: {
      trigger: clearMutation.trigger,
      isMutating: clearMutation.isMutating,
    },
    select,
    selected,
    watchlist: {
      items: watchlist || [],
      error: error,
      loading: isLoading,
    }
  }

  return <WatchlistContext.Provider value={state}>
    { children }
  </WatchlistContext.Provider>
}

export const useWatchList = () => useContext(WatchlistContext)