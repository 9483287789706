import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { Guest } from "./auth/components/Guest";
import { Protected } from "./auth/components/Protected";
import { AuthLayout } from "./auth/layouts/AuthLayout";
import { Login } from "./auth/pages/Login";
import { ResetPassword } from "./auth/pages/ResetPassword";
import { Signup } from "./auth/pages/Signup";
import { Dashboard } from "./dashboard/pages/Dashboard";
import { AppLayout } from "./shared/layouts/AppLayout";
import { WebullConnectLayout } from "./webull_connect/layouts/WebullConnectLayout";
import { WebullConnect } from "./webull_connect/pages/WebullConnect";
import { MarketPlacePage } from "./marketplace/pages/MarketPlace";
import { SignalProviderDetails } from "./signal-providers/pages/SignalProviderDetails";
import PurchaseMembershipPage from "./membership/pages/PurchaseMembershipPage";
import AccountLayout from "./account/layouts/AccountLayout";
import { TradePreference } from "./account/pages/TradePreference";
import { SignalProviderRedirector } from "./account/pages/SignalProviderRedirector";
import { MyMemberships } from "./account/pages/MyMemberships";
import { SignalProviderRegister } from "./account/pages/SignalProviderRegister";
import SignalProviderMembershipPlans from "./account/pages/SignalProviderMembershipPlans";
import PurchaseMembershipPaymentStatusPage from "./membership/pages/PurchaseMembershipPaymentStatus";
import RechargePaymentStatusPage from "./recharge/pages/RechargePaymentStatusPage";
import { NotFound } from "./shared/pages/NotFound";
import { AccountInfo } from "./account/pages/AccountInfo";
import { ContactInquiry } from "./account/pages/ContactInquiry";
import { RobinhoodConnect } from "./robinhood-connect/pages/RobinhoodConnect";
import { SignalProviderLayout } from "./signal-providers/layout/SignalProviderLayout";
import { VerifyEmail } from "./auth/pages/VerifyEmail";
import { PrivacyPolicy } from "./static-pages/pages/PrivacyPolicy";
import { StaticPagesLayout } from "./static-pages/layout/StaticPagesLayout";
import { TermsOfUse } from "./static-pages/pages/TermsOfUse";
import SignalProviderMembershipPlanDetail from "./account/pages/SignalProviderMembershipPlanDetail";
import { SignalProviderEarningStats } from "./account/pages/SignalProviderEarningStats";
import { AnalystApplication } from "./account/pages/AnalystApplication";
import { AnalystApplicationFormPage } from "./account/pages/AnalystApplicationFormPage";
import { ReferralPage } from "./account/pages/ReferralPage";
import { AutotradePage } from "./autotrade/pages/autotrade";
import { AutotradePreference } from './account/pages/AutotradePreference';
import { CandeFiMembership } from './account/pages/CandefiMembership';
import { PurchaseCandeFiMembershipPage } from "./candefi-memberships/pages/PurchaseCandeFiMembershipPage";
import PurchaseCandeFiMembershipPaymentStatusPage from "./candefi-memberships/pages/PurchaseCandeFiMembershipPaymentStatus";
import { TradeProfiler } from "./account/pages/TradeProfiler";
import { BrokerConnections } from "./brokers/pages/BrokerConnections";
import { BuyCoins } from "./buy-coins/pages/buy-coins";
import { PageContainer } from "./shared/components/PageContainer";
import { SignupWithReferral } from "./auth/pages/SignupWithReferral";
import { Impersonate } from "./impersonate/pages/impersonate";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to='/app/dashboard' replace={true} />,
  },
  {
    path: '/impersonate/:token',
    element: <Impersonate />
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: (
          <Guest>
            <Login />
          </Guest>
        ),
      },
      {
        path: "sign-up",
        children: [
          { 
            path: '', 
            element:  <Guest>
              <Signup />
            </Guest>
          },
        ],
      },
      {
        path: "reset-password",
        element: (
          <Guest>
            <ResetPassword />
          </Guest>
        ),
      },
      {
        path: "verify-email",
        element: (
          <VerifyEmail />
        ),
      },
    ],
  },
  {
    path: '/ref',
    element: <AuthLayout />,
    children: [
      {
        path: ':referralCode',
        element: (
          <Guest>
            <SignupWithReferral />
          </Guest>
        )
      }
    ]
  },
  {
    path: '/app/signal-providers/:username',
    element: <SignalProviderLayout/>,
    children: [
      {
        path: '',
        element: <SignalProviderDetails />
      }
    ]
  },
  {
    path: "/app",
    element: <AppLayout />,
    children: [
      {
        path: "dashboard",
        element: (
          <Protected>
            <Dashboard />
          </Protected>
        ),
      },
      {
        path: "autotrade",
        element: (
          <Protected>
            <AutotradePage />
          </Protected>
        ),
      },
      {
        path: 'broker-connections',
        element: (
          <Protected>
            <BrokerConnections />
          </Protected>
        )
      },
      {
        path: "marketplace",
        element: (
          <Protected>
            <MarketPlacePage />
          </Protected>
        ),
      },
      {
        path: "buy-coins",
        element: (
          <Protected>
            <BuyCoins />
          </Protected>
        ),
      },
      {
        path: "signal-providers/:username/plans/:planId/purchase",
        element: (
          <Protected>
            <PurchaseMembershipPage />
          </Protected>
        ),
      },
      {
        path: 'account/analyst',
        element: <PageContainer>
          <Outlet />
        </PageContainer>,
        children: [
          {
            path: '',
            element: (
              <Protected>
                <SignalProviderRedirector />
              </Protected>
            )
          },
          {
            path: "register",
            element: (
              <Protected>
                <SignalProviderRegister />
              </Protected>
            ),
          },
          {
            path: "plans",
            element: (
              <Protected>
                <SignalProviderMembershipPlans />
              </Protected>
            ),
          },
          {
            path: "plans/:planId",
            element: (
              <Protected>
                <SignalProviderMembershipPlanDetail />
              </Protected>
            ),
          },
          {
            path: "stats",
            element: (
              <Protected>
                <SignalProviderEarningStats />
              </Protected>
            ),
          },
        ]
      },
      {
        path: "account",
        element: (
          <Protected>
            <AccountLayout />
          </Protected>
        ),
        children: [
          {
            path: "brokers",
            element: (
              <Protected>
                <BrokerConnections />
              </Protected>
            ),
          },
          {
            path: "referral",
            element: (
              <Protected>
                <ReferralPage />
              </Protected>
            ),
          },
          {
            path: "memberships",
            element: (
              <Protected>
                <MyMemberships />
              </Protected>
            ),
          },
          {
            path: "trade-preference",
            element: (
              <Protected>
                <TradePreference />
              </Protected>
            ),
          },
          {
            path: "autotrade-preference",
            element: (
              <Protected>
                <AutotradePreference />
              </Protected>
            ),
          },
          {
            path: "info",
            element: (
              <Protected>
                <AccountInfo />
              </Protected>
            ),
          },
          {
            path: "contact-us",
            element: (
              <Protected>
                <ContactInquiry />
              </Protected>
            ),
          },
          {
            path: "analyst-application",
            element: (
              <Protected>
                <AnalystApplication />
              </Protected>
            ),
          },
          {
            path: "analyst-application/form",
            element: (
              <Protected>
                <AnalystApplicationFormPage />
              </Protected>
            ),
          },
          {
            path: 'candefi-memberships',
            element: (
              <Protected>
                <CandeFiMembership />
              </Protected>
            )
          },
          {
            path: 'candefi-memberships/plans/:planId/purchase',
            element: (
              <Protected>
                <PurchaseCandeFiMembershipPage />
              </Protected>
            )
          },
          {
            path: 'trade-profiler',
            element: (
              <Protected>
                <TradeProfiler />
              </Protected>
            )
          }
          // {
          //   path: "analyst",
          //   element: (
          //     <Protected>
          //       <SignalProviderRedirector />
          //     </Protected>
          //   ),
          // },
          // {
          //   path: "analyst/register",
          //   element: (
          //     <Protected>
          //       <SignalProviderRegister />
          //     </Protected>
          //   ),
          // },
          // {
          //   path: "analyst/plans",
          //   element: (
          //     <Protected>
          //       <SignalProviderMembershipPlans />
          //     </Protected>
          //   ),
          // },
          // {
          //   path: "analyst/plans/:planId",
          //   element: (
          //     <Protected>
          //       <SignalProviderMembershipPlanDetail />
          //     </Protected>
          //   ),
          // },
        ],
      },
    ],
  },
  {
    path: "/purchase-membership-payment-status",
    element: <PurchaseMembershipPaymentStatusPage />,
  },
  {
    path: '/purchase-candefi-membership-payment-status',
    element: <PurchaseCandeFiMembershipPaymentStatusPage />
  },
  {
    path: "/recharge-payment-status",
    element: <RechargePaymentStatusPage />,
  },
  {
    path: "/webull-connect",
    element: <WebullConnectLayout />,
    children: [
      {
        path: "",
        element: (
          <Protected>
            <WebullConnect />
          </Protected>
        ),
      },
    ],
  },
  {
    path: "/robinhood-connect",
    element: (
      <Protected>
        <RobinhoodConnect />
      </Protected>
    ),
  },
  {
    path: "",
    element: (
      <StaticPagesLayout />
    ),
    children: [
      { path: '/privacy-policy', element: <PrivacyPolicy /> },
      { path: '/terms-of-use', element: <TermsOfUse /> },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
]);
