import { Button, Layout, Result } from "antd"
import { Link } from "react-router-dom"
import { LargeLogo } from "../components/Logo"

export const NotFound = () => {
  return <Layout>
    <Layout.Content style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100vh', 
      justifyContent: 'center' 
    }}>
      <LargeLogo />
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to={'/app/dashboard'}>
            <Button 
              size="large"
              type="primary">
                Go to dashboard
            </Button>
          </Link>
        }
      />
    </Layout.Content>
  </Layout>
}