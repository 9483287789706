import { Descriptions, Typography } from "antd";
import { fixDecimalPlaces, formatMoneyAuto, formatNumberAuto } from "../../shared/utils";
import { FeeResponse } from "../../api/fees";

const { Title, Paragraph, Text } = Typography

export function FeesExplantion({ fee }: { fee: FeeResponse; }) {
  const stripPerTransactionFee = 2.9;
  const stripePerPayoutFee = 0.3;
  const stripeFee = stripPerTransactionFee + stripePerPayoutFee;
  const totalFeePrecent = fee.subscription;
  const paymentAmount = 15;
  const analystPercent = 100 - totalFeePrecent;
  const analystAmount = (analystPercent / 100) * paymentAmount;
  const stripeAmount = (stripPerTransactionFee / 100) * paymentAmount + stripePerPayoutFee;
  const candeFiAmount = paymentAmount - analystAmount - stripeAmount;

  return <>
    <Title level={5}>
      {formatNumberAuto(fixDecimalPlaces(totalFeePrecent))}% Platform & Stripe Fees
    </Title>
    <Paragraph>
      <Text style={{ marginBottom:'1rem', display: 'inline-block' }}>Example</Text>
      <Descriptions size="small" bordered column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}>
        <Descriptions.Item label="Subscription amount">
          {formatMoneyAuto(fixDecimalPlaces(paymentAmount, 2))}
        </Descriptions.Item>
        <Descriptions.Item label="Analyst paid">
          {formatMoneyAuto(fixDecimalPlaces(analystAmount, 2))}
        </Descriptions.Item>
        <Descriptions.Item label="Stripe fee">
          {formatMoneyAuto(fixDecimalPlaces(stripeAmount, 2))}
        </Descriptions.Item>
        <Descriptions.Item label="CandeFi fee">
          {formatMoneyAuto(fixDecimalPlaces(candeFiAmount, 2))}
        </Descriptions.Item>
      </Descriptions>
    </Paragraph>
  </>;
}