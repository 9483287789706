import { Card, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../api/enums";
import { RegisterForm } from "../../signal-providers/components/RegisterForm";
import { RegisterIntro } from "../../signal-providers/components/RegisterIntro";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { useRefreshUser } from "../../auth/hooks/useRefreshUser";

export const SignalProviderRegister = () => {
  const { user } = useAuthUser();
  const refresh = useRefreshUser();
  const [ showIntro, setShowIntro ] = useState(true);
  const [ messageApi, contextHolder ] = message.useMessage();


  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.role !== UserRole.analyst) {
      return navigate('/app/dashboard', { replace: true });
    }
    if (user.signal_providers.length > 0) {
      return navigate('/app/account/analyst/plans', { replace: true });
    }
  }, [user]);

  return <Card title="Create Your Account" style={{ minHeight: '100%' }}>   
      { contextHolder }
      {
        showIntro && <RegisterIntro onFinish={() => setShowIntro(false)}/>
      }
      {
        !showIntro && <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RegisterForm onSuccess={() => {
            refresh();
            messageApi.success('Created your account');
            navigate('/app/account/analyst');
          }}/>
        </div>
      }
    </Card>
}