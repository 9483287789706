import { Modal, Result } from "antd"
import { ChangeBotPasswordForm } from "./change-bot-password-form"
import { useEffect, useState } from "react"

export function ChangeBotPasswordModal({
  open,
  onClose,
  botId,
  onSuccess,
} : {
  open: boolean,
  onClose: () => void,
  botId: number,
  onSuccess?: () => void,
}) {

  const [success, setSuccess] = useState(false);
  
  useEffect(() => {
    if (open) {
      setSuccess(false);
    }
  }, [open]);

  return <Modal 
    open={open} 
    onCancel={onClose}
    footer={null}
  >
    {
      !success && (
        <ChangeBotPasswordForm
          botId={botId}
          onSuccess={() => {
            setSuccess(true);
            if (onSuccess) {
              onSuccess();
            }
          }}
        />
      )
    }
    {
      success && (
        <Result
          status={'success'}
          title='Changed Bot Password'
        />
      )
    }
  </Modal>
}