import endOfMonth from "date-fns/endOfMonth";
import { EarningsItem } from "../api/analyst-earnings";

export type EarningsByPlanId = Record<number, EarningsItem[]>

export type ChartEarnings = {
  date: Date;
  earning: number;
  plan: string;
}

export function transformApiResponse(
  startDate: Date,
  endDate: Date,
  response : Array<EarningsItem>
) : EarningsByPlanId {

  const byPlan : Record<number, EarningsItem[]> = {};

  response.forEach(item => {
    byPlan[item.plan.id] = byPlan[item.plan.id] || [];
    byPlan[item.plan.id].push({
      ...item,
      month: item.month - 1,
    });
  });

  const months = getMonthsBetween(startDate, endDate);

  Object.entries(byPlan).forEach(entry => {
    let [groupKey, groupEarnings] = entry;
    groupEarnings = addMissingMonthsEarnings(months, groupEarnings); 
    byPlan[Number(groupKey)] = groupEarnings;
  });

  return byPlan;
}

export function toLineChartData(data : EarningsByPlanId) {
  const results : ChartEarnings[] = [];

  Object.values(data).forEach((earnings) => {
    earnings.forEach(earning => {

      let date = endOfMonth(new Date(earning.year, earning.month));

      results.push({
        date: date,
        earning: earning.earnings,
        plan: earning.plan.name,
      });
    });
  });

  return results;
}

function addMissingMonthsEarnings(
  months : {
    month: number // jan is 0, 
    year: number
  }[],
  earnings: EarningsItem[],
) {

  let plan = earnings[0].plan;
  let results : EarningsItem[] = [];

  months.forEach(({ month, year }) => {
    let monthEarning = earnings.find(item => item.month === month && item.year === year);
    if (monthEarning === undefined) {
      monthEarning = {
        earnings: 0,
        month: month,
        plan: plan,
        year: year,
      }
    }
    results.push({...monthEarning});
  });

  return results;
}

function getMonthsBetween(startDate: Date, endDate: Date) {
  let months : { month: number, year: number }[] = [];

  let month = startDate.getMonth(); // 0-11
  let year = endDate.getFullYear();

  months.push({ month, year });

  while (month != endDate.getMonth() || year != endDate.getFullYear()) {
    if (month + 1 > 11) {
      month = 0
      year = year + 1;
    } else {
      month = month + 1;
    }  
    months.push({ month, year });
  }

  return months;
}

