import { Result } from "antd";
import { useEffect, useState } from "react";
import { useSelectedBroker } from "../context/SelectedBrokerContext";
import { ConnectBrokerModal } from "./ConnectBrokerModal";
import { SelectBrokerDropdown } from "./SelectBrokerDropdown";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { useRefreshUser } from "../../auth/hooks/useRefreshUser";
import { FirstTimeBrokerConnect } from "./FirstTimeBrokerConnect";

export const RequiresBroker = ({
  children
}: {
  children: React.ReactElement,
}) => {

  const { user } = useAuthUser();
  const refetchUser = useRefreshUser();

  const { broker } = useSelectedBroker();
  const [connectModalOpen, setConnectModalOpen] = useState(false);

  // refetch user
  // to refetch his broker accounts
  useEffect(() => {
    refetchUser();
  }, []);

  if (!user) {
    return null;
  }

  if (user.broker_accounts.length <= 0) {
    return <>
      <ConnectBrokerModal
        open={connectModalOpen}
        setOpen={setConnectModalOpen}
      />
      <FirstTimeBrokerConnect openModal={() => setConnectModalOpen(true)} />
    </>
  }

  if (!broker) {
    return <Result
      title="Select Broker Account"
      subTitle="Plese select a broker account to continue"
      extra={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SelectBrokerDropdown type="primary" />
        </div>
      }
    />
  }

  return children;
}