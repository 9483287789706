import { Button, Card, Typography } from "antd";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;

export const BecomeAnalystCard = () => {
  return <Card>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8, textAlign: 'center' }}>
      <Title level={3}>Become A Signal Provider!</Title>
      <Paragraph>
        {/* Run your exclusive trade signal business on CandeFi and monetize your trading expertise. Join our growing community of collaborative traders. */}
        Run your exclusive trade signal business on CandeFi. Leverage our cutting-edge technology to monetize your trading knowledge and skills.
      </Paragraph>
      <Link to='/app/account/analyst-application'>
        <Button type="primary" size="large">
          Learn More
        </Button>
      </Link>
      
    </div>
  </Card>;
};