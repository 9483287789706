import { BasicChatroom } from "../api/chat";

export function isGroupChatroom(chatroom: BasicChatroom) {
  return chatroom.dm_request_status === null
}

export function isDmChatroom(chatroom: BasicChatroom) {
  return !isGroupChatroom(chatroom);
}

export function dmGetOtherUser(chatroom: BasicChatroom, currentUserId: number) {
  const isDm = isDmChatroom(chatroom);
  return isDm 
    ? (
        chatroom.dm_request_sender!.id === (currentUserId).toString()
        ? chatroom.dm_request_recipient!
        : chatroom.dm_request_sender!
    )
    : null
}

export function getTitle(chatroom : BasicChatroom, currentUserId: number) {
  const title = isGroupChatroom(chatroom)
    ? chatroom.title
    : dmGetOtherUser(chatroom, currentUserId)?.name 
    
  return title || ''
}

export function getDMChatroomId(userAId: number, userBId: number) {
  const min = Math.min(userAId, userBId);
  const max = Math.max(userAId, userBId);
  return `dm_${min}_${max}`;
}