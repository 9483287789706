import { useEmotionCss } from "@ant-design/use-emotion-css";
import { Button, Popover, Typography } from "antd";
import { Link } from "react-router-dom";
import { RobotFilled, InfoCircleFilled } from "@ant-design/icons";

export function CreatedByBotIcon({ variant = 'table' } : { variant?: 'table' | 'chat' }) {
  const robotIconClass = useEmotionCss(({ theme, token }) => ({
    'color': {
      'table': token.colorPrimary,
      'chat': token.colorText,
    }[variant],
    'fontSize': 20,
  }));

  const popoverBgColor = {
    'table': 'blue',
    'chat': 'blue-inverse'
  }[variant];

  return <Popover color={popoverBgColor} content={<CreatedByBotPopupContent/>}> 
      <Typography.Text className={robotIconClass}>
        <RobotFilled  />
      </Typography.Text>
  </Popover>
}

function CreatedByBotPopupContent() {
  return <div>
    <span>Created by bot</span> 
    <Link to='/app/autotrade'>
      <Button type="ghost" size="small" shape="circle" icon={<InfoCircleFilled/>}></Button>
    </Link> 
  </div>
}