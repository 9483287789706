import { SortOrder } from 'antd/es/table/interface';
import axios from './axios';
import { BillingIntervalUnit } from './enums';
import { GenericIDResponse, ListRequest, PaginatedResponse } from './types';

export interface SignalProviderListItem {
  signal_provider: SignalProvider;
  options: boolean;
  stocks: boolean;
  starting_price: number;
}

export interface SignalProvider {
  id: number;
  username: string;
  name: string;
  type: string;
  bio: string;
}

export interface MembershipPlan {
  id: number;
  active: boolean;
  name: string;
  amount: number;
  billing_interval: number;
  billing_interval_unit: BillingIntervalUnit;
  billing_cycles: number;
  options: boolean;
  stocks: boolean;
}

export interface SignalProviderWithMembershipPlans {
  id: number;
  name: string;
  type: string;
  bio: string;
  membership_plans: MembershipPlan[];
  username: string
}

export interface CreateSignalProviderRequest {
  name: string;
  username: string;
  bio: string;
}

export enum SignalProviderListSortField {
  Name = 'name',
  Price = 'price',
}

export class ListSignalProvidersRequest extends ListRequest {
  options?: boolean
  stocks?: boolean
  sort_field?: SignalProviderListSortField;
  sort_order?: SortOrder;
}

const signalProvidersApi = {
  async index (request : ListSignalProvidersRequest) {
    const {data} = await axios.get<PaginatedResponse<SignalProviderListItem>>(
      "/signal-providers",
      { params: request }
    );
    return data;
  },

  async getSubscribedSignalProviders(){
    const {data} = await axios.get<SignalProvider[]>('/signal-providers/subscribed')
    return data
  },
  
  async getSignalProviderById(id: number){
    const {data} = await axios.get<SignalProviderWithMembershipPlans>(`/signal-providers/${id}`)
    return data
  },

  async findByUsername(username: string) {
    const { data } = await axios.get<SignalProviderWithMembershipPlans>(`/signal-providers/by-username/${username}`);
    return data;
  },

  async createSignalProvider(request: CreateSignalProviderRequest){
    const {data} = await axios.post<GenericIDResponse>('/signal-providers/individual', request)
    return data
  }

}

export default signalProvidersApi